import React from 'react'

const PositionSelect = ({list, getFilesTemplate}) => {
  return (
    <React.Fragment>
      <div className="row mb-4 fs-13px col-md-12">
        <label
          className="form-label col-form-label col-md-3 text-end"
        >
          Seleccionar plantilla
        </label>

        <div className="col-md-9">
          <select
            className={`form-select form-control fs-14px`}
            id="floatingSelect"
            name="warranty_type_id"
            style={{
              width: "100%",
              display: "block",
            }}
            onChange={(e) => getFilesTemplate(e.target.value)}
          >
            <option value="_">
              -- Selecciona un puesto --
            </option>
            {list.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PositionSelect