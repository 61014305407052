import * as yup from "yup";

export const ChangePostionSchema = yup.object().shape({
  idPosition: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idBranch: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  reason: yup.string().required("Campo requerido"),
});
