import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { BlackListExcel } from "../../models/ImportBlackList.ts";
import { ImportBlackListSchema } from "../../validation/schemas/ImportBlackListSchema.ts";
import { fillListaNegra } from "../../interfaces/formik/IListaNegra.ts";
import { setShowModalEditCurrentBlackList } from "../../store/slices/listaNegraSlice.ts";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import CustomTextArea from "../formik/CustomTextArea.tsx";

const ImportBlackListForm = ({
  initialValues,
  records,
  setRecords,
  currentIndex,
}: {
  initialValues: BlackListExcel;
  records: BlackListExcel[];
  setRecords: (records: BlackListExcel[]) => void;
  currentIndex: number;
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ImportBlackListSchema,
    initialValues: fillListaNegra(initialValues),
    onSubmit: async (values) => {
      const { curp, unbanDate, reason, isPermanent } = values;

      const newObject: BlackListExcel = new BlackListExcel({
        CURP: curp,
        RAZON: reason,
        ES_PERMANENTE: isPermanent ? "Si" : "No",
        FECHA_BANEO: isPermanent ? "" : unbanDate,
      });

      const newRecords = [...records];
      if (currentIndex === -1) {
        newRecords.push(newObject);
      } else {
        newRecords[currentIndex] = newObject;
      }
      setRecords(newRecords);
      dispatch(setShowModalEditCurrentBlackList(false));
    },
  });

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="curp"
        label="CURP"
        placeholder="La CURP del colaborador"
        sm={9}
      />

      <CustomTextArea
        formik={formik}
        field="reason"
        label="Razón"
        placeholder="La razón del baneo"
        sm={9}
      />
      {!formik.values.isPermanent && (
        <CustomInput
          formik={formik}
          field="unbanDate"
          label="Fecha de baneo"
          type="date"
          sm={9}
        />
      )}
      <CustomCheckboxField
        formik={formik}
        field="isPermanent"
        label="Permanente"
      />

      {/* <CustomInput
        formik={formik}
        field="isPermanent"
        label="Es permanente"
        placeholder="Si o No"
        sm={8}
        smLabel={4}
      /> */}
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-secondary me-2"
          onClick={() => dispatch(setShowModalEditCurrentBlackList(false))}
        >
          Cancelar
        </button>
        <button
          className="btn btn-blue"
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          Actualizar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ImportBlackListForm;
