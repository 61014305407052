import React from "react";

const FooterChili = ({ position = "fixed" }) => {
  return (
    <div
      className="w-1000px h-75px mt-4"
      style={{
        backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
        backgroundRepeat: "repeat-x",
        position: position,
        bottom: "0",
        width: "100%",
        zIndex: "100",
      }}
    />
  );
};

export default FooterChili;
