import httpClient from "./httpClient";

const prefix = "/positions";

export async function readPositions() {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function paginatePuestos(query: string) {
  return ( await httpClient.get( `${prefix}/list-paginated?${query}`)).data;
}

export async function updatePosition(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/${id}`, data)).data;
}

export async function deletePosition(id: number) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function readPosition(id: number) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}

export async function savePosition(data: any) {
  return (await httpClient.post(prefix, data)).data;
}

export async function readPositionBySocialName(id: number) {
  return (await httpClient.get(`${prefix}/get-by-social-name/${id}`)).data;
}
