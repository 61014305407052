import React, { useEffect } from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import ModalContainer from "../ModalContainer.tsx";
import AddResponsability from "./AddResponsabilitie.tsx";
import { readPositions } from "../../services/position.service.ts";

const ResponsabilityiesContract = ({
  formik,
  originalField,
}: IFormikProps & {
  originalField: string;
}) => {
  const error = formik.touched[originalField] && formik.errors[originalField];
  const [showModal, setShowModal] = React.useState(false);

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />
        <div className="col-md-10">
          <button
            type="button"
            className={`btn btn-outline-${error ? "danger" : "secondary"}`}
            onClick={() => setShowModal(true)}
          >
            <i className="fas fa-plus"></i> Agregar responsabilidad
          </button>
          <button
            type="button"
            className={`btn btn-outline-danger ms-2`}
            onClick={() => {
              formik.setFieldValue(originalField, []);
            }}
          >
            <i className="fas fa-trash"></i> Borrar todas las responsabilidades
          </button>

          <div
            className="invalid-feedback"
            style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
          >
            {formik.touched[originalField] && formik.errors[originalField] && (
              <>{formik.errors[originalField]}</>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />

        <div className="widget-list rounded">
          {formik.values[originalField]?.map((doc, index) => (
            <div className="widget-list-item" key={index}>
              <div className="widget-list-media icon">
                <a style={{ cursor: 'pointer' }}>
                  <i
                    className="fa fa-trash bg-yellow text-dark"
                    onClick={() => {
                      const updatedValues = formik.values[originalField].filter((item: any) => item !== doc);
                      formik.setFieldValue(originalField, updatedValues);
                    }}
                  ></i>
                </a>
              </div>
              <div className="widget-list-content">
                <h4 className="widget-list-title">
                  {doc}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Agregar responsabilidad"
        children={
          <AddResponsability
            formik={formik}
            closeFn={() => setShowModal(false)}
            originalField={originalField}
          />
        }
      />
    </React.Fragment>
  );
};

export default ResponsabilityiesContract;
