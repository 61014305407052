import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { setShowModalEditCurrentPartner } from "../../store/slices/colaboradoresSlice.ts";
import { PartnerExcel } from "../../models/ImportPartner.ts";
import { IDefaultArray } from "../../utils/index.ts";
import {
  ImportPartnerSchema,
  ImportPartnerSchemaLider,
} from "../../validation/schemas/ImportPartnerSchema.ts";
import { fillImportPartner } from "../../interfaces/formik/IImportPartner.ts";
import CustomInput from "../formik/CustomInput.tsx";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { RootState } from "../../store/index.ts";

const ImportPartnerForm = ({
  initialValues,
  branches,
  positions,
  sizes,
  periodicities,
  records,
  setRecords,
  currentIndex,
  shifts,
}: {
  initialValues: PartnerExcel;
  branches: IDefaultArray[];
  positions: IDefaultArray[];
  sizes: IDefaultArray[];
  periodicities: IDefaultArray[];
  records: PartnerExcel[];
  shifts: IDefaultArray[];
  setRecords: (records: PartnerExcel[]) => void;
  currentIndex: number;
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const formik = useFormik({
    validationSchema: user?.branch
      ? ImportPartnerSchemaLider
      : ImportPartnerSchema,
    initialValues: fillImportPartner({
      initialValues,
      branches,
      positions,
      sizes,
      periodicities,
      shifts,
    }),
    onSubmit: async (values) => {
      const findBranch = branches.find((b) => b.name === user?.branch);
      console.log("asdasds");
      console.log(findBranch);
      const { position, branch, size, shift } = values;

      const positionName = positions.find((p) => p.id === position)?.name;
      const branchName = branches.find((b) => b.id === branch)?.name;
      const sizeName = sizes.find((s) => s.id === size)?.name;
      // const periodicityName = periodicities.find(
      //   (p) => p.id === periodicity
      // )?.name;
      const shiftName = shifts.find((s) => s.id === shift)?.name;

      const newObject: PartnerExcel = new PartnerExcel({
        position: positionName ? positionName : "",
        branch: findBranch ? findBranch.name : branchName ? branchName : "",
        size: sizeName ? sizeName : "",
        // periodicity: periodicityName ? periodicityName : "",
        shift: shiftName ? shiftName : "",

        name: values.name,
        lastName: values.lastName,
        secondLastName: values.secondLastName,
        curp: values.curp,
        phone: values.phone,
        address: values.address,
        // monthlySalary: values.monthlySalary,

        birthdate: new Date(values.birthdate),
        // recruitmentDate: new Date(values.recruitmentDate),
        alergias: values.alergias,
        tipoSangre: values.tipoSangre,
        enfermedad: values.enfermedad,
        tratamiento: values.tratamiento,
      });

      const newRecords = [...records];
      if (currentIndex !== -1) {
        newRecords[currentIndex] = newObject;
      } else {
        newRecords.push(newObject);
      }
      setRecords(newRecords);
      dispatch(setShowModalEditCurrentPartner(false));
    },
  });

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="curp"
        label="CURP"
        placeholder="La CURP del colaborador"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="name"
        label="Nombre (s)"
        placeholder="El nombre del colaborador"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="lastName"
        label="Apellido paterno"
        placeholder="El apellido paterno del colaborador"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="secondLastName"
        label="Apellido materno"
        placeholder="El apellido materno del colaborador"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="birthdate"
        label="Fecha de nacimiento"
        type="date"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="phone"
        label="Teléfono"
        placeholder="El teléfono del colaborador"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="address"
        label="Dirección"
        placeholder="La dirección del colaborador"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="alergias"
        label="Alergias"
        placeholder="Alergias"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="tipoSangre"
        label="Tipo de sangre"
        placeholder="Tipo de sangre"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="enfermedad"
        label="Enfermedades"
        placeholder="Enfermedades"
        sm={8}
        smLabel={4}
      />

      <CustomInput
        formik={formik}
        field="tratamiento"
        label="Tratamientos"
        placeholder="Tratamientos"
        sm={8}
        smLabel={4}
      />

      {/* <CustomInput
        formik={formik}
        field="recruitmentDate"
        label="Fecha de reclutamiento"
        type="date"
        sm={8}
        smLabel={4}
      /> */}

      <CustomSelectField
        formik={formik}
        field="position"
        list={positions}
        label="Puesto"
        placeholder="puesto"
        sm={8}
        smLabel={4}
      />

      {/* <CustomInput
        formik={formik}
        field="monthlySalary"
        label="Salario mensual"
        type="number"
        sm={8}
        smLabel={4}
      /> */}

      {!user?.branch && (
        <CustomSelectField
          formik={formik}
          field="branch"
          list={branches}
          label="Sucursal"
          placeholder="registro de sucursal"
          sm={8}
          smLabel={4}
        />
      )}

      <CustomSelectField
        formik={formik}
        field="size"
        list={sizes}
        label="Talla"
        placeholder="registro de talla"
        sm={8}
        smLabel={4}
      />

      {/* <CustomSelectField
        formik={formik}
        field="periodicity"
        list={periodicities}
        label="Periodicidad"
        placeholder="registro de periodicidad"
        sm={8}
        smLabel={4}
      /> */}

      <CustomSelectField
        formik={formik}
        field="shift"
        list={shifts}
        label="Turno"
        placeholder="registro de turno"
        sm={8}
        smLabel={4}
      />

      <div className="d-flex justify-content-end">
        <button
          className="btn btn-secondary me-2"
          onClick={() => dispatch(setShowModalEditCurrentPartner(false))}
        >
          Cancelar
        </button>
        <button className="btn btn-blue" onClick={() => formik.handleSubmit()}>
          Actualizar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ImportPartnerForm;
