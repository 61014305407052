import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionMenuSelected, MenuSelectedEnum } from "../../types/CatalogSliceTypes.ts";
import Branch from "../../models/Branch.ts";
import {Position}  from "../../models/Position.ts";
import Shift from "../../models/Shift.ts";
import Schooling from "../../models/Schooling.ts";
import Size from "../../models/Size.ts";

const initialState = {
  menuSelected: MenuSelectedEnum.branch,
  catalog: [] as Branch[],
  positions: [] as Position[],
  shifts: [] as Shift[],
  schoolings: [] as Schooling[],
  sizes: [] as Size[],
  loading: true,
  fetch: false,
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    
    setMenu: (state, action: ActionMenuSelected) => {
        state.menuSelected = action.payload;
      },

      setCatalog: (state, action: PayloadAction<Branch[]>) => {
        state.catalog = action.payload;
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      setFetch: (state, action: PayloadAction<boolean>) => {
        state.fetch = !state.fetch;
      },
  },
});

export const {
    setMenu,
    setCatalog,
    setLoading,
    setFetch,
    } = catalogSlice.actions;

export default catalogSlice.reducer;

