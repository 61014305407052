import * as yup from "yup";

export const DeletePostulanteSchema = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  curp: yup
    .string()
    .required("Campo requerido")
    .matches(
      /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/,
      "Ingresa una CURP válida"
    ),
  unbanDate: yup.date().when("isPermanent", ([isPermanent], schema) => {
    return Boolean(isPermanent)
      ? schema.optional()
      : schema
          .required("Campo requerido")
          .min(new Date(), "La fecha no puede ser anterior al día de hoy");
  }),
  isPermanent: yup.boolean().required("Campo requerido"),
  reason: yup.string().optional(),
});

export const DeletePostulanteSchemaPerson = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  curp: yup
    .string()
    .required("Campo requerido")
    .matches(
      /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/,
      "Ingresa una CURP válida"
    ),
  unbanDate: yup.date().when("isPermanent", ([isPermanent], schema) => {
    return Boolean(isPermanent)
      ? schema.optional()
      : schema
          .required("Campo requerido")
          .min(new Date(), "La fecha no puede ser anterior al día de hoy");
  }),
  isPermanent: yup.boolean().required("Campo requerido"),
  reason: yup.string().optional(),
  idPeople: yup.number().min(1, "Campo requerido").required("Campo requerido"),
});
