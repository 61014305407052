import React from 'react'
import EjemploINE from '../../assets/img/ejemplo_ine.png'

const TooltipContent = ({ type }) => {
  return (
    type === 'IDMEX' ?
      <div className='p-3'>
        <h1>IDMEX (1. CIC)</h1>
        <br />
        <p>
          El IDMEX en la credencial para votar del Instituto Nacional Electoral (INE) de México es una clave de identificación única que se encuentra en el reverso de la credencial.
        </p>
        <p>
          Este código se encuentra justo después de las letras "IDMEX" y antes del símbolo {` ${'“<<”'} `} y es conocido como el CIC (Clave de Identificación de la Credencial)​        </p>
        <p>
          Se utiliza para verificar la autenticidad y seguridad de la credencial
        </p>

        <img src={EjemploINE} className='w-100' alt="Ejemplo INE" />
        <p className='text-center fs-14px fw-600'>
          El IDMEX es el que se presenta en la imagen con el número 1.
        </p>
      </div>
      :
      <div className='p-3'>
        <h1>OCR (2. Identificador del Ciudadano)</h1>
        <p>
          El OCR (Optical Character Recognition) es un identificador de datos de la credencial de elector.
        </p>
        <p>
          El número de identificación de la credencial para votar, también conocido como OCR se encuentra al reverso del INE.
        </p>
        <p>
          En los modelos más antiguos de la credencial se encuentra a un costado, mientras que en los modelos más recientes de la credencial, se encuentran en la primera línea de la parte inferior después de los símbolos {` ${'“<<”'} `}.
        </p>
        <img src={EjemploINE} className='w-100' alt="Ejemplo INE" />
        <p className='text-center fs-14px fw-600'>
          El OCR es el que se presenta en la imagen con el número 2.
        </p>
      </div>
  )
}

export default TooltipContent