import httpClient from "./httpClient";

const prefix = "/blacklists";

export async function readBlacklistByCurp(curp: string) {
  return (await httpClient.get(`${prefix}/get-by-curp/${curp}`)).data;
}

export async function saveRecordBlackList(body) {
  return (await httpClient.post(`${prefix}/`, body)).data;
} 

export async function paginateBlackList(query) {
  return (await httpClient.get(`${prefix}${query}`)).data;
}

export async function deleteRecord(id) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function readRecordsByCurp(curp: string) {
  return (await httpClient.get(`${prefix}/records-curp/${curp}`)).data;
}

export async function importBlackList(data: any) {
  return (await httpClient.post(`${prefix}/import`, data)).data;
}
