import React from 'react'
import CustomInput from '../formik/CustomInput.tsx'
import { PanelBody } from '../panel/panel.jsx'

const PlantillaFormEdit = ({ formik }) => {
  return (
    <PanelBody>
      <CustomInput
        formik={formik}
        field="name"
        label="Nombre de la plantilla"
        placeholder="Nombre de la plantilla"
        sm={3}
      />
    </PanelBody>
  )
}

export default PlantillaFormEdit