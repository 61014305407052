export const SucursalesInitial = {
    name: "",
    idBusinessName: 0,
};

export interface ISucursal {
    name?: string;
    idBusinessName: number;
  }
  
  export const DefaultBranch: ISucursal = {
    idBusinessName: -99,
  };
  