import React from "react";
import Loader from "../Loader.tsx";
import { Partner } from "../../models/Partner.ts";
import InputEditProfile from "../formik/InputEditProfile.tsx";
import { useFormik } from "formik";
import { FillPreviewInfo } from "../../interfaces/formik/IEditUser.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import ApplicationList from "../history/ApplicationList.tsx";
// import { ApplicationProperties } from "../../models/Application.ts";
import { readApplicationsByCurp } from "../../services/application.service.ts";
import { useNavigate } from "react-router-dom";
import { notification } from "../../utils/Notifications.tsx";
import { updateSalary } from "../../services/partner.service.ts";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { getShifts } from "../../services/shift.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import SelectEditProfile from "../formik/SelectEditProfile.tsx";

const InformationPosition = ({
  partner,
  reloadPage,
}: {
  partner: Partner;
  reloadPage: () => void;
}) => {
  const navigate = useNavigate();
  console.log("partner", partner);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    // validationSchema: EditProfileSchema,
    initialValues: FillPreviewInfo(partner),
    onSubmit: async (values) => {
      const { monthlySalaryIMSS, monthlySalary, banDate, recruitmentDate, shiftManager, shift } = values;
      setLoading(true);
      try {
        // updateSalary by partner
        const response = await updateSalary(partner.id, {
          salary: Number(monthlySalary),
          salaryIMSS: Number(monthlySalaryIMSS),
          banDate,
          recruitmentDate,
          shift,
          shiftManager,
        });
        notification("", "Datos actualizados", "success");
        reloadPage();
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al actualizar el salario",
          "danger"
        );
      } finally {
        setLoading(false);
      }
    },
  });
  const { user } = useSelector((state: RootState) => state.auth);

  const curp = partner.person.curp;
  const [applications, setApplications] = React.useState<
    any[]
  >([]);
  const [shifts, setShifts] = React.useState<any[]>([]);

  const fetchApplications = async () => {
    if (!curp) return;
    try {
      const response = await readApplicationsByCurp(curp);
      setApplications(response);

      const shifts = await getShifts();
      setShifts(shifts);
    } catch (error) {
      setApplications([]);
    } finally {
    }
  };

  React.useEffect(() => {
    fetchApplications();
  }, [curp]);

  console.log("partner", partner);

  return (
    <div className="tab-pane fade" id="empleo">
      <div className="table-responsive form-inline">
        <table className="table table-profile align-middle">
          <thead>
            <tr>
              <th />
              <th>
                <h4>
                  <small>Puesto: </small>
                  {partner.candidate.vacancy.position.name}
                </h4>
              </th>
            </tr>
          </thead>
          <tbody className="overflow-x-hidden">
            <tr className="highlight">
              <td colSpan={2} />
            </tr>
            <InputEditProfile
              formik={formik}
              field="recruitmentDate"
              label="Fecha de reclutamiento"
              placeholder="Fecha de reclutamiento"
              sm={3}
              type="date"
              unclickable={user.rol !== "Administrador"}
            />
            {user.rol === 'Administrador' && (
              <InputEditProfile
                formik={formik}
                field="monthlySalary"
                label="Salario"
                placeholder="Salario"
                sm={3}
                unclickable={user.rol !== "Administrador"}
                type="number"
              />
            )}
            {user.rol === 'Contador' && (
              <InputEditProfile
                formik={formik}
                field="monthlySalaryIMSS"
                label="Salario IMSS"
                placeholder="Salario IMSS"
                sm={3}
                type="number"
              />
            )}
            <tr>
              <td className="field">Turno: </td>
              <td>
                <SelectEditProfile
                  list={shifts}
                  formik={formik}
                  field="shift"
                  label="Turno"
                  key={shifts.length}
                  sm={3}
                  disabled={user.rol !== "Administrador"}
                />
              </td>
            </tr>
            <InputEditProfile
              formik={formik}
              field="branchName"
              label="Sucursal"
              placeholder="Sucursal"
              sm={3}
              unclickable={true}
            />
            <InputEditProfile
              formik={formik}
              field="comments"
              label="Comentarios"
              placeholder="Comentarios"
              sm={3}
              unclickable={true}
            />
            <tr className="w-100" style={{ overflow: 'hidden' }}>
              <td colSpan={2} className="ps-5">
                <CustomCheckboxField
                  formik={formik}
                  classInput="ps-4 ms-5 w-75"
                  field="shiftManager"
                  label="Jefe de turno"
                  mdLabel={2}
                  disable={user.rol !== "Administrador"}
                />
              </td>
            </tr>

            {partner.partnerStatus.name === "Baja" && (
              <InputEditProfile
                formik={formik}
                field="banDate"
                label="Fecha de baja"
                placeholder="Fecha de baja"
                sm={3}
                type="date"
              />
            )}

            {user.rol === "Administrador" && (
              <tr className="highlight">
                <td className="field">&nbsp;</td>
                <td className="">
                  <button
                    type="submit"
                    className="btn btn-secondary w-150px ms-5px"
                    style={{ marginRight: "4%" }}
                    onClick={() => navigate("/colaboradores")}
                  >
                    Regresar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-azul w-150px"
                    onClick={() => formik.handleSubmit()}
                  >
                    Actualizar
                  </button>
                </td>
              </tr>
            )}
            {
              user.rol !== "Lider de sucursal" &&
              <tr>
                <td className={`field`} style={{ color: `black` }}>
                  <h4 className="pt-3">Historial:</h4>
                </td>
                <td>
                  <div className={`col-md-7`}>
                    <ApplicationList applications={applications} size="sm" />
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
        {/* <table className="table table-profile align-middle mt-5">
          <thead>
            <tr>
              <th />
              <th>
                <h4>Historial:</h4>
              </th>
            </tr>
          </thead>
        </table>
        <div className="w-50 d-flex align-items-center justify-content-center">
          <ApplicationList applications={applications} size="sm" />
        </div> */}
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};

export default InformationPosition;
