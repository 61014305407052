import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Confirmation({ route }) {
  const location = useLocation();

  const [date, setDate] = useState();

  const [ubications, setUbications] = useState([
    {
      name: "Grupo Chili Guajili",
      address:
        "Avenida Universidad # 1000, Universidad, Real de Candiani, 68130 Oaxaca de Juárez, Oax.",
      frame:
        "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Avenida%20Universidad%2068130%20oaxaca%20Chili%20Guajili+(Chili%20guajili%20universidad)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed",
    },
    {
      name: "Grupo Guajaqueñisimo",
      address: "Benito Juárez 5-127, 68290 San Agustín Yatareni OAX, México",
      frame:
        "https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Avenida%20Universidad%2068130%20oaxaca%20Chili%20Guajili+(Chili%20guajili%20universidad)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed",
    },
  ]);

  useEffect(() => {
    console.log(location.state);
    const date = new Date(
      location.state.interviewDate + " " + location.state.applicationTime
    );
    console.log("date");
    console.log(date);
    setDate(
      new Intl.DateTimeFormat("es-MX", {
        dateStyle: "full",
        timeStyle: "medium",
      }).format(date)
    );
    return () => {};
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <div className="brand p-2">
            <div className="w-50px">
              <img
                src="../../../../assets/img/logo/logo_chili_rojo.png"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </div>
      </nav>
      <div className="row">
        <div className="col" align="center">
          <br />
          <div className="w-90px">
            <img src="../assets/img/Ok.png" alt="" className="mw-100" />
          </div>
          <br />
          <h2>¡Cita creada para el {date}!</h2>
          <br />
          <h3>No olvides llevar:</h3>
          <br />
          <h3>• Identificación oficial</h3>
          <h3>• Comprobante de domicilio</h3>
          <h3>• Solicitud de empleo</h3>
          <br />
          <h3>
            Te esperamos en nuestras oficinas ubicadas en{" "}
            <i>
              {
                ubications.find((u) => u.name === location.state.bussinameName)
                  .address
              }
            </i>
          </h3>
          <div className="brand p-2">
            {location.state.bussinameName === "Grupo Chili Guajili" ? (
              <iframe
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Avenida%20Universidad%2068130%20oaxaca%20Chili%20Guajili+(Chili%20guajili%20universidad)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            ) : (
              <iframe
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Hacienda%20San%20Agust%C3%ADn/%20La%20Caballeriza%20Restaurante+(Yatareni)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      />
    </>
  );
}

export default Confirmation;
