import httpClient from "./httpClient";

const prefix = "/vacancies";

export async function getVacancies() {
  return (await httpClient.get(prefix + "/available")).data;
}

export async function paginateVacancies(query: string) {
  return (await httpClient.get(prefix + `/list-paginated?${query}`)).data;
}

export async function getActiveVacancies() {
  return (await httpClient.get(prefix)).data;
}

export async function desactivateVacancie(id: number) {
  return (await httpClient.patch(prefix + "/desactivate/" + id)).data;
}

export async function saveVacancie(data: any) {
  return (await httpClient.post(prefix, data)).data;
}

export async function findBranchesByVacancie(id: number) {
  return (await httpClient.get(prefix + "/find-branches/" + id)).data;
}

export async function desactivatePosition(id: number) {
  return (await httpClient.delete(prefix + "/remove-position/" + id)).data;
}