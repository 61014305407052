import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import TabsCatalog from "../../components/catalog/TabsCatalog.tsx";
import TableCatalog from "./TableCatalog.tsx";
import PaginationCatalog from "./PaginationCatalog.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import FormNuevo from "../../components/catalog/FormNuevo.tsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader.tsx";
import { RootState } from "../../store/index.ts";
import {
  setMenu,
  setCatalog,
  setLoading,
  setFetch,
} from "../../store/slices/catalogSlice.ts";

import FooterChili from "../../components/FooterChili.jsx";
import { de } from "date-fns/locale";
import Layout from "../../components/Layout.tsx";

const ListCatalog = () => {
  const dispatch = useDispatch();
  const context = useAppSettings();
  const [showModal, setShowModal] = React.useState(false);
  const [dataToRemove, setDataToRemove] = React.useState({
    id: -1,
    name: "",
  });
  const { menuSelected, page, limit, param, loading, fetch } = useSelector(
    (state: RootState) => state.catalog
  );

  const fetchCatalogos = async () => {
    try {
      const filter = `${menuSelected}`;
      const response = await CatalogosService.getCatalogos(filter);
      console.log("response", response);
      dispatch(setCatalog(response));
    } catch (error) {
      notification("Error", "Error al cargar los catalogos", "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const nombreSelect = (nombre: string) => {
    switch (nombre) {
      case "branches":
        return "Sucursales";
        break;
      case "positions":
        return "Puestos";
        break;
      case "shifts":
        return "Turnos";
        break;
      case "schoolings":
        return "Escolaridades";
        break;
      case "sizes":
        return "Tamaños";
      default:
        return "Sucursales";
        break;
    }
  };
  React.useEffect(() => {
    fetchCatalogos();
  }, [page, limit, menuSelected, param, fetch]);

  return (
    <Layout loading={loading}>
      <div style={{ flex: "1 0 auto" }}>
        <div className="card border-0 m-4">
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              paddingTop: "10px",
              paddingRight: "10px",
            }}
            className="input-group"
          >
            <button
              className="btn btn-naranja"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Agregar
            </button>
          </div>
          <TabsCatalog />
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* <FiltreosColaboradores /> */}
              <TableCatalog />
              {/* <PaginationCatalog /> */}
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => {
          setShowModal(false);
          setDataToRemove({
            id: -1,
            name: "",
          });
        }}
        title={`Agregar un nuevo registro en ${nombreSelect(menuSelected)}`}
        children={
          <FormNuevo
            closeFn={() => {
              setShowModal(false);
              setDataToRemove({
                id: -1,
                name: "Nuevo",
              });
            }}
            tapSelected={menuSelected}
            dataToEdit={{ id: -1, name: "" }}
          />
        }
      />
    </Layout>
  );
};

export default ListCatalog;
