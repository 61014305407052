import React from "react";
import { IModalProps } from "../interfaces/others/IModal";

const ModalContainer: React.FC<IModalProps> = ({
  open,
  title,
  closeFn,
  size,
  children,
  ...props
}) => {
  const sizeClass = size ? `modal-${size}` : "";

  if (!open) return null;

  const handleCloseOnOutsideClick = (event: React.MouseEvent) => {
    if (!closeFn) return;

    const target = event.target as Element;

    if (target.classList.contains('modal')) {
      closeFn(event);
    }
  };

  return (
    <React.Fragment>
      <div className="modal-backdrop show"></div>
      <div
        className={`modal fade ${open ? `show` : ""}`}
        tabIndex={-1}
        style={{ display: open ? "block" : "none" }}
        onClick={handleCloseOnOutsideClick}
        ref={props?.refModal}
      >
        <div className={`modal-dialog modal-dialog-centered ${sizeClass}`}>
          <div className={`modal-content ${props.classModal}`}>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              {true && (
                <button type="button" className="btn-close" onClick={closeFn} />
              )}
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalContainer;
