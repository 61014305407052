import React from "react";
import { removeAvailability } from "../../services/availabilities.service.ts";

const RemoveAvailability = ({
  closeFn,
  id,
  refreshFn,
}: {
  closeFn: () => void;
  id: number;
  refreshFn: () => void;
}) => {

  const handleDelete = async () => {
    try {
      await removeAvailability(id);
    } catch (error) {
      console.error(error);
    } finally {
      refreshFn();
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas eliminar esta disponibilidad?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => handleDelete()}>
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default RemoveAvailability;
