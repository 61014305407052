import React from 'react'
import ContractPrint from '../../pages/ContractPrint.tsx'
import SignatureCanvas from 'react-signature-canvas'
import { updateSignature } from '../../services/partner.service.ts'
import { uploadImage } from '../../services/files.service.ts'
import moment from 'moment'
import { notification } from '../../utils/Notifications.tsx'

const SignContract = ({ ref, contractData, refetch, partner }) => {
  console.log('contractData', contractData)
  const signature = React.useRef<any>(null)

  function base64ToFile(base64String, fileName) {
    const [metadata, base64Data] = base64String.split(',');
    const mimeType = metadata.match(/:(.*?);/)[1];

    const byteCharacters = atob(base64Data);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  const [signed, setSigned] = React.useState(false)
  const handleSubmitSignature = async (base64) => {
    const file = base64ToFile(base64, `FIRMA_${contractData.curp}.png`)
    const response = await uploadImage(file)

    const response2 = await updateSignature(partner.candidate.id, { signature: response.id })
    console.log('response', response)
    console.log('response2', response2)


    notification('Éxito', 'Firma guardada correctamente', 'success')
    await refetch()
    setReaded(true)
    setSigned(true)
  }

  const [readed, setReaded] = React.useState(false)

  if (signed) {
    return (
      <div className='alert alert-success' role='alert'>
        <h4 className='alert-heading'>¡Contrato firmado!</h4>
        <p>El contrato ya ha sido firmado el {moment(contractData.signature.created_at).format('DD/MM/YYYY')}.</p>
      </div>
    )
  }
  
  if (!readed) {
    return (
      <div className='position-relative bg-white mx-auto' style={{ width: '710px' }}>
        {
          !contractData.signature ? (
            <div className='bg-white py-4 d-flex flex-column'>
              <div style={{ height: '450px', overflowY: 'scroll' }}>
                <div style={{ pointerEvents: 'none', userSelect: 'none' }}>
                  <ContractPrint ref={ref} contractData={contractData} />
                </div>
              </div>
              <button className='btn btn-primary mx-auto mt-4' onClick={() => setReaded(true)}>
                He leído y acepto los términos y condiciones
              </button>
            </div>
          )
            : (
              <div className='alert alert-success' role='alert'>
                <h4 className='alert-heading'>¡Contrato firmado!</h4>
                <p>El contrato ya ha sido firmado el {moment(contractData.signature.created_at).format('DD/MM/YYYY')}.</p>
              </div>
            )
        }
      </div>
    )
  } else {
    return (
      <div className='bg-white d-flex flex-column '>
        <h4 className='text-center mt-4'>Firma tu contrato</h4>
        <p className='text-center'>Firma en la siguiente área</p>
        <hr />
        <div
          style={{ width: 450 }}
          className='d-flex flex-column align-items-center justify-content-center mx-auto'>
          <div className='border border-primary '>
            <SignatureCanvas
              ref={signature}
              penColor='blue'
              backgroundColor='#f5f5f5'
              canvasProps={{ width: 350, height: 220, className: 'sigCanvas' }}
            />
          </div>

          <div style={{ width: 250 }} className='d-flex mt-4 pb-4'>
            <button
              className='btn btn-secondary me-auto'
              onClick={() => signature?.current?.clear()}
            >
              Limpiar
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                handleSubmitSignature(signature?.current?.getTrimmedCanvas().toDataURL('image/png'))
              }}
            >
              Firmar
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default SignContract