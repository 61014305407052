import React from 'react'
import EmptyImage from "../../assets/img/ic_content.svg";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { setCurrent } from '../../store/slices/traspasosSlice.ts';
import { toCamelCase } from '../../hooks/toCameCase.tsx';

const TableTraspasos = () => {
  const dispatch = useDispatch();
  const { query, records } = useSelector(
    (state: RootState) => state.traspasos
  );

  console.log('traspasos: ', records);

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Colaborador</th>
            {
              !query.includes('rejected') && (
                <React.Fragment>
                  <th>Fecha de aprobación</th>
                  <th>Estatus</th>
                  <th>Completado</th>
                </React.Fragment>
              )
            }
            <th>Sucursal nueva </th>
            <th>Posición nueva</th>
            <th>Sucursal anterior</th>
            <th>Posición anterior</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {records?.length > 0 ?
            records
              .filter((item) =>
                item.newBranch.name !== item.oldBranch.name
              )
              .map((item, index) => {
                const person = item.partner.person;
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{toCamelCase(`${person.firstName} ${person.lastName}`)}</td>

                    {
                      !query.includes('rejected') &&
                      <React.Fragment>
                        <td>{item.approvalDate ? moment(item.approvalDate).format('DD/MM/YYYY') : 'Sin fecha'}</td>
                        <td>{item.isApproved ? 'Aprobado' : 'Sin aprobar'}</td>
                        <td>{item.isFinished ? 'Completado' : 'Pendiente'}</td>
                      </React.Fragment>
                    }

                    <td>{item.newBranch.name}</td>
                    <td>{item.newPosition.name}</td>
                    <td>{item.oldBranch.name}</td>
                    <td>{item.oldPosition.name}</td>
                    <td>
                      <Tooltip title="Ver detalles" arrow>
                        <button
                          onClick={() => dispatch(setCurrent(item))}
                          className="btn btn-sm btn-primary">
                          <i className="fa-solid fa-eye text-white"></i>
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                )
              })
            :
            <tr>
              <td colSpan={10}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no hay nadie en la lista negra</h5>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

export default TableTraspasos