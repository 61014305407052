import httpClient from "./httpClient";

const prefix = "/business-names";

export async function readBusinessNames() {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function SaveBusinessName(body) {
  return (await httpClient.post(`${prefix}/`, body)).data;
}

export async function getPuestos(params?: string) {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function paginateBusinessNames(query) {
  return (await httpClient.get(`${prefix}${query}`)).data;
}

export async function deleteRecordPuestos(id) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function updateBusinessName(id, body) {
  return (await httpClient.patch(`${prefix}/${id}`, body)).data;
}

export async function paginateBusiness(
  limit: number = 10,
  offset: number = 0,
  type: string = "all",
  param: string = ""
) {
  return (
    await httpClient.get(
      `${prefix}/list-paginated?limit=${limit}&offset=${offset}&type=${type}&param=${param}`
    )
  ).data;
}











export async function readBusinessNameById(id) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}


