import React, { useEffect, useState } from 'react'
import CustomTextEditor from '../../../CustomTextEditor.tsx'
import { useFormik } from 'formik'
import { readBranch, updateMessage } from '../../../../services/branch.service.ts'
import { notification } from '../../../../utils/Notifications.tsx'

const EditBranchMessage = ({ branchId, closeFn }) => {

  const [branch, setBranch] = useState(null)

  const fetchDependencies = async () => {
    const branch = await readBranch(branchId)
    console.log(branch)
    setBranch(branch)
    formik.setValues({ message: branch.message })
  }

  useEffect(() => {
    fetchDependencies()
  }, [])

  const formik = useFormik({
    initialValues: {
      message: branch?.message || ''
    },
    onSubmit: async (values) => {
      try {
        await updateMessage(branchId, values)
        notification('Éxito', 'Mensaje actualizado', 'success')
        closeFn()
      } catch (error) {
        console.error(error)
        notification('Error', 'Ocurrió un error al actualizar el mensaje', 'danger')
      }
    }
  })

  return (
    <div className="d-flex flex-column">
      <CustomTextEditor
        label='Mensaje de la sucursal'
        formik={formik}
        field='message'
        required
      />
      <button
        className='btn btn-primary ms-auto mt-4'
        type='submit'
        onClick={() => formik.handleSubmit()}>
        Guardar
      </button>
    </div>
  )
}

export default EditBranchMessage