import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";
import { Typeahead } from "react-bootstrap-typeahead";

const CustomAutocomplete = ({
  formik,
  field,
  list,
  label,
  sm = 9,
  smLabel = 3,
  onChange = () => { },
  placeholder = label,
  updateFormik = (e) => { },
  disabled = false,
}: IFormikProps & {
  field: string;
  list: any[];
  label: string;
  onChange?: () => void;
  sm?: number;
  placeholder?: string;
  updateFormik?: (e: any) => void;
  smLabel?: number;
  disabled?: boolean;
}) => {
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  // Obtener el valor actual desde formik para el autocompletado
  const selectedValue = list.find(item => item.id === formik.values[field]);

  return (
    <React.Fragment>
      <div className={`row mb-4 fs-13px col-md-12`}>
        <label
          className={`form-label col-form-label col-md-${smLabel} text-end`}
          style={{ color: error ? "red" : "inherit" }}
        >
          {label}
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        </label>

        <div className={`col-md-${sm}`}>
          <Typeahead
            labelKey="name"
            style={{
              width: "100%",
              display: "block",
              color: error ? "red" : "inherit",
            }}
            onChange={(selected) => {
              // Asegurarse de que solo un elemento está seleccionado
              const value = selected.length > 0 ? selected[0].id : '';
              formik.setFieldValue(field, value);
              onChange();
              updateFormik(value);
            }}
            options={list}
            placeholder={placeholder}
            selected={selectedValue ? [selectedValue] : []}
            disabled={disabled}
            defaultInputValue={selectedValue ? selectedValue.name : ''}
          />
          {formik.touched[field] && formik.errors[field] && (
            <div
              className="invalid-feedback"
              style={{
                marginTop: "1px",
                display: "flex",
                marginLeft: "0.25em",
              }}
            >
              {formik.touched[field] && formik.errors[field] ? (
                <>{formik.errors[field]}</>
              ) : (
                <p> </p>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomAutocomplete;
