import React from "react";
import { useFormik } from "formik";
import { IDeleteUser } from "../../interfaces/others/IDeleteUser";
import { notification } from "../../utils/Notifications.tsx";
import { unsubscribePartner } from "../../services/partner.service.ts";
import { DeletePartnerSchema } from "../../validation/schemas/DeletePartnerSchema.ts";
import CustomInput from "../formik/CustomInput.tsx"

const DeletePartner: React.FC<IDeleteUser> = ({ name, id, closeFn }) => {
  const formik = useFormik({
    validationSchema: DeletePartnerSchema,
    initialValues: {
      reasonUnsubscribe: "",
    },
    onSubmit: async (values) => {
      try {
        await unsubscribePartner(id, { ...values });
        notification("", `Usuario ${name} dado de baja correctamente`, "info");
      } catch (error) {
        notification(
          "Error :(",
          `Ocurrio un error al tratar de dar de baja a ${name}`,
          "danger"
        );
      } finally {
        closeFn();
      }
    },
  });

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas dar de baja a {name}?
      </p>
      <CustomInput
          formik={formik}
          field="reasonUnsubscribe"
          label="Razón de la baja"
          placeholder="Escribe la razón de la baja"
          sm={8}
          smLabel={4}
        />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-danger"
          onClick={() => formik.handleSubmit()}
        >
          Dar de baja
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeletePartner;
