import React from "react";
import FooterChili from "./FooterChili";
import Loader from "./Loader.tsx";

const Layout = ({ children, loading = false }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 75)",
        position: "relative",
      }}
    >
      <Loader isLoading={loading} />
      <div style={{ flex: "1 0 auto", marginBottom: "75px" }}>{children}</div>
      <FooterChili />
    </div>
  );
};

export default Layout;
