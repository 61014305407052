import React from "react";
import { deleteReject } from "../../services/application.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setFetch } from "../../store/slices/solicitudesSlice.ts";

const DeleteRejectPhase = ({
  name,
  id,
  closeFn,
}: {
  name: string;
  id: number;
  closeFn: () => void;
}) => {
  const dispatch = useDispatch();
  const { fetch } = useSelector((state: RootState) => state.solicitud);
  const handleClick = async () => {
    try {
      const response = await deleteReject(id);
      notification(
        "",
        response?.message || "Acción realizada con éxito",
        "info"
      );
    } catch (error) {
      notification("Error", "Ocurrio un error", "danger");
    } finally {
      closeFn();
      dispatch(setFetch(!fetch));
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas resetear el proceso de {name}?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-success" onClick={() => handleClick()}>
          Resetear
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteRejectPhase;
