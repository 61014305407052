import React from 'react'
import { useAppSettings } from '../../hooks/useAppSettings.tsx'
import Layout from '../../components/Layout.tsx'
import QrScanner from './QrScanner.tsx';

type Props = {}

const RelojChecador = (props: Props) => {
  useAppSettings()

  return (
    <Layout>
      <QrScanner />
    </Layout>
  )
}

export default RelojChecador