import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import FiltroSolicitudes from "../../components/solicitudes/FiltroSolicitudes.tsx";
import TableSolicitudes from "../../components/solicitudes/TableSolicitudes.tsx";
import PaginationSolicitudes from "../../components/solicitudes/PaginationSolicitudes.tsx";
import Title from "../../components/Title.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { RootState } from "../../store/index.ts";
import { getApplications } from "../../services/application.service.ts";
import {
  resetState,
  setLoading,
  setModalBan,
  setModalSetStatus,
  setShowModal,
  setSolicitudes,
  setTotal,
} from "../../store/slices/solicitudesSlice.ts";
import Layout from "../../components/Layout.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import DeleteRejectPhase from "../../components/solicitudes/DeleteRejectPhase.tsx";
import ModalBan from "../../components/solicitudes/ModalBan.tsx";
import ModalSetStatus from "../../components/solicitudes/ModalSetStatus.tsx";

const ListSolicitudes = () => {
  const dispatch = useDispatch();
  const { page, limit, query, loading, fetch, currentUser, showModal, modalBan, modalSetStatus } = useSelector(
    (state: RootState) => state.solicitud
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const context = useAppSettings();

  const fetchSolicitudes = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryURL = `?offset=${offset}&limit=${limit}&${query}`;

      if (user.branch) {
        queryURL = `?branch=${user.branch}&status=4&offset=${offset}&limit=${limit}&${query}`;
      }

      if (user.rol === "Reclutador/Archivos") {
        queryURL = `?rol=reclutador-archivos&offset=${offset}&limit=${limit}&${query}`;
      }

      const response = await getApplications(queryURL);
      dispatch(setSolicitudes(response.results));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al obtener las solicitudes",
        "danger"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchSolicitudes();
  }, [page, limit, query, fetch]);

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Solicitudes"
        basePath="/solicitudes"
        title="Solicitudes"
      />
      <div className="card border-0 m-4">
        {/* <SolicitudesTabs /> */}
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <FiltroSolicitudes />
            <TableSolicitudes refetch={()=>fetchSolicitudes()} />
            <PaginationSolicitudes />
          </div>
        </div>
      </div>
      <ModalContainer
        open={showModal}
        title={`Resetear el proceso de ${currentUser?.name}`}
        closeFn={() => dispatch(setShowModal(false))}
        children={
          <DeleteRejectPhase
            name={currentUser?.name}
            closeFn={() => dispatch(setShowModal(false))}
            id={currentUser?.id}
          />
        }
      />
      <ModalContainer
        open={modalBan}
        title={`Rechazo de ${currentUser?.name}`}
        closeFn={() => dispatch(setModalBan(false))}
        children={
          <ModalBan
            name={currentUser?.name}
            closeFn={() => dispatch(setModalBan(false))}
            id={currentUser?.id}
          />
        }
      />
      <ModalContainer
        open={modalSetStatus}
        title={`Cambiar el status de ${currentUser?.name}`}
        closeFn={() => dispatch(setModalSetStatus(false))}
        children={
          <ModalSetStatus
            name={currentUser?.name}
            closeFn={() => dispatch(setModalSetStatus(false))}
            id={currentUser?.id}
          />
        }
      />
    </Layout>
  );
};

export default ListSolicitudes;
