import httpClient from "./httpClient";

const prefix = "/shifts";

export async function getShifts() {
  return (await httpClient.get(prefix)).data;
}

export async function paginateTurnos(query: string) {
  return (await httpClient.get(`${prefix}/list-paginated?${query}`)).data;
}