import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import DatePicker, { registerLocale } from "react-datepicker";
import { PostulanteService } from "../../services/postulantes.service.js";
import { es } from "date-fns/locale";
import InputMask from "react-input-mask";
import moment from "moment";
import { getVacancies } from "../../services/vacancy.service.ts";
import { getSchoolings } from "../../services/schooling.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import {
  createApplicationForm,
  getByApplicationHours,
} from "../../services/application.service.ts";
import { getAvailabilitiesByPosition } from "../../services/availabilities.service.ts";

registerLocale("es", es);
function FormPreguntasInciales() {
  const navigate = useNavigate();
  // const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [schoolings, setSchoolings] = useState([]);
  const [positions, setPositions] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [interviewDate, setInterviewDate] = useState(new Date());
  const [fechasAvaibles, setFechasAvaibles] = useState([]);
  const [horasAvaibles, setHorasAvaibles] = useState([]);
  const [horas, setHoras] = useState();
  const [position, setPosition] = useState();
  const [horasAgendadas, setHorasAgendadas] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  let curp = searchParams.get("curp") || "";
  let nombre = searchParams.get("nombre") || "";
  let apellido_p = searchParams.get("apellido_p") || "";
  let apellido_m = searchParams.get("apellido_m") || "";

  // obtener nacimiento
  let today = new Date();
  let actualYear = today.getFullYear();
  actualYear = Number(String(actualYear).slice(2, 4));
  let year = curp.slice(4, 6);
  if (year > actualYear) {
    year = "19" + String(year);
  } else {
    year = "20" + String(year);
  }
  let month = curp.slice(6, 8);
  let day = curp.slice(8, 10);
  let nacimiento = year + "-" + month + "-" + day;

  useEffect(() => {
    dependecies();
  }, []);

  const dependecies = async () => {
    try {
      const positions = await getVacancies();
      setPositions(positions);

      const schoolings = await getSchoolings();
      setSchoolings(schoolings);

      const shifts = await getShifts();
      setShifts(shifts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeDate = async (date) => {
    setInterviewDate(date);
    try {
      const response = await getByApplicationHours(position, {
        date: moment(date).format("YYYY-MM-DD"),
      });
      const conteoHoras = {};
      response.forEach((item) => {
        conteoHoras[moment(item.applicationTime, "HH:mm:ss").format("HH:mm")] =
          moment(item.applicationTime, "HH:mm:ss").format("HH:mm");
      });
      setHorasAgendadas(conteoHoras);
    } catch (error) {
      notification("error", "Error al obtener las horas disponibles", "danger");
    }
    let horasArray = [];
    const currentDate = moment().format("YYYY-MM-DD");
    const selectedDate = moment(date).format("YYYY-MM-DD");
    const currentTime = moment();
    horasAvaibles[moment(date).format("YYYY-MM-DD")].map((item) => {
      const inicio = item.horaInicio;
      const fin = item.horaFin;

      let horaInicio = moment(inicio, "HH:mm");
      let horaFin = moment(fin, "HH:mm");

      let diff = horaFin.diff(horaInicio, "minutes");

      for (let i = 0; i <= diff; i += 10) {
        let hora = horaInicio.clone().add(i, "minutes");
        if (selectedDate !== currentDate || hora.isAfter(currentTime)) {
          horasArray.push(hora.format("HH:mm"));
        }
      }
    });

    setHoras(horasArray);

    setInterviewDate(date);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let form = event.target;
    let formData = new FormData(form);
    let formDataObj = Object.fromEntries(formData.entries());
    const json = formatJson(curp, formDataObj, moment);
      
    try {
      const jsonParse = JSON.parse(json);
      let bussinameName = "Grupo Chili Guajili";

      const position = positions?.find(
        (position) => position.id === Number(jsonParse.positionId)
      );

      if (position && position?.businessName)
        bussinameName = position?.businessName.name;

      const response = await createApplicationForm(json);

      navigate("/preguntas-iniciales/confirmation", {
        state: {
          interviewDate: response?.applicationDate.substring(0, 10),
          applicationTime: response?.applicationTime,
          bussinameName,
        },
      });
    } catch (error) {
      console.log(error);
      notification("Algo salió mal", error.message, "danger");
    }
  };

  const formatJson = (curp, formData, moment) => {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let phone = formData.telefono;
    console.log(phone);
    phone = phone
      .split("-")
      .join("")
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("+")
      .join("")
      .split(" ")
      .join("");
    console.log(phone);

    let body = JSON.stringify({
      curp: curp,
      firstName: formData.nombre,
      lastName: formData.apellido_p,
      secondLastName: formData.apellido_m,
      birthdate: formData.nacimiento,
      phone: phone,
      schoolingId: formData.escolaridad,
      address: formData.direccion,
      positionId: formData.puesto,
      monthlySalary: formData.expectativaSalarial,
      experience: formData.experiencia,
      shiftId: formData.horario,
      stayingMonths: formData.permanencia,
      // applicationDate: moment(formData.entrevista).format("YYYY-MM-DD"),
      applicationDate: formatDate(formData.entrevista),
      applicationTime: formData.horarioE,
      children: formData.children,
      health: formData.health,
      diseases: formData.diseases,
    });

    return body;
  };

  function formatDate(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return `${year}-${month}-${day}`;
  }

  if (redirect) {
    return <Navigate to="/user/login" />;
  }

  const getAvaibles = async (id) => {
    setPosition(id);

    setInterviewDate(new Date());
    try {
      const response = await getAvailabilitiesByPosition(id);
      const fechas = [];
      const fechasHoras = {};

      response.forEach((element, i) => {
        const itemFound = fechas.findIndex(
          (item) =>
            item === moment(element.availabilityStartDate).format("YYYY-MM-DD")
        );

        if (itemFound !== -1) {
          const horasTempIntern = [];

          horasTempIntern.push({
            horaInicio: moment(element.availabilityStartDate).format("HH:mm"),
            horaI: moment(element.availabilityStartDate).format("HH"),
            minutoI: moment(element.availabilityStartDate).format("mm"),
            horaFin: moment(element.availabilityEndDate).format("HH:mm"),
            horaF: moment(element.availabilityEndDate).format("HH"),
            minutoF: moment(element.availabilityEndDate).format("mm"),
          });

          const horasTemp =
            fechasHoras[
              moment(element.availabilityStartDate).format("YYYY-MM-DD")
            ];

          let combinedArray = [...horasTemp, ...horasTempIntern];
          combinedArray.sort((a, b) => b - a);

          fechasHoras[
            moment(element.availabilityStartDate).format("YYYY-MM-DD")
          ] = combinedArray;
          return;
        }
        fechas.push(moment(element.availabilityStartDate).format("YYYY-MM-DD"));
        const horasTempIntern = [];

        horasTempIntern.push({
          horaInicio: moment(element.availabilityStartDate).format("HH:mm"),
          horaI: moment(element.availabilityStartDate).format("HH"),
          minutoI: moment(element.availabilityStartDate).format("mm"),
          horaFin: moment(element.availabilityEndDate).format("HH:mm"),
          horaF: moment(element.availabilityEndDate).format("HH"),
          minutoF: moment(element.availabilityEndDate).format("mm"),
        });

        fechasHoras[
          moment(element.availabilityStartDate).format("YYYY-MM-DD")
        ] = horasTempIntern;
      });
      setFechasAvaibles(fechas);
      setHorasAvaibles(fechasHoras);
      setHoras();
    } catch (error) {
      console.log(error);
    }
  };

  const filterDates = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return fechasAvaibles.includes(formattedDate);
  };

  return (
    <div style={{ overflow: "auto" }}>
      <div className="d-flex justify-content-center m-2">
        <div className="brand">
          <div className="w-90px">
            <img
              src="../assets/img/logo/logo_chili_rojo.png"
              alt=""
              className="mw-100"
            />
          </div>
        </div>
      </div>
      <div>
        <form
          action="/result/form"
          method="GET"
          className="fs-13px"
          onSubmit={handleSubmit}
        >
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Datos Generales
            </PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre"
                      defaultValue={nombre}
                      required
                    />
                    <label
                      htmlFor="nombre"
                      className="d-flex align-items-center fs-13px"
                    >
                      Nombre(s)
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="apellido_p"
                      name="apellido_p"
                      defaultValue={apellido_p}
                      placeholder="Apellido Paterno"
                      required
                    />
                    <label
                      htmlFor="apellido_p"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Paterno
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="apellido_m"
                      name="apellido_m"
                      defaultValue={apellido_m}
                      placeholder="Apellido Materno"
                      required
                    />
                    <label
                      htmlFor="apellido_m"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Materno
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      required
                      type="date"
                      className="form-control fs-15px"
                      id="nacimiento"
                      name="nacimiento"
                      defaultValue={nacimiento}
                    />
                    <label
                      htmlFor="nacimiento"
                      className="d-flex align-items-center fs-13px"
                    >
                      Fecha de Nacimiento
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <select
                      className="form-select"
                      id="escolaridad"
                      name="escolaridad"
                      required
                    >
                      <option value="">Seleccione una opción</option>
                      {schoolings.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      htmlFor="escolaridad"
                      className="d-flex align-items-center fs-13px"
                    >
                      Escolaridad
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <InputMask
                      mask="(999) 999-9999"
                      className="form-control"
                      placeholder="(999) 999-9999"
                      name="telefono"
                    ></InputMask>
                    <label
                      htmlFor="masked-input-phone"
                      className="d-flex align-items-center fs-13px"
                    >
                      Teléfono
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="direccion"
                      name="direccion"
                      required
                    />
                    <label
                      htmlFor="direccion"
                      className="d-flex align-items-center fs-13px"
                    >
                      Domicilio (Calle, número, colonia, código postal)
                    </label>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Datos del Empleo
            </PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3 mb-md-0">
                    <select
                      className="form-select"
                      id="puesto"
                      name="puesto"
                      onChange={(e) => getAvaibles(e.target.value)}
                      required
                    >
                      <option value="">Seleccione una opción</option>
                      {positions.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.position.name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      htmlFor="puesto"
                      className="d-flex align-items-center fs-13px"
                    >
                      Listado de puestos disponibles
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="number"
                      className="form-control fs-15px"
                      id="expectativaSalarial"
                      name="expectativaSalarial"
                      placeholder="Expectativa Salarial al Mes"
                      min="0"
                      required
                    />
                    <label
                      htmlFor="expectativaSalarial"
                      className="d-flex align-items-center fs-13px"
                    >
                      Expectativa Salarial al Mes
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-floating mb-3 mb-md-0">
                    <textarea
                      rows="5"
                      className="form-control fs-15px"
                      id="experiencia"
                      name="experiencia"
                      required
                    ></textarea>
                    <label
                      htmlFor="experiencia"
                      className="d-flex align-items-center fs-13px"
                    >
                      Describe tu experiencia en el área
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-floating mb-3 mb-md-0">
                    <select
                      className="form-select"
                      id="horario"
                      name="horario"
                      required
                    >
                      <option value="">Seleccione una opción</option>
                      {shifts.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      htmlFor="horario"
                      className="d-flex align-items-center fs-13px"
                    >
                      Horario
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="number"
                      className="form-control fs-15px"
                      id="permanencia"
                      name="permanencia"
                      placeholder="Expectativa de permanencia en meses"
                      min="0"
                      required
                    />

                    <label
                      htmlFor="permanencia"
                      className="d-flex align-items-center fs-13px"
                    >
                      Expectativa de permanencia en meses
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <DatePicker
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                    selected={interviewDate}
                    onChange={handleChangeDate}
                    name="entrevista"
                    id="entrevista"
                    className="form-control p-3"
                    filterDate={filterDates}
                    minDate={moment()}
                    maxDate={moment().add(5, "days")}
                    zone="America/Mexico_City"
                    placeholderText="Fecha de la entrevista"
                    popperPlacement="bottom-end"
                    calendarContainer={({ children }) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                            height: "100%",
                          }}
                        >
                          <div
                            className="d-flex visible bottom-100 justify-content-center bg-white p-2  "
                            style={{
                              backgroundColor: "white",
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: "5px",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              {children}
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="col-md-6">
                  {/* <div className="form-floating mb-3 " > */}
                  {/* <DateTime onChange={handleHoraEntrevista} dateFormat={false} inputProps={{ placeholder: 'Hora de la entrevista', className: 'form-control p-3' }} /> */}
                  <select
                    className=" form-control p-3"
                    id="horarioE"
                    name="horarioE"
                    required
                  >
                    <option value="">Hora de la entrevista</option>
                    {horas?.map((item, index) => {
                      // let horarender = horasAgendadas[item] != 5 ? <option key={index} value={item}>{item}</option> : null
                      let horarender = horasAgendadas[item] ? null : (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                      // console.log(horarender)
                      return horarender;
                    })}
                  </select>
                  {/* <label htmlFor="horario" className="d-flex align-items-center fs-13px">Hora de la entrevista</label> */}

                  {/* </div> */}
                </div>
              </div>
            </PanelBody>
          </Panel>
          <Panel>
            <PanelBody>
              <div className="row noGutters">
                <div className="col-md-6"></div>
                <div
                  className="col-md-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <button
                    type="submit"
                    className="btn btn-amarillo d-block h-45px w-75 btn-lg fs-14px"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </form>
      </div>
      <div className="h-75px"></div>
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
        }}
      />
    </div>
  );
}

export default FormPreguntasInciales;
