import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";

const CustomSelectField = ({
  formik,
  field,
  list,
  label,
  sm = 9,
  smLabel = 3,
  onChange = () => {},
  placeholder = label,
  updateFormik = (e) => {},
  disabled = false,
  otherPlaceHolder = false,
}: IFormikProps & {
  field: string;
  list: any[];
  label: string;
  onChange?: () => void;
  sm?: number;
  placeholder?: string;
  updateFormik?: (e: any) => void;
  smLabel?: number;
  disabled?: boolean;
  otherPlaceHolder?: boolean;
}) => {
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  return (
    <React.Fragment>
      <div className={`row mb-4 fs-13px col-md-12`}>
        <label
          className={`form-label col-form-label col-md-${smLabel} text-end`}
          style={{ color: error ? "red" : "inherit" }}
        >
          {label}
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        </label>

        <div className={`col-md-${sm}`}>
          <select
            className={`form-select form-control fs-14px ${
              error ? "is-invalid" : ""
            }`}
            id="floatingSelect"
            name="warranty_type_id"
            onChange={(e) => {
              onChange();
              formik.setFieldValue(field, Number(e.target.value));
              updateFormik(e);
            }}
            style={{
              color: error ? "red" : "inherit",
              width: "100%",
              display: "block",
            }}
            value={formik.values[field] !== -99 ? formik.values[field] : "_"}
            disabled={disabled}
          >
            <option value="_" disabled>
              {!otherPlaceHolder
                ? `-- Selecciona un ${placeholder} --`
                : placeholder}
            </option>
            {list.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
          {formik.touched[field] && formik.errors[field] && (
            <div
              className="invalid-feedback"
              style={{
                marginTop: "1px",
                display: "flex",
                marginLeft: "0.25em",
              }}
            >
              {formik.touched[field] && formik.errors[field] ? (
                <>{formik.errors[field]}</>
              ) : (
                <p> </p>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomSelectField;
