import moment from "moment";
import { notification } from "./Notifications.tsx";

export const APP_URL = String(process.env.REACT_APP_APP_URL);

export const colors = [
  "#FFDFDF", // Very Light Pink (warm)
  "#D4F0F0", // Very Light Blue-Green (cool)
  "#FFE8D8", // Very Light Peach (warm)
  "#E0E0FF", // Very Light Lavender (cool)
  "#FF9C9C", // Very Light Red (warm)
  "#C0E4E4", // Very Light Blue-Grey (cool)
  "#FFDCDC", // Very Light Coral (warm)
  "#D5F8D5", // Very Light Pale Green (cool)
  "#FF9F80", // Very Light Coral (warm)
  "#F2F2F9", // Very Light Lavender (cool)
  "#FFEFD0", // Very Light Peach Puff (warm)
  "#E1A6E1", // Very Light Purple-Pink (cool)
  "#F2B9FF", // Very Light Purple (cool)
  "#C3D9FF", // Very Light Blue (cool)
  "#F5D0A5", // Very Light Sandy Brown (warm)
  "#E0FFFF", // Very Light Cyan (cool)
  "#FFECD5", // Very Light Navajo White (warm)
  "#F2A8F1", // Very Light Orchid (cool)
  "#FFD9D8", // Very Light Misty Rose (warm)
  "#C9E0E6", // Very Light Blue-Grey (cool)
  "#FF6F3F", // Very Light Red-Orange (warm)
  "#D4F0F0", // Very Light Blue-Green (cool)
  // Duplicated and re-ordered to maintain contrast
  "#FFDFDF", // Very Light Pink (warm)
  "#D4F0F0", // Very Light Blue-Green (cool)
  "#FFE8D8", // Very Light Peach (warm)
  "#E0E0FF", // Very Light Lavender (cool)
  "#FF9C9C", // Very Light Red (warm)
  "#C0E4E4", // Very Light Blue-Grey (cool)
  "#FFDCDC", // Very Light Coral (warm)
  "#D5F8D5", // Very Light Pale Green (cool)
  "#FF9F80", // Very Light Coral (warm)
  "#F2F2F9", // Very Light Lavender (cool)
  "#FFEFD0", // Very Light Peach Puff (warm)
  "#E1A6E1", // Very Light Purple-Pink (cool)
  "#F2B9FF", // Very Light Purple (cool)
  "#C3D9FF", // Very Light Blue (cool)
  "#F5D0A5", // Very Light Sandy Brown (warm)
  "#E0FFFF", // Very Light Cyan (cool)
  "#FFECD5", // Very Light Navajo White (warm)
  "#F2A8F1", // Very Light Orchid (cool)
  "#FFD9D8", // Very Light Misty Rose (warm)
  "#C9E0E6", // Very Light Blue-Grey (cool)
  "#FF6F3F", // Very Light Red-Orange (warm)
  "#D4F0F0", // Very Light Blue-Green (cool)
];


export const occupiedArray = [
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33
];

export const weekDays = [
  { id: 0, name: "Lunes", value: "Lunes" },
  { id: 1, name: "Martes", value: "Martes" },
  { id: 2, name: "Miercoles", value: "Miercoles" },
  { id: 3, name: "Jueves", value: "Jueves" },
  { id: 4, name: "Viernes", value: "Viernes" },
  { id: 5, name: "Sabado", value: "Sabado" },
  { id: 6, name: "Domingo", value: "Domingo" },
];

export const shifts = [
  { id: 0, name: 'Matutino' },
  { id: 1, name: 'Intermedio' },
  { id: 2, name: 'Vespertino' },
  { id: 3, name: 'Todos' },
];

export function isOverlapping(layout, newItem) {
  const newItemId = layout.find((item) => item?.i === newItem?.i)?.originalId;

  const itemsFounds = layout.filter((item) => item.originalId === newItemId);

  // Helper function to check if two ranges overlap
  const rangesOverlap = (start1, end1, start2, end2) => {
    return Math.max(start1, start2) < Math.min(end1, end2);
  };

  // Check for overlaps between the new item and any existing items
  let overlapCount = 0;
  for (let i = 0; i < itemsFounds.length; i++) {
    const item = itemsFounds[i];
    if (rangesOverlap(newItem.x, newItem.x + newItem.w, item.x, item.x + item.w)) {
      overlapCount++;
    }
  }

  // If more than two items overlap
  if (overlapCount > 2) {
    notification("Alerta", "Un colaborador no puede tener más de 2 puestos al mismo tiempo", "info");
    return false;
  }

  return true;
}


export function getBackgroundColor(item, i, positionsArray) {
  const whitePercentage = item.w <= 16 ? 100 : (16 / item.w) * 100;
  const positionIndex = positionsArray.findIndex(
    (record) =>
      record?.positionId === positionsArray[item.y]?.positionId
  );
  const color = colors[positionIndex];
  return {
    backgroundImage: "none",
    backgroundColor: `${color}`,
    border: `1px solid ${color}00`,
    borderLeft: `3px solid #f005`,
    background: `linear-gradient(to right, ${color} ${whitePercentage}%, #f005 ${whitePercentage}%, #f005 ${100}%)`,
  };
};

export function getBackgroundColorColaborators(item, i, layout) {
  const count = layout.filter(item => item.y === i);

  if (count.length === 0) return "FFF"

  const xOccupiedSet = new Set();
  count.forEach(item => {
    for (let i = item.x; i < item.x + item.w; i++) {
      xOccupiedSet.add(i);
    }
  });
  const xOccupied = Array.from(xOccupiedSet);
  const sorted = xOccupied.sort((a, b) => a - b);

  if (JSON.stringify(sorted) === JSON.stringify(occupiedArray)) {
    return "#e5f5e0"
  } else {
    return "#fff7bc"
  }
}

const getWeekRange = (startDate) => {
  const dayOfWeek = startDate.getDay();
  const startOfWeek = new Date(startDate);
  const endOfWeek = new Date(startDate);

  startOfWeek.setDate(startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const startStr = formatDate(startOfWeek);
  const endStr = formatDate(endOfWeek);

  return `${startStr} a ${endStr}`;
};

const getFormatWeekRange = (startDate) => {
  const dayOfWeek = startDate.getDay();
  const startOfWeek = new Date(startDate);
  const endOfWeek = new Date(startDate);

  // Calcular el inicio y fin de la semana
  startOfWeek.setDate(startDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('es-ES', { month: 'long' });
    return `${day} de ${month}`;
  };

  const startStr = formatDate(startOfWeek);
  const endStr = formatDate(endOfWeek);

  return `${startStr} al ${endStr}`;
};

const today = new Date();

export const weeks = [
  {
    id: 0,
    name: getFormatWeekRange(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
    value: getWeekRange(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)),
    exactDates: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 - today.getDay() + (today.getDay() === 0 ? -6 : 1)),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 - today.getDay() + (today.getDay() === 0 ? -6 : 1) + 6),
    },
  },
  {
    id: 1,
    name: getFormatWeekRange(new Date()),
    value: getWeekRange(new Date()),
    exactDates: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1) + 6),
    },
  },
  {
    id: 2,
    name: getFormatWeekRange(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    value: getWeekRange(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    exactDates: {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 - today.getDay() + (today.getDay() === 0 ? -6 : 1)),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7 - today.getDay() + (today.getDay() === 0 ? -6 : 1) + 6),
    },
  },
];

export function generateReportObject(positions, assignments, values) {
  // Define horarios de cada turno
  const shiftSchedules = {
    0: { start: '07:00', end: '14:30' },
    1: { start: '10:00', end: '18:00' },
    2: { start: '14:30', end: '23:00' },
  };

  const shifts = ['Matutino', 'Intermedio', 'Vespertino'];

  const timeStringToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const minutesToTimeString = (minutes) => {
    const hours = String(Math.floor(minutes / 60)).padStart(2, '0');
    const mins = String(minutes % 60).padStart(2, '0');
    return `${hours}:${mins}`;
  };

  const generateScheduleForShift = (shift, defaultStartTime, defaultEndTime) => {
    let schedules = [];

    positions.forEach(pos => {
      const positionAssignments = assignments
        .filter(a => a.positionName === pos.positionName && a.shift === shift)
        .sort((a, b) => timeStringToMinutes(a.startTime) - timeStringToMinutes(b.startTime));

      let lastEndTime = defaultStartTime;

      positionAssignments.forEach(assignment => {
        const assignmentStartMinutes = timeStringToMinutes(assignment.startTime);
        const assignmentEndMinutes = timeStringToMinutes(assignment.endTime);
        const lastEndMinutes = timeStringToMinutes(lastEndTime);

        if (assignmentStartMinutes > lastEndMinutes) {
          schedules.push({
            isOccupied: false,
            startTime: lastEndTime,
            endTime: assignment.startTime,
            occupiedBy: null
          });
        }

        // Add occupied time
        schedules.push({
          isOccupied: true,
          startTime: assignment.startTime,
          endTime: assignment.endTime,
          occupiedBy: `${assignment.partner.person.firstName} ${assignment.partner.person.lastName} ${assignment.partner.person.secondLastName}`
        });

        lastEndTime = assignment.endTime;
      });

      if (timeStringToMinutes(lastEndTime) < timeStringToMinutes(defaultEndTime)) {
        schedules.push({
          isOccupied: false,
          startTime: lastEndTime,
          endTime: defaultEndTime,
          occupiedBy: null
        });
      }
    });

    // Agrupar horarios "Sin asignar"
    const assignedSchedules = schedules.filter(schedule => schedule.isOccupied);
    const unassignedSchedules = schedules.filter(schedule => !schedule.isOccupied);

    return [...assignedSchedules, ...unassignedSchedules];
  };

  let result;

  if (values.shift === 3) {
    // Generar reportes para todos los turnos
    result = shifts.map((shift, index) => ({
      shift,
      schedule: positions.map(pos => ({
        position: pos.positionName,
        schedules: generateScheduleForShift(index, shiftSchedules[index].start, shiftSchedules[index].end)
      }))
    }));
  } else {
    // Generar reporte para un turno específico
    const shift = shifts[values.shift];
    result = [{
      shift,
      schedule: positions.map(pos => ({
        position: pos.positionName,
        schedules: generateScheduleForShift(values.shift, shiftSchedules[values.shift].start, shiftSchedules[values.shift].end)
      }))
    }];
  }

  return result;
}


export function getDateByDayOfWeek(dateRange, dayOfWeek) {
  const parseDate = (dateStr) => new Date(dateStr.split('-').reverse().join('-'));

  const [startDateStr, endDateStr] = dateRange.split(' a ');
  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);

  const daysOfWeek = {
    'Domingo': 0,
    'Lunes': 1,
    'Martes': 2,
    'Miercoles': 3,
    'Jueves': 4,
    'Viernes': 5,
    'Sábado': 6
  };

  const targetDayIndex = daysOfWeek[dayOfWeek];
  if (targetDayIndex === undefined) {
    throw new Error('Invalid day of the week');
  }

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === targetDayIndex) {
      return new Date(d - 1);
    }
  }

  // Return null if no matching day is found
  return null;
}

export function generateTimeSlots(schedule) {
  function timeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
    const mins = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}`;
  }

  function createTimeSlots(start, end) {
    let slots = [];
    let startTime = timeToMinutes(start);
    let endTime = timeToMinutes(end);

    for (let i = startTime; i < endTime; i += 30) {
      slots.push({
        key: slots.length,
        display: `${minutesToTime(i)} - ${minutesToTime(i + 30)}`
      });
    }

    return slots;
  }

  return [
    {
      shift: "Matutino",
      ranges: createTimeSlots(schedule.matutinoStart, schedule.matutinoEnd)
    },
    {
      shift: "Intermedio",
      ranges: createTimeSlots(schedule.intermedioStart, schedule.intermedioEnd)
    },
    {
      shift: "Vespertino",
      ranges: createTimeSlots(schedule.vespertinoStart, schedule.vespertinoEnd)
    }
  ];
}

const screenWith = window.innerWidth - 40;
const greenColumns = 16;
const totalColumns = 34;
const columnWidth = 2400 / totalColumns;
const columnWidthFullScreen = screenWith / totalColumns;
const lineWidth = 1; // Ancho de la línea gris entre columnas

export function generateGratientTemp(totalColumns) {
  const columnWidth = 2400 / totalColumns;

  return `
    repeating-linear-gradient(
      to right,
      transparent,
      transparent ${columnWidth - lineWidth}px,
      #EEEEEE ${columnWidth - lineWidth}px,
      #EEEEEE ${columnWidth}px
    )
  `;
}

export function generateGradientBackground(hoursRange, hours, shift) {
  const lineWidth = 1; // Ancho de la línea gris entre columnas
  const hoursRanges = generateTimeSlots(hours);
  const columnWidth = 2400 / hoursRange.length;

  console.log(hoursRanges);

  const transpaentColumnsInitial = (() => {
    if (shift === 0) {
      return 0;
    }
  
    if (shift === 1) {
      const index = hoursRanges[0].ranges.findIndex(range => range.display === hoursRanges[1].ranges[0].display);
      return index + 2;
    }
  
    return hoursRanges[0].ranges.length + 2 + hoursRanges[0].ranges.findIndex(range => range.display === hoursRanges[1].ranges[0].display);
  })();
  
  const greenColumns = shift === 0 ? (hoursRanges[0].ranges.length + 2) : shift === 1 ? hoursRanges[1].ranges.length : hoursRanges[2].ranges.length;

  if (shift === 3) {
    return `
      repeating-linear-gradient(
        to right,
        transparent,
        transparent ${columnWidth - lineWidth}px,
        #EEEEEE ${columnWidth - lineWidth}px,
        #EEEEEE ${columnWidth}px
      )
    `;
  }

  const gradientBackground = `
    linear-gradient(to right, 
      transparent ${columnWidth * transpaentColumnsInitial}px, /* Primeras 8 columnas transparentes */
      #90EE9044 ${columnWidth * transpaentColumnsInitial}px, /* Comienzo del verde */
      #90EE9044 ${columnWidth * (greenColumns + transpaentColumnsInitial)}px, /* Las siguientes 16 columnas en verde */
      transparent ${columnWidth * (greenColumns + transpaentColumnsInitial)}px, /* Resto transparente */
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      transparent,
      transparent ${columnWidth - lineWidth}px,
      #EEEEEE ${columnWidth - lineWidth}px,
      #EEEEEE ${columnWidth}px
    )
  `;

  return gradientBackground;
}


export const gradientBackground = `
  repeating-linear-gradient(
    to right,
    transparent,
    transparent ${columnWidth - lineWidth}px,
    #EEEEEE ${columnWidth - lineWidth}px,
    #EEEEEE ${columnWidth}px
  )
`;

export const gradientBackgroundFullScreen = `
  repeating-linear-gradient(
    to right,
    transparent,
    transparent ${columnWidthFullScreen - lineWidth}px,
    #EEEEEE ${columnWidthFullScreen - lineWidth}px,
    #EEEEEE ${columnWidthFullScreen}px
  )
`;

export const gradientBackgroundMatutino = `
  linear-gradient(to right, 
    #90EE9044 ${columnWidth * (greenColumns + 1) - lineWidth}px,
    transparent ${columnWidth * (greenColumns + 1) - lineWidth}px,
    transparent 100%
  ),
  repeating-linear-gradient(
    to right,
    transparent,
    transparent ${columnWidth - lineWidth}px,
    #EEEEEE ${columnWidth - lineWidth}px,
    #EEEEEE ${columnWidth}px
  )
`;

export const gradientBackgroundIntermedio = `
linear-gradient(to right, 
  transparent ${columnWidth * 8}px, /* Primeras 8 columnas transparentes */
  #90EE9044 ${columnWidth * 8}px, /* Comienzo del verde */
  #90EE9044 ${columnWidth * 24}px, /* Las siguientes 16 columnas en verde */
  transparent ${columnWidth * 24}px, /* Resto transparente */
  transparent 100%
),
repeating-linear-gradient(
  to right,
  transparent,
  transparent ${columnWidth - lineWidth}px,
  #EEEEEE ${columnWidth - lineWidth}px,
  #EEEEEE ${columnWidth}px
)
`;

export const gradientBackgroundVespertino = `
linear-gradient(to right, 
  transparent ${columnWidth * 17}px, /* Primeras 17 columnas transparentes */
  #90EE9044 ${columnWidth * 17}px, /* Comienzo del verde */
  #90EE9044 ${columnWidth * 34}px, /* Las siguientes 17 columnas en verde */
  transparent ${columnWidth * 34}px, /* Resto transparente */
  transparent 100%
),
repeating-linear-gradient(
  to right,
  transparent,
  transparent ${columnWidth - lineWidth}px,
  #EEEEEE ${columnWidth - lineWidth}px,
  #EEEEEE ${columnWidth}px
)
`;

export function generateTimeIntervals(startTime, endTime) {
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const minutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  const intervals = [];
  let currentStart = startMinutes;

  while (currentStart < endMinutes) {
    const currentEnd = currentStart + 30;
    if (currentEnd > endMinutes) break;

    intervals.push({
      key: intervals.length,
      display: `${minutesToTime(currentStart)} - ${minutesToTime(currentEnd)}`
    });

    currentStart = currentEnd;
  }

  return intervals;
}

export function generateShiftReport(users, positions, morningStart, morningEnd, midStart, midEnd, eveningStart, eveningEnd) {
  // Convert time strings to minutes for easy comparison
  function timeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Helper function to format minutes back to time string
  function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
    const mins = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}`;
  }

  // Helper function to get unoccupied time ranges
  function getUnoccupiedRanges(start, end, occupiedRanges) {
    let unoccupiedRanges = [];
    let currentStart = timeToMinutes(start);
    const endTime = timeToMinutes(end);

    occupiedRanges.sort((a, b) => timeToMinutes(a.startTime) - timeToMinutes(b.startTime));

    occupiedRanges.forEach(range => {
      const rangeStart = timeToMinutes(range.startTime);
      const rangeEnd = timeToMinutes(range.endTime);

      if (currentStart < rangeStart) {
        unoccupiedRanges.push({
          startTime: minutesToTime(currentStart),
          endTime: minutesToTime(rangeStart)
        });
      }

      currentStart = Math.max(currentStart, rangeEnd);
    });

    if (currentStart < endTime) {
      unoccupiedRanges.push({
        startTime: minutesToTime(currentStart),
        endTime: minutesToTime(endTime)
      });
    }

    return unoccupiedRanges;
  }

  // Initialize report object
  const report = {
    Matutino: [],
    Intermedio: [],
    Vespertino: []
  };

  // Populate the report with all positions
  positions.forEach(position => {
    report[position.shift].push({
      positionName: position.positionName,
      originalPositionName: position.originalPositionName,
      shift: position.shift,
      occupiedBy: [],
      unoccupiedBy: []
    });
  });

  // Process each user
  users.forEach(user => {
    const userPosition = user.positionName;
    const userShift = user.shift;
    const userStart = user.startTime.split(' - ')[0];
    const userEnd = user.endTime.split(' - ')[1];

    // Find the matching position in the report
    const shiftReport = report[userShift];
    const positionReport = shiftReport.find(position => position.positionName === userPosition);

    if (positionReport) {
      positionReport.occupiedBy.push({
        user: `${user.partner.person.firstName} ${user.partner.person.lastName} ${user.partner.person.secondLastName}`,
        startTime: userStart,
        endTime: userEnd
      });
    }
  });

  // Calculate unoccupied times for each position in the report
  for (const shift in report) {
    let shiftStart, shiftEnd;
    switch (shift) {
      case 'Matutino':
        shiftStart = morningStart;
        shiftEnd = morningEnd;
        break;
      case 'Intermedio':
        shiftStart = midStart;
        shiftEnd = midEnd;
        break;
      case 'Vespertino':
        shiftStart = eveningStart;
        shiftEnd = eveningEnd;
        break;
    }

    report[shift].forEach(position => {
      position.unoccupiedBy = getUnoccupiedRanges(shiftStart, shiftEnd, position.occupiedBy);
    });
  }

  // Create a summary report array
  const summaryReport = Object.keys(report).map(shift => ({
    shift,
    positions: report[shift].map(position => ({
      positionName: position.positionName,
      originalPositionName: position.originalPositionName,
      shift: position.shift,
      occupiedBy: position.occupiedBy.length ? position.occupiedBy : 'Unoccupied',
      unoccupiedBy: position.unoccupiedBy.length ? position.unoccupiedBy : 'Fully Occupied'
    }))
  }));

  return summaryReport;
} 

export function checkUserTiming(userSchedules, userCheckIn, config) {
  // Verificar si userSchedules es un array y tiene elementos
  if (!Array.isArray(userSchedules) || userSchedules.length === 0) {
    return { status: "Fuera de horario", evaluatedSchedule: null };
  }

  // Verificar si userCheckIn es una cadena y tiene el formato correcto
  const checkInTime = moment(userCheckIn, 'HH:mm:ss', true);
  if (!checkInTime.isValid()) {
    return { status: "Hora de entrada inválida", evaluatedSchedule: null };
  }

  // Verificar si config tiene las propiedades necesarias
  if (!config || !config.onTime || !config.late || 
      typeof config.onTime.beforeMinutes !== 'number' || 
      typeof config.onTime.afterMinutes !== 'number' ||
      typeof config.late.beforeMinutes !== 'number' ||
      typeof config.late.afterMinutes !== 'number') {
    return { status: "Configuración inválida", evaluatedSchedule: null };
  }

  const parseTime = (time) => moment(time, 'HH:mm:ss');

  const isWithinRange = (checkTime, startTime, endTime, beforeMinutes, afterMinutes) => {
    const start = startTime.clone().subtract(beforeMinutes, 'minutes');
    const end = endTime.clone().add(afterMinutes, 'minutes');
    return checkTime.isBetween(start, end, null, '[]');
  };

  for (const schedule of userSchedules) {
    // Verificar que schedule tiene las propiedades necesarias
    if (!schedule.startTime || !schedule.endTime) {
      return { status: "Datos de horario inválidos en la programación", evaluatedSchedule: null };
    }

    // Convertir startTime y endTime en objetos moment
    const [assignedStartTime, assignedEndTime] = schedule.startTime.split(' - ').map(parseTime);

    // Verificar si los horarios son válidos
    if (!assignedStartTime.isValid() || !assignedEndTime.isValid()) {
      return { status: "Horario de inicio o fin inválido", evaluatedSchedule: null };
    }

    // Verificar entrada
    if (isWithinRange(checkInTime, assignedStartTime, assignedEndTime, config.onTime.beforeMinutes, config.onTime.afterMinutes)) {
      return { status: "Asistencia", evaluatedSchedule: schedule };
    } else if (isWithinRange(checkInTime, assignedStartTime, assignedEndTime, config.late.beforeMinutes, config.late.afterMinutes)) {
      return { status: "Retardo", evaluatedSchedule: schedule };
    }

    // Verificar salida si es un registro de salida
    if (checkInTime.isBetween(assignedEndTime.clone().subtract(config.late.afterMinutes, 'minutes'), assignedEndTime.clone().add(config.late.afterMinutes, 'minutes'), null, '[]')) {
      return { status: "Salida registrada", evaluatedSchedule: schedule };
    } else if (checkInTime.isAfter(assignedEndTime.clone().add(config.late.afterMinutes, 'minutes'))) {
      return { status: "Salida registrada", evaluatedSchedule: schedule };
    }
  }

  return { status: "Fuera de horario", evaluatedSchedule: null };
}
