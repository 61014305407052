import React from "react";
import { useDispatch } from "react-redux";
// import { setMenu } from "../../store/slices/colaboradoresSlice.ts";
import { setMenu } from "../../store/slices/catalogSlice.ts";
import { MenuSelectedEnum } from "../../types/CatalogSliceTypes.ts";

const TabsCatalog = () => {
  const dispatch = useDispatch();

  return (
    <ul className="nav nav-tabs nav-tabs-v2 px-3">
      <li className="nav-item me-2">
        <a
          href="#/"
          data-bs-toggle="tab"
          className="nav-link px-2 active"
          onClick={() => dispatch(setMenu(MenuSelectedEnum.branch))}
        >
          Sucursales
        </a>
      </li>
      <li className="nav-item me-2">
        <a
          href="#/"
          onClick={() => dispatch(setMenu(MenuSelectedEnum.position))}
          data-bs-toggle="tab"
          className="nav-link px-2"
        >
          Puestos
        </a>
      </li>
      <li className="nav-item me-2">
        <a
          href="#/"
          onClick={() =>
            dispatch(setMenu(MenuSelectedEnum.shift))
          }
          data-bs-toggle="tab"
          className="nav-link px-2"
        >
          Turnos
        </a>
      </li>
      <li className="nav-item me-2">
        <a
          href="#/"
          onClick={() =>
            dispatch(setMenu(MenuSelectedEnum.schooling))
          }
          data-bs-toggle="tab"
          className="nav-link px-2"
        >
          Escolaridad
        </a>
      </li>
      <li className="nav-item me-2">
        <a
          href="#/"
          onClick={() =>
            dispatch(setMenu(MenuSelectedEnum.size))
          }
          data-bs-toggle="tab"
          className="nav-link px-2"
        >
          Tallas
        </a>
      </li>
    </ul>
  );
};

export default TabsCatalog;
