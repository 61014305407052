import React, { ForwardedRef } from "react";
import QRCode from "react-qr-code";
import { IPrint } from "../../interfaces/others/IPrint";
import "./print.css";
import { APP_URL } from "../../utils/constanst";

const Print: React.FC<IPrint> = React.forwardRef(
  (props: IPrint, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
        className="print-content"
      >
        <div
        style={{
          width: "100%",
          height: "50%",
          backgroundColor: "blue",
          position: "relative",
          marginBottom: "20px",
        }}
        >
          <div style={{ width: "100%", height: "50%", backgroundColor: "#f24242" }} />
          <div style={{ width: "100%", height: "50%", backgroundColor: "white" }} />
          <div style={{ position: "absolute", top: "50%", left: "50%", width:"100%", transform: "translate(-50%, -50%)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <h1 style={{ textAlign: "center", color: "#fff", fontSize: "4em", fontWeight: "bold", alignSelf: "center", marginBlock: '20px'}}>Sucursal {props.branch}</h1>
            <img
              src={props.photo}
              alt="profile"
              style={{ width: "30%", aspectRatio: "1/1", objectFit: "cover", borderRadius: "50%"}}
            />
            <h1 style={{ textAlign: "center", fontSize: "3.3em", fontWeight: "bold", alignSelf: "center", marginTop: '10px'}}>{props.name} {props.lastName} {props.secondLastName}</h1>
            <h1 style={{ textAlign: "center", color: "#f24242", fontSize: "4em", fontWeight: "bold", alignSelf: "center"}}>{props.position}</h1>
            <h2 style={{ textAlign: "center", fontSize: "2.8em", fontWeight: "bold", alignSelf: "center"}}>Horario: {props.shift}</h2>
          </div>
        </div>
        <div style={{paddingInline: '100px', display: 'flex', flexDirection: 'row'}}>
          
          
          <div style={{display:'flex', flex:1, flexDirection: 'column', marginRight: '40px'}}>
            <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'right'}}>Dirección <i className="fa-solid fa-house ms-2" /></p>
              <p style={{fontSize: '20px', textAlign: 'right' }}>{props.address}</p>
            </div>
            <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'right'}}>Estado de salud <i className="fa-solid fa-file-medical ms-2" /></p>
              <p style={{fontSize: '20px', textAlign: 'right' }}>{props?.health}</p>
            </div>
            
            <div style={{marginBlock: '30px'}}>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'right'}}>Escolaridad <i className="fa-solid fa-graduation-cap ms-2"></i></p>
              <p style={{fontSize: '20px', textAlign: 'right'}}>{props.escolarity}</p>
            </div>
            <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'right'}}>Fecha de nacimiento <i className="fa-solid fa-calendar ms-2" /></p>
              <p style={{fontSize: '20px', textAlign: 'right' }}>{props.birthdate}</p>
            </div>
          </div>


          <div style={{display:'flex', flex:1, flexDirection: 'column', marginLeft: '40px'}}>
            <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px'}}><i className="fa-solid fa-phone me-2" /> Número telefónico</p>
              <p style={{fontSize: '20px' }}>{props.phone}</p>
            </div>
            <div style={{marginBlock: '10px'}}>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px'}}><i className="fa-solid fa-people-group"></i> Hijos</p>
              <p style={{fontSize: '20px'}}>{props?.children}</p>
            </div>
            <div style={{marginBlock: '10px'}}>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px'}}><i className="fa-solid fa-heart-pulse"></i> Enfermedades crónicas</p>
              <p style={{fontSize: '20px'}}>{props?.diseases}</p>
            </div>
            {/* <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'right'}}>Enfermedades crónicas <i className="fa-solid fa-heart-pulse ms-2" /></p>
              <p style={{fontSize: '20px', textAlign: 'right' }}>{props?.diseases}</p>
            </div> */}
            <div style={{marginBlock: '10px'}}>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px'}}><i className="fa-solid fa-shirt me-2"></i> Talla</p>
              <p style={{fontSize: '20px'}}>{props.size}</p>
            </div>
            <div>
              <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px'}}><i className="fa-solid fa-calendar me-2"></i> Fecha de ingreso</p>
              <p style={{fontSize: '20px' }}>{props.entryDate}</p>
            </div>
          </div>

        </div>
        <div>
          <p style={{fontSize: '17px', fontWeight: 700, marginBottom: '5px', textAlign: 'center', marginTop: '20px'}}><i className="fa-solid fa-calendar me-2"></i> Comentarios</p>
          <p style={{fontSize: '20px', textAlign: 'center' }}>{props.comments}</p>
        </div>
        <div style={{position: 'absolute', bottom: '40px', right: '40px',}}>
          <QRCode value={`${APP_URL}/solicitudes/aprobar/${props.id}`} size={180} />
        </div>
      </div>
    );
  }
);

export default Print;
