import moment from "moment";
import React from "react";

const ModalDetailsAssistance = ({ record, closeFn }) => {
  const coords = record?.coords ? JSON.parse(record?.coords) : {};

  console.log("record", record);
  console.log("coords", coords);

  return (
    <div>
      <div>
        <h3>Datos generales</h3>
        <div className="mt-4">
          <div className="d-flex flex-row">
            <h5 className="me-2">Turno:</h5>
            <p>{record?.branchSchedule?.shift}</p>
          </div>
          <div className="d-flex flex-row">
            <h5 className="me-2">Fecha:</h5>
            <p>
              {moment(record?.branchSchedule?.exactDate).format(
                "dddd, D [de] MMMM [de] YYYY"
              )}
            </p>
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex flex-row col-6">
              <h5 className="me-2">Hora de entrada:</h5>
              <p>
                {record?.branchSchedule?.startTime.split(" - ")[0] ||
                  "Sin registro"}
              </p>
            </div>
            <div className="d-flex flex-row col-6">
              <h5 className="me-2">Hora de salida:</h5>
              <p>
                {record?.branchSchedule?.endTime?.split(" - ")[1] ||
                  "Sin registro"}
              </p>
            </div>
          </div>

          <div className="d-flex flex-row">
            <div className="d-flex flex-row col-6">
              <h5 className="me-2">Checado entrada:</h5>
              <p>
                {record?.branchSchedule?.assistanceCheck?.checkTime ||
                  "Sin registro"}
              </p>
            </div>
            <div className="d-flex flex-row col-6">
              <h5 className="me-2">Checado salida:</h5>
              <p>
                {record?.branchSchedule?.assistanceCheckout?.checkTime ||
                  "Sin registro"}
              </p>
            </div>
          </div>
          <div className="d-flex flex-row">
            <h5 className="me-2">Estatus:</h5>
            <p>{record?.branchSchedule?.assistanceStatus || "Sin registro"}</p>
          </div>
        </div>
      </div>
      <div className="py-4">
        <h3>Datos del dispositivo</h3>
        {!record?.branchSchedule?.assistanceCheck?.deviceId ? (
          <div className="mt-4">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Id:</h5>
                <p>{record?.deviceId}</p>
              </div>
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">IP:</h5>
                <p>{record?.ip}</p>
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Nombre:</h5>
                <p>{record?.deviceName}</p>
              </div>
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Modelo:</h5>
                <p>{record?.deviceModel}</p>
              </div>
            </div>
            <div>
              <h5 className="me-2">Ubicación:</h5>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.google.com/maps?q=${coords?.latitude},${coords?.longitude}&z=15&output=embed`}
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <div className="d-flex flex-row col-6">
              <h5 className="me-2">Id:</h5>
              <p>{record?.branchSchedule?.assistanceCheck?.deviceId}</p>
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Nombre:</h5>
                <p>{record?.branchSchedule?.assistanceCheck?.deviceName}</p>
              </div>
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Modelo:</h5>
                <p>{record?.branchSchedule?.assistanceCheck?.deviceModel}</p>
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">IP:</h5>
                <p>{record?.branchSchedule?.assistanceCheck?.ip}</p>
              </div>
              <div className="d-flex flex-row col-6">
                <h5 className="me-2">Municipio:</h5>
                <p>{record?.branchSchedule?.assistanceCheck?.location}</p>
              </div>
            </div>
            <div>
              <h5 className="me-2">Ubicación:</h5>
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.google.com/maps?q=${coords?.latitude},${coords?.longitude}&z=15&output=embed`}
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <button className="btn btn-primary ms-auto" onClick={closeFn}>
        Cerrar
      </button>
    </div>
  );
};

export default ModalDetailsAssistance;
