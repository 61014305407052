import React from 'react'
import { PanelBody } from '../../panel/panel'
import CustomSelectField from '../../formik/CustomSelectField.tsx'
import { shifts, weekDays, weeks } from '../../../utils/constanst.js'
import CustomSelectValues from '../../formik/CustomSelectValues.tsx'

type Props = {}

const ReporteSucursalForm = ({ formik }) => {
  return (
    <PanelBody>
      <CustomSelectField
        formik={formik}
        field="week"
        label="Semana"
        list={weeks}
        sm={3}
        onChange={() =>
          formik.submitForm()
        }
      />
      <CustomSelectValues
        formik={formik}
        field="shift"
        label="Turno"
        sm={4}
        list={shifts}
        onChange={() =>
          formik.submitForm()
        }
      />
      <CustomSelectValues
        formik={formik}
        field="dayOfWeek"
        label="Día de la semana"
        sm={4}
        list={weekDays}
        onChange={() =>
          formik.submitForm()
        }
      />
    </PanelBody>
  )
}

export default ReporteSucursalForm