import httpClient, { httpFormDataClient } from "./httpClient";

const prefix = "/proceedings";

export async function uploadImage(file) {
  if(!file.size) return
  const formData = new FormData();
  formData.append("file", file);
  return (
    await httpFormDataClient.post(
      `${prefix}/upload-file`,
      formData
    )
  ).data;
}

export async function saveFileProceeding(data) {
  if(!data.fileId) return
  return (await httpClient.post(`${prefix}/save-file-proceeding`, data)).data;
}

export async function updateFileProceeding(id, data) {
  return (await httpClient.put(`${prefix}/update-file-proceeding/${id}`, data)).data;
}
