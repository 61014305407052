import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { PanelBody } from "../panel/panel.jsx";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { fetchDepartments } from "../../services/department.service.ts";

const ContractForm = ({ formik }: IFormikProps) => {
  const [departments, setDepartments] = React.useState([]);
  const getDependencies = async () => {
    try {
      const departments = await fetchDepartments();
      setDepartments(departments)
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  return (
    <React.Fragment>
      <div>
        <PanelBody>
          <CustomInput
            formik={formik}
            field="contractName"
            label="Nombre del contrato"
            placeholder="Contrato"
            sm={3}
          />
          <CustomSelectField
            formik={formik}
            field="idDepartment"
            list={departments}
            label="Departamento"
            sm={3}
          />
        </PanelBody>
      </div>
    </React.Fragment>
  );
};

export default ContractForm;
