import React from "react";
import {
  setQuery,
  setShowModalPosition,
} from "../../store/slices/puestosSlice.ts";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { Tooltip } from "@mui/material";
import ModalContainer from "../ModalContainer.tsx";
import EditContracts from "../catalogo/Sucursales/EditContracts.tsx";

const FilterPuestos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    type: "all",
    status: "all",
    param: "",
  };
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);

  const [edit, setEdit] = React.useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        console.log(queryString);
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filtros)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "param":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "type":
            keyName = "Razón social";
            valueName = value;
            break;
          case "active":
            keyName = "Activo";
            valueName = value === "true" ? "Si" : "No";
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter(
        (key) =>
          updatedFilters[key] !== -1 &&
          updatedFilters[key] !== "" &&
          updatedFilters[key] !== "Todos"
      )
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            updatedFilters[key]
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Razón social:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            value={filters.type}
          >
            <option value={"all"}>Todos</option>
            <option value={"Grupo Chili Guajili"}>Grupo Chili Guajili</option>
            <option value={"Grupo Guajaqueñisimo"}>Grupo Guajaqueñisimo</option>
          </select>
          <label>Estatus:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            value={filters.status}
          >
            <option value={"all"}>Todos</option>
            <option value={"true"}>Activos</option>
            <option value={"false"}>Inactivos</option>
          </select>
          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    type: "all",
                    param: "",
                    status: "all",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
            <Tooltip title="Agregar contratos" arrow>
              <button
                className="btn btn-verde"
                onClick={() => navigate(`/puestos/nuevo-contrato`)}
              >
                <i className="fa fa-file-contract"></i>
              </button>
            </Tooltip>
            <Tooltip title="Editar contratos" arrow>
              <button
                className="btn btn-azul"
                onClick={() => setEdit(true)}
              >
                <i className="fa fa-file-signature"></i>
              </button>
            </Tooltip>
            <button
              className="btn btn-red"
              onClick={() => navigate(`/puestos/nuevo`)}
            >
              Agregar Puestos
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters).map((filter: any) => (
            <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {toCamelCase(
                filter.keyName === "status" ? "Estatus" : filter.keyName
              )}
              &nbsp;:&nbsp;
              {["false", "true"].indexOf(filter.valueName) > -1
                ? filter.valueName === "true"
                  ? "Activos"
                  : "Inactivos"
                : filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))}
      </div>
      <ModalContainer
        open={edit}
        title="Editar Contratos"
        closeFn={() => setEdit(false)}
        size="lg"
      >
        <EditContracts closeFn={() => setEdit(false)} />
      </ModalContainer>

    </div>
  );
};

export default FilterPuestos;
