import * as Yup from "yup";

export const ImportBlackListSchema = Yup.object().shape({
  curp: Yup.string()
    .required("Campo requerido")
    .matches(
      /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/,
      "Ingresa una CURP válida"
    ),
  unbanDate: Yup.date().when("isPermanent", ([isPermanent], schema) => {
    return Boolean(isPermanent)
      ? schema.optional()
      : schema
          .required("Campo requerido")
          .min(new Date(), "La fecha no puede ser anterior al día de hoy");
  }),
  reason: Yup.string().required("Campo requerido"),
  isPermanent: Yup.boolean()
});
