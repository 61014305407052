import React, { useEffect } from 'react'
import { useAppSettings } from '../../../hooks/useAppSettings.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { readPositions } from '../../../services/position.service.ts'
import { useDispatch } from 'react-redux'
import { setPositions, setPositionsOriginal } from '../../../store/slices/sucursalesSlice.ts'
import { readPositionsByBranch, readTemplates, updatePositions } from '../../../services/branchSchedule.service.ts'
import { readBranch, updateBranch } from '../../../services/branch.service.ts'
import { notification } from '../../../utils/Notifications.tsx'
import Layout from '../../Layout.tsx'
import Title from '../../Title.tsx'
import { Panel, PanelHeader } from '../../panel/panel.jsx'
import SucursalFormGeneral from './SucursalFormGeneral.tsx'
import { useFormik } from 'formik'
import SucursalesFormPuestos from './SucursalesFormPuestos.tsx'
import Button from '../../Button.tsx'
import { BranchTemplateSchema } from '../../../validation/schemas/BranchTemplateSchema.ts'

type Props = {}

const SucursalesForm = (props: Props) => {
  useAppSettings()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState<any>(null);
  const [templates, setTemplates] = React.useState<any>(null);

  const { id } = useParams();

  React.useEffect(() => {
    const loadDependencies = async () => {
      setLoading(true);
      try {
        const positions = await readPositions();
        dispatch(setPositions(positions));
        dispatch(setPositionsOriginal(positions));

        const templates = await readTemplates();
        setTemplates(templates);

        if (id) {
          const branch = await readBranch(Number(id));
          setEditing(branch);

          const positionsA = await readPositionsByBranch(Number(id));
          console.log('positions', positionsA);

          const groupedPositions = positionsA.reduce((acc, current) => {
            const { position, shift, numberOfWorkers } = current;
            const positionId = position.id;

            // Busca un objeto existente en el acumulador que tenga el mismo puesto
            const existing = acc.find(item => item.puesto === positionId);

            if (existing) {
              // Si ya existe, suma los trabajadores al turno correspondiente
              if (shift.includes('Matutino')) {
                existing.matutino += Number(numberOfWorkers);
              }
              if (shift.includes('Intermedio')) {
                existing.intermedio += Number(numberOfWorkers);
              }
              if (shift.includes('Vespertino')) {
                existing.vespertino += Number(numberOfWorkers);
              }
            } else {
              // Si no existe, crea un nuevo objeto para este puesto
              acc.push({
                puesto: positionId,
                matutino: shift.includes('Matutino') ? Number(numberOfWorkers) : 0,
                intermedio: shift.includes('Intermedio') ? Number(numberOfWorkers) : 0,
                vespertino: shift.includes('Vespertino') ? Number(numberOfWorkers) : 0,
              });
            }

            return acc;
          }, []);

          formik.setFieldValue('puestos', groupedPositions);
        }
      } catch (error) {
        console.log('error', error);
        notification('Error al cargar los puestos', 'Sucedió algo al intentar cargar los puestos', 'danger');
      } finally {
        setLoading(false);
      }
    };

    loadDependencies();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: editing?.name ?? '',
      branchTemplate: editing?.branchTemplate ?? '',
      branch: editing?.branch ?? '',
      templates: templates ?? [],
      puestos: editing?.puestos ?? [],
    },
    validationSchema: BranchTemplateSchema,
    onSubmit: async (values) => {
      try {
        const name = values.name;
        const branchTemplate = values.branchTemplate;

        const branch = await updateBranch(Number(id), { name, templateBranch: branchTemplate });

        await updatePositions(branch.id, values);
        notification('Sucursal guardada', 'La sucursal se guardó correctamente', 'success');
      } catch (error) {
        console.log('error', error);
        notification('Error al guardar la sucursal', 'Sucedió algo al intentar guardar la sucursal', 'danger');
      } finally {
        navigate('/sucursales');
      }
    }
  });

  useEffect(() => {
    if (editing && editing?.id) {
      formik.setFieldValue('name', editing.name);
      formik.setFieldValue('branchTemplate', editing.branchTemplate);
      formik.setFieldValue('branch', editing.branch);
    }
  }, [editing]);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle={`${id ? 'Editar' : 'Nueva'} sucursal`}
        title={`${id ? 'Editar' : 'Nueva'} sucursal`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la plantilla
          </PanelHeader>
          <SucursalFormGeneral formik={formik} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales de la plantilla
          </PanelHeader>
          <SucursalesFormPuestos error={formik.errors.puestos} formik={formik} templates={templates} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SucursalesForm