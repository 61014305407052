import React from "react";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { updateAppoinmentTime } from "../../services/configuration.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { IModalEditTimeAppoinment } from "../../interfaces/others/IModalEditTimeAppoinment.ts";
import { EditTimeAppoinmentSchema } from "../../validation/schemas/EditTimeAppoinmentSchema.ts";

const EditTimeAppoinment: React.FC<IModalEditTimeAppoinment> = ({
  currentTime,
  setLoading,
  closeFn,
  refreshFn,
}) => {
  const formik = useFormik({
    initialValues: {
      time: currentTime,
    },
    validationSchema: EditTimeAppoinmentSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await updateAppoinmentTime(values.time);
      } catch (error) {
        notification(
          "error",
          "Error al actualizar el tiempo de la cita",
          "danger"
        );
      } finally {
        setLoading(true);
        refreshFn();
        closeFn();
      }
    },
  });

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="time"
        label="Tiempo por cita"
        placeholder="Escribe el tiempo por cita"
        type="number"
        sm={8}
        smLabel={4}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Actualizar
        </button>
      </div>
    </React.Fragment>
  );
};

export default EditTimeAppoinment;
