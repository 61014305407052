import * as Yup from "yup";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  last_name: Yup.string().required("Campo requerido"),
  second_last_name: Yup.string().required("Campo requerido"),
  email: Yup.string()
    .email("El correo electrónico no es válido")
    .required("Campo requerido"),
  phone: Yup.string()
    .required("Campo requerido")
    .matches(/^\d{10}$/, "Debe ser un número de 10 dígitos"),
  password: Yup.string()
    .min(5, "La contraseña debe tener al menos 5 caracteres")
    .required("Campo requerido"),
  id_rol: Yup.number().min(1, "Campo requerido").required("Campo requerido"),
  id_branch: Yup.number().when("id_rol", ([id_rol], schema) => {
    return Boolean(Number(id_rol) === 2)
      ? schema.required("Campo requerido").min(1, "Campo requerido")
      : schema;
  }),
  status: Yup.boolean().required("Campo requerido"),
});

export default UserSchema;

export const UserUpdateSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  last_name: Yup.string().required("Campo requerido"),
  second_last_name: Yup.string().required("Campo requerido"),
  email: Yup.string()
    .email("El correo electrónico no es válido")
    .required("Campo requerido"),
  phone: Yup.string()
    .required("Campo requerido")
    .matches(/^\d{10}$/, "Debe ser un número de 10 dígitos"),
  password: Yup.string().min(
    5,
    "La contraseña debe tener al menos 5 caracteres"
  ),
  id_rol: Yup.number().min(1, "Campo requerido").required("Campo requerido"),
  // id_branch: Yup.number().test(
  //   "id_branch",
  //   "Campo requerido",
  //   function (value) {
  //     const { id_rol } = this.parent;
  //     return id_rol !== 1 ? value !== undefined : true;
  //   }
  // ),
  id_branch: Yup.number().when("id_rol", ([id_rol], schema) => {
    return Boolean(Number(id_rol) === 2)
      ? schema.required("Campo requerido").min(1, "Campo requerido")
      : schema;
  }),
  status: Yup.boolean().required("Campo requerido"),
});
