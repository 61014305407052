import React, { useState, useEffect } from "react";
import { getPdfPreviewById } from "../../services/proceedings.service.ts";

const DocumentsViewer = ({ fileData }) => {
  const [previewUrls, setPreviewUrls] = useState({});

  useEffect(() => {
    const fetchPreviews = async () => {
      const urls = await Promise.all(
        fileData.map(async (file) => {
          if (file?.file?.extension === "pdf") {
            const url = await fetchPreview(file.file.id);
            return { id: file.file.id, url };
          } else {
            return { id: file.file.id, url: file.url };
          }
        })
      );
      const urlsMap = {};
      urls.forEach(({ id, url }) => {
        urlsMap[id] = url;
      });
      setPreviewUrls(urlsMap);
    };

    fetchPreviews();
  }, [fileData]);

  const fetchPreview = async (fileId) => {
    const response = await getPdfPreviewById(fileId);
    return response?.url;
  };

  const renderFile = (file, index) => {
    if (file?.file?.extension === "pdf") {
      return (
        <div
          key={index}
          style={{
            width: `100%`,
            height: "100%",
            backgroundColor: "#ccc",
            borderRadius: "5px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          {previewUrls[file.file.id] ? (
            <iframe
              src={previewUrls[file.file.id]}
              title="pdf"
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-scripts"
            ></iframe>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <img
              key={index}
              src={file.url}
              alt={file.name}
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                flex: 1,
                borderRadius: "5px",
                objectFit: "cover",
                backgroundColor: "#ccc",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <button
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => window.open(file.url, "_blank")}
              >
                <i className="fa-solid fa-download"></i>
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  return fileData.length === 1 ? (
    <div style={{ width: "100%", height: 300 }}>
      {fileData.map((file, index) => renderFile(file, index))}
    </div>
  ) : fileData.length === 2 ? (
    <div className="d-flex flex-row" style={{ width: "100%", height: 300 }}>
      {renderFile(fileData[0], 0)}
      {renderFile(fileData[1], 1)}
    </div>
  ) : fileData.length === 3 ? (
    <React.Fragment>
      <div className="d-flex flex-row" style={{ width: "100%", height: 300 }}>
        {renderFile(fileData[0], 0)}
        <div
          className="d-flex flex-column"
          style={{ width: "100%", height: 300 }}
        >
          <div className="h-50">{renderFile(fileData[1], 1)}</div>
          <div className="h-50">{renderFile(fileData[2], 2)}</div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    fileData.length > 3 && (
      <React.Fragment>
        <div className="d-flex flex-row" style={{ width: "100%", height: 300 }}>
          {renderFile(fileData[0], 0)}
          <div
            className="d-flex flex-column"
            style={{ width: "100%", height: 300 }}
          >
            <div className="h-50">{renderFile(fileData[1], 1)}</div>
            <div className="h-50 bg-primary" style={{ position: "relative" }}>
              {renderFile(fileData[2], 2)}
              <div
                className="text-white fw-600 d-flex justify-content-center align-items-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%",
                  height: "100%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
              >
                + {fileData.length - 3} archivos
              </div>
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => window.open(fileData[2].url, "_blank")}
              >
                <i className="fa-solid fa-download"></i>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default DocumentsViewer;
