import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChipStatus from "../history/ChipStatus.tsx";
import { formatDate } from "../../utils/index.ts";
import { Partner } from "../../models/Partner.ts";
import {
  setCurrentUser,
  setModalAcountant,
} from "../../store/slices/colaboradoresSlice.ts";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const NoIMSSRow = ({ partner }: { partner: Partner }) => {
  const dispatch = useDispatch();

  const handleShowModal = () => {
    dispatch(setCurrentUser(partner.id));
    dispatch(setModalAcountant(true));
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={
            partner.urlImage
              ? partner.urlImage
              : "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
          }
          onError={(e: any) => {
            e.target.onerror = null; // Prevents infinite looping when error source also fails
            e.target.src =
              "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg";
          }}
          alt=""
          className="mw-100 rounded-pill"
          style={{ width: "60px", height: "60px" }}
        />
        <div className="flex-1 ps-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h5>
              {
                toCamelCase(`
                  ${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}
                `)
              }
            </h5>{" "}
            <div>
              <ChipStatus status="Sin salario IMSS asignado" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>{`Solicitado: ${formatDate(partner?.candidate?.createdAt)}`}</p>
            <div>
              <button className="btn btn-primary " onClick={handleShowModal}>
                Revisar solicitud
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-gray-500" />
    </React.Fragment>
  );
};

export default NoIMSSRow;
