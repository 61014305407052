import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuery } from '../../store/slices/traspasosSlice.ts';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/index.ts';

const FilterTraspasos = () => {
  const dispatch = useDispatch();
  const [timer, setTimer] = React.useState<any>(null);
  const { filters: initialState } =
  useSelector((state: RootState) => state.traspasos);
  const [filters, setFilters] = React.useState(initialState);

  console.log('filters: ', filters)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));

        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filtros)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  return (
    <div>
      <div className="input-group mb-2">
        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un parámetro de búsqueda..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
            <button
              className={`btn ${filters.type === "rejected" ? "btn-primary" : "btn-secondary"}`}
              onClick={() => {
                setFilters({ ...filters, type: "rejected" });
                updateFilters({ type: "rejected" });
              }}
            >
              Rechazados
            </button>
            <button
              className={`btn ${filters.type === "pending" ? "btn-primary" : "btn-secondary"}`}
              onClick={() => {
                setFilters({ ...filters, type: "pending" });
                updateFilters({ type: "pending" });
              }}
            >
              Pendientes
            </button>
            <button
              className={`btn ${filters.type === "approved" ? "btn-primary" : "btn-secondary"}`}
              onClick={() => {
                setFilters({ ...filters, type: "approved" });
                updateFilters({ type: "approved" });
              }}
            >
              Aprobados
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterTraspasos;
