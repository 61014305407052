import React, { useEffect } from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import { readPositions } from "../../services/position.service.ts";
import { fetchBaseContractByDepartment } from "../../services/contract.service.ts";
import { fetchDepartments } from "../../services/department.service.ts";
import { BusinessNames } from "../../models/BusinessNames.ts";
import { readBusinessNames } from "../../services/businessName.service.ts";

const BaseContract = ({
  formik,
  originalField,
}: IFormikProps & {
  originalField: string;
}) => {
  const [businessNameList, setBusinessNameList] = React.useState<
    BusinessNames[]
  >([]);
  const [positions, setPositions] = React.useState<[]>([]);
  const [selectedPositions, setSelectedPositions] = React.useState<Set<number>>(new Set());

  const loadResponsabilities = async () => {
    try {
      const response = await fetchBaseContractByDepartment(formik.values.idDepartment);
      setPositions(response);


    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    loadResponsabilities();
  }, [formik.values.idDepartment]);

  const fetchDependencies = async () => {
    const businessNames = await readBusinessNames();
    setBusinessNameList(businessNames);
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const handleCardClick = (positionId: number, functions: any[]) => {
    const updatedSelectedPositions = new Set(selectedPositions);
    if (updatedSelectedPositions.has(positionId)) {
      updatedSelectedPositions.delete(positionId);
    } else {
      updatedSelectedPositions.add(positionId);
    }
    setSelectedPositions(updatedSelectedPositions);

    const currentFunctions = formik.values[originalField] || [];

    const functionsToRemove = Array.from(selectedPositions)
      .filter(id => !updatedSelectedPositions.has(id))
      .flatMap(id => positions.find(position => position.id === id)?.funtions || []);

    const newFunctions = Array.from(updatedSelectedPositions)
      .flatMap(id => positions.find(position => position.id === id)?.funtions || []);

    const functionsAfterRemoval = currentFunctions.filter(fn => !functionsToRemove.includes(fn));

    const updatedFunctions = [...new Set([...functionsAfterRemoval, ...newFunctions])];

    formik.setFieldValue(originalField, updatedFunctions);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "2%" }}>
        <div className="col-md-1" />
        <div className="col-md-3 border-end">
          {
            businessNameList.length > 0 && businessNameList.map((businessName) => (
              <div className="d-flex flex-row overflow-auto py-2">
                <div
                  className={`card mb-3 me-3 ${formik.values.idBusinessName === businessName.id ? 'border-2 border-primary shadow' : ''}`}
                  style={{ minWidth: '330px', maxWidth: '200px', cursor: 'pointer' }}
                >
                  <div className="card-body">
                    <h5 className="card-title">Contrato para {businessName.name}</h5>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="col-md-7 ps-4">
          <div className="d-flex flex-row overflow-auto py-2">
            {positions.length > 0 ? positions.map((position: any) => (
              <div
                key={position.id}
                className={`card mb-3 me-3 ${selectedPositions.has(position.id) ? 'border-2 border-primary shadow' : ''}`}
                style={{ minWidth: '300px', maxWidth: '300px', cursor: 'pointer' }}
                onClick={() => handleCardClick(position.id, position.funtions)}
              >
                <div className="card-body">
                  <h5 className="card-title">{position.name}</h5>
                  <ul className="list-group list-group-flush">
                    {position.funtions.slice(0, 3).map((res: any, index: number) => (
                      <li key={index} className="list-group-item">{res}</li>
                    ))}
                    {position.funtions.length > 3 && (
                      <li className="list-group-item">
                        +{position.funtions.length - 3} más
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )) : (
              <div className="alert alert-warning" role="alert">
                No hay contratos base disponibles para este departamento
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BaseContract;
