import React, { ForwardedRef } from "react";
import { IContractProps } from "../interfaces/others/IContract";
import sinValidezImg from "../assets/img/SinValidez.jpg";
import { toCamelCase } from "../hooks/toCameCase.tsx";

const ContractPrint: React.FC<IContractProps> = React.forwardRef(
  ({ contractData }: IContractProps, ref: ForwardedRef<any>) => {
    const contractRef = React.useRef(null);

    return (
      <>
        <div
          style={{
            paddingInline: "50px",
            textAlign: "justify",
            backgroundColor: "white",
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${sinValidezImg})`,            
            backgroundBlendMode: "luminosity",
            width: "100%",
            height: "100%",
          }}
          ref={ref}
        >
          <div
              ref={contractRef}
              style={{
                display: "inline-block",
                padding: "10px",
              }}
            >
              <p>
                <b>CONTRATO INDIVIDUAL DE TRABAJO POR TIEMPO INDETERMINADO</b>{" "}
                QUE CELEBRAN LA PERSONA MORAL{" "}
                <b>{contractData?.bussinessName} SAPI DE CV</b> Y EL &nbsp;
                <b className="azul">
                  C. {contractData?.firstName?.toUpperCase()} {contractData?.lastName?.toUpperCase()}{" "}
                  {contractData?.secondLastName?.toUpperCase()}
                </b>
                , A QUIENES EN LO SUCESIVO SE LES DENOMINARÁ <b>EL PATRÓN</b> Y
                <b>EL TRABAJADOR</b> RESPECTIVAMENTE, <b>LAS PARTES</b> PARA
                AMBAS Y, AL HACER ALUSIÓN A LA <b>LEY FEDERAL DEL TRABAJO</b> SE
                DIRÁ <b>LA LEY</b>, DE CONFORMIDAD CON LAS SIGUIENTES
                DECLARACIONES Y CLÁUSULAS.
              </p>
              <br />
              <br />
              <p className="text-center">
                <b>D E C L A R A C I O N E S:</b>
              </p>
              <br />
              <p>
                <b>I.</b> DECLARA EL PATRÓN A TRAVÉS de SU REPRESENTANTE LEGAL;
              </p>
              <p className="sangria">
                <b>I. A.</b> Ser una Persona Moral con personalidad jurídica y
                patrimonio propios; constituida en apego a las Leyes Mexicanas,
                lo que consta en el Instrumento Notarial 12,102, Volumen 185 de
                fecha 09 de Diciembre del 2019, pasado ante la Fe del Notario
                Público N°96 del Estado de Oaxaca Lic. Gustavo Manzano Trovamala
                Heredia.
              </p>
              <p className="sangria">
                <b>I. B.</b> Para los efectos legales correspondientes es
                representada por el C. Roberto Espinosa Vásquez en su carácter
                de Presidente del Consejo de Administración y Representante
                Legal de la misma, personalidad que acredita con el Instrumento
                Notarial descrito en la declaración que antecede, con todas las
                facultades para celebrar todo tipo de actos jurídicos.
              </p>
              <p className="sangria">
                <b>I. C.</b> Que el objeto social de su existencia es, entre
                otros: Establecer, comercializar, comprar, vender y administrar
                negocios de cafeterías, restaurantes, fuentes de soda y bares,
                así como sus insumos y equipos.
              </p>
              <p className="sangria">
                <b>I. D.</b> Tiene su domicilio fiscal y principal asiento de
                operaciones en {contractData?.bussinessDirection}, y con RFC.{" "}
                {contractData?.bussinessRFC}.
              </p>
              <p className="sangria">
                <b>I. E.</b> Con fundamento en los Arts. 25, 31 y 35 de LA LEY,
                es su voluntad pactar con EL TRABAJADOR para que le brinde sus
                Servicios Personales Subordinados, con la categoría de
                <span className="azul">{contractData?.postionName}</span> y por
                Tiempo Indeterminado.
              </p>
              <p>
                <b>II.</b> De EL TRABAJADOR, por SÍ MISMO y BAJO PROTESTA de
                DECIR VERDAD;
              </p>
              <p className="sangria">
                <b>II. A.</b> Ser de Nacionalidad {contractData?.nacionalidad}{" "}
                de {contractData?.age} años de edad; Sexo {contractData?.genero}{" "}
                de Estado Civil {contractData?.estadoCivil} de ocupación
                empleado; con CURP {contractData?.curp} con RFC{" "}
                {contractData?.rfc} Número de Seguridad Social
                {contractData?.nss} y con domicilio para los efectos legales
                consiguientes en
                <span className="azul">{contractData?.direccion}.</span>
              </p>
              <p className="sangria">
                <b>II. B.</b> Para los efectos legales a que haya lugar, se
                identifica con su Credencial de Elector expedida por el
                Instituto Nacional Electoral con
                <span className="azul">IDMEX {contractData?.idmex}</span> y
                <span className="azul">OCR {contractData?.ocr}</span>, la que se
                agrega al presente en copia simple; manifestando que la firma
                que aparece al reverso de la misma es la que usa para todos sus
                asuntos, y es la propia que estampa en el presente con las
                mismas características caligráficas, grafoscópicas y
                grafométricas.
              </p>
              <p className="sangria">
                <b>II. C.</b> Tener la capacidad, aptitudes y preparación
                suficientes para desarrollar con esmero y eficiencia los
                servicios requeridos por EL PATRÓN, en los términos descritos en
                la Declaración I.E.
              </p>
              <p className="sangria">
                <b>II. D.</b> Que no padece discapacidad, ausencia, pérdida o
                restricción de habilidades para desarrollar una actividad
                neuromotora, intelectual, visual o auditiva en la forma o dentro
                del margen considerado como normal para todo ser humano, que le
                limite la capacidad de ejercer eficientemente las que EL PATRÓN
                le encomienda y para las que le contrata.
              </p>
              <p className="sangria">
                <b>II. E.</b> Estar conforme en desempeñar los requerimientos de
                EL PATRÓN, y en plasmar las condiciones generales de trabajo
                sobre las cuales prestará sus servicios personales, por lo que
                se somete a las siguientes
              </p>
              <br />
              <br />
              <p className="text-center">
                <b>C L A U S U L A S:</b>
              </p>
              <br />
              <p>
                PRIMERA. LAS PARTES se reconocen mutuamente la personalidad con
                que se ostentan, para los efectos legales a que haya lugar,
                manifestando que es su libre voluntad signar este contrato.
              </p>

              <p>
                <b>A). Del OBJETO;</b>
              </p>
              <p>
                SEGUNDA. EL TRABAJADOR prestará sus servicios personales
                subordinados a EL PATRÓN con la Categoría de
                <span className="azul">{contractData?.postionName}, </span>{" "}
                adscrito al Departamento de
                {contractData?.positionDepartment}, desempeñando el trabajo con
                esmero y eficiencia, con las funciones propias del puesto, que
                son, entre otras:
              </p>
              <ul>
                {contractData?.positionFunctions?.map((funcion, index) => (
                  <li key={index}>{funcion}</li>
                ))}
              </ul>
              <p>
                funciones que se describen de manera enunciativa más no
                limitativa, y que desarrollará acatando las órdenes que le dé EL
                PATRÓN; lo anterior, en estricto apego a lo establecido en la
                Declaración I.E.
              </p>
              <p>
                II.1. EL TRABAJADOR prestará sus servicios en el
                <span className="azul">Domicilio Fiscal</span> de EL PATRÓN,
                sito en
                <span className="azul">
                  2A PRIVADA DE CAMINO NACIONAL N°17, SAN SEBASTIAN TUTLA, OAX.,
                  C.P. 71320,
                </span>
                , en el propio de cualquiera de sus sucursales, o en otro futuro
                que le resulte por apertura de nuevos centros de trabajo o
                reubicación de los actuales.
              </p>

              <p>
                <b>B). De la VIGENCIA;</b>
              </p>
              <p>
                TERCERA. LAS PARTES pactan que este contrato es por TIEMPO
                INDETERMINADO, según lo dispuesto por el Artículo 35 de LA LEY,
                surtiendo sus efectos a partir del{" "}
                {contractData?.recruitmentDate}, fecha que se toma como la de
                ingreso de EL TRABAJADOR a laborar para EL PATRÓN para los
                efectos de la antigüedad en el empleo.
              </p>
              <p>
                III.1. Este contrato podrá modificarse, suspenderse o darse por
                terminado anticipadamente y sin responsabilidad para EL PATRÓN,
                por las causales de rescisión contempladas en el Artículo 47 de
                LA LEY, o si se actualizan los supuestos contemplados en el
                Artículo 53 de la misma legislación.
              </p>

              <p>
                <b>C). De la JORNADA de TRABAJO;</b>
              </p>
              <p>
                CUARTA. La duración de la jornada será la máxima semanal de
                cuarenta y ocho horas para la diurna, quedando LAS PARTES en la
                disposición de repartir las horas de trabajo de acuerdo a las
                necesidades de EL PATRÓN y de conformidad con el Artículo 59 de
                LA LEY. Queda convenido que por cada seis días laborados EL
                TRABAJADOR disfrutará de un día de descanso semanal.
              </p>
              <p>
                IV.1. Queda estrictamente prohibido a EL TRABAJADOR que labore
                jornada extraordinaria, a menos que reciba autorización expresa
                y por escrito de EL PATRÓN para tal efecto.
              </p>
              <p>
                IV.2. Conforme a su derecho de administración, EL PATRÓN queda
                en libertad de cambiar los sistemas de trabajo, organización de
                las labores y adecuar los horarios sin exceder de la jornada
                legal diaria; en consecuencia, EL TRABAJADOR está obligado a
                obedecer cualquier orden relacionada con los nuevos sistemas de
                trabajo o la nueva organización.
              </p>

              <p>
                <b>D). Del SALARIO;</b>
              </p>
              <p>
                QUINTA. Por la prestación de sus servicios EL TRABAJADOR
                percibirá el pago de {contractData?.monthlySalary} M.N. mensual,
                pagaderos por periodo {contractData?.periodicity}, en moneda de
                curso legal y mediante pago en efectivo. EL PATRÓN retendrá del
                salario el importe de las cuotas que correspondan a EL
                TRABAJADOR por concepto de IMSS e INFONAVIT, así como las
                retenciones que mandata la Ley del Impuesto Sobre la Renta en
                sus Artículos 96 y 99.
              </p>
              <p>
                V.1. En apego a lo dispuesto en el párrafo cuarto del Artículo
                101 de LA LEY, contra el pago de su salario, EL TRABAJADOR se
                obliga a firmar los recibos CFDI o nómina correspondientes que
                para tal efecto destine EL PATRÓN y las autoridades competentes.
              </p>
              <p>
                V.2. EL TRABAJADOR gozará del pago y disfrute del periodo anual
                de Vacaciones, así como de la Prima Vacacional correspondiente,
                en términos de los Artículos 76, 78, 80 y 81 de LA LEY. Así
                también percibirá el pago del Aguinaldo equivalente a quince
                días de salario, o la parte proporcional al periodo que haya
                trabajado en el ejercicio fiscal, en apego a lo establecido en
                el Artículo 87 de LA LEY.
              </p>

              <p>
                <b>E). De las OBLIGACIONES de LAS PARTES;</b>
              </p>
              <p>
                SEXTA. Son OBLIGACIONES de EL TRABAJADOR: VI.1. Con base en el
                Artículo 134 de LA LEY fracciones X y XI, EL TRABAJADOR se
                obliga a someterse a los exámenes médicos que las autoridades
                del Sector Salud Federal, Estatal o Municipal, la Comisión Mixta
                de Seguridad e Higiene, los reglamentos aplicables y/o EL PATRÓN
                indiquen, notificándole oportunamente si padece alguna
                enfermedad contagiosa. En particular, para el caso de EMERGENCIA
                SANITARIA decretada por las autoridades competentes, EL
                TRABAJADOR se someterá a todas las medidas sanitarias
                preventivas o restrictivas que se dicten.
              </p>
              <p>
                VI.2. En caso de que EL PATRÓN así lo requiera, EL TRABAJADOR se
                obliga a acatar las disposiciones que en materia de control de
                asistencia establezca, registrando su hora de entrada y salida a
                las labores, así como estampando su firma autógrafa en dichos
                registros.
              </p>
              <p>
                VI.3. Obedecer y acatar el REGLAMENTO INTERIOR DE TRABAJO que
                rige las relaciones laborales entre EL PATRÓN y sus
                TRABAJADORES, sujetándose a éste en todas sus partes.
              </p>
              <p>
                VI.4. Conservar en las mejores condiciones de uso, limpieza,
                estado y funcionamiento los instrumentos y útiles de trabajo que
                le proporcione EL PATRÓN, utilizándolos de manera correcta y
                exclusivamente para el desempeño de sus funciones laborales,
                obligándose a restituirlos en las mejores condiciones que uso y
                desgaste natural, en el tiempo en que EL PATRÓN lo requiera; lo
                anterior con base en el Artículo 134 Fracción VI de LA LEY.
              </p>
              <p>
                VI.5. Dada la naturaleza del Objeto Social de EL PATRÓN, si en
                términos del Artículo 75 de LA LEY EL TRABAJADOR es requerido
                para laborar en algún día de descanso obligatorio, queda
                obligado a prestar sus servicios, gozando del pago establecido
                en dicho ordenamiento.
              </p>
              <p>
                VI.6. En términos del Artículo 99 de la Ley del Impuesto Sobre
                la Renta fracciones IV y V, EL TRABAJADOR se obliga a
                proporcionar a EL PATRÓN los datos necesarios para inscribirlo
                en el Registro Federal de Contribuyentes; si ya estuviera
                inscrito, le proporcionará su clave de registro con la
                Constancia de Situación Fiscal. Adicionalmente, antes de que se
                efectúe el primer pago de salarios de cada ejercicio fiscal, EL
                TRABAJADOR se obliga a comunicar por escrito a EL PATRÓN si
                presta servicios a otro empleador, y si éste le aplica el
                Subsidio para el Empleo.
              </p>
              <p>
                VI.7. Participar en todas las capacitaciones y cursos de
                actualización que proporcione EL PATRÓN, aprobándolas, siendo
                prioritarias las aplicables a su Categoría, en apego al Artículo
                153 de LA LEY.
              </p>
              <p>
                SÉPTIMA. EL TRABAJADOR queda obligado a guardar en estricta
                confidencialidad y secrecía los métodos administrativos,
                sistemas de trabajo, metodologías, políticas, secretos
                comerciales y de fabricación de productos, o cualquier otro de
                los que tenga conocimiento por razón del trabajo que se
                desempeña, así como de los asuntos administrativos reservados
                cuya divulgación cause perjuicio a EL PATRÓN o a terceros
                interesados.
              </p>
              <p>
                VII.1. Queda prohibido a EL TRABAJADOR reproducir copias de la
                documentación, planes de trabajo, manuales de procedimientos y/o
                archivos, ya sea en forma escrita o en cualquier medio digital,
                así como, en general, de todos los materiales de trabajo que le
                sean entregados por EL PATRÓN.
              </p>
              <p>
                VII.2. En caso de ser requerido por EL PATRÓN, EL TRABAJADOR
                manifiesta su disposición en firmar un CONVENIO DE
                CONFIDENCIALIDAD respecto de la Información, Datos,
                Conocimientos, Archivos digitales o impresos que reciba de EL
                PATRÓN para el ejercicio de sus funciones laborales.
              </p>
              <p>
                Por violación a lo dispuesto en esta cláusula, EL TRABAJADOR
                estará sujeto a la Rescisión de la Relación Laboral sin
                responsabilidad para EL PATRÓN, en términos del Artículo 47 de
                LA LEY.
              </p>
              <p>OCTAVA. Son OBLIGACIONES de EL PATRÓN:</p>
              <p>
                VIII.1. Proporcionar capacitación y adiestramiento de acuerdo a
                la carga de trabajo y sus necesidades, en apego al TÍTULO CUARTO
                de los Derechos y Obligaciones de los Trabajadores y de los
                Patrones; Capítulo III BIS De la Productividad, Formación y
                Capacitación de los Trabajadores de LA LEY.
              </p>
              <p>
                VIII.2. Proporcionar a EL TRABAJADOR los instrumentos de trabajo
                necesarios para el buen desempeño de sus funciones.
              </p>

              <p>
                <b>F). CAUSAS de RESCISIÓN y VALIDEZ del CONTRATO;</b>
              </p>
              <p>
                NOVENA. La violación a cualquiera de las obligaciones
                consignadas en este contrato por parte de EL TRABAJADOR, es
                causal de rescisión del mismo sin responsabilidad para EL
                PATRÓN, relacionadas a las enunciadas en el Artículo 47 de LA
                LEY.
              </p>
              <p>
                IX.1. Cualquier modificación al presente solo es válida si se
                hace por escrito entre LAS PARTES y se agrega como anexo a este
                contrato.
              </p>
              <p>
                DÉCIMA. Para los efectos de los Artículos 29, Fracción V del
                Código Fiscal de la Federación y 99, Fracción III de la Ley del
                Impuesto Sobre la Renta, en este acto, bajo protesta de decir
                verdad, EL TRABAJADOR manifiesta que su CORREO ELECTRÓNICO es{" "}
                {contractData?.email}, autorizando a EL PATRÓN para enviarle a
                dicho correo electrónico los archivos CFDI que correspondan al
                pago de sus salarios, eximiéndolo de cualquier responsabilidad
                por el manejo de dicha cuenta electrónica, así como del cumplir
                con las disposiciones de la ley de la materia.
              </p>
              <p>
                DÉCIMA PRIMERA. En este acto, para la integración de su
                expediente personal, EL TRABAJADOR entrega a EL PATRÓN los
                siguientes documentos: 1). Original de Solicitud de Empleo; 2).
                Acta de Nacimiento; 3). Copia de la Credencial de Elector con
                Número Identificador OCR {contractData?.ocr} expedida por el
                I.N.E.; 4). Currículum Vitae; 5). Comprobante de Domicilio
                actual; 6). Constancia de Situación Fiscal con RFC; 7).
                Constancia oficial que acredita su último grado de estudios; 8).
                Clave Única de Registro de Población; 9). Constancia de Semanas
                Cotizadas en el IMSS; 10). Dos Cartas de Recomendación de fecha
                reciente a su contratación, expedidas por personas con solvencia
                moral reconocida por EL PATRÓN; 11). Croquis señalizado de la
                Ruta de Trayecto de su domicilio particular al CENTRO DE TRABAJO
                y viceversa; 12). Constancia de Trabajo de su Patrón inmediato
                anterior; 13). Certificado de Antecedentes Penales expedido por
                la Secretaría de Seguridad Pública de Oaxaca y/o autoridad
                competente, y cualesquier otro que le requiera EL PATRÓN.
              </p>
              <p>
                DÉCIMA SEGUNDA. Para el caso de su fallecimiento o desaparición
                derivada de un acto delincuencial, en apego a lo establecido en
                los Artículos 25 Fracción X y 501 de LA LEY, EL TRABAJADOR
                designa como su beneficiario para el pago de los salarios y
                prestaciones devengadas y no cobradas, en primer lugar con
                derecho al 100%, al C.{toCamelCase(contractData?.firstNameFamiliar + ' ' +  contractData?.lastNameFamiliar + ' ' +  contractData?.secondLastNameFamiliar)} en su calidad de{" "}
                {contractData?.relationshipFamiliar}, con número de teléfono{" "}
                {contractData?.phoneFamiliar}, y con domicilio en{" "}
                <span className="azul">{contractData?.addressFamiliar}</span>
              </p>

              <p>
                Para todo lo no previsto en el presente, su cumplimiento e
                interpretación, LAS PARTES se someten a lo establecido en LA LEY
                y a la jurisdicción de los Tribunales Laborales de la Ciudad de
                Oaxaca, Oax., firmando de aceptación al mismo en &nbsp;
                <span className="azul">
                  SAN SEBASTIÁN TUTLA, OAX., EL DÍA {contractData?.todayDay} DEL
                  MES DE {contractData?.todayMonth} DEL AÑO {contractData?.todayYear}
                </span>
                .
              </p>
            </div>
          {
            /*
            <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginTop: "20px" }}>
                <b>EL PATRÓN</b>
              </div>
              <img
                style={{
                  marginLeft: "25px",
                  width: "250px",
                  height: "100px",
                  marginTop: "30px",
                  marginBottom: "-30px",
                  objectFit: "contain",
                }}
                src=""
                alt=""
              />
              <hr
                style={{
                  border: "1px solid #333",
                  width: "250px",
                  marginInline: "auto",
                }}
              />
              <div>
                <p>
                  <b>GRUPO {contractData?.bussinessName} SAPI DE CV </b> <br />
                  C. ROBERTO ESPINOSA VÁSQUEZ Presidente del Consejo de
                  Administración y Representante Legal
                </p>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: contractData.signature ? "0" : "100px",
                }}
              >
                <b>EL TRABAJADOR</b>
              </div>
              {contractData.signature && (
                <img
                  style={{
                    width: "270px",
                    height: "100px",
                    marginTop: "30px",
                    marginBottom: "-30px",
                    objectFit: "contain",
                    marginLeft: "15px",
                  }}
                  src={contractData.signature.url}
                  alt=""
                />
              )}
              <hr
                style={{
                  border: "1px solid #333",
                  width: "250px",
                  marginInline: "auto",
                }}
              />
              <div>
                C. {contractData?.firstName} {contractData?.lastName}{" "}
                {contractData?.secondLastName}
              </div>
            </div>
          </div>
            */
          }
          
        </div>
      </>
    );
  }
);

export default ContractPrint;
