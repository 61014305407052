export const ListaNegraInitial = {
  curp: "",
  unbanDate: "",
  isPermanent: false,
  reason: "",
  name: ''
};

export const RejectPostulanteInitial = (data) => {
  return {
    idPeople: Number(data?.person?.id) || -99,
    curp: data?.person?.curp || "",
    unbanDate: "",
    isPermanent: false,
    reason: "",
    name: data?.person?.firstName
  };
};


export const fillListaNegra = (data) => {
  return {
    curp: data?.CURP,
    unbanDate: data?.FECHA_BANEO,
    isPermanent: data.ES_PERMANENTE === "Si" ? true : false,
    reason: data.RAZON,
    name: ""
  };
}