import * as yup from "yup";

export const EditProfileSchema = yup.object({
  name: yup.string().required("Campo requerido"),
  lastName: yup.string().required("Campo requerido"),
  secondLastName: yup.string().required("Campo requerido"),
  email: yup
    .string()
    .email("El correo electrónico no es válido")
    .required("Campo requerido"),
  phone: yup
    .string()
    .required("Campo requerido")
    .matches(/^\d{10}$/, "Debe ser un número de 10 dígitos"),
  idSchooling: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  address: yup.string().required("Campo requerido"),
  birthdate: yup
    .date()
    .required("Campo requerido")
    .max(new Date(), "Fecha invalida"),
  genere: yup.string().required("Campo requerido"),
  maritalStatus: yup.string().required("Campo requerido"),
  rfc: yup.string().required("Campo requerido"),
  nss: yup.string().required("Campo requerido"),
  idMex: yup.string().required("Campo requerido"),
  ocr: yup.string().required("Campo requerido"),
  alergias: yup.string().required("Campo requerido"),
  tipoSangre: yup.string().required("Campo requerido"),
  enfermedad: yup.string().required("Campo requerido"),
  tratamiento: yup.string().required("Campo requerido"),
});
