import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setQuery } from "../../store/slices/entradasSlice.ts";
import { readBranches } from "../../services/branch.service.ts";
import moment from "moment";

const FiltersEntradas = () => {
  const dispatch = useDispatch();
  const initialValues = {
    partnerBranch: "",
    checkBranch: "",
    param: "",
    startDate: "",
    endDate: "",
  };
  const [filters, setFilters] = useState(initialValues);
  const [branches, setBranches] = useState<any[]>([]);
  const [timer, setTimer] = useState<any>(null);
  const [showPills, setShowPills] = useState(false);

  const fetchBranches = async () => {
    try {
      const response = await readBranches();
      setBranches(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filters)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  console.log(branches);

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "param":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "partnerBranch":
            keyName = "Sucursal asignada";
            valueName = branches.find((branch) => branch.id === Number(value))?.name;
            break;
          case "checkBranch":
            keyName = "Sucursal de checado";
            valueName = branches.find((branch) => branch.id === value)?.name;
            break;
          case "startDate":
            keyName = "Fecha de inicio";
            valueName = moment(value).format("DD/MM/YYYY");
            break;
          case "endDate":
            keyName = "Fecha de fin";
            valueName = moment(value).format("DD/MM/YYYY");
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "" && updatedFilters[key] !== "Todos")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(updatedFilters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  useEffect(() => {
    fetchBranches();
  }, []);
  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Sucursal asignada:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, partnerBranch: e.target.value })}
            value={filters.partnerBranch}
          >
            <option value={"all"}>Todos</option>
            {
              branches?.map((branch: any) => (
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              ))
            }
          </select>
          <label>Sucursal de checado:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, checkBranch: e.target.value })}
            value={filters.checkBranch}
          >
            <option value={"all"}>Todos</option>
            {
              branches?.map((branch: any) => (
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              ))
            }
          </select>
          <label>Fecha de inicio:</label>
          <input
            type="date"
            className="form-control mt-1"
            onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
            value={filters.startDate}
          />
          <label>Fecha de fin:</label>
          <input
            type="date"
            className="form-control mt-1"
            onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
            value={filters.endDate}
          />
          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters(initialValues);
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}>
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        {
          showPills && selectedFilters(filters).map((filter: any) => (
            <div key={filter.key} className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {filter.keyName}: {filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default FiltersEntradas;
