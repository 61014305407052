import React, { useEffect } from 'react'
import WorkerRow from './componentes/WorkerRow.tsx';
import GridLayout from "react-grid-layout";
import { v4 as uuid } from "uuid";
import moment from 'moment';
import { Tooltip, Typography } from '@mui/material';
import { readPartnersByBranch } from '../../../services/partner.service.ts';
import { generateGradientBackground, generateTimeIntervals, getBackgroundColor, getDateByDayOfWeek, isOverlapping, shifts, weekDays, weeks } from '../../../utils/constanst.js';
import { findAssistanceByDate } from '../../../services/assistanceCheck.service.ts';
import { notification } from '../../../utils/Notifications.tsx';
import { useParams } from 'react-router-dom';

function SheduleComponent({ hours, existingsSchedules, formik, positionsArray, setPositionsArray, selectedWeek, selectedDay = 0, turno = 0 }) {
  const branchId = formik.values.branchId
  const [layout, setLayout] = React.useState<any[]>([]);
  const [workers, setWorkers] = React.useState<any[]>([]);
  const [lastItem, setLastItem] = React.useState<any>(null);
  const [update, setUpdate] = React.useState(false);

  const [sortType, setSortType] = React.useState('Asc');
  const [sortBy, setSortBy] = React.useState('name');

  const [hoursRange, setHoursRange] = React.useState<any[]>([]);

  const handleSortPositions = (type) => {
    if (type === 'name') {
      setSortBy('name');
      if (sortType === 'Asc') {
        setPositionsArray(positionsArray.sort((a, b) => a.positionName.localeCompare(b.positionName)));
        setSortType('Desc');
      } else {
        setPositionsArray(positionsArray.sort((a, b) => b.positionName.localeCompare(a.positionName)));
        setSortType('Asc');
      }
    } else {
      setSortBy('shift');
      if (sortType === 'Asc') {
        const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType('Desc');
      } else {
        const shiftOrder = ['Vespertino', 'Intermedio', 'Matutino'];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType('Asc');
      }
    }

    const newSortedLayout = layout.map((item) => {
      return {
        ...item,
        y: positionsArray.findIndex((position) => (position.positionName === item.position) && (position.shift === item.shift))
      }
    });
    setLayout(newSortedLayout);

    setUpdate(!update);
  }

  React.useEffect(() => {
    if (existingsSchedules?.length > 0) {
      const newLayout = existingsSchedules.map((schedule) => {
        const position = positionsArray.find((position) => position.positionName === schedule.positionName);
        const existing = positionsArray.findIndex((position) => (position.positionName === schedule.positionName) && (position.shift === schedule.shift))

        return {
          i: uuid(),
          minH: 1,
          maxH: 1,
          minW: 1,
          show: existing !== -1,
          pending: false,
          name: schedule.partner.person.firstName,
          position: schedule.positionName,
          originalId: schedule.partner.id,
          shift: schedule.shift,
          x: Number(schedule.positionX),
          y: positionsArray.findIndex((position) => (position.positionName === schedule.positionName) && (position.shift === schedule.shift)),
          w: Number(schedule.positionW),
          h: 1,
          positionId: position?.positionId,
        };
      });

      setLayout(newLayout);
      formik.setFieldValue('schedule', newLayout);
    } else {
      setLayout([]);
    }
  }, [existingsSchedules]);

  React.useEffect(() => {
    setUpdate(!update);
  }, [turno, selectedDay]);

  const [existingAssistances, setExistingAssistances] = React.useState<any[]>([]);

  React.useEffect(() => {
    const fetchWorkers = async () => {
      const workers = await readPartnersByBranch(branchId, { active: true });
      setWorkers(workers.map(worker => ({
        ...worker,
        show: true,
        pending: false
      })));
    }

    const setHours = async () => {
      const hoursArray = await generateTimeIntervals(hours?.matutinoStart, hours?.vespertinoEnd);
      hoursArray.unshift({ key: -99, display: 'Hora' }, { key: -99, display: 'Hora' });
      setHoursRange(hoursArray);
    }

    const fetchDayAssistances = async () => {
      const selectedDate = getDateByDayOfWeek(weeks[selectedWeek]?.value, weekDays[selectedDay].name);
      const assistances = await findAssistanceByDate(moment(selectedDate).format('YYYY-MM-DD'));
      setExistingAssistances(assistances);
    }

    setHours();
    fetchWorkers();
    fetchDayAssistances();
  }, []);

  const sortWorkers = () => {
    const reorderedShifts = [
      shifts[turno],
      ...shifts.filter((_, index) => index !== turno)
    ];

    const resultArray: any = [];

    reorderedShifts.forEach(shift => {
      const workersByShift = workers.filter(worker => worker.candidate?.shift?.name === shift.name);
      workersByShift.forEach(worker => {
        resultArray.push(worker);
      });
    });

    setWorkers(resultArray);
  }

  useEffect(() => {
    sortWorkers();
  }, [turno]);

  const handleDrop = (id: string) => {
    const worker = workers.find((worker) => String(worker.id) === String(id));

    if (worker) {
      const newItem = {
        i: uuid(),
        minH: 1,
        maxH: 1,
        minW: 1,
        show: false,
        pending: true,
        name: `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`,
        position: positionsArray[lastItem?.y]?.positionName,
        positionId: positionsArray[lastItem?.y]?.positionId,
        originalId: worker.id,
        shift: positionsArray[lastItem?.y]?.shift,
      };

      setLayout((currentLayout) => [...currentLayout, newItem]);

      setUpdate(!update);
    }
  };

  useEffect(() => {
    formik.setFieldValue('schedule', layout);
    const hasPending = layout.findIndex((item) => item?.pending);

    if (lastItem && hasPending !== -1) {
      updatePending(lastItem, hasPending);
      setLastItem(null);
    }
  }, [lastItem, layout]);

  useEffect(() => {
    setLayout((currentLayout) => {
      const newLayout = currentLayout.map((record) => ({
        ...record,
        i: uuid(),
      }));
      return newLayout;
    });
  }, [update]);

  const updatePending = (oldRecord: any, neRecord: number) => {
    const newLayout = [...layout];

    newLayout[neRecord] = {
      ...newLayout[neRecord],
      x: oldRecord.x,
      y: oldRecord.y,
      w: 17,
      h: 1,
      pending: false,
      show: true,
    };

    const itemsFounds = newLayout.filter(
      (item) => item.originalId === layout[neRecord].originalId
    );

    console.log('itemsFounds', itemsFounds);
    console.log('itemsFounds.length > 2', itemsFounds.length > 2);

    const newLayoutA = [...layout];
    newLayoutA[neRecord] = {
      ...newLayout[neRecord],
      x: oldRecord.x,
      y: oldRecord.y,
      w: itemsFounds.length > 2 ? 2 : 15,
      h: 1,
      pending: false,
      show: true,
    };
    if (oldRecord.x === 0 || oldRecord.x === 1) {
      newLayout.splice(neRecord, 1);
    }
    const maxY = Number(positionsArray?.length);
    if (oldRecord.y >= maxY) {
      newLayout.splice(neRecord, 1);
    }

    console.log('newLayout', newLayoutA);

    if (!isOverlapping(newLayout, newLayout[neRecord])) {
      return;
    }

    setLayout(newLayoutA);
  };

  const deleteItem = (id: string) => {
    if (existingAssistances.find(assistance => assistance.partner.id === layout.find(item => item.i === id)?.originalId)) {
      notification('Precaución', 'El trabajador tiene asistencias registradas', 'warning');
    }

    setLayout((currentLayout) => {
      const newLayout = currentLayout.filter((item) => item?.i !== id);
      return newLayout;
    });

    setUpdate(!update);
  };

  const handleResize = (layout_, oldItem, newItem, placeholder, e, element) => {
    const index = layout.findIndex((item) => item?.i === oldItem?.i);
    let item = { ...layout[index] }; // Haz una copia en lugar de usar JSON.stringify

    if (item) {
      item.w = newItem.w;
    }

    const newLayout = [...layout];
    newLayout[index] = item;
    setLayout(newLayout);
  };

  const handleResizeStop = (layout_, oldItem, newItem, placeholder, e, element) => {
    const newLayout = [...layout];

    const index = newLayout.findIndex((item) => item?.i === oldItem?.i);
    let item = { ...newLayout[index] };

    item.h = newItem.h;
    item.w = newItem.w;
    item.y = newItem.y;
    item.x = newItem.x;

    const overlapDetected = !isOverlapping(newLayout, item);

    if (!overlapDetected) {
      if (existingAssistances.find(assistance => assistance.partner.id === layout.find(item => item.i === oldItem.i)?.originalId)) {
        notification('Precaución', 'El trabajador tiene asistencias registradas', 'warning');
      }

      newLayout[index] = item;
      setLayout(newLayout);
    } else {
      item.h = oldItem.h;
      item.w = oldItem.w;
      item.y = oldItem.y;
      item.x = oldItem.x;

      newLayout[index] = item;

      setLayout(newLayout);
      setUpdate(!update);
    }
  };





  const onDragStop = (layout_, oldItem, newItem, placeholder, e, element) => {

    // Si el nuevo item está en las primeras columnas, no actualizar la posición
    if (newItem.x === 0 || newItem.x === 1) {
      setLayout([...layout]);
      setUpdate(!update);
      return;
    }

    const currentPosition = positionsArray[newItem.y]?.positionName || "";

    // Crear una copia del layout y actualizar la posición del elemento
    const copyLayout = [...layout];
    const updatedLayout = copyLayout.map(item =>
      item.i === oldItem.i
        ? {
          ...item,
          x: newItem.x,
          y: newItem.y,
          h: newItem.h,
          position: currentPosition,
          shift: positionsArray[newItem.y]?.shift
        }
        : item
    );

    // Verificar si el nuevo layout genera solapamientos
    const overlappingItem = isOverlapping(updatedLayout, updatedLayout.find(item => item.i === oldItem.i));

    if (overlappingItem) {
      console.log('No overlapping detected');
      // Verificar si el trabajador tiene asistencias registradas
      if (existingAssistances.find(assistance => assistance.partner.id === layout.find(item => item.i === oldItem.i)?.originalId)) {
        notification('Precaución', 'El trabajador tiene asistencias registradas', 'warning');
      }

      setLayout(updatedLayout);
      setUpdate(!update);
    } else {
      console.log('Overlapping detected, reverting changes');
      setLayout([...layout]);  // Revertir al layout original si hay solapamiento
      setUpdate(!update);
    }
  };


  const autocompleteLayout = () => {
    //no existe layout para este momento
    setLayout([]);

    //primero comparar los trabajadores con las posiciones
    const avaliablePositions = turno !== 3 ? positionsArray.filter((position) => position.shift === shifts[turno].name) : positionsArray;
    const avaliableWorkers = turno !== 3 ? workers.filter((worker) => worker.candidate?.shift?.name === shifts[turno].name) : workers;

    let newLayout: any = [];
    avaliablePositions.forEach((position) => {
      const workersByPosition = avaliableWorkers.filter((worker) => worker.candidate?.vacancy?.position?.name === position.originalPositionName);

      workersByPosition.forEach((worker, index) => {
        newLayout.push({
          i: uuid(),
          minH: 1,
          maxH: 1,
          minW: 1,
          show: true,
          pending: false,
          name: `${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`,
          position: position.positionName,
          originalId: worker.id,
          x: position.shift === 'Matutino' ? 2 : position.shift === 'Intermedio' ? 8 : position.shift === 'Vespertino' ? 17 : 0,
          y: positionsArray.findIndex((positionA) => (positionA.positionName === position.positionName) && (positionA.shift === position.shift)),
          w: 15,
          h: 1,
          positionId: position.positionId,
          shift: worker.candidate?.shift?.name,
        });
      });
    });

    setLayout(newLayout);
  }

  function sortByPositionName(shiftToSort: string): any[] {
    const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];

    // Primero, ordena el array por shift
    const sortedByShift = positionsArray.sort((a, b) => {
      return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
    });

    // Dividir el array en secciones por turno
    const resultArray: any[] = [];
    let currentShift = '';

    sortedByShift.forEach(position => {
      if (position.shift !== currentShift) {
        currentShift = position.shift;
        resultArray.push({ id: -1, positionId: -1, positionName: `--- ${currentShift} ---`, originalPositionName: '', shift: currentShift } as any);
      }

      resultArray.push(position);
    });

    // Filtrar y ordenar solo el turno específico
    if (shiftToSort) {
      const shiftIndex = resultArray.findIndex(p => p.shift === shiftToSort && p.positionName.startsWith('---'));
      if (shiftIndex !== -1) {
        const start = shiftIndex + 1;
        let end = start;
        while (end < resultArray.length && resultArray[end].shift === shiftToSort) {
          end++;
        }
        const shiftSection = resultArray.slice(start, end);
        const sortedShiftSection = shiftSection.sort((a, b) => a.positionName.localeCompare(b.positionName));
        resultArray.splice(start, shiftSection.length, ...sortedShiftSection);
      }
    }

    return resultArray;
  }

  if (!hoursRange.length) return

  return (
    <div
      className="row d-flex justify-content-between"
      style={{
        flex: 1,
        flexDirection: "row",
        borderRadius: "0.25rem",
        overflow: "hidden",
        border: "1px solid #AFAFAF55",
      }}
    >
      <div
        className="col-2 py-2"
        style={{
          backgroundColor: "#F0F0F0",
          overflowX: "auto",
          maxHeight: (positionsArray?.length * 50) + 65
        }}
      >
        <h4 className="mb-2">Trabajadores</h4>
        {workers.filter(worker => worker.show).map((worker) => (
          <WorkerRow
            originalId={worker.id}
            name={`${worker.person.firstName} ${worker.person.lastName} ${worker.person.secondLastName}`}
            position={worker.candidate?.vacancy?.position?.name}
            freeDay={
              worker.candidate.freeDay === weekDays[selectedDay].name
            }
            shift={worker.candidate?.shift?.name}
            key={worker.id}
            handleDrop={(id: string) => handleDrop(id)}
            curp={worker.person.curp}
            count={layout.filter((item) => item?.originalId === worker.id).length}
          />
        ))}
      </div>

      <div
        className="col-10"
        style={{
          paddingBlock: "10px",
          boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <>
          <div className='position-absolute d-flex flex-row px-2' style={{ zIndex: 3 }}>
            {
              turno !== 3 &&
              (
                <React.Fragment>
                  <a
                    href="#/"
                    onClick={() => handleSortPositions('name')}
                    className="fw-600 me-3">Nombre <i className={`fa-solid ${sortBy === 'name' && (sortType === 'Asc' ? 'fa-sort-up' : 'fa-sort-down')} ms-1`}></i>
                  </a>
                </React.Fragment>
              )
            }
            {
              turno === 3 &&
              (
                <React.Fragment>
                  <a
                    href="#/"
                    onClick={() => { sortByPositionName('Matutino') }}
                    className="fw-600">Nombre <i className={`fa-solid ${sortBy === 'shift' && (sortType === 'Asc' ? 'fa-sort-up' : 'fa-sort-down')} ms-1`}></i></a>
                  <a
                    href="#/"
                    onClick={() => handleSortPositions('shift')}
                    className="fw-600">Turno <i className={`fa-solid ${sortBy === 'shift' && (sortType === 'Asc' ? 'fa-sort-up' : 'fa-sort-down')} ms-1`}></i></a>
                </React.Fragment>
              )
            }
          </div>
          <GridLayout
            className="layout"
            layout={Array.from({ length: (hoursRange.length) }, (_, i) => ({
              i: i.toString(),
              x: i,
              y: 0,
              w: 1,
              h: 1,
              static: true,
            }))}
            cols={hoursRange.length}
            rowHeight={30}
            width={2400}
            margin={[0, 0]}
            compactType={null}
          >
            {
              hoursRange.map((hour, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    borderRight: (index !== 0) ? "2px solid #EEE" : "none",
                    borderBottom: '2px solid #EEE',
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 600,
                    fontSize: "10px",
                  }}
                >
                  {index > 1 && hour.display}
                </div>
              ))
            }
          </GridLayout>

          <GridLayout
            className="layout"
            layout={layout}
            cols={hoursRange.length}
            maxRows={positionsArray?.length}
            rowHeight={50}
            width={2400}
            preventCollision={false}
            allowOverlap={true}
            isResizable={true}
            isDroppable={true}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            onDragStop={onDragStop}
            compactType={null}
            margin={[0, 0]}
            style={{
              background: generateGradientBackground(hoursRange, hours, turno),
              width: `${2400}px`
            }}
            onDrop={(layoutx, layoutItem, _event) => {
              setLastItem(layoutItem);
            }}
          >
            {positionsArray?.map((key, index) => {
              return (
                <div
                  key={index}
                  data-grid={{ x: 0, y: index, w: key?.title ? 36 : 2, h: 1, static: true }}
                  style={{
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    borderRight: "2px solid #EEE",
                    borderBottom: `${key?.title ? '3px' : '2px'} solid #EEE`,
                    borderTop: `${key?.title ? '3px solid' : ''} ${key?.title === 'Matutino' ? '#FFD700' : key.title === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                    paddingInline: "5px",
                    fontWeight: 600,
                    borderLeft: `3px solid ${key.shift === 'Matutino' ? '#FFD700' : key.shift === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                    backgroundColor: 'white'
                  }}
                >
                  {key.title && <p className="w-100 mt-3 fs-15px fw-800 bg">{key?.title ?? key.positionName}</p>}
                  <p className="w-100 mt-auto">{key.positionName}</p>
                </div>
              );
            })}
            {layout.map((item, index) => {
              if (!item?.x || !item?.show) return <></>;
              return (
                <div
                  key={item?.i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "move",
                  }}
                  data-grid={{ x: item?.x, y: item?.y, w: item?.w, h: 1, }}

                >
                  <div
                    style={{
                      paddingLeft: "8px",
                      width: "100%",
                      borderRadius: "5px",
                      paddingBlock: "4px",
                      ...getBackgroundColor(item, index, positionsArray),
                    }}
                    className="shadow-sm"
                  >
                    <Tooltip
                      title={`${item.name} | ${item.position} | ${item.w / 2} hrs`}
                      arrow
                    >
                      <div className="d-flex flex-row">
                        <div
                          onMouseDown={(e) => e.stopPropagation()}
                          className='position-absolute m-2'
                          style={{
                            left: 0,
                            top: '35%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                          }}
                          onClick={() => deleteItem(item?.i)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                        <Typography fontWeight={500} marginLeft={1.5}>
                          {item?.name?.split(" ")[0]}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </GridLayout>
        </>
      </div>
      <div className='w-100 pt-4 pb-2 d-flex justify-content-end'>
        <button
          className='btn btn-primary ms-auto'
          onClick={autocompleteLayout}
        >
          Autocompletar
        </button>
      </div>
    </div >
  )
}

export default SheduleComponent