import React from 'react';
import { FormHelperText, Grid } from '@mui/material';
import Editor from './Editor.tsx';
import { IFormikFieldProps } from '../interfaces/formik/IFormikField.ts';

const CustomTextEditor = ({
  sm = 12,
  label = '',
  required = false,
  ...props
}: IFormikFieldProps) => {

  console.log('props.formik.values[props.field]: ', props.formik.values[props.field]);
  return (
    <Grid item xs={12} sm={sm}>
      <Editor
        id={label}
        value={props.formik.values[props.field]}
        onChange={(event) => props.formik.setFieldValue(props.field, event)}
        error={props.formik.touched[props.field] && Boolean(props.formik.errors[props.field])}
        helperText={
          props.formik.touched[props.field] && props.formik.errors[props.field] ? (
            <FormHelperText error={true}>
              <>{props.formik.errors[props.field]}</>
            </FormHelperText>
          ) : null
        }
        simple={true}
      />
    </Grid>
  );
};

export default CustomTextEditor;