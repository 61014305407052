export function toCamelCase(input) {
  if(!input) return input;
  const words = input?.split(/[\s\-_]+/);
  
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  
  return camelCaseWords.join(' ');
}