import React from "react";
import { useParams } from "react-router-dom";
import ChipStatus from "./ChipStatus.tsx";
import { getCurrencyFormat } from "../../utils/index.ts";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";

const ApplicationList = ({ applications, size = "lg" }) => {
  const { curp } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="col-xl-8" id="bsSpyContent">
      <div id="Aplications" className="mb-4 pb-3">
        {size === "lg" && (
          <React.Fragment>
            <h4 className="d-flex align-items-center mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                data-icon="solar:user-bold-duotone"
                className="iconify fs-24px me-2 text-body text-opacity-75 my-n1 iconify--solar"
              >
                <circle cx={12} cy={6} r={4} fill="currentColor" />
                <path
                  fill="currentColor"
                  d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5"
                  opacity=".5"
                />
              </svg>{" "}
              Aplicaciones
            </h4>
            <p>
              Aquí puedes encontrar todas las postulaciones que se han realizado
              con la CURP {curp}.
            </p>
          </React.Fragment>
        )}
        <div className="card">
          <div className="list-group list-group-flush fw-bold">
            {/* {applications?.applications?.map((application, index) => {
              const dateTimeString = `${application.applicationDate} ${application.applicationTime}`;
              const date = parseISO(dateTimeString);

              const formattedDateTime = format(
                date,
                "dd 'de' MMMM yyyy, HH:mm 'hrs'",
                { locale: es }
              );

              return (
                <div
                  className="list-group-item d-flex align-items-center"
                  key={index}
                >
                  <div className="flex-fill">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontSize: 15,
                        }}
                      >
                        {application.vacancy.position.name} en{" "}
                        {application.vacancy.branch.name}
                        <ChipStatus
                          status={application.applicationStatus.name as any}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: ".3%",
                          fontSize: 15,
                        }}
                      >
                        {user?.rol === "Administrador" && (
                          <React.Fragment>
                            Expectativa salarial:{" "}
                            {getCurrencyFormat(application.monthlySalary)}
                          </React.Fragment>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: ".3%",
                          fontSize: 15,
                        }}
                      />
                    </div>
                    <div className="text-body text-opacity-60 fs-15px">
                      Cita: {formattedDateTime}
                    </div>
                  </div>
                </div>
              );
            })} */}
            {applications?.candidate?.map((application, index) => {
              if (!application.application) return null;
              const dateTimeString = `${application.application.applicationDate} ${application.application.applicationTime}`;
              const date = parseISO(dateTimeString);

              const formattedDateTime = format(
                date,
                "dd 'de' MMMM yyyy, HH:mm 'hrs'",
                { locale: es }
              );

              return (
                <div className="list-group-item d-flex align-items-center" key={index}>
                  <div className="flex-fill">
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ fontWeight: "bold", fontSize: 15 }}>
                          {application.application.vacancy.position.name}
                        </span>
                        <ChipStatus
                          status={application.application.applicationStatus.name as any}
                        />
                      </div>
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        en {application.application.vacancy.branch.name}
                      </div>
                      {user?.rol === "Administrador" && (
                        <div style={{ fontSize: 14, color: "#6c757d" }}>
                          Expectativa salarial: {getCurrencyFormat(application.application.monthlySalary)}
                        </div>
                      )}
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        Cita: {formattedDateTime}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {applications?.candidate?.map((application, index) => {
              if (application.application) return null;
              return (
                <div className="list-group-item d-flex align-items-center" key={index}>
                  <div className="flex-fill">
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ fontWeight: "bold", fontSize: 15 }}>
                          {application.vacancy.position.name}
                        </span>
                        <ChipStatus status="Cambio de puesto" />
                      </div>
                      <div style={{ fontSize: 14, color: "#6c757d" }}>
                        en {application.vacancy.branch.name}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationList;
