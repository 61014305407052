import React, { useEffect } from 'react'
import FiltersEntradas from './FiltersEntradas.tsx'
import TableEntradas from './TableEntradas.tsx'
import PagintationEntradas from './PagintationEntradas.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/index.ts'
import { findDifferentBranch } from '../../services/assistanceCheck.service.ts'
import { setLoading, setRecords, setTotal } from '../../store/slices/entradasSlice.ts'
import { notification } from '../../utils/Notifications.tsx'

type Props = {}

const EntradasComponent = (props: Props) => {
  const dispatch = useDispatch();

  const { page, limit, query } = useSelector((state: RootState) => state.entradas);

  const fetchDependencies = async () => {
    try {
      const offset = page * limit;
      const queryToSend = query ? `?offset=${offset}&limit=${limit}&${query}` : `?offset=${offset}&limit=${limit}`;
      const response = await findDifferentBranch(queryToSend);
      dispatch(setRecords(response.records));
      dispatch(setTotal(response.total));
    } catch (error) {
      console.error(error);
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, [page, limit, query]);

  return (
    <div>
      <FiltersEntradas />
      <TableEntradas />
      <PagintationEntradas />
    </div>
  )
}

export default EntradasComponent