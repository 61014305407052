import React from "react";

const SideBar = () => {
  return (
    <div style={{ width: 230 }}>
      <nav className="navbar navbar-sticky d-none d-xl-block my-n4 py-4 h-100 text-end">
        <nav className="nav" id="bsSpyTarget">
          <a
            className="nav-link active"
            // href="#notifications"
            // data-toggle="scroll-to"
            style={{ cursor: "pointer" }}
          >
            Historial
          </a>
        </nav>
      </nav>
    </div>
  );
};

export default SideBar;
