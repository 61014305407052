import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import FiltersListaNegra from "../../components/lista-negra/FiltersListaNegra.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import {
  resetState,
  setDeleteModal,
  setListaNegra,
  setLoading,
  setShowModalImportExcel,
  setTotal,
} from "../../store/slices/listaNegraSlice.ts";
import NewRecordListaNegra from "../../components/lista-negra/NewRecordListaNegra.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { paginateBlackList } from "../../services/blacklist.service.js";
import PaginationListaNegra from "../../components/lista-negra/PaginationListaNegra.tsx";
import TableListaNegra from "../../components/lista-negra/TableListaNegra.tsx";
import Title from "../../components/Title.tsx";
import Layout from "../../components/Layout.tsx";
import DeleteRecordBlackList from "../../components/lista-negra/DeleteRecordBlackList.tsx";
import ImportModal from "../../components/lista-negra/ImportModal.tsx";
import ImportBlackList from "../../components/lista-negra/ImportBlackList.tsx";
import Joyride, { ACTIONS, CallBackProps, STATUS, Step } from "react-joyride";

const ListListaNegra = () => {
  useAppSettings();
  const {
    showModal,
    page,
    limit,
    menuSelected,
    param,
    fetch,
    loading,
    query,
    deleteModal,
    showModalImportExcel,
  } = useSelector((state: RootState) => state.listaNegra);
  const dispatch = useDispatch();

  const [steps, setSteps] = React.useState<Step[]>();
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [run, setRun] = React.useState<boolean>(false);
  const [walktour, setWalktour] = React.useState<boolean>(false);

  const [disabled, setDisabled] = React.useState({
    buttons: true,
    curp: true,
    motivo: true,
    fechaBloqueo: true,
  });

  React.useEffect(() => {
    setSteps([
      {
        content: (
          <div>
            Cuando quieras agregar a una persona a lista negra presiona este
            botón
            <br />
            Haz clic sobre él
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: "bottom",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso1-blackList",
        title: "Nuevo registro",
      },
      {
        content: (
          <div>
            Para agregar un nuevo registro en la lista negra, necesitas rellenar
            el siguiente formulario
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-azul"
                onClick={() => handleButtonPress(2)}
              >
                Siguiente
              </button>
            </div>
          </div>
        ),
        title: "Formulario de registro",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso2-blackList",
      },
      {
        content: (
          <div>
            Ingresa el nombre y la CURP
            <br />
            <small style={{ fontSize: "11px" }}>
              Cuando finalices presiona el botón que dice “Siguiente”
            </small>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-azul"
                onClick={() => handleButtonPress(3)}
              >
                Siguiente
              </button>
            </div>
          </div>
        ),
        title: "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso3-blackList",
      },
      {
        content: (
          <div>
            Agrega el motivo del porqué está dado de baja
            <br />
            <small style={{ fontSize: "11px" }}>
              Cuando finalices presiona el botón que dice “Siguiente”
            </small>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-azul"
                onClick={() => handleButtonPress(4)}
              >
                Siguiente
              </button>
            </div>
          </div>
        ),
        title: "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso4-blackList",
      },
      {
        content: (
          <div>
            Si existe fecha de desbloqueo indícalo en el primer campo, si es
            permanente solo pica el segundo campo
            <br />
            <small style={{ fontSize: "11px" }}>
              Cuando finalices presiona el botón que dice “Siguiente”
            </small>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-azul"
                onClick={() => handleButtonPress(5)}
              >
                Siguiente
              </button>
            </div>
          </div>
        ),
        title: "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso5-blackList",
      },
      {
        content: (
          <div>
            En los siguientes botones puedes elegir si cancelar o guardar el
            registro
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button className="btn btn-azul" onClick={() => setRun(false)}>
                Cerrar
              </button>
            </div>
          </div>
        ),
        title: "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso6-blackList",
      },

      {
        content: (
          <div>
            En esta tabla puede ver todos los registros
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                className="btn btn-azul"
                onClick={() => {
                  setRun(false);
                  setWalktour(false);
                  setCurrentStep(0);
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        ),
        title: "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        target: ".paso7-blackList",
      },
    ]);
  }, []);

  const fetchListaNegra = async () => {
    dispatch(setLoading(true));
    try {
      let queryToSend = query;
      if (queryToSend.length === 0) {
        queryToSend = "tab=todos";
      }
      const offset = page === 0 ? 0 : page * limit;
      const querySend = `?offset=${offset}&limit=${limit}&${queryToSend}`;
      const response = await paginateBlackList(querySend);
      dispatch(setListaNegra(response.results));
      dispatch(setTotal(response.total));
      console.log(response);
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    dispatch(resetState());
  }, []);
  React.useEffect(() => {
    fetchListaNegra();
  }, [page, menuSelected, param, fetch, query]);

  const handleButtonPress = (id: number) => {
    setRun(false);
    setCurrentStep(id);

    if (id === 2) {
      setDisabled({
        buttons: false,
        curp: false,
        motivo: false,
        fechaBloqueo: false,
      });
    }

    setTimeout(() => {
      setRun(true);
    }, 400);
  };

  return (
    <Layout loading={loading}>
      {walktour && (
        <Joyride
          run={false}
          steps={steps}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          stepIndex={currentStep}
          // callback={handleJoyrideCallback}
        />
      )}

      <Title
        baseTitle={`Lista negra`}
        iconTitle={
          <i
            className="fa-solid fa-circle-question fs-16px"
            style={{
              cursor: "pointer",
              color: `${!walktour ? "black" : "green"}`,
            }}
            onClick={() => {
              setWalktour(true);
              handleButtonPress(0);
              setRun(true);
            }}
          ></i>
        }
        basePath="/lista-negra"
        title="Lista negra"
      />
      <div className="card border-0 m-4">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active">
            <FiltersListaNegra
              onClickRef={() => handleButtonPress(1)}
              classNameFilter="paso1-blackList"
            />
            <div className="paso7-blackList">
              <TableListaNegra />
            </div>
            <PaginationListaNegra />
          </div>
        </div>
      </div>
      <ModalContainer
        classModal="paso2-blackList"
        open={showModal}
        title="Nuevo registro para la lista negra"
        closeFn={() => dispatch(setDeleteModal(false))}
        children={
          <NewRecordListaNegra
            disabled={walktour && disabled}
            tableWalktour={() => {
              setRun(true);
              handleButtonPress(6);
              setWalktour(true);
            }}
            resetWaltour={() => {
              setRun(false);
              setWalktour(false);
              handleButtonPress(0);
            }}
          />
        }
      />
      <ModalContainer
        open={showModalImportExcel}
        closeFn={() => dispatch(setShowModalImportExcel(false))}
        title="Importar lista negra"
        children={<ImportBlackList />}
      />
      <ModalContainer
        open={deleteModal}
        title="Eliminar registro"
        children={
          <DeleteRecordBlackList
            closeFn={() => dispatch(setDeleteModal(false))}
          />
        }
        closeFn={() => dispatch(setDeleteModal(false))}
      />
    </Layout>
  );
};

export default ListListaNegra;
