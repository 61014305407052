import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { LoginInitial } from "../../types/ILogin.ts";
import { signIn } from "../../services/auth.service.ts";
import { login } from "../../store/slices/authSlice.ts";
import AppVersion from "../../config/appVersion.js";

function LoginV2() {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [activeBg, setActiveBg] = React.useState(
    "/assets/img/login-bg/login-bg-17.jpg"
  );
  const [bg1, setBg1] = React.useState(true);
  const [bg2, setBg2] = React.useState(false);
  const [bg3, setBg3] = React.useState(false);
  const [bg4, setBg4] = React.useState(false);
  const [bg5, setBg5] = React.useState(false);
  const [bg6, setBg6] = React.useState(false);
  const selectBg = (e, active, bg) => {
    e.preventDefault();

    setActiveBg(bg);
    setBg1(active === "bg1");
    setBg2(active === "bg2");
    setBg3(active === "bg3");
    setBg4(active === "bg4");
    setBg5(active === "bg5");
    setBg6(active === "bg6");
  };

  const formik = useFormik({
    initialValues: LoginInitial,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await signIn(values);
        if (response?.token) {
          const { token, ...user } = response;
          dispatch(login({ token, user, rol: response?.rol }));
          if (user?.rol !== "Trabajador") {
            return navigate("/");
          }
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
        setError(error?.message || "Usuario o contraseña incorrectos");

        setTimeout(() => {
          setError(null);
        }, 3000);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="login login-v2 fw-bold">
        <div className="login-cover">
          <div
            className="login-cover-img"
            style={{ backgroundImage: `url(${activeBg})` }}
          ></div>
          <div className="login-cover-bg"></div>
        </div>

        <div className="login-container">
          <div className="login-header">
            <div className="brand">
              <div className="d-flex align-items-center">
                {/* <img
                  src="../../../assets/img/logo/logo_chili_rojo.png"
                  style={{ width: "28px", height: "28px" }}
                /> */}
                <b>Chili</b>Guajili
              </div>
              <small>Chilitos reclutamiento</small>
            </div>
            <div className="icon">
              {/* <i className="fa fa-lock"></i> */}
              <img
                src="../../../assets/img/logo/logo_chili_rojo.png"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="login-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-floating mb-20px">
                <input
                  type="email"
                  className="form-control fs-13px h-45px border-0"
                  placeholder="Correo electrónico"
                  id="emailAddress"
                  autoComplete="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                />

                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center text-gray-600 fs-13px"
                >
                  Correo electrónico
                </label>
              </div>

              <div className="form-floating mb-20px">
                <input
                  type="password"
                  className="form-control fs-13px h-45px border-0"
                  placeholder="Contraseña"
                  required
                  autoComplete="current-password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name="password"
                />
                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center text-gray-600 fs-13px"
                >
                  Contraseña
                </label>
              </div>
              {/* <div className="form-check mb-20px">
                <input className="form-check-input border-0" type="checkbox" value="1" id="rememberMe" />
                <label className="form-check-label fs-13px text-gray-500" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div> */}
              <div className="mb-20px">
                <button
                  type="submit"
                  className="btn btn-theme d-block w-100 h-45px btn-lg"
                  disabled={loading}
                  style={{
                    ...(loading && {
                      opacity: 0.7,
                      pointerEvents: "none",
                      cursor: "not-allowed",
                    }),
                  }}
                >
                  {loading ? "Iniciando sesión..." : "Iniciar sesión"}
                </button>
              </div>
              {/* <div className="text-gray-500">
                Not a member yet? Click <Link to="/user/register-v3">here</Link> to register.
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <p className="position-absolute text-secondary fw-300 bottom-0 z-3 p-3 m-0 fs-10px" style={{width: '100vw', textAlign: 'right'}}>
        {AppVersion()}
      </p>
      {/* <div className="login-bg-list clearfix">
        <div className={`login-bg-list-item ${bg1 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg1", "/assets/img/login-bg/login-bg-17.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-17.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
        <div className={`login-bg-list-item ${bg2 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg2", "/assets/img/login-bg/login-bg-16.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-16.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
        <div className={`login-bg-list-item ${bg3 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg3", "/assets/img/login-bg/login-bg-15.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-15.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
        <div className={`login-bg-list-item ${bg4 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg4", "/assets/img/login-bg/login-bg-14.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-14.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
        <div className={`login-bg-list-item ${bg5 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg5", "/assets/img/login-bg/login-bg-13.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-13.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
        <div className={`login-bg-list-item ${bg6 ? "active" : ""}`}>
          <Link
            to="/user/login-v2"
            onClick={(e) =>
              selectBg(e, "bg6", "/assets/img/login-bg/login-bg-12.jpg")
            }
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-12.jpg)",
            }}
            className="login-bg-list-link"
          ></Link>
        </div>
      </div> */}
    </React.Fragment>
  );
}

export default LoginV2;
