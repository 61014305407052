import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetch, setShowModalRazonSocial } from "../../store/slices/puestosSlice.ts";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { RootState } from "../../store/index.ts";
import { SaveBusinessName, updateBusinessName } from "../../services/businessName.service.ts";
import { PuestosInitial } from "../../interfaces/formik/IPuestos.ts";
import { PuestosSchema } from "../../validation/schemas/PuestosSchema.ts";

const NewRecordPuestos = ({ initialValues, onclose }) => {
    const isEditing = initialValues && initialValues.id;
    const initialName = isEditing ? initialValues.name : "";
    const { fetch } = useSelector((state: RootState) => state.puestos) || { fetch: false };
    const dispatch = useDispatch();

    const formik = useFormik({
        validationSchema: PuestosSchema,
        initialValues: {
            ...PuestosInitial,
            name: initialName,
        },
        onSubmit: async (values) => {
            try {
                let response;
                if (isEditing) {
                    response = await updateBusinessName(initialValues.id, values);
                } else {
                    response = await SaveBusinessName(values);
                }
                notification("", isEditing ? "Registro actualizado" : "Registro guardado con exito", "info");
                dispatch(setShowModalRazonSocial(false));
                dispatch(setFetch(!fetch));
                onclose();
                console.log(response);
            } catch (error) {
                notification("Error", error.message, "danger");
            }
            console.log(values);
        },
    });

    return (
        <React.Fragment>
            <CustomInput
                formik={formik}
                field="name"
                label={`Razón Social ${isEditing ? "(Editar)" : ""}`}
                placeholder="Razón Social"
                sm={9}
            />
            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-secondary me-2"
                    onClick={() => dispatch(setShowModalRazonSocial(false))}
                >
                    Cancelar
                </button>
                <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
                    {isEditing ? "Actualizar registro" : "Guardar registro"}
                </button>
            </div>
        </React.Fragment>
    );
};

export default NewRecordPuestos;