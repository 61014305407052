import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Branch from "../../models/Branch";
import ModalContainer from "../../components/ModalContainer.tsx";
import FormNuevo from "../../components/catalog/FormNuevo.tsx";
import { setFetch } from "../../store/slices/catalogSlice.ts";
import moment from "moment";
import "moment/locale/es";
import { ca } from "date-fns/locale";
moment.locale("es");

const TableCatalog = () => {
  const { catalog, menuSelected } = useSelector(
    (state: RootState) => ({
      catalog: state.catalog.catalog,
      menuSelected: state.catalog.menuSelected
    })
  );
  
  const [showModal, setShowModal] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState({
    id: -1,
    name: "",
  });

  const formatDate = (date: string) => {
    const fechaFormateada = moment(date).format(
      "dddd, D [de] MMMM [de] YYYY, HH:mm:ss"
    );

    return fechaFormateada;
  };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th className='text-center'>ID</th>
            <th className='text-center'>Nombre</th>
            <th className=' text-end'>Accion </th>
          </tr>
        </thead>
        <tbody>
          {catalog?.map((catalogo: Branch, index) => (
            
            <tr key={index}>
              <td className=' text-center'>
                {catalogo.id}
              </td>
              <td className=' text-center'>{catalogo.name}</td>
              <td className=' text-end'>
                {catalog.name !== "Activo" && (
                  <a
                    className="btn btn-rojo m-2"
                    onClick={() => {
                      setShowModal(true);
                      setDataToEdit({
                        id: parseInt(catalogo.id),
                        name: `${catalogo.name} `,
                      });
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-edit"></i>
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalContainer
        open={showModal}
        closeFn={() => {
          setShowModal(false);
          setDataToEdit({
            id: -1,
            name: "",
          });
        }}
        title={`Editar `}
        children={
          <FormNuevo
            closeFn={() => {
              setShowModal(false);
              setDataToEdit({
                id: -1,
                name: "",
              });
            }}
            tapSelected={menuSelected}
            dataToEdit={dataToEdit}
          />
        }
      />
    </div>
  );
};

export default TableCatalog;
