import React from "react";
import { IDeletePostulante } from "../../interfaces/others/IDeletePostulante.ts";
import { readReason } from "../../services/reasons.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { useFormik } from "formik";
import { DeletePostulanteSchema } from "../../validation/schemas/DeletePostulanteSchema.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import { rejectPhase } from "../../services/application.service.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { RejectPostulanteInitial } from "../../interfaces/formik/IListaNegra.ts";
import CustomTextArea from "../formik/CustomTextArea.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { DeletePostulanteSchemaPerson } from "../../validation/schemas/BlackListSchema.ts";
import { saveRecordBlackList } from "../../services/blacklist.service.js";

const DeletePostulante: React.FC<IDeletePostulante> = ({
  name,
  id,
  closeFn,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const [reason, setReason] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(false);
  const location = useLocation();
  const formikBaneo = useFormik({
    enableReinitialize: true,
    initialValues: RejectPostulanteInitial(location?.state || {}),
    validationSchema: DeletePostulanteSchemaPerson,
    onSubmit: async (values) => {
      try {
        const body = { ...values } as any;
        console.log('+++++++')
        console.log(body)
        console.log('+++++++')
        if (values.isPermanent) {
          delete body.unbanDate;
        }
        const response = await saveRecordBlackList(body);
        console.log(response);
      } catch (error) {
        notification("Error", error?.message, "danger");
      }
    },
  });

  const formik = useFormik({
    validationSchema: DeletePostulanteSchema,
    initialValues: {
      reasonId: -99,
      comments: "",
    },
    onSubmit: async (values) => {
      try {
        if (isChecked) {
          const errors = await formikBaneo.validateForm();
          if (Object.keys(errors).length > 0) {
            formikBaneo.handleSubmit();
            return;
          }
          formikBaneo.handleSubmit();
        }
        const response = await rejectPhase(String(id), values);
        notification("", "Solicitud cancelada correctamente", "info");
        navigate("/solicitudes");
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al cancelar la solicitud",
          "danger"
        );
      }
    },
  });

  const fetchReasons = async () => {
    try {
      const data = await readReason();
      setReason(data);
    } catch (error) {
      notification("Error", "Ocurrió un error al cargar las razones", "danger");
    }
  };

  React.useEffect(() => {
    fetchReasons();
  }, []);

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Está seguro que deseas cancelar solicitud de {name}?
      </p>
      <div className="row">
        <CustomSelectField
          formik={formik}
          field="reasonId"
          label="Razón"
          list={reason}
        />
      </div>
      <CustomInput
        formik={formik}
        field="comments"
        label="Comentarios"
        placeholder="Comentarios"
        sm={7}
        smLabel={5}
      />
      <div className="row mb-15px fs-13px d-flex align-items-center">
        <label className="form-label col-form-label col-md-5 text-end">
          Ingresar a la lista negra:
        </label>
        <input
          type="checkbox"
          className="form-check-input"
          data-checked="user-status-checkbox"
          id="statusUser"
          data-change="user-status"
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          checked={isChecked}
        />
      </div>
      {isChecked && (
        <>
          <CustomInput
            formik={formikBaneo}
            field="curp"
            label="Curp"
            placeholder="Curp"
            sm={7}
            smLabel={5}
            disabled={true}
          />
          <CustomTextArea
            formik={formikBaneo}
            field="reason"
            label="Motivo"
            placeholder="Motivo por el cual se va a bloquear a la persona"
            sm={7}
            smLabel={5}
          />
          {!formikBaneo.values.isPermanent && (
            <CustomInput
              formik={formikBaneo}
              field="unbanDate"
              label="Fecha de desbloqueo"
              placeholder="-"
              type="date"
              sm={7}
              smLabel={5}
            />
          )}
          <CustomCheckboxField
            formik={formikBaneo}
            field="isPermanent"
            label="Permanente"
          />
        </>
      )}
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-secondary me-2"
          onClick={() => closeFn()}
        >
          Regresar
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Cancelar solicitud
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeletePostulante;
