import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ModalContainer from "../../components/ModalContainer.tsx";
import moment from "moment";
import {
  readAvailabilities,
  saveAvailability,
  updateAvailability,
} from "../../services/availabilities.service.ts";
import RemoveAvailability from "../../components/calendar/RemoveAvailability.tsx";

function CalendarDisponibilidad() {
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState({ start: "", end: "" });
  const [id, setId] = useState();
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const plugins = [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
    listPlugin,
    bootstrapPlugin,
  ];
  const headerToolbar = {
    left: "dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    right: "prev,next today",
  };
  const buttonText = {
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
  };
  const views = {
    timeGrid: {
      eventLimit: 6,
    },
  };

  const colors = {
    1: "#DC2C27",
    2: "#CBD623",
  };

  const [events, setEvents] = useState();

  useEffect(() => {
    var containerEl = document.getElementById("external-events");
    new Draggable(containerEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        return {
          id: eventEl.getAttribute("business"),
          title: eventEl.innerText,
          color: eventEl.getAttribute("data-color"),
        };
      },
    });
  }, []);

  const getCalendarEvents = async (start = date.start, end = date.end) => {
    try {
      const data = await readAvailabilities(start, end);
      if (data.length > 0) {
        const eventos = [];
        data.map((element) => {
          const record = {
            id: element.id,
            id_business: element.businessName.id,
            business_name: element.businessName.name,
            title: element.name,
            start: element.startDate,
            end: element.endDate,
            color: colors[element.businessName.id],
            display: "block",
            startEditable: true,
            durationEditable: true,
          };
          eventos.push(record);
        });
        setEvents(eventos);
      }
    } catch (error) {
      console.log("Error fetching events: ", error);
    }
  };

  const postCalendarEvents = async (datoStop) => {
    if (currentView === "dayGridMonth") {
      return;
    }
    const fechaIinicio = new Date(datoStop.event.start);
    const fechaFin = new Date(datoStop.event.start);
    const horas = fechaIinicio.getHours();
    fechaIinicio.setHours(9, 0, 0, 0);
    fechaFin.setHours(13, 0, 0, 0);

    const { event } = datoStop;
    const startTime = event.start;
    const endTime = event.end;

    let body = {};
    if (datoStop) {
      body = {
        name: datoStop.event.title,
        idBusinessName: Number(datoStop.event.id),
        startDate:
          horas == 0
            ? moment(fechaIinicio).format("YYYY-MM-DD HH:mm:ss.SSS")
            : datoStop.event.startStr,
        endDate:
          datoStop.event.endStr == ""
            ? moment(fechaFin).format("YYYY-MM-DD HH:mm:ss.SSS")
            : datoStop.event.endStr,
      };
    }
    // await CalendarService.postAvaibles(body);
    //
    try {
      await saveAvailability(body);
    } catch (error) {
      console.log(error);
    } finally {
      getCalendarEvents();
    }
  };

  const putCalendarEvents = async (dato) => {
    if (currentView === "dayGridMonth") return;
    let fechaIn = new Date(dato.event.start);
    let fechaFin = new Date(dato.event.end);
    let horaI = fechaIn.getHours();
    let horaF = fechaFin.getHours();

    const { event } = dato;
    const startTime = event.start;
    const endTime = event.end;

    let body = {
      name: dato.event._def.extendedProps.business_name,
      idBusinessName: Number(dato.event._def.extendedProps.id_business),
      startDate: startTime,
      endDate: endTime,
    };
    const id = dato.event.id;

    try {
      await updateAvailability(body, Number(id));
    } catch (error) {
      console.log(error);
    } finally {
      getCalendarEvents();
    }
  };

  const handleDateRangeData = (date) => {
    const start = date.startStr;
    const end = date.endStr;
    getCalendarEvents(start, end);
    setDate({ start, end });
  };

  return (
    <div>
      {currentView}
      <div className="row m-4">
        <div className="d-sm-block" style={{ width: "215px" }}>
          <div id="external-events" className="fc-event-list">
            <h5 className="mb-3">Razón Social</h5>
            <div className="fc-event" business="1" data-color="#DC2C27">
              <div className="fc-event-text">Chili Guajili</div>
              <div className="fc-event-icon">
                <i className="fas fa-circle fa-fw fs-9px text-rojo"></i>
              </div>
            </div>
            <div className="fc-event" business="2" data-color="#CBD623">
              <div className="fc-event-text">Oaxaqueñisimo</div>
              <div className="fc-event-icon">
                <i className="fas fa-circle fa-fw fs-9px text-verde"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg">
          <FullCalendar
            datesSet={(arg) => {
              handleDateRangeData(arg);
            }}
            plugins={plugins}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            headerToolbar={headerToolbar}
            buttonText={buttonText}
            events={events}
            views={views}
            editable={currentView === "dayGridMonth"}
            droppable={currentView === "dayGridMonth"}
            defaultTimedEventDuration="04:00:00"
            // slotMinTime="00:00:00"
            // slotMaxTime="24:00:00"
            slotMinTime="07:00:00"
            slotMaxTime="23:00:00"
            weekends="true"
            eventReceive={(events) => {
              postCalendarEvents(events);
              getCalendarEvents();
            }}
            eventChange={(events) => {
              putCalendarEvents(events);
              getCalendarEvents();
            }}
            navLinks="true"
            navLinkDayClick={function (date) {
              this.changeView("timeGridWeek", date);
            }}
            eventClick={(info) => {
              setShowModal(true);
              setId(info.event.id);
            }}
            allDaySlot={false}
            viewDidMount={function (info) {
              setCurrentView(info.view.type);
            }}
          />
        </div>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Eliminar disponibilidad"
        children={
          <RemoveAvailability
            closeFn={() => setShowModal(false)}
            id={id}
            refreshFn={() => getCalendarEvents()}
          />
        }
      />
    </div>
  );
}

export default CalendarDisponibilidad;
