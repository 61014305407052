import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { deleteBaseContract, fetchBaseContracts, updateBaseContractFuntions } from '../../../services/contract.service.ts';
import ModalContainer from '../../ModalContainer.tsx';
import AddResponsability from '../../positions/AddResponsabilitie.tsx';
import { useFormik } from 'formik';
import Button from '../../Button.tsx';
import { notification } from '../../../utils/Notifications.tsx';

function createData(
  id: number,
  name: string,
  department: string,
  funtions: string[],
) {
  return {
    id,
    name,
    department,
    funtions,
  };
}

function Row(props: { row: ReturnType<typeof createData>, reload: () => void }) {
  const { row, reload } = props;
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      funtions: row.funtions,
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await updateBaseContractFuntions(row.id, values.funtions);
        console.log("response", response);

        notification('Éxito', 'Contrato actualizado correctamente', 'success');
      } catch (error) {
        console.error("Error updating base contract funtions:", error);
        notification('Error', 'Error al actualizar contrato', 'danger');
      }
    },
  });

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>
        <TableCell component="th" scope="row">
          {row.name || 'N/A'}
        </TableCell>
        <TableCell align="right">{row.department || 'N/A'}</TableCell>
        <TableCell align="right">{Array.isArray(row.funtions) ? row.funtions.length : 0}</TableCell>
        <TableCell align="right">
          {open ? <i className="fa-solid fa-sort-up" /> : <i className="fa-solid fa-sort-down" />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <div className="d-flex flex-row w-100 justify-content-between">
                  <h2>{row.name || 'N/A'}</h2>
                  <button
                    type="button"
                    className={`btn btn-outline-secondary`}
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fas fa-plus"></i> Agregar responsabilidad
                  </button>
                </div>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Funciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <div className="widget-list rounded">
                    {row.funtions && row.funtions.map((historyRow, index) => (
                      <TableRow key={index}>
                        <div className="widget-list-item mt-1" key={index}>
                          <div className="widget-list-media icon">
                            <a style={{ cursor: 'pointer' }}>
                              <i
                                className="fa fa-trash bg-yellow text-dark"
                              ></i>
                            </a>
                          </div>
                          <div className="widget-list-content">
                            <h4 className="widget-list-title">
                              {historyRow || 'N/A'}
                            </h4>
                          </div>
                        </div>
                      </TableRow>
                    ))}
                  </div>
                </TableBody>
              </Table>
              <div className="card border-0 rounded p-3">
                <div className="row justify-content-between">
                  <div className="col-md-3">
                    <Button
                      onClick={() => setDeleteModal(true)}
                      size='sm'
                      title="Eliminar"
                      variant="danger"
                      type="submit"
                    />
                  </div>
                  <div className="col-md-3">
                    <Button
                      onClick={() => formik.handleSubmit()}
                      size='sm'
                      title="Guardar"
                      variant="azul"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Agregar responsabilidad"
        children={
          <AddResponsability
            formik={formik}
            closeFn={() => setShowModal(false)}
            originalField={'funtions'}
          />
        }
      />

      <ModalContainer
        open={deleteModal}
        closeFn={() => setDeleteModal(false)}
        title="Eliminar responsabilidad"
        children={
          <div>
            <h4>¿Estás seguro de eliminar esta responsabilidad?</h4>
            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-secondary me-2"
                onClick={() => setDeleteModal(false)}
              >
                Cancelar
              </button>
              <button
                className="btn btn-danger"
                onClick={async () => {
                  try {
                    await deleteBaseContract(row.id);
                    notification('Éxito', 'Contrato eliminado correctamente', 'success');
                    reload();
                  } catch (error) {
                    console.error("Error deleting base contract:", error);
                    notification('Error', 'Error al eliminar contrato', 'danger');
                  }
                  setDeleteModal(false);
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}

export default function EditContracts({ closeFn }) {
  const [contracts, setContracts] = React.useState<any>([]);

  const fetchDependencies = async () => {
    try {
      const response = await fetchBaseContracts();
      if (Array.isArray(response)) {
        setContracts(response);
      } else {
        console.error("Error: Response is not an array");
      }
    } catch (error) {
      console.error("Error fetching contracts:", error);
    }
  }

  const rows = contracts.map((contract) =>
    createData(
      contract?.id || 0,
      contract?.name || 'N/A',
      contract?.department?.name || 'N/A',
      Array.isArray(contract?.funtions) ? contract.funtions : []
    )
  );

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell align="right">Departamento</TableCell>
              <TableCell align="right">Funciones</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} reload={fetchDependencies} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex w-100">
        <button className="btn btn-azul ms-auto mt-3" onClick={() => closeFn()}>
          Cerrar
        </button>
      </div>
    </div>

  );
}
