import React from "react";
import { useParams } from "react-router-dom";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";

import { readApplicationsByCurp } from "../../services/application.service.ts";
import Application from "../../models/Application.ts";
import Title from "../../components/Title.tsx";
import SideBar from "../../components/history/SideBar.tsx";
import Loader from "../../components/Loader.tsx";
import ApplicationList from "../../components/history/ApplicationList.tsx";
import FooterChili from "../../components/FooterChili.jsx";
import { ApplicationProperties } from "../../models/ApplicationFull.ts";
import Layout from "../../components/Layout.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const Historial = () => {
  const { curp } = useParams();
  const [applications, setApplications] = React.useState<
    ApplicationProperties[]
  >([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const context = useAppSettings();

  const fetchApplications = async () => {
    if (!curp) return;
    try {
      const response = await readApplicationsByCurp(curp);
      // console.log(response);
      setApplications(response);
    } catch (error) {
      setError("Se produjo un error al buscar las aplicaciones.");
      setApplications([]);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchApplications();
  }, [curp]);

  return (
    <Layout loading={loading}>
      <Title
        title={`Historial de ${
          applications.length > 0
            ? `${toCamelCase(applications[0].person.firstName + ' ' +  applications[0].person.lastName + ' ' +  applications[0].person.secondLastName)}`
            : curp
        }`}
        baseTitle={`Historial`}
        basePath={``}
      />

      <div style={{ marginBottom: "3%", marginLeft: "3%", marginRight: "3%" }}>
        <hr />
      </div>
      {error ? (
        <div
          className="alert alert-danger"
          style={{
            marginLeft: "20%",
            marginRight: "20%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {error}&nbsp;&nbsp;
          <i className="fa-solid fa-triangle-exclamation"></i>
        </div>
      ) : (
        <React.Fragment>
          {!loading && applications.length === 0 ? (
            <div
              className="alert alert-warning"
              role="alert"
              style={{
                marginLeft: "20%",
                marginRight: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h5>
                Aun no hay aplicaciones para {curp}&nbsp;&nbsp;
                <i className="fa-solid fa-triangle-exclamation"></i>
              </h5>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: "3%" }}>
              <SideBar />
              <ApplicationList applications={applications} />
            </div>
          )}
        </React.Fragment>
      )}
    </Layout>
  );
};

export default Historial;
