import httpClient, { httpFormDataClient } from "./httpClient";

const prefix = "/contracts";

export async function fetchContractByCandidate(id: number) {
  return (await httpClient.get(`${prefix}/find-by-candidate/${id}`)).data;
}
export async function fetchContractIMSSByPartner(id: number) {
  return (await httpClient.get(`${prefix}/find-IMSS-by-candidate/${id}`)).data;
}

export async function saveContract(data: {
  candidate_id: number;
  file_id: string;
}) {
  return (await httpClient.post(`${prefix}`, data)).data;
}

export async function saveBaseContract(data: {
  contractName: string;
  idDepartment: string;
  responsibilities: string[];
}) {
  return (await httpClient.post(`${prefix}/create-base-contract`, data)).data;
}

export async function fetchBaseContracts() {
  return (await httpClient.get(`${prefix}/find-all-base-contracts`)).data;
}

export async function fetchBaseContractByDepartment(departmentId: string) {
  return (
    await httpClient.get(`${prefix}/find-base-contract-department/${departmentId}`)
  ).data;
}

export async function fetchBaseContractById(id: string) {
  return (await httpClient.get(`${prefix}/find-base-contract/${id}`)).data;
}

export async function updateBaseContractFuntions(
  id: number,
  data: string[]
) {
  return (await httpClient.patch(`${prefix}/update-base-contract/${id}`, data))
    .data;
}

export async function deleteBaseContract(id: number) {
  return (await httpClient.delete(`${prefix}/delete-base-contract/${id}`)).data;
}