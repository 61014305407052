import React from "react";
import Skeleton from "@mui/material/Skeleton";

const LoaderContractPie = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-auto d-flex align-items-start">
          <Skeleton variant="circular" width={300} height={300} />
        </div>
        <div
          className="col d-flex flex-column justify-content-start"
          style={{ paddingTop: "5%" }}
        >
          <Skeleton variant="text" width="100%" sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" width="100%" sx={{ fontSize: "2rem" }} />
        </div>
      </div>
    </div>
  );
};

export default LoaderContractPie;
