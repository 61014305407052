import httpClient from "./httpClient";

const prefix = "/configuration";

export async function findConfigurationByKey(key: string) {
  return (await httpClient.get(`${prefix}/key/${key}`)).data;
}

export async function updateAppoinmentTime(minutes: number) {
  return (await httpClient.patch(`${prefix}/update-appoinment-time/${minutes}`))
    .data;
}

export async function updateConfig(configName: string, json: any) {
  return (await httpClient.patch(`${prefix}/update-config/${configName}`, json))
    .data;
}

export async function createAssistanceConfig(config: any) {
  return (await httpClient.post(`${prefix}/create-assistance-config`, config))
    .data;
}
