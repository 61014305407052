import * as yup from "yup";

export const ScheduleSchema = yup.object().shape({
  week: yup
    .number()
    .min(0, "Campo semana es requerido")
    .required("Campo requerido")
    .integer("Campo requerido"),
  shift: yup
    .number()
    .min(0, "Campo turno es requerido")
    .required("Campo requerido")
    .integer("Campo requerido"),
  dayOfWeek: yup
    .number()
    .min(0, "Campo día es requerido")
    .required("Campo requerido")
    .integer("Campo requerido"),
});
