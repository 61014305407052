import httpClient from "./httpClient";
const apiUrl = process.env.REACT_APP_API_URL;
export class BlacklistService {
  static postBlackList = async (formData) => {
    const url = apiUrl + "/blacklists";

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const resp = await fetch(url, requestOptions);

    const data = await resp.json();
    return data;
  };

  static putBlackList = async (id, formData) => {
    const url = apiUrl + "/blacklists/" + id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const resp = await fetch(url, requestOptions);

    const data = await resp.json();
    return data;
  };

  static getBlackList = async (id) => {
    console.log(id);
    const url = id ? apiUrl + "/blacklists/" + id : apiUrl + "/blacklists";

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const resp = await fetch(url, requestOptions);
    const data = await resp.json();
    return data;
  };
}

const prefix = "/blacklists";

export async function saveRecordBlackList(body) {
  return (await httpClient.post(`${prefix}/`, body)).data;
}

export async function paginateBlackList(query) {
  return (await httpClient.get(`${prefix}${query}`)).data;
}
