import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { notification } from "../../utils/Notifications.tsx";
import {
  getTemplateByPosition,
  updateTemplate,
} from "../../services/proceedings.service.ts";
import { fetchDocumentTypes } from "../../services/documentTypes.service.ts";
import { PositionSchema } from "../../validation/schemas/PositionSchema.ts";
import { useFormik } from "formik";
import {
  EditPositionProperties,
  FillPosition,
} from "../../interfaces/formik/IPosition.ts";
import Title from "../../components/Title.tsx";
import { Panel, PanelHeader } from "../../components/panel/panel.jsx";
import PositionForm from "../../components/positions/PositionForm.tsx";
import DocumentationPosition from "../../components/positions/DocumentationPosition.tsx";
import Button from "../../components/Button.tsx";
import FooterChili from "../../components/FooterChili.jsx";
import Loader from "../../components/Loader.tsx";
import DocumentationPositionEdit from "../../components/positions/DocumentationPositionEdit.tsx";
import { readPosition, updatePosition } from "../../services/position.service.ts";
import PositionFormEdit from "../../components/positions/PositionFormEdit.tsx";
import Layout from "../../components/Layout.tsx";
import ResponsabilitiesPositionEdit from "../../components/positions/ResponsabilitiesPositionEdit.tsx";
import BaseContract from "../../components/positions/BaseContract.tsx";
import ResponsabilitiesPosition from "../../components/positions/ResponsabilitiesPosition.tsx";

const EditPosition = () => {
  const { id } = useParams();
  const context = useAppSettings();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState<[]>([]);
  const [initialValues, setInitialValues] =
    React.useState<EditPositionProperties>({} as EditPositionProperties);
  const [responsabilities, setResponsabilities] = React.useState<[]>([]);

  const loadDependencies = async () => {
    setLoading(true);
    try {
      const template = await getTemplateByPosition(Number(id));
      const fillObject = FillPosition(template);

      const postion = await readPosition(Number(id));
      setResponsabilities(postion.funtions);

      if (!fillObject) throw new Error("Error al cargar los datos");
      setInitialValues(fillObject);

      const documentTypes = await fetchDocumentTypes();
      setDocuments(documentTypes);
    } catch (error) {
      notification("error", "Error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: PositionSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Update positionxx
        const updatePositionBody = {
          name: values.positionName,
          highRisk: values.highRisk,
          idBusinessName: values.idBusinessName,
          funtions: values.funtions,
          idDepartment: values.idDepartment,
        };
        const position = await updatePosition(Number(id), updatePositionBody);

        // Update templates
        const inmediate = await updateTemplate(Number(values.idInmediate), {
          inmediate: true,
          documentTypes: values.documentTypesInmediate,
        });
        const noInmediate = await updateTemplate(Number(values.idNoInmediate), {
          inmediate: false,
          documentTypes: values.documentTypesNoInmediate,
        });
      } catch (error) {
        notification("error", "Error al guardar los datos", "danger");
      } finally {
        setLoading(false);
        navigate("/puestos");
      }
    },
  });

  React.useEffect(() => {
    loadDependencies();
  }, []);

  let uniqueArray = [];

  if (
    formik &&
    formik.values &&
    formik.values.documentTypesInmediate &&
    formik.values.documentTypesNoInmediate
  ) {
    const combinedArray = [
      ...formik?.values?.documentTypesInmediate,
      ...formik?.values?.documentTypesNoInmediate,
    ];
    uniqueArray = documents.filter((item: any) => {
      return !combinedArray.some((doc: any) => doc === item.id);
    });
  }

  return (
    <Layout>
      <Title
        baseTitle="Puestos"
        basePath="/puestos"
        activeTitle="Editar puesto"
        title="Editar puesto"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Datos generales del puesto
          </PanelHeader>
          {/* <PositionForm formik={formik} /> */}
          <PositionFormEdit formik={formik} />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Documentacion inmediata
          </PanelHeader>
          <DocumentationPositionEdit
            formik={formik}
            inmediate={true}
            list={uniqueArray}
            originalField="documentTypesInmediate"
            originalList={documents}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Documentacion no inmediata
          </PanelHeader>
          <DocumentationPositionEdit
            formik={formik}
            inmediate={true}
            list={uniqueArray}
            originalField="documentTypesNoInmediate"
            originalList={documents}
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Contrato base
          </PanelHeader>
          <BaseContract
            formik={formik}
            originalField="funtions"
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Responsabilidades
          </PanelHeader>
          <ResponsabilitiesPosition
            formik={formik}
            originalField="funtions"
          />
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/puestos")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => formik.handleSubmit()}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditPosition;
