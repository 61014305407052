import React, { useEffect } from "react";
import Loader from "../Loader.tsx";
import { getContractIMSS, getContractPdf } from "../../services/colaborador.pdf.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import ModalContainer from "../ModalContainer.tsx";
import { Partner } from "../../models/Partner.ts";
import UploadContract from "./UploadContract.tsx";
import { Contract } from "../../models/Contract.ts";
import { useReactToPrint } from "react-to-print";
import {
  getFileById,
  getFilePreviewById,
} from "../../services/proceedings.service.ts";
import { IContract } from "../../interfaces/others/IContract.ts";
import ContractIMSSPrint from "../../pages/ContractIMSSPrint.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { fetchContractByCandidate } from "../../services/contract.service.ts";

const AccountantContract = ({ partner }: { partner: Partner }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contracts, setContracts] = React.useState<Contract[]>([]);
  const [modalUpload, setModalUpload] = React.useState<boolean>(false);
  const [isPress, setIsPress] = React.useState<boolean>(false);
  const [previews, setPreviews] = React.useState<any>([]);
  const [contract, setContract] = React.useState<IContract>({});
  const componentRef = React.useRef() as React.MutableRefObject<any>;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          height: initial !important;
          overflow: initial !important;
        }
        .page-break {
          display: block;
          page-break-before: auto;
        }
      }
      @page {
        size: letter;
        margin: 25mm;
      }
    `,
  });  

  const generateContract = async (colaboratorId) => {
    setLoading(true);
    try {
      const response = await getContractIMSS(colaboratorId);
      setContract(response);
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al descargar el contrato",
        "danger"
      );
    } finally {
      setLoading(false);
      setIsPress(true);
    }
  };

  React.useEffect(() => {
    fetchContracts();
  }, [partner]);

  React.useEffect(() => {
    if (contract?.rfc) {
      handlePrint();
    }
  }, [contract]);

  const fetchContracts = async () => {
    try {
      const response = await fetchContractByCandidate(partner.candidate.id);
      setContracts(response);
    } catch (error) {
      notification("Error", "Ocurrió un error al obtener los contratos", "danger");
      console.log(error, " fetch contract");
    }
  };

  const downloadFile = async (id: string) => {
    setLoading(true);
    try {
      const response = await getFileById(id);
      const url = response.url;
      const link = document.createElement("a");
      link.href = url;
      link.download = "file"; // Aquí puedes poner el nombre del archivo que prefieras
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al descargar el contrato",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contracts.length > 0) {
      Promise.all(
        contracts.map(async (contract) => {
          if(contract.file.id === 'f0b6fe93-002a-4c04-9ce4-b1e0f79e5228'){
           return null 
          }
          try {
            const response = await getFilePreviewById(contract.file.id);
            return {...response};
          } catch (error) {
            notification("Error", "Ocurrió un error al obtener los contratos", "danger");
            console.log(error, " fetch contract");
          }
        })
      ).then((values) => {
        const filteredValues = values.filter(value => value !== null);
        setPreviews(filteredValues);
      });
    }
  }, [contracts]);

  const isAnyValueEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === "" || obj[key] === null)) {
        if(key === "tipoSangre" || key === "tratamiento" || key === "alergias" || key === "enfermedad") return false;
        return true;
      }
    }
    return false;
  };

  const isEmpty = isAnyValueEmpty(partner.person);

  if (isEmpty)
    return (
      <div className="tab-pane fade" id="contratoIMSS">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            El colaborador no cuenta con información suficiente para generar el
            contrato.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa la información del colaborador en datos
            generales para poder generar el contrato.
          </p>
        </div>
      </div>
    );

  return (
    <div className="tab-pane fade" id="contratoIMSS">
      <div className="">
        <div className="row">
          <div className="col-sm-12 col-md-2 card">
            <a href="#" className="widget-card rounded h-100" data-id="widget">
              <div className="widget-card-cover rounded" />
              <div className="widget-card-content d-flex flex-column justify-content-between h-100">
                <div>
                  <h5 className="fs-12px text-body">
                    <b>Contrato</b>
                  </h5>
                  <h4 className="mb-10px text-black">
                    <b>
                      {!isPress ? "Imprimir" : "Subir"} contrato
                      <br />
                    </b>
                  </h4>
                </div>

                <i
                  className={`fa fa-file-pen text-gray text-opacity-50`}
                  style={{ fontSize: "150px", textAlign: "center" }}
                />
                <div
                  className="widget-card-content bottom"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <button
                    className="btn btn-primary fs-16px"
                    style={{ gap: 10 }}
                    onClick={() => generateContract(partner.id)}
                  >
                    <i
                      className={`fa-solid fa-download`}
                      style={{ marginRight: 10 }}
                    />
                    Descargar contrato
                  </button>
                  {isPress && (
                    <button
                      className="btn btn-primary fs-16px"
                      style={{ gap: 10 }}
                      onClick={() => setModalUpload(true)}
                    >
                      <i
                        className={`fa-solid fa-upload`}
                        style={{ marginRight: 10 }}
                      />
                      Subir contrato
                    </button>
                  )}
                </div>
              </div>
            </a>
          </div>
          <div
            style={{ marginLeft: "1%" }}
            className="col-sm-12 col-md-6 card mt-auto"
          >
            <div
              className="overflow-auto me-1"
              style={{ width: "100%", maxWidth: "800px", height: "440px" }}
            >
              <ul className="list-unstyled d-flex flex-nowrap min-w-250px p-3">
                {previews && previews.length > 0 ? (
                  previews.map((preview) => (
                    <li
                      key={preview.index}
                      className="position-relative me-3 d-flex flex-column justify-content-center align-items-center bg-white shadow-sm rounded-2"
                      style={{ minWidth: "200px", height: "400px" }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          marginBottom: "10px",
                          textAlign: "center",
                          height: "20px",
                          fontWeight: 600,
                          overflow: "hidden",
                        }}
                      >
                        {preview.name}.{preview.extension}
                      </p>
                      <div className="preview-container">
                        {preview.extension === "pdf" ? (
                          <iframe
                            src={preview.url}
                            title={preview.originalName}
                            style={{ width: "100%", height: "300px" }}
                            sandbox="allow-same-origin allow-scripts"
                          ></iframe>
                        ) : (
                          <img
                            src={preview.url}
                            alt={preview.originalName}
                            style={{
                              width: "100%",
                              height: "300px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                      </div>
                      <button
                        className="btn btn-amarillo btn-sm mt-2"
                        type="button"
                        onClick={() => downloadFile(preview.id)}
                      >
                        <i className="fa-solid fa-cloud-arrow-down"></i>{" "}
                        Descargar
                      </button>
                    </li>
                  ))
                ) : (
                  <p
                    style={{ fontStyle: "italic" }}
                    className="text-center fs-16px text-gray-500 mt-3"
                  >
                    Aún no hay contratos
                  </p>
                )}
              </ul>
            </div>
          </div>
        </div>
        <ModalContainer
          title={`Subir contrato de ${toCamelCase(partner.person.firstName + ' ' + partner.person.lastName + ' ' + partner.person.secondLastName)} `}
          open={modalUpload}
          closeFn={() => setModalUpload(false)}
          children={
            <UploadContract
              partner={partner}
              closeFn={() => {
                setModalUpload(false);
                fetchContracts();
              }}
            />
          }
        // size="lg"
        />
      </div>
      <Loader isLoading={loading} />

      <div style={{ display: "none" }}>
        <ContractIMSSPrint ref={componentRef} contractData={contract} />
        {/* <Print ref={componentRef} /> */}
      </div>
    </div>
  );
};

export default AccountantContract;
