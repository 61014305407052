import React, { useEffect } from "react";
import Loader from "../Loader.tsx";
import { getContractPdf } from "../../services/colaborador.pdf.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { fetchContractByCandidate } from "../../services/contract.service.ts";
import { Partner } from "../../models/Partner.ts";
import { Contract } from "../../models/Contract.ts";
import {
  getFileById,
  getFilePreviewById,
} from "../../services/proceedings.service.ts";
import { IContract, Signature } from "../../interfaces/others/IContract.ts";
import SignContract from "./SignContract.tsx";

const Contracts = ({ partner }: { partner: Partner }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contracts, setContracts] = React.useState<Contract[]>([]);
  const [contract, setContract] = React.useState<IContract>({});
  const componentRef = React.useRef() as React.MutableRefObject<any>;
  const [showContract, setShowContract] = React.useState<boolean>(false);

  const generateContract = async (colaboratorId) => {
    setLoading(true);
    try {
      const response = await getContractPdf(partner.id);
      let signatureUrl: Signature;

      console.log('contract', response);
      if (response?.signature?.id) {
        signatureUrl = await getFileById(response.signature.id)
        response.signature.url = signatureUrl?.url;
      }
      setContract(response);
      if (response.signature?.url) {
        setShowContract(true);
      }
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al descargar el contrato",
        "danger"
      );
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchContracts(partner);
  }, [partner]);

  React.useEffect(() => {
    generateContract(partner.id);
  }, []);

  const fetchContracts = async (partner) => {
    try {
      const response = await fetchContractByCandidate(partner.candidate.id);
      console.log('contracts', response);
      setContracts(response);
    } catch (error) {
      console.log(error, " fetch contract");
    }
  };

  useEffect(() => {
    if (contracts.length > 0) {
      Promise.all(
        contracts.map(async (contract) => {
          try {
            const response = await getFilePreviewById(contract.file.id);
            return response;
          } catch (error) {
            console.log(error, " fetch contract");
          }
        })
      );
    }
  }, [contracts]);

  const isAnyValueEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === "" || obj[key] === null)) {
        if(key === "tipoSangre" || key === "tratamiento" || key === "alergias" || key === "enfermedad") return false;
        return true;
      }
    }
    return false;
  };

  const isEmpty = isAnyValueEmpty(partner.person);

  if (isEmpty)
    return (
      <div className="tab-pane fade" id="contrato">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            El colaborador no cuenta con información suficiente para generar el
            contrato.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa la información del colaborador en datos
            generales para poder generar el contrato.
          </p>
        </div>
      </div>
    );

  return (
    <div className="tab-pane fade" id="contrato">
      {
        showContract ? (
          <React.Fragment>
            <SignContract ref={componentRef} contractData={contract} partner={partner} refetch={generateContract} />
            <Loader isLoading={loading} />
          </React.Fragment>
        ) : (
          <div className="alert alert-warning w-50 mx-auto" role="alert">
            <h4 className="alert-heading fs-30px">¡Atención!</h4>
            <p className="fs-17px">
              La información contenida en el siguiente contrato es estrictamente confidencial y privada. Está prohibido compartir, divulgar o distribuir cualquier parte de este documento sin el consentimiento expreso y por escrito de las partes involucradas.
            </p>
            <p className="fs-17px">
              Por favor, asegúrese de leer detenidamente todo el contenido del contrato para comprender completamente los términos y condiciones establecidos.
            </p>
            <hr />
            <p className="fs-17px">
              Al hacer clic en "Aceptar", usted confirma que ha leído y comprendido este aviso y acepta los términos de confidencialidad y privacidad aquí mencionados.
            </p>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-secondary me-4"
                onClick={() => setShowContract(false)}
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setShowContract(true)}
              >
                Aceptar
              </button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Contracts;
