import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { setPage } from "../../store/slices/listaNegraSlice.ts";

const PaginationListaNegra = () => {
  const dispatch = useDispatch();
  const { page, limit, listaNegraList, total } = useSelector(
    (state: RootState) => state.listaNegra
  );
  return (
    <div className="d-md-flex align-items-center">
      <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
        Mostrando {page * limit + listaNegraList.length} de {total} registros
      </div>
      <ul className="pagination mb-0 justify-content-center">
        <li className={`page-item ${page === 0 && "disabled"} `}>
          <a
            href="#/"
            className="page-link"
            onClick={() => dispatch(setPage(page - 1))}
          >
            Anterior
          </a>
        </li>

        <li
          className={`page-item ${
            total <= page * limit + listaNegraList.length && "disabled"
          } `}
        >
          <a
            href="#/"
            className="page-link"
            onClick={() => dispatch(setPage(page + 1))}
          >
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PaginationListaNegra;
