export const PositionsInitial = {
    name: "",
    highRisk: false,
    idBusinessName: 0,

};


export interface IPosition {
    name?: string;
    highRisk: boolean;
    idBusinessName: number;
}

export const DefaultBranch: IPosition = {
    name: "",
    highRisk: false,
    idBusinessName: -99,
};
