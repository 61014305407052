import React from 'react'

type Props = {}

const CustomSelectValues = ({ formik, field, label, sm, smLabel = 3, list, onChange = () => { } }) => {
  return (
    <div className={`row ${formik?.errors[field] ? 'mb-5' : 'mb-3'} fs-13px col-md-12`}>
      <label
        className={`form-label col-form-label col-md-${smLabel} ${formik?.errors[field] && 'text-danger'} text-end`}
      >
        {label}
      </label>
      <div className={`col-md-${sm} d-flex flex-row justify-content-between align-items-center position-relative`}>
        {
          list.map((item, index) => (
            <div className={`col-md-${sm}`} key={index}>
              <div className="form-check">
                <input
                  className={`form-check-input ${formik?.errors[field] && 'border-1 border-danger'}`}
                  type="radio"
                  name={field}
                  id={item.name}
                  value={item.id}
                  checked={formik.values[field] === item.id}
                  onChange={(e) => { formik.setFieldValue(field, item.id); onChange() }}
                />
                <label className={`form-check-label ${formik?.errors[field] && 'text-danger'}`} htmlFor={item.name}>
                  {item.name}
                </label>
              </div>
            </div>
          ))
        }
        {
          formik?.touched[field] && formik?.errors[field] && (
            <div className="invalid-feedback position-absolute" style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em", top: 35 }}>
              {formik?.touched[field] && formik?.errors[field] ? (
                <>{formik?.errors[field]}</>
              ) : (
                <p> </p>
              )}
            </div>
          )
        }
      </div>

    </div>
  )
}

export default CustomSelectValues



export const CustomSelectValuesModal = ({ formik, field, label, sm, smLabel = 3, list, onChange = () => { } }) => {
  const error = formik?.touched[field] && formik?.errors[field]
  
  return (
    <div className={`row ${error ? 'mb-5' : 'mb-3'} fs-13px col-md-12`}>
      <label
        className={`form-label col-form-label col-md-${smLabel} ${error && 'text-danger'} text-end`}
      >
        {label}
      </label>
            <div className={`col-md-${sm} d-flex flex-wrap justify-content-between align-items-center position-relative`} style={{gap: 5}}>
        {
          list.map((item, index) => (
            <div className="col-12 col-sm-6 col-md-4" key={index}>
              <div className="form-check">
                <input
                  className={`form-check-input ${error && 'border-1 border-danger'}`}
                  type="radio"
                  name={field}
                  id={item.name}
                  value={item.id}
                  checked={formik.values[field] === item.id}
                  onChange={(e) => { formik.setFieldValue(field, item.id); onChange() }}
                />
                <label className={`form-check-label ${error && 'text-danger'}`} htmlFor={item.name}>
                  {item.name}
                </label>
              </div>
            </div>
          ))
        }
       
      </div>

    </div>
  )
}

