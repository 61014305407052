import React, { useEffect } from "react";
import { Partner } from "../../models/Partner";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import {
  saveFileProceeding,
  updateFileProceeding,
  uploadImage,
} from "../../services/files.service.ts";
import { getProceedingByPartner } from "../../services/proceedings.service.ts";
import FileUploader from "./FileUploader.tsx";
import { valiteCURP } from "../../utils/index.ts";
import { updateUserData } from "../../hooks/updateUserData.js";

const AddNewFile = ({
  partner,
  closeFn,
  fields,
  initialValues,
  fileTypeId,
  initialFiles,
  setLoading,
  editing,
  partnerObject,
  loadFiles,
  refetch,
  reloadPage,
}: {
  partner: Partner;
  closeFn: () => void;
  setLoading: (_: boolean) => void;
  fields: any;
  initialValues: any;
  fileTypeId: number;
  initialFiles: any;
  editing: boolean;
  loadFiles: any;
  partnerObject: any;
  refetch: any;
  reloadPage: any;
}) => {
  const [files, setFiles] = React.useState<any | null>(null);
  const [loading1, setLoading1] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      [Object.keys(initialValues)[0]]: initialValues[Object.keys(initialValues)[0]],
    },
    onSubmit: async (values) => {
      setLoading(true);
      setLoading1(true)
      try {
        if (!files || files.length === 0) {
          notification("Error", "Por favor, seleccione una imagen", "danger");
          return;
        }

        const emptyFields = fields.filter((field) => !values[field]);

        if (emptyFields.length > 0) {
          notification("Error", "Por favor, llene todos los campos", "danger");
          return;
        }

        const proceeding = await getProceedingByPartner(partner);
        await Promise.all(files.map(async (file) => {
          if (!file.type && file.fields) {
            file.fields.forEach(async (field) => {
              if (field.value !== values.name) {
                const data = {
                  proceedingId: proceeding.proceeding.id,
                  fileTypeId: fileTypeId,
                  fileId: file.file.id,
                  fields: fields.map((field) => ({
                    field,
                    value: values[field],
                  })),
                };
                await updateFileProceeding(file.id, data);
              }
            });
          } else {
            try {
              let imageId = await uploadImage(file);
              const data = {
                proceedingId: proceeding.proceeding.id,
                fileTypeId: fileTypeId,
                fileId: imageId.id,
                fields: fields.map((field) => ({
                  field,
                  value: values[field],
                })),
              };
              await saveFileProceeding(data);
            } catch (error) { }
          }
        }));

        if (fields[0] === "RFC") {
          const partner = { ...partnerObject }
          await updateUserData(fields[0], partner, values);
          formik.setValues((prev) => ({ ...prev, RFC: values.RFC }));
        }
        //await reloadPage()

        await closeFn();
        notification("Éxito", "Documento subido correctamente", "success");
      } catch (error) {
        notification("Error", "Ocurrió un error al subir el documento", "danger");
        setLoading1(false)
        console.error(error);
      } finally {
        setLoading(false);
        await refetch();
        updateFileds();
        setLoading1(false)
      }
    },
    validate(values) {
      const errors: any = {};
      if (fields.includes("CURP")) {
        if (!valiteCURP(values.CURP)) {
          errors.CURP = "CURP inválido";
        }
      }
      return errors;
    },
  });

  const updateFileds = () => {
    if (Object.keys(initialValues).length !== 0) {
      const initailObject =
        fields.reduce((acc, field) => {
          acc[field] = initialValues[field];
          return acc;
        }, {});
      formik.setValues(
        (prev) => ({ ...prev, ...initailObject })
      );
    } else {
      const initailObject =
        fields.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});
      formik.setValues(initailObject);
    }

    if (fields.includes("CURP")) {
      formik.setValues(
        (prev) => ({ ...prev, CURP: partnerObject.person.curp })
      );
    }

    if (fields.includes("RFC")) {
      formik.setValues(
        (prev) => ({ ...prev, RFC: partnerObject.person.rfc })
      );
    }
  }

  useEffect(() => {
    updateFileds()
    setFiles(initialFiles);
  }, [initialValues, fields]);

  const getTypeInput = (field: string) => {
    if (field.includes("Fecha")) {
      return "date";
    } else {
      return "text";
    }
  }

  return (
    <React.Fragment>
      {fields.map((field, index) => (
        <CustomInput
          key={index}
          formik={formik}
          field={Object.keys(initialValues)[index] || field}
          label={field}
          placeholder={field}
          sm={9}
          type={getTypeInput(field)}
          unclickable={field === "CURP"}
          required={true}
        />
      ))}
      <FileUploader
        onFilesSelected={(files) => setFiles(files)}
        initialFiles={initialFiles}
        fields={fields}
        closeFn={closeFn}
        refetch={refetch}
        legacyFiles={null}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          disabled={!files || loading1}
          className="btn btn-azul"
          onClick={() => formik.handleSubmit()}
          type="submit"
        >
          Subir
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddNewFile;
