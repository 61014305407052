import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILogin } from "../../types/AuthSliceTypes.ts";
import { IAuthSlice } from "../../interfaces/others/IAuthSlice.ts";
import { IUser } from "../../models/User.ts";

const initialState: IAuthSlice = {
  token: "",
  user: {} as IUser,
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<ILogin>) => {
      // console.log(action.payload)
      // return
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isAdmin = action.payload.rol === "Administrador";
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
