import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ModalContainer from '../../ModalContainer.tsx';
import { PanelBody } from '../../panel/panel.jsx';
import CustomSelectField from '../../formik/CustomSelectField.tsx';
import { useFormik } from 'formik';
import SucursalesModalPuestos from './SucursalesModalPuestos.tsx';
import { setPositions } from '../../../store/slices/sucursalesSlice.ts';

const SucursalesFormPuestos = ({ formik, error, templates }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false)
  const [currentRecord, setCurrentRecord] = React.useState(null)
  const { positionsOriginal } = useSelector((state: RootState) => state.sucursales);

  const formikSelect = useFormik({
    initialValues: {
      plantilla: 0,
    },
    onSubmit: async (values) => {
      const plantillaSeleccionada = templates.find((template) => template.id === values.plantilla);

      const groupedPositions = plantillaSeleccionada.positions.reduce((acc, current) => {
        const { position, shifts, numberOfWorkers } = current;
        const positionId = position.id;

        // Busca un objeto existente en el acumulador que tenga el mismo puesto
        const existing = acc.find(item => item.puesto === positionId);

        if (existing) {
          // Si ya existe, suma los trabajadores al turno correspondiente
          if (shifts.includes('Matutino')) {
            existing.matutino += Number(numberOfWorkers);
          }
          if (shifts.includes('Intermedio')) {
            existing.intermedio += Number(numberOfWorkers);
          }
          if (shifts.includes('Vespertino')) {
            existing.vespertino += Number(numberOfWorkers);
          }
        } else {
          // Si no existe, crea un nuevo objeto para este puesto
          acc.push({
            puesto: positionId,
            matutino: shifts.includes('Matutino') ? Number(numberOfWorkers) : 0,
            intermedio: shifts.includes('Intermedio') ? Number(numberOfWorkers) : 0,
            vespertino: shifts.includes('Vespertino') ? Number(numberOfWorkers) : 0,
          });
        }

        return acc;
      }, []);

      formik.setFieldValue('puestos', groupedPositions);
      formik.setFieldValue('branchTemplate', values.plantilla);
    }
  });

  const handleDelete = (index) => {
    const remainingPositions = [...positionsOriginal];
    dispatch(setPositions(remainingPositions));

    const newPuestos = formik.values.puestos.filter((_, i) => i !== index);
    formik.setFieldValue('puestos', newPuestos);
  }

  return (
    <div>
      <PanelBody>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className='col-md-9 d-flex flex-row'>
              <CustomSelectField
                formik={formikSelect}
                field="plantilla"
                list={templates ?? []}
                label="Plantilla"
                disabled={!!currentRecord}
                onChange={() => formikSelect.handleSubmit()}
                sm={5}
              />

            </div>
            <button
              type="button"
              className={`mt-5 btn btn-outline-${error ? 'danger' : 'secondary'}`}
              onClick={() => setShowModal(true)}
            >
              <i className="fas fa-plus"></i> Agregar puesto
            </button>

            {error &&
              <div className="text-danger mt-1" role="alert">
                {error}
              </div>
            }

            <div className="widget-list rounded mt-4 w-100">
              {formik.values.puestos.map((doc, index) => (
                <div className="widget-list-item" key={index}>
                  <div className="widget-list-media icon my-auto">
                    <a href="#/">
                      <i
                        className="fa fa-trash bg-yellow text-dark"
                        onClick={() => handleDelete(index)}
                      ></i>
                    </a>
                  </div>
                  <div className="widget-list-content col-12">
                    <p className="widget-list-title d-flex flex-row">
                      <h4 className='col-5 my-auto'>
                        {positionsOriginal.find((position) => position.id === doc.puesto || position.id === doc.id)?.name}
                      </h4>
                      <h5 className='my-auto col-6'>Matutino: {doc.matutino}, Intermedio: {doc.intermedio}, Vespertino: {doc.vespertino}</h5>
                      <div className="widget-list-media icon col-1">
                        <a href="#/">
                          <i
                            className="fa fa-pen btn-azul text-dark"
                            onClick={() => {
                              setCurrentRecord(doc);
                              setShowModal(true);
                            }}
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PanelBody>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Agregar colaboradores"
        children={
          <SucursalesModalPuestos fomik={formik} closeFn={() => { setShowModal(false); setCurrentRecord(null) }} currentRecord={currentRecord} />
        }
      />
    </div>
  )
}

export default SucursalesFormPuestos