import httpClient from "./httpClient";

const prefix = "/branches";

export async function readBranches() {
  return (await httpClient.get(`${prefix}`)).data;
}

export async function readBranch(id: number) {
  return (await httpClient.get(`${prefix}/${id}`)).data;
}

export async function saveBranch(data: any) {
  return (await httpClient.post(prefix, data)).data;
}
export async function updateBranch(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/${id}`, data)).data;
}

export async function findByName(name: string) {
  return (await httpClient.get(`${prefix}/find-by-name/${name}`)).data;
}

export async function deleteBranch(id: number) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}

export async function readBranchBySocialName(id: number) {
  return (await httpClient.get(`${prefix}/get-by-social-name/${id}`)).data;
}

export async function paginateSucursales(query: string) {
  return (await httpClient.get(`${prefix}/list-paginated?${query}`)).data;
}

export async function updateMessage(id: number, data: any) {
  return (await httpClient.patch(`${prefix}/update-message/${id}`, data)).data;
}