import React from "react";
import DownloadLegacyItem from "./DownloadLegacyItem.tsx";

const DocumentsViewerLegacy = ({ fileData }) => {
  return fileData.length === 1 ? (
    fileData.map((file, index) =>
      //Single file
      file?.file?.extension === "pdf" ? (
        <div
          key={index}
          style={{
            width: `${100 / Math.min(fileData.length, 2)}%`,
            height: "500px",
            backgroundColor: "#ccc",
            borderRadius: "5px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <i className="fa-regular fa-file fs-59px accordion-body me-2" />
          <p className="text-center fs-1 fw-600 my-auto">
            {file.file.name}.{file?.file?.extension}
          </p>
        </div>
      ) : (
        <div
          key={index}
          style={{
            position: "relative",
            width: `${100 / Math.min(fileData.length, 2)}%`,
            height: "500px",
            flex: 1,
            padding: "10px",
            boxSizing: "border-box",
            textAlign: "center",
            backgroundColor: "#f5f5f5",
          }}
        >
          <DownloadLegacyItem url={file.url} />
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
            }}
          >
            <img
              src={file.url}
              alt={file.name}
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "1", // Ajuste el z-index para que el texto esté detrás de la imagen
              marginTop: "100px",
              color: "#555",
            }}
          >
            Archivo
          </div>
        </div>
      )
    )
  ) : fileData.length === 2 ? (
    fileData.map((file, index) =>
      //Single file
      file?.file?.extension === "pdf" ? (
        <div
          key={index}
          style={{
            width: `${100 / Math.min(fileData.length, 2)}%`,
            height: "500px",
            backgroundColor: "#ccc",
            borderRadius: "5px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <i className="fa-regular fa-file fs-59px accordion-body me-2" />
          <p className="text-center fs-1 fw-600 my-auto">
            {file.file.name}.{file?.file?.extension}
          </p>
        </div>
      ) : (
        <div
          key={index}
          style={{
            position: "relative",
            width: `${100 / Math.min(fileData.length, 2)}%`,
            height: "250px",
            flex: 1,
            padding: "10px",
            boxSizing: "border-box",
            textAlign: "center",
            backgroundColor: "#f5f5f5",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
            }}
          >
            <DownloadLegacyItem url={file.url} />
            <img
              src={file.url}
              alt={file.name}
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "1", // Ajuste el z-index para que el texto esté detrás de la imagen
              marginTop: "100px",
              color: "#555",
            }}
          >
            Archivo
          </div>
        </div>
      )
    )
  ) : fileData.length === 3 ? (
    <React.Fragment>
      {fileData[0]?.file?.extension === "pdf" ? (
        <div
          style={{
            width: `60%`,
            height: "300px",
            backgroundColor: "#ccc",
            borderRadius: "5px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <i className="fa-regular fa-file fs-59px accordion-body me-2" />
          <p className="text-center fs-1 fw-600 my-auto">
            {fileData[0].file.name}.{fileData[0]?.file?.extension}
          </p>
        </div>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              width: `60%`,
              height: "300px",
              flex: 1,
              padding: "10px",
              boxSizing: "border-box",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
              }}
            >
              <DownloadLegacyItem url={fileData[0].url} />
              <img
                src={fileData[0].url}
                alt={fileData[0].name}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "1", // Ajuste el z-index para que el texto esté detrás de la imagen
                marginTop: "100px",
                color: "#555",
              }}
            >
              Archivo
            </div>
          </div>
        </>
      )}

      <div style={{ width: "40%" }}>
        {fileData[1]?.file?.extension === "pdf" ? (
          <div
            style={{
              backgroundColor: "#ccc",
              borderRadius: "5px",
            }}
            className="d-flex w-100 aspect-square justify-content-center align-items-center"
          >
            <i className="fa-regular fa-file fs-59px accordion-body me-2" />
            <p className="text-center fs-1 fw-600 my-auto">
              {fileData[1].file.name}.{fileData[1]?.file?.extension}
            </p>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              width: `100%`,
              height: "150px",
              flex: 1,
              padding: "10px",
              boxSizing: "border-box",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
              }}
            >
              <DownloadLegacyItem url={fileData[1].url} />
              <img
                src={fileData[1].url}
                alt={fileData[1].name}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "1", // Ajuste el z-index para que el texto esté detrás de la imagen
                marginTop: "100px",
                color: "#555",
              }}
            >
              Archivo
            </div>
          </div>
        )}
        {fileData[2]?.file?.extension === "pdf" ? (
          <div
            style={{
              width: `100%`,
              height: "150px",
              backgroundColor: "#ccc",
              borderRadius: "5px",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <i className="fa-regular fa-file fs-59px accordion-body me-2" />
            <p className="text-center fs-1 fw-600 my-auto">
              {fileData[2].file.name}.{fileData[2]?.file?.extension}
            </p>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              width: `100%`,
              height: "150px",
              flex: 1,
              padding: "10px",
              boxSizing: "border-box",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
              }}
            >
              <DownloadLegacyItem url={fileData[2].url} />
              <img
                src={fileData[2].url}
                alt={fileData[2].name}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "1", // Ajuste el z-index para que el texto esté detrás de la imagen
                marginTop: "50px",
                color: "#555",
              }}
            >
              Archivo
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  ) : (
    fileData.length > 3 && (
      <React.Fragment>
        {fileData[0]?.file?.extension === "pdf" ? (
          <div
            style={{
              width: `60%`,
              height: "300px",
              backgroundColor: "#ccc",
              borderRadius: "5px",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <i className="fa-regular fa-file fs-59px accordion-body me-2" />
            <p className="text-center fs-1 fw-600 my-auto">
              {fileData[0].file.name}.{fileData[0]?.file?.extension}
            </p>
          </div>
        ) : (
          <>
            <div
              style={{
                position: "relative",
                width: `60%`,
                height: "300px",
                flex: 1,
                padding: "10px",
                boxSizing: "border-box",
                textAlign: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
                }}
              >
                <DownloadLegacyItem url={fileData[0].url} />
                <img
                  src={fileData[0].url}
                  alt={fileData[0].url}
                  className="img-fluid aspect-square"
                  style={{
                    width: `100%`,
                    height: "300px",
                    flex: 1,
                    borderRadius: "5px",
                    objectFit: "cover",
                    backgroundColor: "#ccc",
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div style={{ width: "40%" }}>
          {fileData[1]?.file?.extension === "pdf" ? (
            <div
              style={{
                backgroundColor: "#ccc",
                borderRadius: "5px",
              }}
              className="d-flex w-100 aspect-square justify-content-center align-items-center"
            >
              <i className="fa-regular fa-file fs-59px accordion-body me-2" />
              <p className="text-center fs-1 fw-600 my-auto">
                {fileData[1].file.name}.{fileData[1]?.file?.extension}
              </p>
            </div>
          ) : (
            <>
              <div
                style={{
                  position: "relative",
                  height: "50%",
                  flex: 1,
                  padding: "10px",
                  boxSizing: "border-box",
                  textAlign: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: "2", // Ajuste el z-index para que la imagen esté delante del texto
                  }}
                >
                  <DownloadLegacyItem url={fileData[1].url} />
                  <img
                    src={fileData[1].url}
                    alt={fileData[1].name}
                    className="img-fluid"
                    style={{
                      width: `100%`,
                      height: "150px",
                      flex: 1,
                      borderRadius: "5px",
                      objectFit: "cover",
                      backgroundColor: "#ccc",
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {fileData[2]?.file?.extension === "pdf" ? (
            <div
              style={{
                width: `100%`,
                height: "150px",
                backgroundColor: "#ccc",
                borderRadius: "5px",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <i className="fa-regular fa-file fs-59px accordion-body me-2" />
              <p className="text-center fs-1 fw-600 my-auto">
                {fileData[2].file.name}.{fileData[2]?.file?.extension}
              </p>
            </div>
          ) : (
            <div className="" style={{ position: "relative" }}>
              <DownloadLegacyItem url={fileData[2].url} />
              <img
                src={fileData[2].url}
                alt={fileData[2].name}
                className="img-fluid"
                style={{
                  width: `100%`,
                  height: "150px",
                  flex: 1,
                  borderRadius: "5px",
                  objectFit: "cover",
                  backgroundColor: "#ccc",
                  filter: "brightness(0.5)",
                }}
              />
              <p
                className="text-white fw-600"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                + {fileData.length - 3} archivos
              </p>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  );
};

export default DocumentsViewerLegacy;
