import React from "react";
import { getRejectionByApplication } from "../../services/rejection.service.ts";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import Loader from "../Loader.tsx";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

const ModalBan = ({
  name,
  id,
  closeFn,
}: {
  name: string;
  id: number;
  closeFn: () => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: "",
      comments: "",
      date: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const fetchRejection = async () => {
    setLoading(true);
    try {
      const response = await getRejectionByApplication(id);
      formik.setValues({
        reason: response?.reason || "",
        comments: response?.comments || "",
        date: formatDate(response?.date) || "",
      });
    } catch (error) {
      formik.setValues({
        reason: error?.reason || "",
        comments: error?.comments || "",
        date: formatDate(error?.date) || "",
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRejection();
  }, []);

  const formatDate = (date: string) => {
    const formattedDateTime = format(date, "dd 'de' MMMM yyyy", { locale: es });

    return formattedDateTime;
  };

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="reason"
        label="Razón"
        placeholder="Razón por la que se rechazó la solicitud"
        sm={9}
        disabled
      />

      <CustomInput
        formik={formik}
        field="comments"
        label="Comentarios"
        placeholder="Comentarios"
        sm={9}
        disabled
      />
      <CustomInput
        formik={formik}
        field="date"
        label="Fecha de rechazo"
        placeholder="Fecha en la que se rechazó la solicitud"
        sm={9}
        disabled
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cerrar
        </button>
      </div>
      <Loader isLoading={loading} />
    </React.Fragment>
  );
};

export default ModalBan;
