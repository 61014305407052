import * as yup from "yup";

export const VacancySchema = yup.object().shape({
  idPosition: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idBusinessName: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  idBranch: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  isAvailable: yup.boolean().required("Availability is required"),
});
