import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import CustomSelectField from "../formik/CustomSelectField.tsx";
import * as Yup from "yup";
import { updateApplicationStatus } from "../../services/application.service.ts";
import { RootState } from "../../store/index.ts";
import { setFetch } from "../../store/slices/solicitudesSlice.ts";
import { notification } from "../../utils/Notifications.tsx";

const ModalSetStatus = ({
  name,
  id,
  closeFn,
}: {
  name: string;
  id: number;
  closeFn: () => void;
}) => {
  const dispatch = useDispatch();
  const { fetch } = useSelector((state: RootState) => state.solicitud);
  const formik = useFormik({
    initialValues: {
      status: -99,
    },
    validationSchema: Yup.object().shape({
      status: Yup.number()
        .min(1, "Campo requerido")
        .required("Campo requerido"),
    }),
    onSubmit: async (values) => {
      console.log(values, id);
      await updateApplicationStatus(id, values.status);
      notification("", "Acción realizada con éxito", "info");
      try {
      } catch (error) {
        notification("Error", "Ocurrio un error", "danger");
      } finally {
        closeFn();
        dispatch(setFetch(!fetch));
      }
    },
  });

  const handleClick = async () => {};
  return (
    <React.Fragment>
      <CustomSelectField
        formik={formik}
        field="status"
        label="Estatus"
        placeholder="estatus"
        list={[
          { id: 1, name: "Solicitado" },
          { id: 2, name: "Entrevistado" },
          { id: 3, name: "Pre-Colaborador" },
          { id: 6, name: "Rechazado" },
        ]}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          Cambiar status
        </button>
      </div>
    </React.Fragment>
  );
};

export default ModalSetStatus;
