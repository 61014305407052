import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { IChangePositionModal } from "../../interfaces/others/IChangePositionModal.ts";
import { ChangePostionSchema } from "../../validation/schemas/ChangePositionSchema.ts";
import { ChangePostionInitial } from "../../interfaces/formik/IChangePosition.ts";
import { Branch, Position } from "../../models/Partner.ts";
import { notification } from "../../utils/Notifications.tsx";
import { readPositions } from "../../services/position.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import {
  checkPending,
  savePositionChange,
} from "../../services/changePosition.service.ts";
import { setLoading } from "../../store/slices/colaboradoresSlice.ts";
import { ChangePosition } from "../../models/ChangePosition.ts";
import PendingChange from "./PendingChange.tsx";

const ChangePositionModal: React.FC<IChangePositionModal> = ({
  closeFn,
  partner,
}) => {
  const dispatch = useDispatch();
  const [positions, setPositions] = React.useState<Position[]>([]);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const [pendingChange, setPendingChange] =
    React.useState<ChangePosition | null>(null);

  const loadDependencies = async () => {
    dispatch(setLoading(true));
    try {
      const positionResponde = await readPositions();
      setPositions(positionResponde);

      const branchResponse = await readBranches();
      setBranches(branchResponse);
      checkPendingApproval();
    } catch (error) {
      notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const checkPendingApproval = async () => {
    try {
      const response = await checkPending(partner.id);

      if (response) {
        setPendingChange(response);
      }
    } catch (error) {
      console.log(error);
      notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
    }
  };

  React.useEffect(() => {
    loadDependencies();
  }, []);

  const formik = useFormik({
    validationSchema: ChangePostionSchema,
    initialValues: ChangePostionInitial,
    onSubmit: async (values) => {
      dispatch(setLoading(true));
      try {
        await savePositionChange({
          newPosition: values.idPosition,
          newBranch: values.idBranch,
          reasonForPositionChange: values.reason,
          partner: partner.id,
        });

        notification(
          "",
          "La solicitud de cambio de puesto fue enviada con éxito",
          "success"
        );
      } catch (error) {
        console.log(error);
        notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
      } finally {
        await checkPendingApproval();
        dispatch(setLoading(false));
      }
    },
  });

  if (pendingChange)
    return (
      <PendingChange
        partner={partner}
        pendingChange={pendingChange}
        closeFn={() => closeFn()}
      />
    );

  return (
    <React.Fragment>
      {/* Position */}
      <CustomSelectField
        formik={formik}
        field="idPosition"
        list={positions}
        label="Nueva posición"
        placeholder="posición"
      />
      {/* Branch */}
      <CustomSelectField
        formik={formik}
        field="idBranch"
        list={branches}
        label="Nueva sucursal"
        placeholder="sucursal"
      />
      {/* Reason */}
      <CustomInput
        formik={formik}
        field="reason"
        label="Motivo de cambio de puesto"
        placeholder="Motivo de cambio de puesto"
        sm={9}
        smLabel={3}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          Solicitar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ChangePositionModal;
