import React from "react";
import { useFormik } from "formik";
import { DefaultVacancy } from "../../interfaces/formik/IVacancy.ts";
import { VacancySchema } from "../../validation/schemas/VacancySchema.ts";
import { BusinessNames } from "../../models/BusinessNames.ts";
import { Position } from "../../models/Position.ts";
import { notification } from "../../utils/Notifications.tsx";
import { readBusinessNames } from "../../services/businessName.service.ts";
import { readBranchBySocialName } from "../../services/branch.service.ts";
import { readPositionBySocialName } from "../../services/position.service.ts";
import Branch from "../../models/Branch.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { saveVacancie } from "../../services/vacancy.service.ts";

const NewVacancy = ({ closeFn }: { closeFn: () => void }) => {
  const [businessNameList, setBusinessNameList] = React.useState<
    BusinessNames[]
  >([]);
  const [branchList, setBranchList] = React.useState<Branch[]>([]);
  const [positionList, setPositionList] = React.useState<Position[]>([]);

  const formik = useFormik({
    validationSchema: VacancySchema,
    initialValues: DefaultVacancy,
    onSubmit: async (values) => {
      try {
        await saveVacancie(values);
        notification("Éxito ✅", "Vacante guardada correctamente", "success");
        closeFn();
      } catch (error) {
        notification(
          "Error ⚠️",
          error.message || "Error al guardar la vacante",
          "danger"
        );
      }
    },
  });

  const getDependencies = async () => {
    try {
      const businessNames = await readBusinessNames();
      setBusinessNameList(businessNames);

      // const branches = await readBranches();
      // setBranchList(branches);

      // const positions = await readPositions();
      // setPositionList(positions);
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  const getBranchAndPosition = async (idBusinessName: number) => {
    try {
      const branch = await readBranchBySocialName(idBusinessName);
      setBranchList(branch);

      const position = await readPositionBySocialName(idBusinessName);
      setPositionList(position);
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  React.useEffect(() => {
    if (Number(formik.values.idBusinessName) > 0) {
      console.log("Llamando con el id", formik.values.idBusinessName);
      getBranchAndPosition(formik.values.idBusinessName);
    }
  }, [formik.values.idBusinessName]);

  return (
    <React.Fragment>
      <CustomSelectField
        formik={formik}
        field="idBusinessName"
        list={businessNameList}
        label="razón social"
        onChange={() => {
          formik.setFieldValue("idBranch", -99);
          formik.setFieldValue("idPosition", -99);
        }}
      />
      <CustomSelectField
        formik={formik}
        field="idBranch"
        list={branchList}
        label="sucursal"
      />
      <CustomSelectField
        formik={formik}
        field="idPosition"
        list={positionList}
        label="puesto"
      />
      <CustomCheckboxField formik={formik} field="isAvailable" />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewVacancy;
