import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "../../utils/Notifications.tsx";
import { readPositions } from "../../services/position.service.ts";
import { Position } from "../../models/Position";
import { IShift } from "../../models/Shift.ts";
import { getShifts } from "../../services/shift.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import {
  setQuery,
  setShowModalImportExcel,
} from "../../store/slices/colaboradoresSlice.ts";
import Branch from "../../models/Branch.ts";
import { RootState } from "../../store/index.ts";
import { useNavigate } from "react-router-dom";

const FiltrosColaboradores = ({ branch = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [positions, setPositions] = React.useState<Position[]>([]);
  const [shifts, setShifts] = React.useState<IShift[]>([]);
  const [branchs, setBranchs] = React.useState<Branch[]>([]);
  const initialValues = {
    tab: branch ? "activos" : "Todos",
    search: "",
    sucursal: -1,
    puesto: -1,
    shift: -1,
    salario: 0,
    branch: -1,
  };
  const [filters, setFilters] = React.useState(initialValues);
  const [timer, setTimer] = React.useState<any>(null);
  const [showPills, setShowPills] = React.useState(false);

  const fetchDependencies = async () => {
    try {
      const positionResposne = await readPositions();
      setPositions(positionResposne);

      const shiftsResponse = await getShifts();
      setShifts(shiftsResponse);

      const branchsResponse = await readBranches();
      setBranchs(branchsResponse);
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al tratar de obtener los filtros",
        "danger"
      );
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, search: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filtros)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (
        filters[key] !== -1 &&
        filters[key] !== "" &&
        filters[key] !== 0 &&
        filters[key] !== "Todos"
      ) {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "tab":
            keyName = "Status";
            valueName = filters[key];
            break;
          case "puesto":
            keyName = "Puesto";
            valueName = positions.find((item) => item.id === value)?.name || "";
            break;
          case "shift":
            keyName = "Turno";
            valueName = shifts.find((item) => item.id === value)?.name || "";
            break;
          case "branch":
            keyName = "Sucursal";
            valueName = branchs.find((item) => item.id === value)?.name || "";
            break;
          case "search":
            keyName = "Búsqueda";
            valueName = value;
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter(
        (key) =>
          updatedFilters[key] !== -1 &&
          updatedFilters[key] !== "" &&
          updatedFilters[key] !== "Todos"
      )
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            updatedFilters[key]
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  return (
    <div>
      <div className="input-group mb-2">
        {/* {user.branch} */}
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label className="mt-2">Puesto:</label>
          <select
            className="form-select mt-1"
            id="position"
            name="position"
            onChange={(e) =>
              setFilters({ ...filters, puesto: Number(e.target.value) })
            }
            value={filters.puesto}
          >
            <option value={-1} disabled>
              Todos
            </option>
            {positions.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>

          <label className="mt-2">Turno:</label>
          <select
            className="form-select mt-1"
            id="shift"
            name="shift"
            onChange={(e) =>
              setFilters({ ...filters, shift: Number(e.target.value) })
            }
            value={filters.shift}
          >
            <option value={-1} disabled>
              Todos
            </option>
            {shifts.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          {
            !branch && (
              <React.Fragment>
                <label>Status:</label>
                <select
                  className="form-select mt-1"
                  id="status"
                  name="status"
                  onChange={(e) => setFilters({ ...filters, tab: e.target.value })}
                  value={filters.tab}
                >
                  <option value={"todos"}>Todos</option>
                  <option value={"bajas"}>Bajas</option>
                  <option value={"activos"}>Activos</option>
                </select>
                <label className="mt-2">Sucursal:</label>
                <select
                  className="form-select mt-1"
                  id="shift"
                  name="shift"
                  onChange={(e) =>
                    setFilters({ ...filters, branch: Number(e.target.value) })
                  }
                  value={filters.branch}
                >
                  <option value={-1} disabled>
                    Todos
                  </option>
                  {branchs.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </React.Fragment>
            )
          }


          {/* <label className="mt-2">Salario:</label>
        <input
          type="number"
          className="form-control mt-1"
          id="monthly_salary"
          name="monthlySalary"
          placeholder="Salario"
          onChange={(e) =>
            setFilters({ ...filters, salario: Number(e.target.value) })
          }
          value={filters.salario}
        /> */}

          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    tab: "Todos",
                    search: "",
                    sucursal: -1,
                    puesto: -1,
                    shift: -1,
                    salario: 0,
                    branch: -1,
                  });
                  updateFilters({
                    tab: "Todos",
                    search: "",
                    sucursal: -1,
                    puesto: -1,
                    shift: -1,
                    salario: 0,
                    branch: -1,
                  });
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
                type="button"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                // type="submit"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un nombre..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.search}
            />
          </div>
        </div>
        {
          branch && (
            <React.Fragment>
              <button
                className={`btn ${filters.tab === "bajas" ? "btn-amarillo" : "btn-white"}`}
                onClick={() => {
                  setFilters((prevFilters) => {
                    const updatedFilters = { ...prevFilters, tab: "bajas" };
                    updateFilters(updatedFilters);
                    return updatedFilters;
                  });
                }}
              >
                Inactivos
              </button>
              <button
                className={`btn ${filters.tab === "activos" ? "btn-azul" : "btn-white"}`}
                onClick={() => {
                  setFilters((prevFilters) => {
                    const updatedFilters = { ...prevFilters, tab: "activos" };
                    updateFilters(updatedFilters);
                    return updatedFilters;
                  });
                }}
              >
                Activos
              </button>
            </React.Fragment>
          )
        }
        {Boolean(user?.rol === "Administrador" || user?.rol === "Lider de sucursal") && !branch && (
          <button
            className="btn btn-azul"
            style={{ marginLeft: 10 }}
            // onClick={() => dispatch(setShowModalImportExcel(true))}
            onClick={() => navigate("importar")}
          >
            Agregar
          </button>
        )}
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters)
            .filter((filter: any) => branch ? filter.keyName !== "Status" : true)
            .map((filter: any) => (
              <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
                {filter.keyName}: {filter.valueName}
                <button
                  type="button"
                  className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                  aria-label="Close"
                  onClick={() => removeFilter(filter.key)}
                ></button>
              </div>
            ))}
      </div>
    </div>
  );
};

export default FiltrosColaboradores;
