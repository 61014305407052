import { PartnerExcel } from "../../models/ImportPartner";
import { IDefaultArray, excelDateToJSDate } from "../../utils/index.ts";

export const fillImportPartner = ({
  initialValues,
  branches,
  positions,
  sizes,
  periodicities,
  shifts,
}: {
  initialValues: PartnerExcel;
  branches: IDefaultArray[];
  positions: IDefaultArray[];
  sizes: IDefaultArray[];
  periodicities: IDefaultArray[];
  shifts: IDefaultArray[];
}) => {
  const branch = branches.find(
    (branch) => branch.name === initialValues.branch
  );
  const position = positions.find(
    (position) => position.name === initialValues.position
  );
  const size = sizes.find((size) => size.name === initialValues.size);
  const periodicity = periodicities.find(
    (periodicity) => periodicity.name === initialValues.periodicity
  );
  const shift = shifts.find((shift) => shift.name === initialValues.shift);

  return {
    curp: initialValues.curp,
    name: initialValues.name,
    lastName: initialValues.lastName,
    secondLastName: initialValues.secondLastName,
    birthdate: initialValues.birthdate.toISOString().substring(0, 10),
    phone: initialValues.phone,
    address: initialValues.address,
    branch: branch ? branch.id : -99,
    position: position ? position.id : -99,
    size: size ? size.id : -99,
    shift: shift ? shift.id : -99,
    alergias: initialValues.alergias,
    tipoSangre: initialValues.tipoSangre,
    enfermedad: initialValues.enfermedad,
    tratamiento: initialValues.tratamiento,
    // recruitmentDate: initialValues.recruitmentDate.toISOString().substring(0, 10),
    // monthlySalary: initialValues.monthlySalary,
    // periodicity: periodicity ? periodicity.id : -99,
  };
};
