import { IContract } from "../interfaces/others/IContract";
import httpClient from "./httpClient";

const prefix = "/pdf";

export async function getPostulantePdf(id: number): Promise<Blob> {
  const response= await httpClient.get(`${prefix}/${id}`, { responseType: "blob" })
  return response.data;
}

export async function getContractPdf(id: number): Promise<IContract> {
  const response= await httpClient.get(`${prefix}/contract/${id}`);
  return response.data;
}

export async function getContractIMSS(id: number): Promise<IContract> {
  const response= await httpClient.get(`${prefix}/contractIMSS/${id}`);
  return response.data;
}

export async function getAuxComanderoPdf(id: number): Promise<Blob> {
  const response= await httpClient.get(`${prefix}/auxComandero/${id}`, { responseType: "blob" });
  return response.data;
}

export async function getAuxVentasPdf(id: number): Promise<Blob> {
  const response= await httpClient.get(`${prefix}/auxVentas/${id}`, { responseType: "blob" });
  return response.data;
}