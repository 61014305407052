import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../../components/Layout.tsx";
import PuestosComponent from "./components/PuestosComponent.tsx";
import Title from "../../components/Title.tsx";
import { useDispatch } from "react-redux";
import { resetState } from "../../store/slices/puestosSlice.ts";

const Puestos = () => {
  useAppSettings();
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Layout>
      <Title baseTitle="Puestos" basePath="/puestos" title="Puestos" />
      <PuestosComponent />
    </Layout >
  );
};

export default Puestos;
