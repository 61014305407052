import React from 'react'
import { RootState } from '../../store';
import CustomSelectField from '../formik/CustomSelectField.tsx';
import { useFormik } from 'formik';
import CustomInput from '../formik/CustomInput.tsx';
import { BranchTemplateModalSchema } from '../../validation/schemas/BranchTemplateModalSchema.ts';
import { useSelector } from 'react-redux';
import { notification } from '../../utils/Notifications.tsx';

const PlantillaModalPuestos = ({ fomik, closeFn, currentRecord }) => {
  const { positions } = useSelector((state: RootState) => state.branchTemplates);

  const formikModal = useFormik({
    initialValues: {
      puesto: currentRecord?.puesto ?? -99,
      matutino: currentRecord?.matutino ?? 0,
      intermedio: currentRecord?.intermedio ?? 0,
      vespertino: currentRecord?.vespertino ?? 0,
    },
    validationSchema: BranchTemplateModalSchema,
    onSubmit: async (values) => {
      try {
        if (currentRecord) {
          const newPuestos = fomik.values.puestos.map((puesto) => {
            if (puesto.puesto === currentRecord.puesto) {
              return {
                ...puesto,
                matutino: values.matutino,
                intermedio: values.intermedio,
                vespertino: values.vespertino,
              }
            }
            return puesto;
          });
          fomik.setFieldValue('puestos', newPuestos);
          return
        } else {
          fomik.setFieldValue('puestos', [...fomik.values.puestos, values]);
        }
      } catch (error) {
        console.log('error', error);
        notification('Error', 'Sucedió un error al seleccionar el puesto', 'danger');
      } finally {
        closeFn();
      }
    }
  });


  return (
    <div>
      <CustomSelectField
        formik={formikModal}
        field="puesto"
        list={!currentRecord ? positions.filter((position) => !fomik.values.puestos.some((puesto) => puesto.puesto === position.id)) : positions}
        label="Puesto"
        disabled={!!currentRecord}
      />
      <div style={{ width: '100%', paddingInline: 20 }}>
        <div style={{ width: '100%', height: '1px', background: '#E3E3E3', marginBottom: 20 }} />
      </div>
      <p className='text-center fs-5 fw-600 mb-4'>Número de colaboradores por turno</p>
      <CustomInput
        formik={formikModal}
        field="matutino"
        label="Matutino"
        placeholder="No. colaboradores matutino"
        smLabel={3}
        sm={9}
        type='number'
      />
      <CustomInput
        formik={formikModal}
        field="intermedio"
        label="Intermedio"
        placeholder="No. colaboradores intermedio"
        smLabel={3}
        sm={9}
        type='number'
      />
      <CustomInput
        formik={formikModal}
        field="vespertino"
        label="Vespertino"
        placeholder="No. colaboradores vespertino"
        smLabel={3}
        sm={9}
        type='number'
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formikModal.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </div>
  )
}

export default PlantillaModalPuestos