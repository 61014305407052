import React, { useEffect } from 'react'
import WorkerRow from './componentes/WorkerRow.tsx';
import GridLayout from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { Tooltip, Typography } from '@mui/material';
import { readPartnersByBranch } from '../../../services/partner.service.ts';
import { getBackgroundColor, gradientBackground, gradientBackgroundFullScreen, gradientBackgroundIntermedio, gradientBackgroundMatutino, gradientBackgroundVespertino, isOverlapping, shifts, weekDays } from '../../../utils/constanst.js';

function FullScreenSchedule({ existingsSchedules, formik, positionsArray, setPositionsArray, selectedDay = 0, turno = 0 }) {
  const [layout, setLayout] = React.useState<any[]>([]);
  const [lastItem, setLastItem] = React.useState<any>(null);
  const [update, setUpdate] = React.useState(false);

  const [sortType, setSortType] = React.useState('Asc');
  const [sortBy, setSortBy] = React.useState('name');

  const handleSortPositions = (type) => {
    if (type === 'name') {
      setSortBy('name');
      if (sortType === 'Asc') {
        setPositionsArray(positionsArray.sort((a, b) => a.positionName.localeCompare(b.positionName)));
        setSortType('Desc');
      } else {
        setPositionsArray(positionsArray.sort((a, b) => b.positionName.localeCompare(a.positionName)));
        setSortType('Asc');
      }
    } else {
      setSortBy('shift');
      if (sortType === 'Asc') {
        const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType('Desc');
      } else {
        const shiftOrder = ['Vespertino', 'Intermedio', 'Matutino'];
        const sorted = positionsArray.sort((a, b) => {
          return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
        });

        setPositionsArray(sorted);
        setSortType('Asc');
      }
    }

    const newSortedLayout = layout.map((item) => {
      return {
        ...item,
        y: positionsArray.findIndex((position) => (position.positionName === item.position) && (position.shift === item.shift))
      }
    });
    setLayout(newSortedLayout);

    setUpdate(!update);
  }

  React.useEffect(() => {
    if (existingsSchedules?.length > 0) {
      const newLayout = existingsSchedules.map((schedule) => {
        const position = positionsArray.find((position) => position.positionName === schedule.positionName);
        return {
          i: uuid(),
          minH: 1,
          maxH: 1,
          minW: 1,
          show: true,
          pending: false,
          name: schedule.partner.person.firstName,
          position: schedule.positionName,
          originalId: schedule.partner.id,
          shift: schedule.shift,
          x: Number(schedule.positionX),
          y: positionsArray.findIndex((position) => (position.positionName === schedule.positionName) && (position.shift === schedule.shift)),
          w: Number(schedule.positionW),
          h: 1,
          positionId: position?.positionId,
        };
      });

      setLayout(newLayout);
    }
  }, [existingsSchedules]);

  useEffect(() => {
    formik.setFieldValue('schedule', layout);
    const hasPending = layout.findIndex((item) => item?.pending);

    if (lastItem && hasPending !== -1) {
      updatePending(lastItem, hasPending);
      setLastItem(null);
    }
  }, [lastItem, layout]);

  useEffect(() => {
    setLayout((currentLayout) => {
      const newLayout = currentLayout.map((record) => ({
        ...record,
        i: uuid(),
      }));
      return newLayout;
    });
  }, [update]);

  const updatePending = (oldRecord: any, neRecord: number) => {
    const newLayout = [...layout];
    newLayout[neRecord] = {
      ...newLayout[neRecord],
      x: oldRecord.x,
      y: oldRecord.y,
      w: 17,
      h: 1,
      pending: false,
      show: true,
    };

    const itemsFounds = newLayout.filter(
      (item) => item.originalId === layout[neRecord].originalId
    );

    setLayout((currentLayout) => {
      const newLayout = [...currentLayout];
      newLayout[neRecord] = {
        ...newLayout[neRecord],
        x: oldRecord.x,
        y: oldRecord.y,
        w: itemsFounds.length > 2 ? 2 : 15,
        h: 1,
        pending: false,
        show: true,
      };
      if (oldRecord.x === 0 || oldRecord.x === 1) {
        newLayout.splice(neRecord, 1);
      }
      const maxY = Number(positionsArray?.length);
      if (oldRecord.y >= maxY) {
        newLayout.splice(neRecord, 1);
      }
      return newLayout;
    });
  };

  const screenWith = window.innerWidth - 40;

  return (
    <div
      className="row d-flex justify-content-between"
      style={{
        flex: 1,
        flexDirection: "row",
        borderRadius: "0.25rem",
        overflow: "hidden",
        border: "1px solid #AFAFAF55",
      }}
    >
      <div
        className="col-12"
        style={{
          paddingBlock: "10px",
          boxShadow: "4px 6px 6px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <>
          <div className='position-absolute d-flex flex-row px-2' style={{ zIndex: 3 }}>
            {
              turno !== 3 &&
              <a
                href="#/"
                onClick={() => handleSortPositions('name')}
                className="fw-600 me-3">Nombre <i className={`fa-solid ${sortBy === 'name' && (sortType === 'Asc' ? 'fa-sort-up' : 'fa-sort-down')} ms-1`}></i></a>
            }
            {
              turno === 3 &&
              <a
                href="#/"
                onClick={() => handleSortPositions('shift')}
                className="fw-600">Turno <i className={`fa-solid ${sortBy === 'shift' && (sortType === 'Asc' ? 'fa-sort-up' : 'fa-sort-down')} ms-1`}></i></a>
            }
          </div>
          <GridLayout
            className="layout"
            layout={Array.from({ length: 34 }, (_, i) => ({
              i: i.toString(),
              x: i,
              y: 0,
              w: 1,
              h: 1,
              static: true,
            }))}
            cols={34}
            rowHeight={50}
            width={screenWith}
            margin={[0, 0]}
            compactType={null}
          >
            {Array.from({ length: 34 }, (_, index) => {
              const hour = 6 + Math.floor(index / 2);
              const minute = index % 2 === 0 ? "00" : "30";
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    borderRight: (index !== 0) ? "2px solid #EEE" : "none",
                    borderBottom: '2px solid #EEE',
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 800,
                    fontSize: "10px",
                  }}
                >
                  {index > 1 && `${hour}:${minute}`} <br />
                  {index > 1 && `-`} <br />
                  {index > 1 && `${minute === "00" ? hour : hour + 1}:${minute === "00" ? "30" : "00"}`}
                </div>
              );
            })}
          </GridLayout>

          <GridLayout
            className="layout"
            layout={layout}
            cols={34}
            maxRows={positionsArray?.length}
            rowHeight={50}
            width={screenWith}
            preventCollision={false}
            allowOverlap={true}
            isResizable={false}
            isDroppable={false}
            isDraggable={false}
            compactType={null}
            margin={[0, 0]}
            style={{
              background: gradientBackgroundFullScreen,
              width: `${screenWith}px`
            }}
          >
            {positionsArray?.map((key, index) => {
              return (
                <div
                  key={index}
                  data-grid={{ x: 0, y: index, w: key?.title ? 36 : 2, h: 1, static: true }}
                  style={{
                    fontSize: "9px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    borderRight: "2px solid #EEE",
                    borderBottom: `${key?.title ? '3px' : '2px'} solid #EEE`,
                    borderTop: `${key?.title ? '3px solid' : ''} ${key?.title === 'Matutino' ? '#FFD700' : key.title === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                    paddingInline: "5px",
                    fontWeight: 600,
                    borderLeft: `3px solid ${key.shift === 'Matutino' ? '#FFD700' : key.shift === 'Vespertino' ? '#FF6347' : '#4682B4'}`,
                    backgroundColor: 'white'
                  }}
                >
                  {key.title && <p className="w-100 mt-3 fs-15px fw-800 bg">{key?.title ?? key.positionName}</p>}
                  <p className="w-100 mt-auto">{key.positionName}</p>
                </div>
              );
            })}
            {layout.map((item, index) => {
              if (!item?.x) return <></>;
              return (
                <div
                  key={item?.i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  data-grid={{ x: item?.x, y: item?.y, w: item?.w, h: 1, }}
                >
                  <div
                    style={{
                      paddingLeft: "8px",
                      width: "100%",
                      borderRadius: "5px",
                      paddingBlock: "4px",
                      ...getBackgroundColor(item, index, positionsArray),
                    }}
                    className="shadow-sm"
                  >
                    <Tooltip
                      title={`${item.name} | ${item.position} | ${item.w / 2} hrs`}
                      arrow
                    >
                      <div className="d-flex flex-row">
                        <Typography fontWeight={500} marginLeft={1.5}>
                          {item?.name?.split(" ")[0]}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
          </GridLayout>
        </>
      </div>
    </div>
  )
}

export default FullScreenSchedule