import React, { useEffect } from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import ModalContainer from "../ModalContainer.tsx";
import AddResponsability from "./AddResponsabilitie.tsx";
import { readPositions } from "../../services/position.service.ts";

const ResponsabilitiesPosition = ({
  formik,
  originalField,
}: IFormikProps & {
  originalField: string;
}) => {
  const [positions, setPositions] = React.useState<[]>([]);
  const [selectedPositions, setSelectedPositions] = React.useState<Set<number>>(new Set());

  useEffect(() => {
    const loadResponsabilities = async () => {
      try {
        const response = await readPositions();
        setPositions(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    loadResponsabilities();
  }, []);

  const error = formik.touched[originalField] && formik.errors[originalField];
  const [showModal, setShowModal] = React.useState(false);

  const handleCardClick = (positionId: number, functions: any[]) => {
    const updatedSelectedPositions = new Set(selectedPositions);

    // Alternar la selección de la tarjeta
    if (updatedSelectedPositions.has(positionId)) {
      updatedSelectedPositions.delete(positionId);
    } else {
      updatedSelectedPositions.add(positionId);
    }
    setSelectedPositions(updatedSelectedPositions);

    // Obtener las funciones actuales del campo de Formik
    const currentFunctions = formik.values[originalField] || [];

    // Obtener las nuevas funciones a agregar o eliminar
    const newFunctions = Array.from(updatedSelectedPositions).flatMap(id =>
      positions.find(position => position.id === id)?.funtions || []
    );

    // Funciones que deben ser eliminadas
    const functionsToRemove = Array.from(selectedPositions)
      .filter(id => !updatedSelectedPositions.has(id))
      .flatMap(id => positions.find(position => position.id === id)?.funtions || []);

    // Eliminar funciones de las posiciones deseleccionadas
    const functionsAfterRemoval = currentFunctions.filter(fn => !functionsToRemove.includes(fn));

    // Crear un nuevo conjunto con funciones únicas
    const updatedFunctions = Array.from(new Set([...functionsAfterRemoval, ...newFunctions]));

    // Actualizar el campo de Formik
    formik.setFieldValue(originalField, updatedFunctions);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />
        <div className="col-md-10">
          <div className="d-flex flex-row overflow-auto pb-2 mb-2">
            {positions.length > 0 && positions
              .filter((position: any) => position.funtions.length > 0)
              .map((position: any) => (
                <div
                  key={position.id}
                  className={`card mb-3 me-3 ${selectedPositions.has(position.id) ? 'border-2 border-primary shadow' : ''}`}
                  style={{ minWidth: '300px', maxWidth: '300px', cursor: 'pointer' }}
                  onClick={() => handleCardClick(position.id, position.funtions)}
                >
                  <div className="card-body">
                    <h5 className="card-title">{position.name}</h5>
                    <ul className="list-group list-group-flush">
                      {position.funtions.slice(0, 3).map((res: any, index: number) => (
                        <li key={index} className="list-group-item">
                          <span className="clamp-text">{res}</span>
                        </li>
                      ))}
                      {position.funtions.length > 3 && (
                        <li className="list-group-item">+{position.funtions.length - 3} más</li>
                      )}
                    </ul>
                  </div>

                </div>
              ))}
          </div>

          <button
            type="button"
            className={`btn btn-outline-${error ? "danger" : "secondary"}`}
            onClick={() => setShowModal(true)}
          >
            <i className="fas fa-plus"></i> Agregar responsabilidad
          </button>
          <button
            type="button"
            className={`btn btn-outline-danger ms-2`}
            onClick={() => {
              formik.setFieldValue(originalField, []);
              setSelectedPositions(new Set());
            }}
          >
            <i className="fas fa-trash"></i> Borrar todas las responsabilidades
          </button>

          <div
            className="invalid-feedback"
            style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
          >
            {formik.touched[originalField] && formik.errors[originalField] && (
              <>{formik.errors[originalField]}</>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
        <div className="col-md-1" />

        <div className="widget-list rounded">
          {formik.values[originalField]?.map((doc, index) => (
            <div className="widget-list-item" key={index}>
              <div className="widget-list-media icon">
                <a style={{ cursor: 'pointer' }}>
                  <i
                    className="fa fa-trash bg-yellow text-dark"
                    onClick={() => {
                      const updatedValues = formik.values[originalField].filter((item: any) => item !== doc);
                      formik.setFieldValue(originalField, updatedValues);
                    }}
                  ></i>
                </a>
              </div>
              <div className="widget-list-content">
                <h4 className="widget-list-title">
                  {doc}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title="Agregar responsabilidad"
        children={
          <AddResponsability
            formik={formik}
            closeFn={() => setShowModal(false)}
            originalField={originalField}
          />
        }
        size="lg"
      />
    </React.Fragment>
  );
};

export default ResponsabilitiesPosition;
