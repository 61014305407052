import React from 'react'
import { useAppSettings } from '../../../hooks/useAppSettings.tsx'
import Layout from '../../Layout.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { readBranch } from '../../../services/branch.service.ts'
import Title from '../../Title.tsx'
import { Panel, PanelHeader } from '../../panel/panel.jsx'
import HorarioSucuralForm from './HorarioSucuralForm.tsx'
import { useFormik } from 'formik'
import Button from '../../Button.tsx'
import { generateTimeIntervals, getDateByDayOfWeek, shifts, weekDays, weeks } from '../../../utils/constanst.js'
import { ScheduleSchema } from '../../../validation/schemas/ScheduleSchema.ts'
import { readHoursByBranch, saveSchedule } from '../../../services/branchSchedule.service.ts'
import { notification } from '../../../utils/Notifications.tsx'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/index.ts'

type Props = {}

const SucursalesHorio = (props: Props) => {
  useAppSettings()
  const navigate = useNavigate()
  const [firstRender, setFirstRender] = React.useState(true)
  const [loading, setLoading] = React.useState(true);
  const [branch, setBranch] = React.useState<any>(null);
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  React.useEffect(() => {
    const loadBranch = async () => {
      if (id) {
        const branch = await readBranch(Number(id));
        setBranch(branch);
      }
      setLoading(false);
    };

    loadBranch();
  }, []);

  const formik = useFormik({
    initialValues: {
      branchId: Number(id),
      week: -99,
      shift: -99,
      dayOfWeek: -99,
      schedule: [
        { position: '', x: 0, w: 0, originalId: 0, positionId: -99, shift: '', show: false },
      ],
    },
    validationSchema: ScheduleSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const branchHours = await readHoursByBranch(values.branchId);
      
      const hoursArray = await generateTimeIntervals(branchHours?.matutinoStart, branchHours?.vespertinoEnd);
      hoursArray.unshift({ key: -99, display: 'Hora' }, { key: -99, display: 'Hora' });
      
      try {
        const object = {
          branchId: values.branchId,
          week: weeks[values.week]?.value,
          shift: shifts[values.shift]?.name,
          dayOfWeek: weekDays[values.dayOfWeek].name,
          schedule: values.schedule.filter(record => record.show).
          map(record => ({
            dateRange: weeks[values.week]?.value,
            dayOfWeek: weekDays[values.dayOfWeek].name,
            positionName: record.position,
            positionX: record.x,
            positionW: record.w,
            startTime: hoursArray[record.x].display,
            endTime: hoursArray[record.x + record.w - 1].display,
            exactDate: getDateByDayOfWeek(weeks[values.week]?.value, weekDays[values.dayOfWeek].name),
            branch: values.branchId,
            partner: record.originalId,
            position: record.positionId,
            shift: record.shift,
          })),
        };
        
        await saveSchedule({
          branchId: values.branchId,
          schedule: object.schedule,
          exactDate: object.schedule[0].exactDate,
          shift: values.shift,
        });

        notification('Éxito', 'Horario guardado correctamente', 'success');
      } catch (error) {
        console.log('error', error);
        notification('error', 'Error al guardar el horario', 'danger');
      }
    }
  });

  return (
    <Layout loading={loading}>
      <Title
        baseTitle={`Horario de ${branch?.name}`}
        basePath="/sucursales"
        activeTitle='Horario'
        title={`Horario de ${branch?.name}`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Horario de la sucursal
          </PanelHeader>
          <HorarioSucuralForm formik={formik} />
          {
            !firstRender &&
            formik.errors.week ||
              formik.errors.shift ||
              formik.errors.dayOfWeek ||
              formik.errors.schedule
              ? (
                <div className="alert alert-danger w-50 mx-auto">
                  {formik.errors.week || formik.errors.shift || formik.errors.dayOfWeek || formik.errors.schedule}
                </div>
              )
              : null
          }
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => {
                formik.handleSubmit()
                setFirstRender(false)
              }}
              title="Guardar"
              variant="azul"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SucursalesHorio