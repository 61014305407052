import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import { notification } from "../../utils/Notifications.tsx";
import Loader from "../Loader.tsx";
import Branch from "../../models/Branch.ts";
import { listAllBranch } from "../../services/bran.service.ts";

const SelectBranch = ({ formik, field }: IFormikProps & { field: string }) => {
  const [branchs, setBranchs] = React.useState<Branch[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  const getBranches = async () => {
    try {
      setLoading(true);
      const response = await listAllBranch();
      setBranchs(response);
    } catch (error) {
      console.error(error);
      notification("Ocurrio un error", "Error al obtener las sucursales", "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getBranches();
  }, []);

  return (
    <React.Fragment>
      <div className="row mb-15px fs-13px">
        <label
          className="form-label col-form-label col-md-3 text-end"
          style={{ color: error ? "red" : "inherit" }}
        >
          Selecciona una sucursal&nbsp;
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        </label>
        <div className="col-md-3">
          <select
            className={`form-select form-control fs-15px ${
              error ? "is-invalid" : ""
            }`}
            id="floatingSelect"
            name="warranty_type_id"
            onChange={(e) => formik.setFieldValue(field, e.target.value)}
            style={{ color: error ? "red" : "inherit" }}
            value={formik.values[field] !== -99 ? formik.values[field] : "_"}
          >
            <option value="_" disabled>
              -- Selecciona una sucursal --
            </option>
            {branchs.map((branch: Branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.name}
              </option>
            ))}
          </select>
          
      {formik.touched[field] && formik.errors[field] && (
        <div
          className="invalid-feedback"
          style={{ marginTop: "1px", display: "flex", marginLeft: "0.25em" }}
        >
          {formik.touched[field] && formik.errors[field] ? (
            <>{formik.errors[field]}</>
          ) : (
            <p> </p>
          )}
        </div>
      )}
        </div>
      </div>
      {loading && <Loader />}
    </React.Fragment>
  );
};

export default SelectBranch;
