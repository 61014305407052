import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import moment from "moment";
import { notification } from "../../utils/Notifications.tsx";
import { readApplicationStatuses } from "../../services/applicationStatuses.service.ts";
import { readPositions } from "../../services/position.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import {
  getApplications,
  getApplicationsFilter,
} from "../../services/application.service.ts";
import { getCurrencyFormat } from "../../utils/index.ts";
import "moment/locale/es";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
moment.locale("es");

function Candidatos() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const contextNav = useAppSettings();
  const [filters, setFilters] = useState(new Map());
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [total, setTotal] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [positions, setPositions] = useState([]);
  const [shifts, setShifts] = useState([]);

  const [applications, setApplications] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [oldData, setOldData] = useState();

  useEffect(() => {
    fetchDependencies();
  }, []);

  const fetchDependencies = async () => {
    try {
      const status = await readApplicationStatuses();
      setStatuses(status);

      const position = await readPositions();
      setPositions(position);

      const shift = await getShifts();
      setShifts(shift);
    } catch (error) {
      notification("Algo salio mal!", error, "danger");
    }
  };

  const fetchData = async () => {
    try {
      const data = await getApplications();
      setOldData(data.results);
      setApplications(data.results);
      setTotal(data.total);
    } catch (error) {
      notification("Algo salio mal!", error, "danger");
    }
  };

  const handleNameChange = async (e) => {
    e.preventDefault();
    console.log("name", e.target.value);

    const data = await getApplicationsFilter(`search=${e.target.value}`);
    setApplications(e.target.value ? data.results : oldData);
  };

  const handlesudmit = async (e) => {
    e.preventDefault();
    let form = e.target;
    let formData = new FormData(form);
    let formDataObj = Object.fromEntries(formData.entries());

    try {
      const filteredData = Object.keys(formDataObj).reduce((acc, key) => {
        if (formDataObj[key]) {
          acc[key] = formDataObj[key];
        }
        return acc;
      }, {});

      const params = new URLSearchParams(filteredData).toString();

      const data = await getApplicationsFilter(params);
      setApplications(data.results);
      setTotal(data.total);
    } catch (error) {
      notification("Algo salio mal!", error, "danger");
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  function handleClick(application) {
    if (
      application.applicationStatus.name == "Solicitado" ||
      application.applicationStatus.name == "Entrevistado"
    ) {
      navigate("/result/form", { state: application });
    } else {
      navigate("/postulante/form", { state: application });
    }
  }

  return (
    <>
      <div className="card border-0 m-4">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
          <li className="nav-item me-2">
            <a href="#allTab" className="nav-link px-2 active">
              Todos
            </a>
          </li>
        </ul>
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-inline">Filtrar</span>
                <span className="d-inline d-md-none">
                  <i className="fa fa-filter"></i>
                </span>{" "}
                <b className="caret"></b>
              </button>
              <form
                id="filtersForm"
                onSubmit={handlesudmit}
                className="fs-13px"
              >
                <div className="dropdown-menu p-4">
                  <label for="status">Status:</label>
                  <select className="form-select" id="status" name="status">
                    <option value="">Todos</option>
                    {statuses.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <label for="position">Puesto:</label>
                  <select className="form-select" id="position" name="position">
                    <option value="">Todos</option>
                    {positions.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <label for="shift">Turno:</label>
                  <select className="form-select" id="shift" name="shift">
                    <option value="">Todos</option>
                    {shifts.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <label for="monthly_salary">Expectativa Salarial:</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    id="monthly_salary"
                    name="monthlySalary"
                    placeholder="Expectativa"
                  />

                  <div className="row">
                    <div className="col">
                      <button
                        onClick={(e) => e.preventDefault(fetchData())}
                        className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
                      >
                        Limpiar{" "}
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="submit"
                        className="btn btn-azul d-block w-100 btn-lg fs-14px"
                      >
                        Aplicar
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                    style={{ zIndex: 10 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    placeholder="Ingresa un nombre..."
                    id="name"
                    name="name"
                    onChange={handleNameChange}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Cita</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Puesto</th>
                    <th>Turno</th>
                    <th>Expectativa</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {applications.map((item) => {
                    let colorStatus;
                    if (item.applicationStatus.name === "Solicitado") {
                      colorStatus =
                        "border-azul text-azul badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                    }
                    if (item.applicationStatus.name === "Entrevistado") {
                      colorStatus =
                        "border-amarillo text-amarillo badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                    }
                    if (item.applicationStatus.name === "Aceptado") {
                      colorStatus =
                        "border-verde text-verde badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                    }
                    if (item.applicationStatus.name === "Pre-Colaborador") {
                      colorStatus =
                        "border-naranja text-naranja badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                    }
                    if (item.applicationStatus.name === "Rechazado") {
                      colorStatus =
                        "border-rojo text-rojo badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                    }
                    const fecha = `${item.applicationDate} ${item.applicationTime}`;

                    const fechaFormateada = moment(fecha).format(
                      "dddd, D [de] MMMM [de] YYYY, HH:mm:ss"
                    );

                    return (
                      <tr key={item.id}>
                        <td>
                          <span className={colorStatus}>
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            {toCamelCase(item.applicationStatus.name)}
                          </span>
                        </td>
                        <td>{fechaFormateada}</td>
                        <td>{toCamelCase(item.person.firstName)}</td>
                        <td>
                          {toCamelCase(item.person.lastName +
                            " " +
                            item.person.secondLastName)}
                        </td>
                        <td>{item.vacancy.position.name}</td>
                        <td>{item.shift.name}</td>
                        <td>{getCurrencyFormat(item.monthlySalary)}</td>
                        {item.applicationStatus.name != "Colaborador" &&
                        item.applicationStatus.name != "Rechazado" ? (
                          <td>
                            <button
                              className="btn btn-azul btn-sm"
                              onClick={() => handleClick(item)}
                            >
                              Ver detalle
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                {total} solicitudes
              </div>
              <ul className="pagination mb-0 justify-content-center">
                {(() => {
                  let li = [];

                  const firsPage = "page-item " + (page == 1 ? "disabled" : "");
                  li.push(
                    <li key={-1} className={firsPage}>
                      <button
                        // onClick={() => handlePageChange(-1)}
                        className="page-link"
                      >
                        Anterior
                      </button>
                    </li>
                  );

                  let first = page - 5;
                  let addToLast = 0;
                  while (first < 1) {
                    first++;
                    addToLast++;
                  }

                  let last = page + 5 + addToLast;
                  let addToFirst = 0;
                  while (last > paginationData.pages) {
                    last--;
                    addToFirst++;
                  }

                  while (addToFirst >= 1 && first > 1) {
                    first--;
                    addToFirst--;
                  }

                  for (let i = first; i <= last; i++) {
                    const className =
                      "page-item " + (page == i ? "active" : "");
                    li.push(
                      <li key={i} className={className}>
                        <button
                          // onClick={() => handlePageChange(i)}
                          className="page-link"
                        >
                          {i}
                        </button>
                      </li>
                    );
                  }

                  const lastPage =
                    "page-item " +
                    (page == paginationData.pages ? "disabled" : "");
                  li.push(
                    <li key={0} className={lastPage}>
                      <button
                        // onClick={() => handlePageChange(0)}
                        className="page-link"
                      >
                        Siguiente
                      </button>
                    </li>
                  );

                  return li;
                })()}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-75px"></div>
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          zIndex: 1,
        }}
      />
    </>
  );
}

export default Candidatos;
