import React from 'react';
import { AppSettings } from '../config/app-settings';

export function useAppSettings() {
  const context = React.useContext(AppSettings);

  React.useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-0");
    context.handleSetAppTopMenu(true);

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
      context.handleSetAppTopMenu(false);
    };

    // eslint-disable-next-line
  }, []);

  return context;
}