import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { reactivePartner } from "../../services/partner.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setFetch } from "../../store/slices/colaboradoresSlice.ts";

const ReactivatePartner = ({
  name,
  id,
  closeFn,
}: {
  name: string;
  id: number;
  closeFn: () => void;
}) => {
  const { fetch } = useSelector((state: RootState) => state.colaboradores); 
  const dispatch = useDispatch();

  const reactivate = async () => {
    try {
      const response = await reactivePartner(id);
      dispatch(setFetch(!fetch));
      notification("", "Colaborador reactivado con exito", "success");
    } catch (error) {
      notification("Error", "Ocurrio un error", "danger");
    } finally {
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">¿Estás seguro de que reactivar a {name}?</p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => reactivate()}>
          Reactivar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ReactivatePartner;
