import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setQuery } from '../../../store/slices/sucursalesSlice.ts';

const FilterSucursales = ({ rol }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState({
    type: "all",
    param: ""
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = () => {
    const queryString = Object.keys(filters)
      .filter((key) => filters[key] !== -1 && filters[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  if (rol === 'Lider de sucursal') {
    return (
      <div className="d-flex w-100">
        <button
          onClick={() => navigate(`asistencias`)}
          className="btn btn-azul ms-auto mb-3" type="button">
          Lista de asistencia
        </button>
      </div>
    )
  }

  return (
    <div className="input-group mb-3">
      <button
        className="btn btn-white dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
      >
        <span className="d-none d-md-inline">Filtrar</span>
        <span className="d-inline d-md-none">
          <i className="fa fa-filter"></i>
        </span>
        <b className="caret"></b>
      </button>
      <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
        <label>Estatuss:</label>
        <select
          className="form-select mt-1"
          id="status"
          name="status"
          onChange={(e) => setFilters({ ...filters, type: e.target.value })}
          value={filters.type}
        >
          <option value={"all"}>Todos</option>
          <option value={"true"}>Activa</option>
          <option value={"false"}>Desactiva</option>
        </select>
        <div className="row mt-3 mt-1">
          <div className="col">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setFilters({
                  type: "all",
                  param: "",
                });
                dispatch(setQuery(""));
              }}
              className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
            >
              Limpiar
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                updateFilters();
              }}
              className="btn btn-azul d-block w-100 btn-lg fs-14px"
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>

      <div className="flex-fill position-relative">
        <div className="input-group">
          <div
            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
            style={{ zIndex: 10 }}>
            {timer ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <i className="fa fa-search opacity-5"></i>
            )}
          </div>
          <input
            type="text"
            className="form-control px-35px bg-light"
            placeholder="Search ..."
            onChange={(e) => handleSearchChange(e)}
            value={filters.param}
          />
          <button
            onClick={() => navigate(`birthdates`)}
            className="btn btn-secondary" type="button">
            Cumpleaños
          </button>
          <button
            onClick={() => navigate(`asistencias`)}
            className="btn btn-azul" type="button">
            Lista de asistencia
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterSucursales