import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ChangePosition } from "../../models/ChangePosition.ts";
import ChipStatus from "../history/ChipStatus.tsx";
import { formatDate } from "../../utils/index.ts";
import {
  setCurrentUser,
  setDataToRemove,
  setFetch,
  setModalChildren,
  setModalCredentials,
  setModalDelete,
  setModalTitle,
  setPage,
  setShow,
} from "../../store/slices/colaboradoresSlice.ts";
import { setShowModal as setShowModalSlice } from "../../store/slices/colaboradoresSlice.ts";
import ChangePositionModal from "../colaboradores/ChangePositionModal.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const ChangePositionRow = ({
  changePosition,
}: {
  changePosition: ChangePosition;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("colaboradores");
    dispatch(setShowModalSlice(true));
    dispatch(
      setModalChildren(
        <ChangePositionModal
          closeFn={() => dispatch(setShowModalSlice(false))}
          partner={changePosition.partner as any}
        />
      )
    );
    dispatch(
      setModalTitle(
        `Cambiar de puesto a ${toCamelCase(
          `${changePosition.partner.person.firstName} ${changePosition.partner.person.lastName} ${changePosition.partner.person.secondLastName}`
        )}`
      )
    );
  };
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={
            changePosition.requesterUser.avatar ||
            "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
          }
          alt=""
          className="mw-100 rounded-pill"
          style={{ width: "60px", height: "60px" }}
        />

        <div className="flex-1 ps-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h5>
              {
                toCamelCase(`
                  ${changePosition.partner.person.firstName}${" "}
                  ${changePosition.partner.person.lastName}${" "}
                  ${changePosition.partner.person.secondLastName}
                `)
              }
            </h5>{" "}
            <div>
              <ChipStatus
                status="Cambio de puesto"
                position={changePosition.newPosition.name}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>
              {`Solicitado: ${formatDate(changePosition.requestDate)}`} por{" "}
              {
                toCamelCase(`
                  ${changePosition.requesterUser.name}${" "}
                  ${changePosition.requesterUser.lastName}${" "}
                  ${changePosition.requesterUser.secondLastName}
                `)
              }
            </p>
            <div>
              <button
                className="btn btn-primary "
                onClick={() => handleClick()}
              >
                Revisar solicitud
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-gray-500" />
    </React.Fragment>
  );
};

export default ChangePositionRow;
