import React from 'react'
import moment from 'moment'
import CustomInput from '../formik/CustomInput.tsx'
import { useFormik } from 'formik'
const DetailsModal = ({ record, closeFn }) => {
  const person = record.partner.candidate.person

  console.log('record: ', record)

  const formik = useFormik({
    initialValues: {
      reasonForPositionChange: record.reasonForPositionChange,
      reasonForRejection: record.reasonForRejection,
      comments: record.comments,
      requestDate: record.requestDate,
      requesterUser: `${record.requesterUser.name} ${record.requesterUser.lastName} ${record.requesterUser.secondLastName}`,
    },
    onSubmit: () => { },
  })

  return (
    <div className='d-flex flex-column'>
      <div className="d-flex flex-row">
        <CustomInput
          formik={formik}
          field='reasonForPositionChange'
          label='Razón de cambio'
          sm={9}
          disabled
        />
      </div>

      {
        record?.reasonForRejection && (
          <div className="d-flex flex-row"><CustomInput
            formik={formik}
            field='reasonForRejection'
            label='Razón de rechazo'
            sm={9}
            disabled
          />
          </div>
        )
      }

      {
        record?.comments && (
          <div className="d-flex flex-row">
            <CustomInput
              formik={formik}
              field='comments'
              label='Comentarios'
              sm={9}
              disabled
            />
          </div>
        )
      }

      <div className="d-flex flex-row">
        <CustomInput
          formik={formik}
          field='requestDate'
          label='Fecha de solicitud'
          sm={9}
          type='date'
          disabled
        />
      </div>

      <div className="d-flex flex-row">
        <CustomInput
          formik={formik}
          field='requesterUser'
          label='Usuario que lo solicita'
          sm={9}
          disabled
        />
      </div>

      <button
        className='btn btn-primary ms-auto'
        onClick={closeFn}
      >
        Cerrar
      </button>
    </div>
  )
}

export default DetailsModal