import React from "react";

const DownloadLegacyItem = ({ url }: { url: string }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        right: "10px",
        zIndex: "3",
        paddingTop: "5px",
      }}
    >
      <button
        onClick={() => window.open(url, "_blank")}
        style={{
          cursor: "pointer",
          padding: "5px 10px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#007bff",
          color: "white",
        }}
      >
        <i className="fa-solid fa-download"></i>
      </button>
    </div>
  );
};

export default DownloadLegacyItem;
