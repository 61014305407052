import React, { useEffect } from "react";
import { useFormik } from "formik";
import { notification } from "../../utils/Notifications.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import BussinesNameSelect from "./Sucursales/BussinesNameSelect.tsx";

interface DataObject {
  id?: number;
  name?: string;
  isActive?: boolean;
  bussinesName?: number;
}

const Form = ({ closeFn, tapSelected, dataToEdit, dataToDelete }: { closeFn: () => void, tapSelected: string, dataToEdit: DataObject, dataToDelete?: any }) => {

  const name = (dataToEdit as { name: string }).name;
  const id = (dataToEdit as { id: number }).id || -1;

  const formik = useFormik({
    initialValues: tapSelected === "positions" ? { name: '', status: true } : tapSelected === 'branches' ? { name: '', status: true, businessName: '' } : { name: '' },
    onSubmit: async (values) => {
      try {
        if (!dataToDelete) {
          const data = {
            name: values.name,
            isActive: values.status,
          }
          if (tapSelected === "branches") { data.idBusinessName = values.businessName; }
          id === -1 ? await CatalogosService.saveCatalogos(tapSelected, values) : await CatalogosService.updateCatalogos(tapSelected, data, id);
        } else {
          await CatalogosService.desactivateCatalogos(tapSelected, dataToDelete?.id);
        }
        notification("Éxito ✅", "Registro guardada correctamente", "success");
        closeFn();
      } catch (error) {
        notification(
          "Error ⚠️",
          error.message || "Error al guardar el registro",
          "danger"
        );
      }
    },
  });

  useEffect(() => {
    if (id !== -1 && dataToEdit && dataToEdit.name && dataToEdit.isActive !== undefined) {
      if (tapSelected === "branches") {
        formik.setValues({ name: dataToEdit.name, status: dataToEdit.isActive, businessName: dataToEdit.businessName.id });
      } else {
        formik.setValues({ name: dataToEdit.name, status: dataToEdit.isActive });
      }
    }
  }, [dataToEdit]);

  return (
    <React.Fragment>
      {
        dataToDelete ? (
          <p className="fs-14px pb-4">¿Estás seguro que deseas {dataToDelete?.isActive ? 'desactivar' : 'activar'} este elemento?</p>
        )
          :
          (
            <React.Fragment>
              <CustomInput
                formik={formik}
                field="name"
                label="Nombre"
                placeholder={name}
                required={true}
                sm={8}
              />
              {tapSelected === "positions" && (
                <CustomCheckboxField
                  formik={formik}
                  field="status"
                  label="Estatus"
                />
              )}
              {
                tapSelected === "branches" && (
                  <BussinesNameSelect formik={formik} />
                )
              }
            </React.Fragment>
          )
      }


      <div className="d-flex justify-content-end mt-2">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          type="submit"
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          {
            dataToDelete ? 'Aceptar' : 'Guardar'
          }
        </button>
      </div>
    </React.Fragment>
  );
};

export default Form;
