import axios from "axios";
import { notification } from "../utils/Notifications.tsx";
import { deepParseJson } from "../utils/index.ts";
import io from 'socket.io-client';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'https://thapi.karimnot.com'

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(
  async function (config) {
    const rawPersistData = localStorage.getItem("persist:root");
    const persistData = deepParseJson(rawPersistData);
    const token = persistData?.auth?.token;

    config.headers = {
      "Content-Type": "application/json",
    };

    if (token) config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        notification("Error", "No autorizado", "danger");
        const event = new CustomEvent("unauthorized");
        window.dispatchEvent(event);
      }
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject({
        status: 500,
        message: "Error de conexión con el servidor.",
      });
    }
  }
);

export default httpClient;

const httpFormDataClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

httpFormDataClient.interceptors.request.use(
  function (config) {
    config.headers = {
      "Content-Type": "multipart/form-data",
    };

    const rawPersistData = localStorage.getItem("persist:root");
    const persistData = deepParseJson(rawPersistData);
    const token = persistData?.auth?.token;

    if (token) config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { httpFormDataClient };

// WebSocket Client Setup
const SERVER_URL = process.env.REACT_APP_API_URL;

const socketOptions = () => {
  const rawPersistData = localStorage.getItem("persist:root");
  const persistData = deepParseJson(rawPersistData);
  const token = persistData?.auth?.token;

  console.log('Token de conexión:', token);

  return {
    transportOptions: {
      polling: {
        extraHeaders: {
          dataconnection: token,
        },
      },
    },
    path: '/chili-socket', 
    extraHeaders: {
      dataconnection: token,
    },
  };
};

const socket = io(SERVER_URL, socketOptions());

socket.on('connect', () => {
  console.log('Conectado al servidor de WebSocket');
});

socket.on('disconnect', () => {
  console.log('Desconectado del servidor de WebSocket');
});

socket.on('usuarios-conectados', (message) => {
  console.log('Mensaje del servidor:', message);
});


export { socket };
