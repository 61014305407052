import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { notification } from "../../utils/Notifications.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import { CatalogoSchema } from "../../validation/schemas/CatalogoSchema.ts";

const FormNuevo = ({ closeFn, tapSelected, dataToEdit }: { closeFn: () => void, tapSelected: string, dataToEdit: object }) => {

  const name = (dataToEdit as { name: string }).name;
  const id = (dataToEdit as { id: number }).id;

  const formik = useFormik({

    validationSchema: CatalogoSchema,

    initialValues: tapSelected === "positions" ? { name: '', status: true } : { name: '' },
    onSubmit: async (values) => {
      try {
        const response = id === -1 ? await CatalogosService.saveCatalogos(tapSelected, values) : await CatalogosService.updateCatalogos(tapSelected, values, id);
        notification("Éxito ✅", "Registro guardada correctamente", "success");
        closeFn();
      } catch (error) {
        notification(
          "Error ⚠️",
          error.message || "Error al guardar el registro",
          "danger"
        );
      }

    },
  });


  return (
    <React.Fragment>

      <CustomInput
        formik={formik}
        field="name"
        label="Nombre"
        placeholder={name}
        required={true}
        sm={8}
      />
      {
        tapSelected === "positions" && (
          <CustomCheckboxField
            formik={formik}
            field="status"
            label="Estatus"
          />
        )
      }

      <div className="d-flex justify-content-end mt-2">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formik.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default FormNuevo;
