import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";

const CustomCheckboxField = ({
  formik,
  field,
  label = "Estatus",
  onChange = () => {},
  mdLabel = 3,
  disable = false,
  classInput = ""
}: IFormikProps & {
  field: string;
  label?: string;
  onChange?: () => void;
  mdLabel?: number;
  disable?: boolean;
  classInput?: string
}) => {
  return (
    <div className={`row mb-15px fs-13px d-flex align-items-center ${classInput}`}>
      <label className={`form-label col-form-label col-md-${mdLabel} text-end me-2`}>
        {label}:
      </label>
      <input
        type="checkbox"
        className="form-check-input"
        data-checked="user-status-checkbox"
        id="statusUser"
        data-change="user-status"
        onChange={(e) => {
          formik.setFieldValue(field, e.target.checked);
          onChange();
        }}
        checked={formik.values[field]}
        disabled={disable}
        style={disable ? { cursor: "not-allowed" } : {}}
      />
    </div>
  );
};

export default CustomCheckboxField;
