import React, { useEffect } from 'react'
import CustomSelectField from '../../formik/CustomSelectField.tsx';
import { PanelBody } from '../../panel/panel.jsx';
import CustomSelectValues from '../../formik/CustomSelectValues.tsx';
import SheduleComponent from './SheduleComponent.tsx';
import { readHoursByBranch, readPositionsByBranchAndShift, readSchedulesByBranchShiftWeekDay } from '../../../services/branchSchedule.service.ts';
import { generateTimeIntervals, shifts, weekDays, weeks } from '../../../utils/constanst.js';
import FullScreenContainer from '../../FullScreenContainer.tsx';
import FullScreenSchedule from './FullScreenSchedule.tsx';

const HorarioSucuralForm = ({ formik }) => {
  const [positionsArray, setPositionsArray] = React.useState<any>([]);
  const [existingsSchedules, setExistingsSchedules] = React.useState<any>([]);
  const [positionsSorted, setPositionsSorted] = React.useState<any>([]);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [hours, setHours] = React.useState<any>([]);

  useEffect(() => {
    const loadDependeicies = async () => {
      const values = formik.values;

      const shift = shifts.find((shift) => shift.id === values.shift)?.name ?? '';
      const positions = await readPositionsByBranchAndShift(values.branchId, shift);

      const positionsArray = positions.map((position) => {
        const totalWorkersRequired = position.numberOfWorkers;

        const workers: any = [];

        for (let i = 0; i < totalWorkersRequired; i++) {
          workers.push({
            id: i,
            positionId: position.position.id,
            positionName: position.position.name + ' ' + (i + 1),
            originalPositionName: position.position.name,
            shift: position.shift,
          });
        }

        return workers
      });

      setPositionsArray(positionsArray.flat());

      const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];

      // Ordenar el array por la propiedad shift
      const sorted = positionsArray.flat().sort((a, b) => {
        return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
      });

      const resultArray: any = [];
      let currentShift = '';

      sorted.forEach(position => {
        if (position.shift !== currentShift) {
          currentShift = position.shift;
          resultArray.push({ title: currentShift, shift: currentShift });
        }
        resultArray.push(position);
      });

      setPositionsSorted(resultArray);

      if (values.week === -99 || values.shift === -99 || values.dayOfWeek === -99) {
        return;
      }
      const schedules = await readSchedulesByBranchShiftWeekDay(values.branchId, shift, weeks[values.week]?.value, weekDays[values.dayOfWeek]?.name);
      setExistingsSchedules(schedules);

      const branchHours = await readHoursByBranch(values.branchId);
      setHours(branchHours);

    }

    loadDependeicies();
  }, [formik.values.week, formik.values.shift, formik.values.dayOfWeek]);

  return (
    <PanelBody>
      <CustomSelectField
        formik={formik}
        field="week"
        label="Semana"
        list={weeks}
        sm={3}
      />
      <CustomSelectValues
        formik={formik}
        field="shift"
        label="Turno"
        sm={4}
        list={shifts}
      />
      <CustomSelectValues
        formik={formik}
        field="dayOfWeek"
        label="Día de la semana"
        sm={4}
        list={weekDays}
      />
      {
        fullScreen &&
        <FullScreenContainer open={fullScreen} tittle='Horario de la sucursal' onClsFn={() => setFullScreen(false)}>
          <FullScreenSchedule existingsSchedules={existingsSchedules} positionsArray={formik.values.shift === 3 ? positionsSorted : positionsArray} formik={formik} turno={formik.values.shift} setPositionsArray={setPositionsArray} selectedDay={formik.values.dayOfWeek} />
        </FullScreenContainer>
      }
      {
        formik.values.shift !== -99 && formik.values.dayOfWeek !== -99 && (
          hours?.id
            ? (positionsArray && positionsArray.length > 0 ?
              <SheduleComponent
                selectedWeek={formik.values.week}
                hours={hours} existingsSchedules={existingsSchedules} positionsArray={formik.values.shift === 3 ? positionsSorted : positionsArray} formik={formik} turno={formik.values.shift} setPositionsArray={setPositionsArray} selectedDay={formik.values.dayOfWeek} />
              :
              <p className='text-center mt-5'>
                No hay puestos disponibles para el turno seleccionado
              </p>)
            :
            <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
              <div className="alert alert-warning" role="alert">
                <h4 className="alert-heading">¡Atención!</h4>
                <p>
                  La sucursal no cuenta con un horario establecido.
                </p>
                <hr />
                <p className="mb-0">
                  Por favor, completa el horario de la sucursal para poder asignar los trabajadores.
                </p>
              </div>
            </div>
        )
      }
    </PanelBody>
  )
}

export default HorarioSucuralForm




/*
  Cambio tabs clear layout
  Verde el rango horario
  pantalla completa
*/