import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice.ts";
import NavBarItem from "./NavBarItem.tsx";
import { RootState } from "../../store/index.ts";
import { Routes } from "./Routes.tsx";
import DropdownComponent from "./DropdownComponent.tsx";

function NavBar() {
  const { isAdmin, user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [active, setActive] = React.useState<string>("");

  React.useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    setActive(currentPath);
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a
          className="navbar-brand"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <div className="brand p-2">
            <div className="w-50px">
              <img
                src="../../../../assets/img/logo/logo_chili_rojo.png"
                alt=""
                className="w-100"
              />
            </div>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {Routes.map((route) => {
              const userRole = user.rol;

              if (route.validRoles.includes(userRole)) {
                return (
                  <NavBarItem
                    key={route.name}
                    active={active}
                    name={route.name}
                    to={route.to}
                    activeKey={route.activeKey}
                    icon={route.icon}
                  />
                );
              }
            })}
            <li className="nav-item d-block d-md-none">
              <DropdownComponent />
            </li>
          </div>
        </div>
      </div>
      <div
        className="collapse navbar-collapse"
        id="navbarTogglerDemo03"
        style={{ paddingRight: "2%" }}
      >
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 mr-2">
          <li className="nav-item">
            <DropdownComponent />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
