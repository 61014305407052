import httpClient, { httpFormDataClient } from "./httpClient";

const prefix = "/files";

export default class S3Service {
  static async upload(file, path) {
    const formData = new FormData();
    formData.append('file', file);
    return (await httpFormDataClient.post(`${prefix}/upload/${path}`, formData)).data;
  }

  static async getFile(name: string) {
    return (await httpClient.get(`${prefix}/get/${name}`)).data;
  }

  //get multiple files with almost the same name, for example: getFiles('image1') will return image1, image1_1, image1_2, etc.
  static async getFiles(name: string) {
    return (await httpClient.get(`${prefix}/getFiles/${name}`)).data;
  }

  static async getByID(id: string) {
    return (await httpClient.get(`${prefix}/getByID/${id}`)).data;
  }

  static async deleteFile(id: string) {
    return (await httpClient.delete(`${prefix}/delete-file/${id}`)).data;
  }

  static async replaceFile(oldName: string, newName: string) {
    return (await httpClient.put(`${prefix}/update-file`, {oldName, newName} )).data;
  }
} 
