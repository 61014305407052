export interface IUser {
  id: number;
  name: string;
  last_name: string;
  second_last_name: string;
  email: string;
  phone: string;
  password: string;
  id_rol: number;
  status: boolean;
  user_create: number;
  user_update: number;
  created_at: string;
  updated_at: string;
  role_name: string;

  id_branch: number;
  branch: string;
}

export const UserInitial: IUser = {
  id: -1,
  name: "",
  last_name: "",
  second_last_name: "",
  email: "",
  phone: "",
  password: "",
  id_rol: -99,
  status: false,
  user_create: -99,
  user_update: -99,
  created_at: "",
  updated_at: "",
  role_name: "",
  id_branch: -99,
  branch: "",
};

export const UserFill = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    last_name: data?.lastName,
    second_last_name: data?.secondLastName,
    email: data?.email,
    phone: data?.phone,
    password: data?.password,
    id_rol: data?.rol?.id,
    status: data?.status,
    user_create: data?.user_create,
    user_update: data?.user_update,
    created_at: data?.created_at,
    updated_at: data?.updated_at,
    role_name: data?.rol.name,
    id_branch: data?.branch?.id,
    branch: data?.branch?.name,
  };
};
