import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import { getCurrencyFormat } from "../../utils/index.ts";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/img/ic_content.svg";
import { deleteApplication } from "../../services/appoinment.service.ts";
import { setCurrentUser, setFetch, setModalBan, setModalSetStatus, setShowModal } from "../../store/slices/solicitudesSlice.ts";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { deletePartner } from "../../services/partner.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import ModalContainer from "../ModalContainer.tsx";
import DeleteSolicitudes from "./DeleteSolicitudes.tsx";


const TableSolicitudes = ({ refetch }) => {
  const dispatch = useDispatch();
  const { fetch } = useSelector((state: RootState) => state.solicitud);

  const navigate = useNavigate();
  const { solicitudesList } = useSelector(
    (state: RootState) => state.solicitud
  );
  const { user } = useSelector((state: RootState) => state.auth);

  function handleClick(application, disabledForm = false) {
    if (
      application.applicationStatus.name == "Solicitado" ||
      application.applicationStatus.name == "Entrevistado"
    ) {
      navigate("/result/form", { state: { ...application, disabledForm } });
    } else {
      if (user.branch) {
        navigate("aprobar/" + application.id);
      } else {
        navigate("/postulante/form", { state: application });
      }
    }
  }

  const deleteApplicationF = async (id: number) => {
    try {
      const data = await deleteApplication(id);
      console.log(data);
      dispatch(setFetch(!fetch));
    } catch (error) {
      console.log(error);
    }
  };

  const olderThanTwoDays = (date: Date) => {
    if (!date) return;
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - new Date(date).getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 2;
  }

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [dataToRemove, setDataToRemove] = React.useState({ id: -1, name: "" });

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Status</th>
            <th>{!user.branch ? "Cita" : "Fecha inicio"}</th>
            <th>Puesto</th>
            <th>Turno</th>
            {!user.branch && <th>Expectativa</th>}
            <th style={{ justifyContent: "end", display: "flex" }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {solicitudesList?.length > 0 ?
            solicitudesList.map((item: any) => {
              {
                let colorStatus;
                if (item.applicationStatus.name === "Solicitado") {
                  colorStatus =
                    "border-azul text-azul badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                }
                if (item.applicationStatus.name === "Entrevistado") {
                  colorStatus =
                    "border-amarillo text-amarillo badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                }
                if (item.applicationStatus.name === "Aceptado") {
                  colorStatus =
                    "border-verde text-verde badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                }
                if (item.applicationStatus.name === "Pre-Colaborador") {
                  colorStatus =
                    "border-naranja text-naranja badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                }
                if (item.applicationStatus.name === "Rechazado") {
                  colorStatus =
                    "border-rojo text-rojo badge border px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center";
                }
                const fecha = `${item.applicationDate} ${item.applicationTime}`;

                const fechaFormateada = moment(fecha).format(
                  "dddd, D [de] MMMM [de] YYYY, HH:mm:ss"
                );

                return (
                  <tr key={item.id}>
                    <td>{toCamelCase(item.person.firstName)}</td>
                    <td>
                      {toCamelCase(item.person.lastName + " " + item.person.secondLastName)}
                    </td>
                    <td>
                      <span className={colorStatus}>
                        <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                        {item.applicationStatus.name}{" "}
                      </span>
                    </td>
                    {user.branch ? (
                      <td>{item?.candidates[0]?.recruitmentDate}</td>
                    ) : (
                      <td>{fechaFormateada}</td>
                    )}
                    <td>{item.vacancy.position.name}</td>
                    <td>{item?.shift?.name}</td>
                    {!user.branch && (
                      <td>{getCurrencyFormat(item.monthlySalary)}</td>
                    )}

                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {item.applicationStatus.name === "Rechazado" && (
                          <button
                            className="btn btn-azul btn-sm"
                            type="button"
                            onClick={() => {
                              dispatch(setCurrentUser({
                                id: item.id,
                                name: `${item.person.firstName} ${item.person.lastName} ${item.person.secondLastName}`,
                              }));
                              dispatch(setShowModal(true))
                            }}
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        )}
                        {item.applicationStatus.name != "Colaborador" &&
                          item.applicationStatus.name != "Rechazado" ? (
                          <button
                            className="btn btn-azul btn-sm"
                            onClick={() => handleClick(item)}
                            type="button"
                          >
                            Ver detalle
                          </button>
                        ) : null}

                        {item.applicationStatus.name === "Rechazado" && (
                          <button
                            className="btn btn-rojo btn-sm"
                            type="button"
                            onClick={() => {
                              dispatch(setCurrentUser({
                                id: item.id,
                                name: `${item.person.firstName} ${item.person.lastName} ${item.person.secondLastName}`,
                              }))
                              dispatch(setModalBan(true))
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            <i className="fa-solid fa-eye"></i>
                          </button>
                        )}
                        {user.rol !== "Reclutador/Archivos" && (
                          <React.Fragment>
                            {
                              !olderThanTwoDays(item.createdAt) &&
                              // <button
                              //   className="btn btn-danger ms-1"
                              //   onClick={() => {
                              //     setShowDeleteModal(true)
                              //     setDataToRemove({
                              //       id: item.id,
                              //       name: `${item.person.firstName} ${item.person.lastName} ${item.person.secondLastName}`,
                              //     })
                              //   }}
                              // >
                              //   <i className="fa-solid fa-ban m"></i>
                              // </button>
                              <></>
                            }
                            <button
                              className="btn btn-amarillo btn-sm"
                              type="button"
                              onClick={() => {
                                dispatch(setCurrentUser({
                                  id: item.id,
                                  name: `${item.person.firstName} ${item.person.lastName} ${item.person.secondLastName}`,
                                }));
                                dispatch(setModalSetStatus(true));
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              <i className="fa-solid fa-signs-post"></i>
                            </button>
                          </React.Fragment>
                        )}
                        {/* <button
                        className="btn btn-rojo btn-sm"
                        type="button"
                        onClick={() => {
                          console.log(item.id);
                          deleteApplicationF(Number(item.id));
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        BORRAR
                      </button> */}
                      </div>
                    </td>
                  </tr>
                );
              }
            })
            :
            <tr>
              <td colSpan={8}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>No encontramos registros</h5>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
      <ModalContainer
        open={showDeleteModal}
        closeFn={() => {
          setShowDeleteModal(false);
        }}
        title={`Eliminar solicitud`}
        children={
          <DeleteSolicitudes
            name={dataToRemove?.name}
            id={dataToRemove?.id}
            closeFn={() => {
              setShowDeleteModal(false);
              setDataToRemove({
                id: -1,
                name: "",
              });
              dispatch(setFetch(!fetch));
            }}
          />
        }
      />
    </div>
  );
};

export default TableSolicitudes;
