import * as Yup from "yup";

export const MessagePartnerSchema = Yup.object().shape({
  title: Yup.string().required("Campo requerido"),
  message: Yup.string().required("Campo requerido"),
  duration: Yup.number().required("Campo requerido"),
  icon: Yup.string().required("Campo requerido"),
});

export const isValidForSweetAlert = (values: any) => {
  let flag = false;
  if (
    Boolean(
      values?.title && values?.message && values?.duration && values?.icon
    )
  ) {
    flag = true;
  }
  return flag;
};
