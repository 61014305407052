import React from "react";
import { setQuery, setShowModal, setShowModalImportExcel } from "../../store/slices/listaNegraSlice.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";

const FiltersListaNegra = ({  onClickRef,classNameFilter }:{  onClickRef: () => void,classNameFilter: string }) => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: RootState) => state.listaNegra);
  const [search, setSearch] = React.useState("");
  const initialValues = {
    tab: "todos",
    search: "",
  }
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, search: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "" && updatedFilters[key] !== "todos"
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        // console.log(queryString)
        dispatch(setQuery(queryString));

        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filtros)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "" && filtros[key] !== "todos")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "" && filters[key] !== "todos") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "search":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "tab":
            keyName = "Baneo";
            valueName = value === "todos" ? "Todos" : "Permanentes";
            break;
          default:
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }
    return selected;
  }

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "" && updatedFilters[key] !== "Todos")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(updatedFilters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Baneo:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, tab: e.target.value })}
            value={filters.tab}
          >
            <option value={"todos"}>Todos</option>
            <option value={"permanentes"}>Permanentes</option>
          </select>

          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    tab: "todos",
                    search: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                // type="submit"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}>
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un nombre..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.search}
            />
          </div>
        </div>
        {/* <button
          className="btn btn-azul"
          style={{ marginLeft: 10 }}
          onClick={() => dispatch(setShowModalImportExcel(true))}
        >
          Importar registros
        </button> */}
        <button
          className={`btn btn-azul ${classNameFilter}`}
          style={{ marginLeft: 10 }}
          onClick={() => {
            dispatch(setShowModal(true));
            onClickRef();
          }}
        >
          Nuevo registro
        </button>
      </div>
      <div className="mb-3">
        {
          showPills && selectedFilters(filters).map((filter: any) => (
            <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {filter.keyName}: {filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default FiltersListaNegra;
