import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ImportExcelSchema } from "../../validation/schemas/ImportExcelSchema.ts";
import CustomInput from "../formik/CustomInput.tsx";
import { setCurrentFile, setShowModalImportExcel } from "../../store/slices/listaNegraSlice.ts";

const ImportBlackList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = React.useState<any>(null);

  const formik = useFormik({
    validationSchema: ImportExcelSchema,
    initialValues: { file: null },
    onSubmit: async (values) => {
      dispatch(setShowModalImportExcel(false));
      navigate("/lista-negra/importar");
    },
  });

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="file"
        label="Archivo"
        required={true}
        sm={8}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChangeFile={(e) => dispatch(setCurrentFile(e))}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-secondary me-2"
          onClick={() => dispatch(setShowModalImportExcel(false))}
        >
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Importar
        </button>
      </div>
    </React.Fragment>
  );
};

export default ImportBlackList;
