import React from "react";
import { Partner } from "../../models/Partner";
import { getSchoolings } from "../../services/schooling.service.ts";
import Loader from "../Loader.tsx";
import { useFormik } from "formik";
import { EditProfileSchema } from "../../validation/schemas/EditProfile.ts";
import { FillEditProfile } from "../../interfaces/formik/IEditUser.ts";
import InputEditProfile from "../formik/InputEditProfile.tsx";
import SelectEditProfile from "../formik/SelectEditProfile.tsx";
import InputPhone from "../formik/InputPhone.tsx";
import { useNavigate } from "react-router-dom";
import { notification } from "../../utils/Notifications.tsx";
import { updatePartner } from "../../services/partner.service.ts";
import { getProceedingByPartner } from "../../services/proceedings.service.ts";
import { updateFileProceeding } from "../../services/files.service.ts";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import ModalContainer from "../ModalContainer.tsx";
import TooltipContent from "./TooltipContent.tsx";

const InformationUser = ({
  partner,
  reloadPage,
  disabled = false,
}: {
  partner: Partner;
  reloadPage: () => void;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const [schoolings, setSchoolings] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const loadDependencies = async () => {
    try {
      const response = await getSchoolings();
      setSchoolings(response);
    } catch (error) {
      console.error(error);
    }
  };

  const maritalStatusList = [
    { id: "SOLTERO", name: "Soltero" },
    { id: "CASADO", name: "Casado" },
    { id: "DIVORCIADO LEGALMENTE", name: "Divorciado legalmente" },
    { id: "SEPARADO LEGALMENTE", name: "Separado Legalmente" },
    { id: "VIUDO", name: "Viudo" },
  ];

  const formik = useFormik({
    validationSchema: EditProfileSchema,
    initialValues: FillEditProfile(partner),
    onSubmit: async (values) => {
      const { clockNumber, ...dataToSend } = values;
      setLoading(true);
      try {
        await updatePartner(partner.person.id, dataToSend);
        const proceeding = await getProceedingByPartner(partner.id);
        const proceedingRFC = proceeding.files.find((file) =>
          file.fields.map((field) => field.field).includes("RFC")
        );
        if (proceedingRFC) {
          const data = {
            proceedingId: proceeding.proceeding.id,
            fileTypeId: proceedingRFC.documentType.id,
            fileId: proceedingRFC.file.id,
            fields: [
              {
                field: "RFC",
                value: values.rfc,
              },
            ],
          };
          await updateFileProceeding(proceedingRFC.id, data);
        }

        notification("Éxito", "Usuario actualizado correctamente", "success");
      } catch (error) {
        console.log(error);
        notification(
          "Error",
          "Ocurrió un error al actualizar el usuario",
          "danger"
        );
      } finally {
        reloadPage();
        setLoading(false);
      }
    },
  });

  React.useEffect(() => {
    loadDependencies();
  }, []);

  const [idmexModal, setIdmexModal] = React.useState(false);
  const [ocrModal, setOcrModal] = React.useState(false);

  React.useEffect(() => {
    formik.setValues(FillEditProfile(partner));
    console.log('disabled', disabled);
  }, [partner]);

  return (
    <div className="tab-pane fade active show" id="informacion">
      <div className="table-responsive form-inline">
        <table className="table table-profile align-middle">
          <thead>
            <tr>
              <th />
              <th>
                <h4>
                  {toCamelCase(`${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`)}
                  <small style={{ display: "flex", flexDirection: "column" }}>
                    <em>
                      Número en el reloj checador <b>{partner.clockNumber}</b>{" "}
                    </em>
                  </small>
                </h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="highlight">
              <td className="field">Curp : </td>
              <td>
                <div className={`col-md-5`} style={{ cursor: "not-allowed" }}>
                  <input
                    className={`form-control mb-5px fs-13px `}
                    value={partner.person.curp}
                    style={{ pointerEvents: "none" }}
                    onChange={() => { }}
                  />
                </div>
              </td>
            </tr>
            {/* <tr className="highlight">
              <td className="field">Número en el reloj checador : </td>
              <td>
                <div className={`col-md-5`} style={{ cursor: "not-allowed" }}>
                  <input
                    className={`form-control mb-5px fs-13px `}
                    value={partner.clockNumber}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
              </td>
            </tr> */}
            <tr className="divider">
              <td colSpan={2} />
            </tr>

            <InputEditProfile
              formik={formik}
              field="name"
              label="Nombre"
              placeholder="Nombre"
              sm={3}
              unclickable={disabled}
            />
            <InputEditProfile
              formik={formik}
              field="lastName"
              label="Apellido Paterno"
              placeholder="Apellido Paterno"
              sm={3}
              unclickable={disabled}
            />
            <InputEditProfile
              formik={formik}
              field="secondLastName"
              label="Apellido Materno"
              placeholder="Apellido Materno"
              sm={3}
              unclickable={disabled}
            />
            <InputEditProfile
              formik={formik}
              field="email"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="genere"
              label="Género"
              placeholder="Género"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="rfc"
              label="RFC"
              placeholder="RFC"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="nss"
              label="Número de Seguro Social"
              placeholder="Número de Seguro Social"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="idMex"
              label="IDMEX"
              placeholder="IDMEX"
              sm={4}
              tooltip={
                <a href="#/" onClick={()=>setIdmexModal(true)} className="ms-2 text-secondary">
                  <i className="fa-solid fa-circle-question fs-16px"></i>
                </a>
              }
            />
            <InputEditProfile
              formik={formik}
              field="ocr"
              label="OCR"
              placeholder="OCR"
              sm={4}
              tooltip={
                <a href="#/" onClick={()=>setOcrModal(true)} className="ms-2 text-secondary">
                  <i className="fa-solid fa-circle-question fs-16px"></i>
                </a>
              }
            />

            <tr>
              <td className="field">Estado civil : </td>
              <td>
                <SelectEditProfile
                  formik={formik}
                  field="maritalStatus"
                  list={maritalStatusList}
                  label="Estado Civil"
                  numeric={false}
                  sm={4}
                />
              </td>
            </tr>

            <InputPhone
              formik={formik}
              field="phone"
              label="Teléfono"
              placeholder="Teléfono"
            />

            <tr>
              <td className="field">Escolaridad : </td>
              <td>
                <SelectEditProfile
                  formik={formik}
                  field="idSchooling"
                  list={schoolings}
                  label="Escolaridad"
                />
              </td>
            </tr>

            <InputEditProfile
              formik={formik}
              field="address"
              label={
                <>
                  Domicilio : <br />
                  (Calle, número, colonia, código postal)
                </>
              }
              placeholder="Domicilio"
              sm={6}
            />
            <InputEditProfile
              formik={formik}
              field="birthdate"
              label="Fecha de nacimiento"
              placeholder="Fecha de nacimiento"
              type="date"
              sm={3}
            />
            <InputEditProfile
              formik={formik}
              field="alergias"
              label="Alergias"
              placeholder="Alergias"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="tipoSangre"
              label="Tipo de sangre"
              placeholder="Tipo de sangre"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="enfermedad"
              label="Enfermedad"
              placeholder="Enfermedad"
              sm={4}
            />
            <InputEditProfile
              formik={formik}
              field="tratamiento"
              label="Tratamiento"
              placeholder="Tratamiento"
              sm={4}
            />
            <tr>
              <th />
              <th>
                <h4>
                  <small>
                    Para el caso de su fallecimiento o desaparición derivada de
                    un acto delincuencial, en apego a lo establecido en los
                    Artículos 25 Fracción X y 501 de LA LEY, EL TRABAJADOR
                    designa como su beneficiario para el pago de los salarios y
                    prestaciones devengadas y no cobradas, en primer lugar con
                    derecho al 100%, a:
                  </small>
                </h4>
              </th>
            </tr>
            <InputEditProfile
              formik={formik}
              field="firstNameBeneficiary"
              label="Nombre del Beneficiario"
              placeholder="Nombre del Beneficiario"
              sm={3}
              type="text"
            />
            <InputEditProfile
              formik={formik}
              field="lastNameBeneficiary"
              label="Apellido Paterno del Beneficiario"
              placeholder="Apellido Paterno del Beneficiario"
              sm={3}
            />
            <InputEditProfile
              formik={formik}
              field="secondLastNameBeneficiary"
              label="Apellido Materno del Beneficiario"
              placeholder="Apellido Materno del Beneficiario"
              sm={3}
            />
            <InputEditProfile
              formik={formik}
              field="relationshipBeneficiary"
              label="Parentesco del Beneficiario"
              placeholder="Parentesco del Beneficiario"
              sm={3}
            />
            <InputPhone
              formik={formik}
              field="phoneBeneficiary"
              label="Teléfono del Beneficiario"
              placeholder="Teléfono del Beneficiario"
            />
            <InputEditProfile
              formik={formik}
              field="addressBeneficiary"
              label="Domicilio del Beneficiario"
              placeholder="Domicilio del Beneficiario"
              sm={6}
            />
            <tr className="highlight">
              <td className="field">&nbsp;</td>
              <td className="">
                <button
                  type="submit"
                  className="btn btn-secondary w-150px ms-5px"
                  style={{ marginRight: "4%" }}
                  onClick={() => navigate("/colaboradores")}
                >
                  Regresar
                </button>
                <button
                  type="submit"
                  className="btn btn-azul w-150px"
                  onClick={() => formik.handleSubmit()}
                >
                  Actualizar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <ModalContainer open={idmexModal} title="IDMEX" closeFn={()=>setIdmexModal(false)} size="lg">
        <TooltipContent type="IDMEX" />
      </ModalContainer>

      <ModalContainer open={ocrModal} title="OCR" closeFn={()=>setOcrModal(false)} size="lg">
        <TooltipContent type="OCR" />
      </ModalContainer>

      <Loader isLoading={loading} />
    </div>
  );
};

export default InformationUser;
