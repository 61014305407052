import httpClient from "./httpClient";
const apiUrl = process.env.REACT_APP_API_URL;

export class PostulanteService {

    static getApplications = async (searchParams) => {
        const url = "/applications/?" + searchParams;
        try {
            const response = await httpClient.get(url);
            return response.data;
        } catch (error) {
            // Maneja el error como desees
            console.error(error);
        }
    }

    // static getApplications = async (searchParams) => {
    //     const url = apiUrl + "/applications/?" + searchParams;
    //     let headers = new Headers();
    //     headers.append("Content-Type", "application/json");
    //     const resp = await fetch(url, {
    //         method: "GET",
    //         headers,
    //     });
    //     const data = await resp.json();
    //     return data;
    // }

    static getHoursApplications = async (id, date) => {

        console.log("id: " + id);
        console.log("date: " + date);
        const url = apiUrl + "/applications/hours/" + id + "?fecha=" + date;
        let headers = new Headers();

        const body = JSON.stringify({ fecha: date });
        headers.append("Content-Type", "application/json");

        const resp = await fetch(url, {
            method: "GET",
            headers,
        });

        const data = await resp.json();
        return data;
    }

    static updateApplication = async (id, operation, content) => {
        console.log("id: " + id);
        console.log("operation: " + operation);
        console.log("content: " + JSON.stringify(content));
        const body = JSON.stringify(content);
        const url = apiUrl + "/applications/"  + id + "/" + operation;
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        const resp = await fetch(url, {
            method: "POST",
            headers,
            body
        });

        const data = await resp.json();
        return data;
    }



    static validateCurp = async (content) => {
        const url = apiUrl + "/applications/validate";
        const body = JSON.stringify(content);
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        const resp = await fetch(url, {
            method: "POST",
            headers,
            body
        });

        const data = await resp.json();
        return data;
    }



    static submitApplication = async (curp, formData, moment) => {

        // console.log('formData', formData)
        // console.log('moment', moment(formData.entrevista).format('YYYY-MM-DD'))
        const url = apiUrl + "/applications/submit"

        let today = new Date()
        let day = today.getDate();
        let month = today.getMonth();
        let year = today.getFullYear();
        let hours = today.getHours();
        let minutes = today.getMinutes();
        let seconds = today.getSeconds();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        let phone = formData.telefono;
        console.log(phone);
        phone = phone.split("-",).join("").split("(").join("").split(")").join("").split("+").join("").split(" ").join("")
        console.log(phone);

        let body = JSON.stringify({
            curp: curp,
            firstName: formData.nombre,
            lastName: formData.apellido_p,
            secondLastName: formData.apellido_m,
            birthdate: formData.nacimiento,
            phone: phone,
            schoolingId: formData.escolaridad,
            address: formData.direccion,
            positionId: formData.puesto,
            monthlySalary: formData.expectativaSalarial,
            experience: formData.experiencia,
            shiftId: formData.horario,
            stayingMonths: formData.permanencia,
            applicationDate: moment(formData.entrevista).format('YYYY-MM-DD'),
            applicationTime: formData.horarioE

        })

        console.log(body);

        let headers = new Headers();
        headers.append("Content-Type", "application/json");



        const resp = await fetch(url, {
            method: "POST",
            headers,
            body
        });

        const data = await resp.json();
        return data;
        // return ''
    }

    static getApplication = async (id) => {
        const url = apiUrl + "/applications/" + id;
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        const resp = await fetch(url, {
            method: "GET",
            headers,
        });
        const data = await resp.json();
        return data;
    }
    static getCurpBlacklist = async (curp) => {
        const url = apiUrl + "/blacklists/curp/" + curp;
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        const resp = await fetch(url, {
            method: "GET",
            headers,
        });
        const data = await resp.json();
        return data;
    }
}

