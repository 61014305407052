import { valiteCURP } from "../utils/index.ts";

export interface ImportPartner {
  CURP: string;
  "Nombre (s)": string;
  "Apellido paterno": string;
  "Apellido Materno": string;
  "Fecha de nacimiento": string;
  Teléfono: string;
  Domicilio: string;
  Salario: number;
  "Día de entrada a trabajar": string;
  Puesto: string;
  "Periodicidad de pago": string;
  Talla: string;
  Sucursal: string;
}

// Interfaz
interface IPartnerExcel {
  curp: string;
  name: string;
  lastName: string;
  secondLastName: string;
  birthdate: Date;
  phone: string;
  address: string;
  position: string;
  branch: string;
  size: string;
  shift: string;
  monthlySalary?: number;
  recruitmentDate?: Date;
  periodicity?: string;
  alergias?: string;
  tipoSangre?: string;
  enfermedad?: string;
  tratamiento?: string;
}

// Clase
export class PartnerExcel implements IPartnerExcel {
  curp: string;
  name: string;
  lastName: string;
  secondLastName: string;
  birthdate: Date;
  phone: string;
  address: string;
  position: string;
  branch: string;
  size: string;
  shift: string;
  monthlySalary: number;
  recruitmentDate: Date;
  periodicity: string;
  alergias: string;
  tipoSangre: string;
  enfermedad: string;
  tratamiento: string;

  constructor(data: IPartnerExcel) {
    this.curp = data.curp;
    this.name = data.name;
    this.lastName = data.lastName;
    this.secondLastName = data.secondLastName;
    this.birthdate = data.birthdate;
    this.phone = data.phone;
    this.address = data.address;
    this.monthlySalary = data.monthlySalary || 0;
    this.recruitmentDate = data.recruitmentDate || new Date();
    this.position = data.position;
    this.branch = data.branch;
    this.periodicity = data.periodicity || "";
    this.size = data.size;
    this.shift = data.shift;
    this.alergias = data.alergias || "";
    this.tipoSangre = data.tipoSangre || "";
    this.enfermedad = data.enfermedad || "";
    this.tratamiento = data.tratamiento || "";
  }

  public isInvalid(value: any): boolean {
    return value === null || value === undefined || value === "";
  }

  public curpValid(): boolean {
    return valiteCURP(this.curp);
  }

  public validField(
    field: string,
    values: { id: number; name: string }[]
  ): boolean {
    const originalField = field?.trim()?.toLocaleLowerCase();
    return values.some(
      (value) => value?.name?.trim()?.toLocaleLowerCase() === originalField
    );
  }

  public validate(): boolean {
    const curpIsValid = valiteCURP(this.curp);

    if (!curpIsValid) {
      return false;
    }

    if (
      this.isInvalid(this.name) ||
      this.isInvalid(this.lastName) ||
      this.isInvalid(this.secondLastName) ||
      this.isInvalid(this.birthdate) ||
      this.isInvalid(this.phone) ||
      this.isInvalid(this.address) ||
      this.isInvalid(this.position) ||
      this.isInvalid(this.branch) ||
      // this.monthlySalary === 0 ||
      // this.isInvalid(this.recruitmentDate) ||
      // this.isInvalid(this.periodicity) ||
      this.isInvalid(this.size) ||
      this.isInvalid(this.alergias) ||
      this.isInvalid(this.tipoSangre) ||
      this.isInvalid(this.enfermedad) ||
      this.isInvalid(this.tratamiento)
    ) {
      return false;
    }
    return true;
  }
}

export const defaultPartnerProperties: IPartnerExcel = {
  curp: "",
  name: "",
  lastName: "",
  secondLastName: "",
  birthdate: new Date(),
  phone: "",
  address: "",
  monthlySalary: 0,
  recruitmentDate: new Date(),
  position: "",
  branch: "",
  periodicity: "",
  size: "",
  shift: "",
};


export const DefaultPartner = new PartnerExcel(defaultPartnerProperties);