import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  resetState,
  setLoading,
  setTotal,
  setUsers,
} from "../../store/slices/userSlice.ts";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { paginateUsers } from "../../services/user.service.ts";
import FooterChili from "../../components/FooterChili";
import FilterUsers from "../../components/user/FilterUsers.tsx";
import UserTable from "../../components/user/UserTable.tsx";
import Loader from "../../components/Loader.tsx";
import Title from "../../components/Title.tsx";
import { notification } from "../../utils/Notifications.tsx";
import Layout from "../../components/Layout.tsx";

const ListUsers = () => {
  useAppSettings()
  const dispatch = useDispatch();
  const { menuSelected, page, limit, param, loading, fetch, query } =
    useSelector((state: RootState) => state.user);


  React.useEffect(() => {
    dispatch(resetState());
  }, []);
  React.useEffect(() => {
    fetchUsers();
  }, [page, limit, menuSelected, param, fetch, query]);

  const fetchUsers = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "type=all";
      }
      const response = await paginateUsers(queryToSend);
      dispatch(setUsers(response.data));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Layout loading={loading}>
      <Title baseTitle="Usuarios" basePath="/usuarios" title="Usuarios" />
      <div className="card border-0 m-4">
        {/* <UserTableTabs /> */}
        <div className="tab-content p-3">
          <div className="tab-pane fade show active">
            <FilterUsers />
            <UserTable />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListUsers;
