import React from "react";
import { useFormik } from "formik";

import {
  fetchPartnerCredentials,
  savePartnerCredentials,
} from "../../services/partner.service.ts";
import SaveCredentialsSchema from "../../validation/schemas/SaveCredentialsSchema.ts";

import CustomInput from "../formik/CustomInput.tsx";
import Loader from "../Loader.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { REACT_APP_APP_URL } from "../../config/app-settings.js";

const GetCredentials = ({ name, id, closeFn, colaborador }) => {
  const [loading, setLoading] = React.useState(true);
  const [credentials, setCredentials] = React.useState<{
    email: string;
    password: string;
  } | null>(null);

  const fetchCredentials = async () => {
    try {
      const existingCredentials = await fetchPartnerCredentials(id);
      if (existingCredentials) {
        formik.setFieldValue("email", existingCredentials.email);
        formik.setFieldValue("password", existingCredentials.password);
        setCredentials(existingCredentials);
      } else {
        const email = `${colaborador?.curp.toLowerCase()}@chiliguajili.com`;
        const newCredentials = await savePartnerCredentials({
          email: email,
          partnerId: id,
        });
        formik.setFieldValue("email", newCredentials.email);
        formik.setFieldValue("password", newCredentials.password);
        setCredentials(newCredentials);
      }
    } catch (error) {
      setCredentials(null);
      if (error.message === "No existe un usuario") {
        const email = `${colaborador?.curp.toLowerCase()}@chiliguajili.com`;
        const newCredentials = await savePartnerCredentials({
          email: email,
          partnerId: id,
        });
        formik.setFieldValue("email", newCredentials.email);
        formik.setFieldValue("password", newCredentials.password);
        setCredentials(newCredentials);
        return;
      }

      notification(
        "Error",
        "Ocurrió un error al obtener sus credenciales",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: SaveCredentialsSchema,
    onSubmit: () => {},
  });

  React.useEffect(() => {
    fetchCredentials();
  }, []);

  return (
    <React.Fragment>
      <Loader isLoading={loading} />
      <div className="d-flex flex-column">
        <CustomInput
          formik={formik}
          field="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          sm={8}
          smLabel={4}
          disabled
        />
        <CustomInput
          formik={formik}
          field="password"
          label="Contraseña"
          placeholder="Contraseña"
          sm={8}
          smLabel={4}
          disabled
        />
        <div className="mx-auto mb-3">
          <button
            className="btn btn-amarillo ms-3"
            onClick={() => {
              notification(
                "Copiado",
                "Accesos copiada al portapapeles",
                "success"
              );
              navigator.clipboard.writeText(
                `Para acceder a tu cuenta en Chiliguajili, utiliza los siguientes datos en ${REACT_APP_APP_URL}/login : \n\n` +
                  `Correo electrónico: ${formik.values.email}\n` +
                  `Contraseña: ${formik.values.password}` +
                  "\n\n¡Gracias por ser parte de Chiliguajili!"
              );
            }}
          >
            <i className="fa-solid fa-copy"></i> Copiar al portapapeles
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-azul"
          onClick={() => {
            if (!loading && !credentials) {
              return formik.handleSubmit();
            }
            closeFn();
          }}
        >
          {!loading && !credentials ? "Generar claves" : "Aceptar"}
        </button>
      </div>
    </React.Fragment>
  );
};

export default GetCredentials;
