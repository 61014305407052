import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { setQuery, setShowModal, setShowModalEdit } from '../../store/slices/vacantesSlice.ts';
import { readBusinessNames } from '../../services/businessName.service.ts';
import { notification } from '../../utils/Notifications.tsx';

const FilterVacantes = () => {
  const dispatch = useDispatch();
  const initialValues = {
    type: "all",
    param: "",
  }
  const [filters, setFilters] = React.useState(initialValues);
  const [bussinessName, setBussinessName] = React.useState<any>();
  const [timer, setTimer] = React.useState<any>(null);
  const [showPills, setShowPills] = React.useState(false);

  const fetchDependencies = async () => {
    try {
      const bn = await readBusinessNames();
      setBussinessName(bn);
    } catch (error) {
      notification ("Error", "Ocurrió un error al obtener los filtros", "danger");
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, param: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, param: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const filtros = { ...filters, ...params };
    const queryString = Object.keys(filters)
      .filter((key) => filtros[key] !== -1 && filtros[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filtros[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "param":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "type":
            keyName = "Razón social";
            valueName = value;
            break;
          case "active":
            keyName = "Activo";
            valueName = value === "true" ? "Si" : "No";
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  }

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "" && updatedFilters[key] !== "Todos")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(updatedFilters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  useEffect(() => {
    fetchDependencies()
  }, []);

  return (
    <div className='w-100'>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Razón social:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            value={filters.type}
          >
            <option value={"all"}>Todos</option>
            {
              bussinessName?.map((item) => (
                <option key={item.id} value={item.name}>{item.name}</option>
              ))
            }
          </select>
          <label className='mt-3'>Activo:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            value={filters.type}
          >
            <option value={"all"}>Todos</option>
            <option value={"true"}>Si</option>
            <option value={"false"}>No</option>
          </select>

          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    type: "all",
                    param: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un nombre..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.param}
            />
            <button
              className="btn btn-naranja ms-2"
              onClick={() => {
                dispatch(setShowModal(true));
                dispatch(setShowModalEdit(false));
              }}
            >
              Agregar vacante
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3 w-100">
        {
          showPills && selectedFilters(filters).map((filter: any) => (
            <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
              {filter.keyName}: {filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
                aria-label="Close"
                onClick={() => removeFilter(filter.key)}
              ></button>
            </div>
          ))
        }
      </div>
    </div>

  )
}

export default FilterVacantes