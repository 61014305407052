import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import EmptyImage from '../../assets/img/ic_content.svg';

type Props = {}

const TablePlantilla = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { templates } = useSelector((state: RootState) => state.branchTemplates);

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Nombre</th>
            <th colSpan={35}></th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {templates && templates.length > 0 ? (
            templates.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td colSpan={35}></td>
                <td>
                  <a
                    className="btn btn-rojo m-2"
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-circle-xmark"></i>
                  </a>
                  <a
                    className="btn btn-primary m-2"
                    onClick={() => {
                      navigate(`editar/${item.id}`);
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={38}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no existen plantillas</h5>
                  <h5 className='text-center text-secondary fw-400 fs-15px'>Prueba añadiendo una en</h5>
                  <h5 className='text-center text-secondary fw-400'>el botón de 'Agregar plantilla'</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TablePlantilla