import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BranchTemplate } from "../../models/BranchTemplate";

const initialState = {
  total: 0,
  templates: [] as BranchTemplate[],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  query: "",
  fetch: false,
  showModal: false,
  currentRecord: {
    id: -1,
    name: "",
    numberOfWorkers: 0,
    branch: {
      id: -1,
      name: "",
    },
    position: {
      id: -1,
      name: "",
    },
    shift: [],
  },
  deleteModal: false,
  positionsOriginal: [] as any[],
  positions: [] as any[],
};

const branchTemplateSlice = createSlice({
  name: "branchTemplate",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setTemplates: (state, action: PayloadAction<BranchTemplate[]>) => {
      state.templates = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setCurrentRecord: (state, action: PayloadAction<any>) => {
      state.currentRecord = action.payload;
    },
    setDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.deleteModal = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      state.total = 0;
      state.templates = [];
      state.page = 0;
      state.limit = 10;
      state.loading = true;
      state.param = "";
      state.query = "";
      state.fetch = false;
      state.showModal = false;
      state.currentRecord = {
        id: -1,
        name: "",
        numberOfWorkers: 0,
        branch: {
          id: -1,
          name: "",
        },
        position: {
          id: -1,
          name: "",
        },
        shift: [],
      };
      state.deleteModal = false;
    },
    setPositions: (state, action: PayloadAction<any[]>) => {
      state.positions = action.payload;
    },
    setPositionsOriginal: (state, action: PayloadAction<any[]>) => {
      state.positionsOriginal = action.payload;
    },
  },
});

export const {
  setPage,
  setLimit,
  setTemplates,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setShowModal,
  setCurrentRecord,
  setDeleteModal,
  resetState,
  setQuery,
  setPositions,
  setPositionsOriginal,
} = branchTemplateSlice.actions;

export default branchTemplateSlice.reducer;