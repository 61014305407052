import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/form.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings.js";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import { PostulanteService } from "../../services/postulantes.service.js";
import { useReactToPrint } from "react-to-print";
import Print from "./Print.tsx";
import {
  getCandidateByApplication,
  updateApplicationForm,
  upgradeCollaborator,
} from "../../services/application.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import {
  findBranchesByVacancie,
  getVacancies,
} from "../../services/vacancy.service.ts";
import { getSchoolings } from "../../services/schooling.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import { readApplicationStatuses } from "../../services/applicationStatuses.service.ts";
import { readReason } from "../../services/reasons.service.ts";
import S3Service from "../../services/s3.service.ts";
import { getPeriodicities } from "../../services/periodicty.service.ts";
import { readBranches } from "../../services/branch.service.ts";
import { readSizes } from "../../services/size.service.ts";
import ModalContainer from "../../components/ModalContainer.tsx";
import UpgradeToCollaborator from "./UpgradeToCollaborator.tsx";
import { useSelector } from "react-redux";
import ConfettiExplosion from "react-confetti-explosion";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

function UpdateCollaborator({ location }) {
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const { user } = useSelector((state) => state.auth);

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [redirect, setRedirect] = useState(false);
  const [schoolings, setSchoolings] = useState([]);
  const [positions, setPositions] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [interviewDate, setInterviewDate] = useState(new Date());
  const [candidate, setCandidate] = useState({});

  const [perfilUrl, setPerfilUrl] = useState(null);
  const [ineUrl, setIneUrl] = useState(null);
  const [domicilioUrl, setDomicilioUrl] = useState(null);
  const [solicitudUrl, setSolicitudUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const [isDisabled, setIsDisabled] = useState(
    location.applicationStatus.name !== "Solicitado"
  );

  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    // Si es precolaborar buscar al candidato y devolver los datos y setear campos
    if (location.applicationStatus.name !== "Pre-Colaborador") {
      navigate("/");
    }
    if (location.applicationStatus.name === "Pre-Colaborador") {
      fetchCandidate();
    }

    // CatalogosService.getBranchesByPosition(
    //   location.candidate_id_position
    // ).then((data) => {
    //   setBranches(data);
    // });

    // CatalogosService.getSizes().then((data) => {
    //   setSizes(data);
    // });
    // CatalogosService.getPeriodicities().then((data) => {
    //   setPeriodicities(data);
    // });
    dependecies();

    fetchImages();

    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-4");
    context.handleSetAppTopMenu(true);

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
      context.handleSetAppTopMenu(false);
    };
    // eslint-disable-next-line
  }, []);

  const handleGenerarOrden = (event) => {
    event.preventDefault();
    setIsPrinting(true);
    window.print();
  };

  const updateSucursal = async (event) => {
    try {
      const response = await findBranchesByVacancie(event.target.value);
      setBranches(response);
    } catch (error) {}
    // let positionId;
    // console.log(event.target.value)
    // for (let index = 0; index < positions.length; index++) {
    //   if (positions[index].name == event.target.value) {
    //     positionId = positions[index].id;
    //     index = positions.length;
    //   }
    // }
    // CatalogosService.getBranchesByPosition(positionId).then((data) => {
    //   setBranches(data);
    // });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let operation;
    const body = {};
    // console.log(location.applicationStatus.name);

    body.positionId = Number(event.target.candidatePosition.value);
    body.periodicityId = Number(event.target.candidatePeriodicity.value);
    body.branchId = Number(event.target.candidateBranch.value);
    body.shiftId = Number(event.target.candidateShift.value);
    body.sizeId = Number(event.target.candidateSize.value);
    body.recruitmentDate = event.target.candidateRecruitmentDate.value;
    body.monthlySalary = Number(event.target.candidateMonthlySalary.value);
    body.comments = event.target.candidateComments.value;
    try {
      const response = await updateApplicationForm(location.id, body);
    } catch (error) {
      console.log("-----", error);
    }
    setRedirect(true);
    return;
    if (location.applicationStatus.name == "Aceptado") {
      operation = "complete_candidate_info";

      for (let index = 0; index < positions.length; index++) {
        if (positions[index].name == event.target.candidatePosition.value) {
          body.positionId = positions[index].id;
          index = positions.length;
        }
      }
      for (let index = 0; index < periodicities.length; index++) {
        if (
          periodicities[index].name == event.target.candidatePeriodicity.value
        ) {
          body.periodicityId = periodicities[index].id;
          index = periodicities.length;
        }
      }
      for (let index = 0; index < branches.length; index++) {
        if (branches[index].name == event.target.candidateBranch.value) {
          body.branchId = branches[index].id;
          index = branches.length;
        }
      }
      for (let index = 0; index < shifts.length; index++) {
        if (shifts[index].name == event.target.candidateShift.value) {
          body.shiftId = shifts[index].id;
          index = shifts.length;
        }
      }
      for (let index = 0; index < sizes.length; index++) {
        if (sizes[index].name == event.target.candidateSize.value) {
          body.sizeId = sizes[index].id;
          index = sizes.length;
        }
      }
    } else if (
      location.applicationStatus.name == "Aprobado para Contratación"
    ) {
    }
    await PostulanteService.updateApplication(location.id, operation, body);
    setRedirect(true);
  };

  const fetchCandidate = async () => {
    try {
      const response = await getCandidateByApplication(location.id);
      setCandidate(response);
    } catch (error) {
      console.log(error);
    }
  };

  if (redirect) {
    return <Navigate to="/solicitudes" />;
  }

  const handleClick = async () => {
    try {
      const response = await upgradeCollaborator(Number(location.id));
      notification("Listo ✅", response?.message, "success");
      // navigate("/colaboradores", { confetti: true });
      navigate(`/colaboradores/detalles/${response?.id}`, {
        state: { confetti: true },
      });
    } catch (error) {
      notification("Error", "Ah ocurrido un error", "danger");
    } finally {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 10000);
    }
  };

  const dependecies = async () => {
    try {
      const positions = await getVacancies();
      setPositions(positions);

      const schoolings = await getSchoolings();
      setSchoolings(schoolings);

      const shifts = await getShifts();
      setShifts(shifts);

      // const applicationStatuses = await readApplicationStatuses();
      // setApplicationStatuses(applicationStatuses);

      // const reasons = await readReason();
      // setRea(reasons);

      const periodicities = await getPeriodicities();
      setPeriodicities(periodicities);

      // const branchs = await readBranches();
      // setBranches(branchs);

      const sizes = await readSizes();
      setSizes(sizes);
    } catch (error) {
      notification("Ocurrio un error!", error.message, "danger");
      console.log(error);
    }
  };

  const fetchImages = async () => {
    try {
      const profileUrl = await S3Service.getFile(
        "PERFIL_" + location.person.curp
      );
      setPerfilUrl(profileUrl.url);
      const ineUrl = await S3Service.getFile("INE_" + location.person.curp);
      setIneUrl(ineUrl.url);
      const domicilioUrl = await S3Service.getFile(
        "DOMICILIO_" + location.person.curp
      );
      setDomicilioUrl(domicilioUrl.url);
      const solicitudUrl = await S3Service.getFile(
        "SOLICITUD_" + location.person.curp
      );
      setSolicitudUrl(solicitudUrl.url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit} className="fs-13px">
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              <div className="d-flex align-items-center">
                <div className="flex-1">Datos Generales</div>
                <div className="btn-group my-n1">
                  <a
                    href={`/postulante/historial/${location.person.curp}`}
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      navigate(`/postulante/historial/${location.person.curp}`)
                    }
                  >
                    <span class="badge bg-warning fs-12px">
                      Revisar historial <i class="fa-solid fa-file"></i>
                    </span>
                  </a>
                </div>
              </div>
            </PanelHeader>
            <PanelBody>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="firstName"
                      name="firstName"
                      placeholder="Nombre"
                      defaultValue={location.person.firstName}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="firstName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Nombre(s) *
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="lastName"
                      name="lastName"
                      placeholder="Apellido Paterno"
                      defaultValue={location.person.lastName}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="lastName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Paterno *
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="secondLastName"
                      name="secondLastName"
                      placeholder="Apellido Materno"
                      defaultValue={location.person.secondLastName}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="secondLastName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Materno *
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="date"
                      className="form-control fs-15px"
                      id="nacimiento"
                      name="nacimiento"
                      defaultValue={location.person.birthdate}
                      disabled={isDisabled}
                    />
                    <label
                      htmlFor="nacimiento"
                      className="d-flex align-items-center fs-13px"
                    >
                      Fecha de Nacimiento
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="(999) 999-9999"
                      defaultValue={location.person.phone}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="phone"
                      className="d-flex align-items-center fs-13px"
                    >
                      Teléfono
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="(999) 999-9999"
                      defaultValue={location.person.schooling.name}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="schoolingId"
                      className="d-flex align-items-center fs-13px"
                    >
                      Escolaridad *
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="direccion"
                      name="direccion"
                      defaultValue={location.person.address}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="direccion"
                      className="d-flex align-items-center fs-13px"
                    >
                      Domicilio (Calle, número, colonia, código postal)
                    </label>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>

          <Panel class="no-print">
            <PanelHeader noButton={true} className="bg-azul">
              Información de la solicitud
            </PanelHeader>
            <PanelBody>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Puesto: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={location.vacancy.position.name}
                    disabled={isDisabled}
                    required
                  />
                </div>
              </div>
              {/* <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Expectativa Salarial al Mes: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control fs-15px"
                    id="expectativaSalarial"
                    name="expectativaSalarial"
                    placeholder="Expectativa Salarial al Mes"
                    defaultValue={location.monthlySalary}
                    disabled={isDisabled}
                    min="0"
                    required
                  />
                </div>
              </div> */}
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Expectativa de Permanencia en Meses: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control fs-15px"
                    id="permanencia"
                    name="permanencia"
                    placeholder="Expectativa de permanencia en meses"
                    defaultValue={location.stayingMonths}
                    disabled={isDisabled}
                    min="0"
                    required
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Turno: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={location.shift.name}
                    disabled={isDisabled}
                    required
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Experiencia:</b>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="3"
                    className="form-control fs-15px"
                    id="experiencia"
                    name="experiencia"
                    defaultValue={location.experience}
                    disabled={isDisabled}
                    required
                  ></textarea>
                </div>
              </div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Documentación
            </PanelHeader>
            <PanelBody>
              <div className="row mt-4">
                <div className="col-md-3">
                  <label className="form-label col-form-label ">
                    Imagen de Perfil
                  </label>
                  <a href={perfilUrl}>
                    <img
                      src={perfilUrl}
                      alt="PROFILE"
                      style={{ display: "inline-block", width: "20vw" }}
                    />
                  </a>
                </div>
                <div className="col-md-3">
                  <label className="form-label col-form-label ">
                    Identificación Oficial
                  </label>
                  <a href={ineUrl}>
                    <img
                      src={ineUrl}
                      alt="INE"
                      style={{ display: "inline-block", width: "20vw" }}
                    />
                  </a>
                </div>
                <div className="col-md-3">
                  <label className="form-label col-form-label">
                    Comprobante de domicilio
                  </label>
                  <a href={domicilioUrl}>
                    <img
                      src={domicilioUrl}
                      alt="DOMICILIO"
                      style={{ display: "inline-block", width: "20vw" }}
                    />
                  </a>
                </div>
                <div className="col-md-3">
                  <label className="form-label col-form-label">
                    Solicitud de empleo
                  </label>
                  <a href={solicitudUrl}>
                    <img
                      src={solicitudUrl}
                      alt="SOLICITUD"
                      style={{ display: "inline-block", width: "20vw" }}
                    />
                  </a>
                </div>
              </div>
            </PanelBody>
          </Panel>
          {location.applicationStatus.name === "Pre-Colaborador" ? (
            <Panel>
              <PanelHeader noButton={true} className="bg-azul">
                Información del puesto
              </PanelHeader>
              <PanelBody>
                {!user.branch ? (
                  <>
                    {" "}
                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div
                          className="form-floating mb-3 mb-md-0"
                          data-date-start-date="Date.default"
                        >
                          <input
                            type="date"
                            className="form-control"
                            id="datepicker-autoClose"
                            name="candidateRecruitmentDate"
                            defaultValue={candidate.recruitmentDate}
                            placeholder="Select Date"
                            required
                            disabled
                          />
                          <label
                            htmlFor="datepicker-autoClose"
                            className="d-flex align-items-center fs-13px"
                          >
                            Fecha de ingreso
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate.vacancy?.position?.name}
                            placeholder="Puesto"
                            required
                            disabled
                          />
                          <label
                            htmlFor="position"
                            className="d-flex align-items-center fs-13px"
                          >
                            Puesto
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.periodicity?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="periodicity"
                            className="d-flex align-items-center fs-13px"
                          >
                            Periodicidad
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.monthlySalary}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="salario"
                            className="d-flex align-items-center fs-13px"
                          >
                            Salario
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.branch?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="sucursal"
                            className="d-flex align-items-center fs-13px"
                          >
                            Sucursal
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.shift?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="turno"
                            className="d-flex align-items-center fs-13px"
                          >
                            Turno
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.size?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="talla"
                            className="d-flex align-items-center fs-13px"
                          >
                            Talla
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.comments}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="comentarios"
                            className="d-flex align-items-center fs-13px"
                          >
                            Comentarios
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div
                          className="form-floating mb-3 mb-md-0"
                          data-date-start-date="Date.default"
                        >
                          <input
                            type="date"
                            className="form-control"
                            id="datepicker-autoClose"
                            name="candidateRecruitmentDate"
                            defaultValue={candidate.recruitmentDate}
                            placeholder="Select Date"
                            required
                            disabled
                          />
                          <label
                            htmlFor="datepicker-autoClose"
                            className="d-flex align-items-center fs-13px"
                          >
                            Fecha de ingreso
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate.vacancy?.position?.name}
                            placeholder="Puesto"
                            required
                            disabled
                          />
                          <label
                            htmlFor="position"
                            className="d-flex align-items-center fs-13px"
                          >
                            Puesto
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.branch?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="sucursal"
                            className="d-flex align-items-center fs-13px"
                          >
                            Sucursal
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.shift?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="turno"
                            className="d-flex align-items-center fs-13px"
                          >
                            Turno
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.size?.name}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="talla"
                            className="d-flex align-items-center fs-13px"
                          >
                            Talla
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3 mb-md-0">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={candidate?.comments}
                            placeholder="Periocidad"
                            required
                            disabled
                          />
                          <label
                            htmlFor="comentarios"
                            className="d-flex align-items-center fs-13px"
                          >
                            Comentarios
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </PanelBody>
            </Panel>
          ) : (
            <Panel>
              <PanelHeader noButton={true} className="bg-azul">
                Información del puesto
              </PanelHeader>
              <PanelBody>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div
                      className="form-floating mb-3 mb-md-0"
                      data-date-start-date="Date.default"
                    >
                      <input
                        type="date"
                        className="form-control"
                        id="datepicker-autoClose"
                        name="candidateRecruitmentDate"
                        defaultValue={location.candidate_recruitment_date}
                        placeholder="Select Date"
                        required
                      />
                      <label
                        htmlFor="datepicker-autoClose"
                        className="d-flex align-items-center fs-13px"
                      >
                        Fecha de ingreso
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        className="form-select"
                        onChange={updateSucursal}
                        id="candidatePosition"
                        name="candidatePosition"
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {positions.map((item) => {
                          // const selected =
                          //   location.vacancy.position.id == item.position.id
                          //     ? "selected"
                          //     : "";
                          return (
                            <option
                              key={item.id}
                              value={item.position.id}
                              // selected={selected}
                            >
                              {item.position.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="position"
                        className="d-flex align-items-center fs-13px"
                      >
                        Puesto
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        className="form-select"
                        id="candidatePeriodicity"
                        name="candidatePeriodicity"
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {periodicities.map((item) => {
                          // const selected =
                          //   location.candidate_id_periodicity == item.id
                          //     ? "selected"
                          //     : "";
                          return (
                            <option
                              key={item.id}
                              value={item.id}
                              // selected={selected}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="periodicity"
                        className="d-flex align-items-center fs-13px"
                      >
                        Periodicidad
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        type="text"
                        className="form-control fs-15px"
                        id="candidateMonthlySalary"
                        name="candidateMonthlySalary"
                        defaultValue={location.candidate_monthly_salary}
                        placeholder="salario"
                        required
                      />
                      <label
                        htmlFor="salario"
                        className="d-flex align-items-center fs-13px"
                      >
                        Salario
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        className="form-select"
                        id="candidateBranch"
                        name="candidateBranch"
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {branches.map((item) => {
                          // const selected =
                          //   location.candidate_id_branch == item.id
                          //     ? "selected"
                          //     : "";
                          return (
                            <option
                              key={item.branch.id}
                              value={item.branch.id}
                              // selected={selected}
                            >
                              {item.branch.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="sucursal"
                        className="d-flex align-items-center fs-13px"
                      >
                        Sucursal
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        className="form-select"
                        id="candidateShift"
                        name="candidateShift"
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {shifts.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="turno"
                        className="d-flex align-items-center fs-13px"
                      >
                        Turno
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        className="form-select"
                        id="candidateSize"
                        name="candidateSize"
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {sizes.map((item) => {
                          // const selected =
                          //   location.candidate_id_size == item.id
                          //     ? "selected"
                          //     : "";
                          return (
                            <option
                              key={item.id}
                              value={item.id}
                              // selected={selected}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="talla"
                        className="d-flex align-items-center fs-13px"
                      >
                        Talla
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        type="text"
                        className="form-control fs-15px"
                        id="candidateComments"
                        name="candidateComments"
                        defaultValue={location.candidate_comments}
                        placeholder="comentarios"
                        required
                      />
                      <label
                        htmlFor="comentarios"
                        className="d-flex align-items-center fs-13px"
                      >
                        Comentarios
                      </label>
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          )}

          <Panel>
            <PanelBody>
              <div className="row noGutters">
                <div
                  className="col-md-6"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {location.applicationStatus.name === "Pre-Colaborador" ? (
                    // <a
                    //   onClick={handlePrint}
                    //   className="btn btn-secondary h-45px w-75 btn-lg fs-14px"
                    // >
                    //   Generar orden de presentación
                    // </a>
                    <></>
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ display: "none" }}>
                  <Print
                    id={location.id}
                    ref={componentRef}
                    name={location.person.firstName}
                    lastName={location.person.lastName}
                    secondLastName={location.person.secondLastName}
                    birthdate={location.person.birthdate}
                    phone={location.person.phone}
                    escolarity={location.person.schooling.name}
                    address={location.person.address}
                    photo={perfilUrl}
                    entryDate={candidate?.recruitmentDate}
                    position={candidate.vacancy?.position?.name}
                    periodicity={candidate?.periodicity?.name}
                    salary={candidate?.monthlySalary}
                    branch={candidate?.branch?.name}
                    shift={candidate?.shift?.name}
                    size={candidate?.size?.name}
                    comments={candidate?.comments}

                    children={location?.state?.children}
                    health={location?.state?.health}
                    diseases={location?.state?.diseases}
                  />
                </div>

                <div
                  className="col-md-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {location.applicationStatus.name === "Pre-Colaborador" ? (
                    <a
                      // onClick={handleClick}
                      onClick={() => setShowModal(true)}
                      className="btn btn-amarillo h-45px w-75 btn-lg fs-14px"
                    >
                      Aprobar
                    </a>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-amarillo h-45px w-75 btn-lg fs-14px"
                      // onClick={(e) => handleSubmit(e)}
                    >
                      Guardar
                    </button>
                  )}
                </div>
              </div>
            </PanelBody>
          </Panel>
        </form>
      </div>
      <div className="h-75px"></div>
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title={`Hacer colaborador a ${toCamelCase(location.person.firstName + ' ' +  location.person.lastName)}`}
        children={
          <UpgradeToCollaborator
            id={location.person.id}
            name={`${toCamelCase(location.person.firstName + ' ' +  location.person.lastName)}`}
            func={() => handleClick()}
            closeFn={() => setShowModal(false)}
          />
        }
      />
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          zIndex: 1,
        }}
      />
    </div>
  );
}

export default UpdateCollaborator;
