import React from "react";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import { useFormik } from "formik";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { AddDocSchema } from "../../validation/schemas/AddDocSchema.ts";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";

const AddResponsability = ({
  formik,
  closeFn,
  originalField,
}: IFormikProps & {
  closeFn: () => void;
  originalField: string;
}) => {
  const formikDoc = useFormik({
    initialValues: {
      text: "",
    },
    onSubmit: (values) => {
      const valuesMap = values.text;
      const originalArray = formik.values[originalField];

      if(originalArray.includes(valuesMap)) {
        notification("Duplicado", "La responsabilidad ya existe", "info");
        closeFn();
        return;
      }

      originalArray.push(valuesMap);
      formik.setFieldValue(originalField, originalArray);
      closeFn();
      console.log("values", values);
    },
  });

  return (
    <React.Fragment>
      <CustomInput
        formik={formikDoc}
        field="text"
        label="Responsabilidad"
        type="text"
        sm={8}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formikDoc.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddResponsability;
