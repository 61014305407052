import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PerfectScrollbar from "react-perfect-scrollbar";
import Layout from "../../components/Layout.tsx";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { Application } from "../../models/Application";
import { notification } from "../../utils/Notifications.tsx";
import { fetchApplicationByDate } from "../../services/application.service.ts";
import ChipStatus from "../../components/history/ChipStatus.tsx";
import moment from "moment";
import S3Service from "../../services/s3.service.ts";
import { getMissingIMSSSalary } from "../../services/candidate.service.ts";
import { checkAllPending } from "../../services/changePosition.service.ts";
import { ChangePosition } from "../../models/ChangePosition.ts";
import ChangePositionRow from "../../components/dashboard/ChangePositionRow.tsx";
import NoIMSSRow from "../../components/dashboard/NoIMSSRow.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import EditSalary from "../../components/colaboradores/EditSalary.tsx";
import { setModalAcountant } from "../../store/slices/colaboradoresSlice.ts";
import { Partner } from "../../models/Partner.ts";
import { fetchPartnersIncompleted } from "../../services/partner.service.ts";
import { IncompletedPartner } from "../../models/IncompletedPartner.ts";
import CompletePartner from "../../components/forms/CompletePartner.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const AdminDashboard = ({ rol }) => {
  const navigate = useNavigate();
  const [applications, setApplications] = React.useState<Application[]>([]);
  const [noIMSS, setNoIMSS] = React.useState<Partner[]>([]);
  const [changePositionPending, setChangePositionPending] = React.useState<
    ChangePosition[]
  >([]);
  const [incompletedPartners, setIncompletedPartners] = React.useState<
    IncompletedPartner[]
  >([]);
  const [value, setValue] = React.useState(0);


  const [imageUrls, setImageUrls] = React.useState<any[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [currentIncompletedPartner, setCurrentIncompletedPartner] =
    React.useState<IncompletedPartner>({} as IncompletedPartner);

  React.useEffect(() => {
    fetchApplications();
    fetchNoIMSS();
    fetchPendingPartners();
  }, []);

  const fetchApplications = async () => {
    setLoading(true);
    // const date = new Date();

    try {
      const date = new Date().toISOString().split("T")[0];
      const response = (await fetchApplicationByDate(date)) as Application[];

      for (const item of response) {
        const profileUrl = await S3Service.getFile(
          "PERFIL_" + item?.person?.curp
        );
        item.person.image = profileUrl?.url;
      }
      setApplications(response);
      const initialImageUrls = response.map(applications => applications?.person?.image || "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg");
      setImageUrls(initialImageUrls);

      await fetchChangePositionPending();
    } catch (error) {
      notification(
        "Ocurrió un error",
        "Error al obtener las solicitudes",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchChangePositionPending = async () => {
    try {
      const response = await checkAllPending();
      setChangePositionPending(response);
    } catch (error) {
      notification("Error", "Error al obtener las solicitudes", "danger");
    }
  };

  const fetchPendingPartners = async () => {
    setLoading(true);
    try {
      const response = await fetchPartnersIncompleted();
      console.log(response);
      setIncompletedPartners(response);
    } catch (error) {
      console.log(error);
      notification("", "Error al obtener los colaboradores", "danger");
    } finally {
      setLoading(false);
    }
  };

  const fetchNoIMSS = async () => {
    setLoading(true);

    try {
      const response = await getMissingIMSSSalary();
      setNoIMSS(response);
    } catch (error) {
      notification(
        "Ocurrió un error",
        "Error al obtener las solicitudes",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: string = String(new Date())) => {
    const formattedDate = moment(date).format("MMMM D [del] YYYY");
    return formattedDate.replace(/^(.)/, (match) => match.toUpperCase());
  };

  function handleClick(application, disabledForm = false) {
    if (
      application.applicationStatus.name == "Solicitado" ||
      application.applicationStatus.name == "Entrevistado"
    ) {
      navigate("/result/form", { state: { ...application, disabledForm } });
    } else {
      // navigate("/postulante/form", { state: application });
      navigate("/solicitudes/aprobar/" + application.id);
    }
  }

  const { currentUser, modalAcountant } = useSelector(
    (state: RootState) => state.colaboradores
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const handleImageError = (index) => {
    const newImageUrls = [...imageUrls] as any[];
    newImageUrls[index] = "https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg";
    setImageUrls(newImageUrls);
  };

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <div style={{ padding: "20px" }}>
          <ol className="breadcrumb float-xl-end">
            <li className="breadcrumb-item">
              <Link to="/">Inicio</Link>
            </li>
          </ol>
          <h1 className="page-header">
            Tablero <small>Resumen de los últimos eventos</small>
          </h1>

          {Boolean(
            rol === "Administrador" ||
            rol === "Reclutador" ||
            rol === "Talento humano" ||
            rol === "Lider de sucursal"
          ) && (
              <div>
                <Panel>
                  <PanelHeader noButton={true}>Pendientes</PanelHeader>
                  <PanelBody>
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Solicitudes" {...a11yProps(0)} />
                          {rol !== "Lider de sucursal" && <Tab label="Cambios de posición" {...a11yProps(1)} />}
                          {rol !== "Lider de sucursal" && <Tab label="Perfiles incompletos" {...a11yProps(2)} />}
                          {rol === "Lider de sucursal" && <Tab label="Cambios de posición entrantes" {...a11yProps(4)} />}
                          {rol === "Lider de sucursal" && <Tab label="Cambios de posición salientes" {...a11yProps(5)} />}
                          {(rol === "Contador" || rol === "Administrador") && (
                            <Tab label="Colaboradores sin IMSS" {...a11yProps(3)} />
                          )}
                        </Tabs>
                      </Box>
                      <PerfectScrollbar className="h-500px" options={{ suppressScrollX: true }}>
                        <CustomTabPanel value={value} index={0}>
                          {applications.map((application, index) => (
                            <React.Fragment key={index}>
                              <div className="d-flex">
                                <Link to="/" className="w-60px">
                                  <img
                                    src={imageUrls[index]}
                                    onError={() => handleImageError(index)}
                                    alt=""
                                    className="mw-100 rounded-pill"
                                  />
                                </Link>
                                <div className="flex-1 ps-3">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5>
                                      {toCamelCase(application.person.firstName)}{" "}
                                      {toCamelCase(application.person.lastName)}{" "}
                                      {toCamelCase(application.person.secondLastName)}
                                    </h5>
                                    <ChipStatus
                                      status={application.applicationStatus.name as any}
                                      position={
                                        application.candidates[0]?.recruitmentDate
                                          ? application.candidates[0]?.vacancy.position.name
                                          : application?.vacancy?.position?.name
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p>
                                      {application.candidates[0]?.recruitmentDate
                                        ? `Entrada: ${formatDate(application.candidates[0]?.recruitmentDate)}`
                                        : `Entrevista: ${formatDate(application.applicationDate)} - ${application.applicationTime.slice(0, 5)}`}
                                    </p>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => handleClick(application)}
                                    >
                                      Revisar postulación
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr className="bg-gray-500" />
                            </React.Fragment>
                          ))}
                          {applications.length === 0 && (
                            <div className="text-center">
                              <h4>No hay solicitudes pendientes</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          {changePositionPending.map((changePosition, index) => (
                            <React.Fragment key={index}>
                              <ChangePositionRow changePosition={changePosition} />
                            </React.Fragment>
                          ))}
                          {changePositionPending.length === 0 && (
                            <div className="text-center">
                              <h4>No hay cambios de posición pendientes</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                          {incompletedPartners.map((partner, index) => (
                            <React.Fragment key={index}>
                              <div className="d-flex">
                                <Link to="/" className="w-60px">
                                  <img
                                    src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                                    alt=""
                                    className="mw-100 rounded-pill"
                                  />
                                </Link>
                                <div className="flex-1 ps-3">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5>
                                      {toCamelCase(partner.person.firstName)}{" "}
                                      {toCamelCase(partner.person.lastName)}{" "}
                                      {toCamelCase(partner.person.secondLastName)}
                                    </h5>
                                    <ChipStatus
                                      status="Aceptado"
                                      position={partner?.candidate?.vacancy?.position?.name}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <p>Sin fecha de entrada</p>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        setCurrentIncompletedPartner(partner);
                                        setOpen(true);
                                      }}
                                    >
                                      Completar información
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr className="bg-gray-500" />
                            </React.Fragment>
                          ))}
                          {incompletedPartners.length === 0 && (
                            <div className="text-center">
                              <h4>No hay colaboradores incompletos</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                          {noIMSS.map((partner, index) => (
                            <NoIMSSRow key={index} partner={partner} />
                          ))}
                          {noIMSS.length === 0 && (
                            <div className="text-center">
                              <h4>No hay colaboradores sin salario de IMSS asignado</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                          {changePositionPending
                            .filter((changePosition) => changePosition?.newBranch?.name === user.branch)
                            .map((changePosition, index) => (
                              <React.Fragment key={index}>
                                <ChangePositionRow changePosition={changePosition} />
                              </React.Fragment>
                            ))}
                          {changePositionPending.length === 0 && (
                            <div className="text-center">
                              <h4>No hay cambios de posición pendientes</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                          {changePositionPending
                            .filter((changePosition) => changePosition?.oldBranch?.name === user.branch)
                            .map((changePosition, index) => (
                              <React.Fragment key={index}>
                                <ChangePositionRow changePosition={changePosition} />
                              </React.Fragment>
                            ))}
                          {changePositionPending.length === 0 && (
                            <div className="text-center">
                              <h4>No hay cambios de posición pendientes</h4>
                            </div>
                          )}
                        </CustomTabPanel>
                      </PerfectScrollbar>
                    </Box>
                  </PanelBody>

                </Panel>
              </div>
            )}
        </div>
      </Layout>
      <ModalContainer
        title={`Completar información de ${toCamelCase(currentIncompletedPartner?.person?.firstName)} ${toCamelCase(currentIncompletedPartner?.person?.lastName)} ${toCamelCase(currentIncompletedPartner?.person?.secondLastName)}`}
        open={open}
        closeFn={() => {
          setOpen(false);
          setCurrentIncompletedPartner({} as IncompletedPartner);
        }}
        children={
          <CompletePartner
            partner={currentIncompletedPartner}
            close={() => {
              setOpen(false);
              setCurrentIncompletedPartner({} as IncompletedPartner);
            }}
            refetch={() => fetchPendingPartners()}
          />
        }
        size="lg"
      />
    </React.Fragment >
  );
};

export default AdminDashboard;
