import httpClient from "./httpClient";

const prefix = "/candidates";

export async function updateSalary(id: number, data: {}) {
  return (await httpClient.put(`${prefix}/update-salary/${id}`, data)).data;
}

export async function getMissingIMSSSalary() {
  return (await httpClient.get(`${prefix}/missing-IMSS-salary`)).data;
}