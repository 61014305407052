import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Shift } from "../../models/Shift.ts";

const initialState = {
  total: 0,
  turnos: [] as Shift[],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  query: "",
  fetch: false,
  showModal: false,
  currentRecord: {
    id: -1,
    name: "",
  },
  showEditModal: false,
  record: {},
  showDeleteModal: false,
};

const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setTurnos: (state, action: PayloadAction<Shift[]>) => {
      state.turnos = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
      state.page = 0;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setCurrentRecord: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      state.currentRecord = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    setShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
    setRecord: (state, action: PayloadAction<any>) => {
      state.record = action.payload;
    }
  },
});

export const {
  setPage,
  setLimit,
  setTurnos,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  setShowModal,
  setCurrentRecord,
  setShowEditModal,
  setShowDeleteModal,
  setRecord,
} = shiftSlice.actions;

export default shiftSlice.reducer;
