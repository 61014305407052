import React from 'react'
import Layout from '../../components/Layout.tsx';
import NavBar from '../puestos/components/NavBar.tsx';
import PlantillasComponent from '../puestos/components/PlantillasComponent.tsx';
import { useAppSettings } from '../../hooks/useAppSettings.tsx';
import Title from '../../components/Title.tsx';

type Props = {}

const Plantillas = (props: Props) => {
  useAppSettings();
  return (
    <Layout>
      <Title baseTitle="Plantillas" basePath="/plantillas" title="Plantillas" />
      <PlantillasComponent />
    </Layout >
  );
}

export default Plantillas