import React, { ReactNode } from "react";

const Button: React.FC<ButtonProps> = ({ onClick, title, type, variant, size = 'lg' }) => {
  return (
    <button
      type={type}
      className={`btn btn-${variant} d-block h-${size === 'lg' ? '50' : '30'}px w-100 btn-${size} fs-${size === 'lg' ? '14' : '12'}px `}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Button;

interface ButtonProps {
  onClick: () => void;
  title: string | ReactNode;
  type: "submit" | "reset" | "button" | undefined;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "azul";
  size?: "sm" | "lg";
}
