
import React from "react";
import { useNavigate } from "react-router-dom";
import IFormikProps from "../../interfaces/others/IFormikProps";
import CustomTextField from "../formik/CustomTextField.tsx";
import SelectRoles from "../roles/SelectRoles.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import Button from "../Button.tsx";
import CustomInput from "../formik/CustomInput.tsx";
import { PanelBody } from "../panel/panel.jsx";
import SelectBranch from "../branch/SelectBranch.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";

const UserForm = ({ formik }: IFormikProps) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div>
      <PanelBody>
        <CustomInput
          formik={formik}
          field="name"
          label="Nombre(s)"
          placeholder="Nombre(s)"
          sm={3}
        />
        <CustomInput
          formik={formik}
          field="last_name"
          label="Apellido paterno"
          placeholder="Apellido paterno"
          sm={3}
        />
        <CustomInput
          formik={formik}
          field="second_last_name"
          label="Apellido materno"
          placeholder="Apellido materno"
          sm={3}
        />

        <CustomInput
          formik={formik}
          field="email"
          label="Correo electrónico"
          placeholder="Correo electrónico"
          sm={3}
        />
        <CustomInput
          formik={formik}
          field="phone"
          label="Teléfono"
          placeholder="Teléfono"
          sm={3}
        />
        <CustomInput
          formik={formik}
          field="password"
          label="Contraseña"
          placeholder="Contraseña"
          type="password"
          sm={3}
        />
        <div
          style={{
            display:
              Number(user.id) === 1 && Number(formik.values.id) === 1
                ? "none"
                : "block",
          }}
        >
          <SelectRoles formik={formik} field="id_rol" />
        </div>
        <div
          style={{
            display:
              formik.values.id_rol === "1" ||
              formik.values.id_rol === -99 ||
              formik.values.id_rol === 1 ||
              formik.values.id_rol === 3 ||
              formik.values.id_rol === "3"

                ? "none"
                : "block",
          }}
        >
          <SelectBranch formik={formik} field="id_branch" />
        </div>
        <CustomCheckboxField formik={formik} field="status" />
      </PanelBody>
    </div>
  );
};

export default UserForm;
