import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetch, setShowModalSucursal } from "../../store/slices/puestosSlice.ts";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { RootState } from "../../store/index.ts";
import { SucursalesSchema } from "../../validation/schemas/SursalesSchema.ts";
import { DefaultBranch } from "../../interfaces/formik/ISucursales.ts";
import { saveBranch } from "../../services/branch.service.ts";
import { BusinessNames } from "../../models/BusinessNames.ts";
import { readBusinessNames } from "../../services/businessName.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";


const NewRecordBranch = () => {
    const { fetch } = useSelector((state: RootState) => state.puestos) || { fetch: false };
    const dispatch = useDispatch();
    const [businessNameList, setBusinessNameList] = React.useState<BusinessNames[]>([]);


    const formik = useFormik({
        validationSchema: SucursalesSchema,
        initialValues: DefaultBranch,
        onSubmit: async (values) => {
            try {
                const response = await saveBranch(values);
                notification("", "Sucursal guardado con exito", "info");
                dispatch(setShowModalSucursal(false));
                dispatch(setFetch(!fetch));
            } catch (error) {
                console.log("error", error);
                notification("Error", error.message, "danger");
            }
        },
    });

    const getDependencies = async () => {
        try {
            const businessNames = await readBusinessNames();
            setBusinessNameList(businessNames);
        } catch (error) {
            notification("Error ⚠️", "Error al obtener las dependencias", "danger");
        }
    };

    React.useEffect(() => {
        getDependencies();
    }, []);


    return (
        <React.Fragment>
            <CustomInput
                formik={formik}
                field="name"
                label="Surcursal "
                placeholder="Sucursal"
                sm={9}
            />

            <CustomSelectField
                formik={formik}
                field="idBusinessName"
                list={businessNameList}
                label="Razón Social"

            />



            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-secondary me-2"
                    onClick={() => dispatch(setShowModalSucursal(false))}
                >
                    Cancelar
                </button>
                <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
                    Guardar Sucursal
                </button>
            </div>
        </React.Fragment>
    );
};

export default NewRecordBranch;
