import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { readPartnersByBranch } from '../../../services/partner.service.ts';
import moment from 'moment';
import { findByExactDateAndBranch, readHoursByBranch, readPositionsByBranchAndShift } from '../../../services/branchSchedule.service.ts';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import { generateTimeIntervals } from '../../../utils/constanst.js';
import Title from '../../Title.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index.ts';
import { toCamelCase } from '../../../hooks/toCameCase.tsx';

const ResumenHorarios = () => {
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }
  
  useAppSettings();
  const [schedules, setSchedules] = React.useState<any[]>([]);
  const [positions, setPositions] = React.useState<any[]>([]);
  const [hours, setHours] = React.useState<any[]>([]);
  const [branchHours, setBranchHours] = React.useState<any[]>([]);
  const [hoursRange, setHoursRange] = React.useState<any>();

  const fetchDependencies = async () => {
    const today = moment().format('YYYY-MM-DD');

    const schedules = await findByExactDateAndBranch(today, Number(id));
    const branchHours = await readHoursByBranch(Number(id));
    setBranchHours(branchHours);



    if(!branchHours) return;

    const hoursArray = await generateTimeIntervals(branchHours?.matutinoStart, branchHours?.vespertinoEnd);

    const positions = await readPositionsByBranchAndShift(Number(id), 'Todos');
    const positionsArray = positions.map((position) => {
      const totalWorkersRequired = position.numberOfWorkers;

      const workers: any = [];

      for (let i = 0; i < totalWorkersRequired; i++) {
        workers.push({
          id: i,
          positionId: position.position.id,
          positionName: position.position.name + ' ' + (i + 1),
          originalPositionName: position.position.name,
          shift: position.shift,
        });
      }

      return workers
    });

    const sortedByNames = positionsArray.flat().sort((a, b) => {
      return a.positionName.localeCompare(b.positionName);
    });

    const sortOrder = ['Matutino', 'Intermedio', 'Vespertino'];

    const sorted = sortedByNames.sort((a, b) => {
      return sortOrder.indexOf(a.shift) - sortOrder.indexOf(b.shift);
    });

    setPositions(sorted);

    const shiftOrder = ['Matutino', 'Intermedio', 'Vespertino'];
    const schedulesSortedByShift = schedules.sort((a, b) => {
      return shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
    });

    const schedulesGroupedByShift = groupHoursByShift(hoursArray, branchHours);
    setHoursRange(schedulesGroupedByShift);

    setHours(hoursArray);

    setSchedules(schedulesSortedByShift);
  }

  const isHourInRange = (startHour: string, endHour: string, hour: string): boolean => {
    const format = 'HH:mm';
    const start = moment(startHour, format);
    const end = moment(endHour, format);
    const checkHour = moment(hour, format);

    if (start.isAfter(end)) {
      return checkHour.isBetween(start, moment('23:59', format), undefined, '()') ||
        checkHour.isBetween(moment('00:00', format), end, undefined, '()');
    }

    return checkHour.isBetween(start, end, undefined, '(]');  // Cambiado a '[]' para inclusión
  };

  useEffect(() => {
    fetchDependencies();
  }, [])

  const calculateColorRange = (scheduleShift, hour) => {
    const startHour = scheduleShift === 'Matutino' ? branchHours.matutinoStart : scheduleShift === 'Intermedio' ? branchHours.intermedioStart : branchHours.vespertinoStart;
    const endHour = scheduleShift === 'Matutino' ? branchHours.matutinoEnd : scheduleShift === 'Intermedio' ? branchHours.intermedioEnd : branchHours.vespertinoEnd;

    console.log(startHour, endHour, hour);
    
    if (isHourInRange(startHour.substring(0, 5), endHour.substring(0, 5), hour)) {
      return '#F6FB7A';
    } else {
      return '#75869444';
    }
  }

  type TimeSlot = { key: number; display: string };
  type ShiftTimes = {
    matutinoStart: string;
    matutinoEnd: string;
    intermedioStart: string;
    intermedioEnd: string;
    vespertinoStart: string;
    vespertinoEnd: string
  };

  type ShiftResult = {
    matutino: TimeSlot[];
    intermedio: TimeSlot[];
    vespertino: TimeSlot[];
  };

  const groupHoursByShift = (timeSlots: TimeSlot[], shifts: ShiftTimes): ShiftResult => {
    const format = 'HH:mm:ss';

    const result: ShiftResult = {
      matutino: [],
      intermedio: [],
      vespertino: [],
    };

    timeSlots.forEach((slot) => {
      const [startHour, endHour] = slot.display.split(' - ').map(time => `${time}:00`);

      const start = moment(startHour, format);
      const end = moment(endHour, format);

      if (start.isBetween(moment(shifts.matutinoStart, format), moment(shifts.matutinoEnd, format), undefined, '()') ||
        end.isBetween(moment(shifts.matutinoStart, format), moment(shifts.matutinoEnd, format), undefined, '()')) {
        result.matutino.push(slot);
      }

      if (start.isBetween(moment(shifts.intermedioStart, format), moment(shifts.intermedioEnd, format), undefined, '()') ||
        end.isBetween(moment(shifts.intermedioStart, format), moment(shifts.intermedioEnd, format), undefined, '()')) {
        result.intermedio.push(slot);
      }

      if (start.isBetween(moment(shifts.vespertinoStart, format), moment(shifts.vespertinoEnd, format), undefined, '()') ||
        end.isBetween(moment(shifts.vespertinoStart, format), moment(shifts.vespertinoEnd, format), undefined, '()')) {
        result.vespertino.push(slot);
      }
    });

    return result;
  };

  const getIntermedioIndex = () => {
    let intermedioIndex = 0;

    if (
      hoursRange.matutino[hoursRange.matutino.length - 1].display.split(' - ')[1] ===
      hoursRange.intermedio[0].display.split(' - ')[0]
    ) {
      intermedioIndex = hoursRange.matutino.findIndex((hour) => hour.display.split(' - ')[1] === hoursRange.intermedio[0].display.split(' - ')[0]) + 1;
    } else {
      intermedioIndex = hoursRange.matutino.indexOf(hoursRange.intermedio[0]);
    }
    return intermedioIndex;
  }

  const getVespertinoIndex = () => {
    let vespertinoIndex = 0;
    if (
      hoursRange.matutino[hoursRange.matutino.length - 1].display.split(' - ')[1] ===
      hoursRange.intermedio[0].display.split(' - ')[0]
    ) {
      vespertinoIndex = hoursRange.matutino.length + hoursRange.intermedio.length;
    } else {
      vespertinoIndex = getIntermedioIndex() + hoursRange.intermedio.findIndex((hour) => hour.display.split(' - ')[1] === hoursRange.vespertino[0].display.split(' - ')[0]) + 1;
    }
    return vespertinoIndex;
  }

  if (schedules.length === 0) return (
    <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
      <div className="alert alert-warning" role="alert">
        <h4 className="alert-heading">¡Atención!</h4>
        <p>
          La sucursal no cuenta con un horario establecido.
        </p>
        <hr />
        <p className="mb-0">
          Por favor, completa el horario de la sucursal para poder ver los horarios de los trabajadores.
        </p>
      </div>
    </div>
  )

  return (
    <Layout>
      <Title baseTitle="Resumen de horarios" basePath="/Resumen de horarios" title="Resumen de horarios" />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <div className="table-responsive mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th className="border text-center" colSpan={hoursRange.matutino.length}>
                      Matutino
                    </th>
                  </tr>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th
                      className="border text-center"
                      colSpan={getIntermedioIndex()}
                    ></th>
                    <th className="border text-center" colSpan={hoursRange.intermedio.length}>
                      Intermedio
                    </th>
                  </tr>
                  <tr>
                    <th className="border-end text-center"></th>
                    <th
                      className="border text-center"
                      colSpan={getVespertinoIndex()}
                    ></th>
                    <th className="border text-center" colSpan={hoursRange.vespertino.length}>
                      Vespertino
                    </th>
                  </tr>
                  <tr>
                    <th className="border-end text-center"></th>
                    {hours.map((hour, index) => (
                      <th className="border text-center" key={index}>
                        {hour.display}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    positions.map((position, index) => (
                      <tr key={index}>
                        <td>{position.positionName}</td>
                        {
                          hours.map((hour, index) => {
                            const posibleSchedule = schedules.find((schedule) => schedule.positionName === position.positionName && schedule.shift === position.shift && isHourInRange(schedule.startTime.split(' - ')[0], schedule.endTime.split(' - ')[1], hour.display.split(' - ')[1]));
                            if (posibleSchedule) {
                              return (
                                <td
                                  className='border'
                                  style={{
                                    backgroundColor: '#0F0'
                                  }}
                                >
                                  <span>{toCamelCase(`${posibleSchedule.partner.person.firstName} ${posibleSchedule.partner.person.lastName}`)}</span>
                                </td>
                              )
                            } else {
                              if (hoursRange[position.shift.toLowerCase()].includes(hour)) {
                                return (
                                  <td
                                    className='border'
                                    style={{
                                      backgroundColor: calculateColorRange(position.shift, hour.display.split(' - ')[1])
                                    }}
                                  >
                                    <span>Sin asignar</span>
                                  </td>
                                )
                              }
                              else {
                                return (
                                  <td
                                    className='border'
                                    style={{
                                      backgroundColor: '#75869444',
                                    }}
                                  >
                                    <span>No aplica</span>
                                  </td>
                                )
                              }
                            }
                          })
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResumenHorarios