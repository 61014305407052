import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import "moment/locale/es";
import { setCurrentRecord, setShowDeleteModal } from "../../store/slices/puestosSlice.ts";
import ModalContainer from "../ModalContainer.tsx";
import DeleteRecordPuestos from "./DeleteRecordPuestos.tsx";
import NewRecordPuestos from "./NewRecordPuestos.tsx";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/img/ic_content.svg";

moment.locale("es");

const TablePuestos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editRecord, setEditRecord] = useState(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { puestos } = useSelector((state: RootState) => state.puestos);

  const handleCloseEditModal = () => {
    setEditRecord(null);
    setShowEditModal(false);
  };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Puesto</th>
            <th>Estatus</th>
            <th>Razón social</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {puestos && puestos.length > 0 ? (
            puestos.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.status ? 'Activo' : 'Desactivado'}</td>
                <td>{item.businessName.name}</td>
                <td>
                  <a
                    className="btn btn-rojo m-2"
                    onClick={() => {
                      dispatch(
                        setCurrentRecord({ id: item.id, name: item.name })
                      );
                      dispatch(setShowDeleteModal(true));
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa fa-circle-xmark"></i>
                  </a>
                  <a
                    className="btn btn-primary m-2"
                    onClick={() => {
                      navigate(`/puestos/editar/${item.id}`);
                    }}
                  >
                    <span className="d-none d-sm-inline"></span>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no existen puestos</h5>
                  <h5 className='text-center text-secondary fw-400 fs-15px'>Prueba añadiendo uno en</h5>
                  <h5 className='text-center text-secondary fw-400'>el botón de 'Agregar puestos'</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showEditModal && (
        <ModalContainer
          open={showEditModal}
          title={
            editRecord ? "Actualizar Razón Social" : "Agregar Razón Social"
          }
          closeFn={handleCloseEditModal}
          children={
            <NewRecordPuestos
              initialValues={editRecord}
              onclose={handleCloseEditModal}
            />
          }
        />
      )}
    </div>
  );
};

export default TablePuestos;
