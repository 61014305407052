import { valiteCURP } from "../utils/index.ts";

export interface ImportBlackList {
  CURP: string;
  FECHA_BANEO: string;
  RAZON: string;
  ES_PERMANENTE: string;
}

// Interfaz
interface IBlackListExcel {
  CURP: string;
  FECHA_BANEO: Date;
  RAZON: string;
  ES_PERMANENTE: string;
}

// Clase
export class BlackListExcel implements IBlackListExcel {
  CURP: string;
  FECHA_BANEO: Date;
  RAZON: string;
  ES_PERMANENTE: string;

  constructor(data: IBlackListExcel) {
    this.CURP = data.CURP;
    this.FECHA_BANEO = data.FECHA_BANEO;
    this.RAZON = data.RAZON;
    this.ES_PERMANENTE = data.ES_PERMANENTE;
  }

  public isInvalid(value: any): boolean {
    return value === null || value === undefined || value === "";
  }

  public dateValid(date: any): boolean {
    return (
      new Date(date).toString() === "Invalid Date" ||
      date === null ||
      date === undefined ||
      date === "Invalid Date"
    );
  }

  public curpValid(): boolean {
    return valiteCURP(this.CURP);
  }

  public validField(
    field: string,
    values: { id: number; name: string }[]
  ): boolean {
    return true;
    const originalField = field?.trim()?.toLocaleLowerCase();
    return values.some(
      (value) => value?.name?.trim()?.toLocaleLowerCase() === originalField
    );
  }

  public validate(): boolean {
    const curpIsValid = valiteCURP(this.CURP);

    if (!curpIsValid) {
      return false;
    }
    return true;
  }
}
