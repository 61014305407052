import React from "react";
import { INavBarItem } from "../../interfaces/others/INavBarItem";
import { useNavigate } from "react-router-dom";

const NavBarItem: React.FC<INavBarItem> = ({
  active,
  name,
  to,
  activeKey,
  icon,
}) => {
  const navigate = useNavigate();
  return (
    <a
      className={active.includes(activeKey) ? "nav-link active" : "nav-link"}
      style={{ cursor: "pointer" }}
      onClick={() => navigate(to)}
    >
      {name}
      &nbsp;
      &nbsp;
      <i className={`fa-solid fa-${icon}`}></i>
    </a>
  );
};

export default NavBarItem;
