import { Template } from "../others/IEditTemplate";

export const DefaultPosition = {
  positionName: null,
  highRisk: false,
  documentTypesInmediate: [],
  documentTypesNoInmediate: [],
  funtions: [],
  idBusinessName: -99,
  idDepartment: -99,
  copyTemplate: -99,
};

export interface EditPositionProperties {
  id: number;
  positionName: string;
  idBusinessName: number;
  highRisk: boolean;
  documentTypesInmediate: number[];
  idDepartment: number;
  idInmediate: number;
  documentTypesNoInmediate: number[];
  funtions: string[];
  idNoInmediate: number;
  copyTemplate: number;
}

export const FillPosition = (data: Template): EditPositionProperties | null => {
  const inmediate = data.templates.find((t) => t.template.inmediate);
  const noImediate = data.templates.find((t) => !t.template.inmediate);

  if (!inmediate || !noImediate) return null;

  const inmediateDocuments = inmediate.documents.map((d) => d.id);
  const noInmediateDocuments = noImediate.documents.map((d) => d.id);

  return {
    id: data.position.id,
    positionName: data.position.name,
    idBusinessName: data.position.businessName.id,
    highRisk: data.position.highRisk,
    funtions: data.position.funtions,
    idDepartment: data.position.department?.id,
    documentTypesInmediate: inmediateDocuments,
    idInmediate: inmediate.template.id,
    documentTypesNoInmediate: noInmediateDocuments,
    idNoInmediate: noImediate.template.id,
    copyTemplate: -99,
  };
};
