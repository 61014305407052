import { IncompletedPartner } from "../../models/IncompletedPartner";

export const FillPartner = (partner: IncompletedPartner) => {
  return {
    id: partner.id,
    curp: partner.person.curp,
    name:
      partner.person.firstName +
      " " +
      partner.person.lastName +
      " " +
      partner.person.secondLastName,
    position: partner.candidate.vacancy.position.name,
    branch: partner.candidate.vacancy.branch.name,

    recruitmentDate: new Date(),
    monthlySalary: 0,
    periodicity: -99,
  };
};

export type CompletedPartner = {
  id: number; 
  curp: string;
  name: string;
  position: string;
  branch: string;
  recruitmentDate: Date;
  monthlySalary: number;
  periodicity: number;
};