import React, { useEffect, useState } from 'react'
import { useAppSettings } from '../../hooks/useAppSettings.tsx'
import Layout from '../../components/Layout.tsx'
import { findAll } from '../../services/changePosition.service.ts'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Title from '../../components/Title.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrent, setFilters, setQuery, setRecords, setTotal } from '../../store/slices/traspasosSlice.ts'
import { notification } from '../../utils/Notifications.tsx'
import { RootState } from '../../store/index.ts'
import ModalContainer from '../../components/ModalContainer.tsx'
import ChangePositionModal from '../../components/colaboradores/ChangePositionModal.tsx'
import { useParams } from 'react-router-dom'
import TableTraspasos from '../../components/traspasos/TableTraspasos.tsx';
import FilterTraspasos from '../../components/traspasos/FilterTraspasos.tsx';
import PaginationTraspasos from '../../components/traspasos/PaginationTraspasos.tsx';
import DetailsModal from '../../components/traspasos/DetailsModal.tsx';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Traspasos = () => {
  useAppSettings()
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }
  
  const dispatch = useDispatch()

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      updateQuery('entrantes');
    } else {
      updateQuery('salientes');
    }
  };

  const { current, page, limit, query } =
    useSelector((state: RootState) => state.traspasos);
  const [loading, setLoading] = React.useState(true)

  const fetchDependencies = async () => {
    try {
      let queryToSend = query;
      if (queryToSend.length === 0) {
        queryToSend = "tab=entrantes&type=approved";
      }
      const offset = page === 0 ? 0 : page * limit;
      const querySend = `?offset=${offset}&limit=${limit}&branch=${id}&${queryToSend}`;
      const response = await findAll(querySend);
      dispatch(setRecords(response.records));
      dispatch(setTotal(response.total));
      console.log(response);
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      setLoading(false);
    }
  }

  const updateQuery = (tab: string) => {
    let newQuery = `tab=${tab}&type=approved`;
    dispatch(setQuery(newQuery));
    dispatch(setFilters({ type: 'approved', tab: tab }));
  }

  useEffect(() => {
    fetchDependencies()
  }, [page, limit, query])

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Traspasos"
        basePath="/traspasos"
        title="Traspasos"
      />
      <div className="card border-0 m-4">
        <div className="tab-content">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Entrantes" {...a11yProps(0)} />
              <Tab label="Salientes" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <FilterTraspasos />
            <TableTraspasos />
            <PaginationTraspasos />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FilterTraspasos />
            <TableTraspasos />
            <PaginationTraspasos />
          </CustomTabPanel>
        </div>
      </div>
      <ModalContainer
        open={current !== null}
        title='Detalles del traspaso'
        closeFn={() => dispatch(setCurrent(null))}
      >
        <DetailsModal
          closeFn={() => dispatch(setCurrent(null))}
          record={current}
        />
      </ModalContainer>
    </Layout>
  )
}

export default Traspasos