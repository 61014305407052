import React from "react";
import { useAppSettings } from "../hooks/useAppSettings.tsx";
import { fethContractStats } from "../services/partner.service.ts";
import Layout from "../components/Layout.tsx";
import Title from "../components/Title.tsx";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import LoaderContractPie from "../components/reports/Contracts/LoaderContractPie.tsx";
import { ContractStats } from "../models/ContractStats.ts";
import Skeleton from "@mui/material/Skeleton";
const Reportes = () => {
  useAppSettings();

  const [loading, setLoading] = React.useState(true);
  const [stats, setStats] = React.useState<ContractStats>({} as ContractStats);

  const fetchData = async () => {
    try {
      const response = await fethContractStats();
      setStats(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout loading={loading}>
      <Title baseTitle="Reportes" basePath="/reportes" title="Reportes" />
      <div className="border-0 m-4  ">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Relación entre contratos</h5>
                {loading ? (
                  <LoaderContractPie />
                ) : (
                  <div style={{ overflowX: "auto" }}>
                    <div style={{ minWidth: "400px" }}>
                      <PieChart
                        series={[
                          {
                            data: [
                              {
                                id: 0,
                                value: stats?.partnersWithContract,
                                label: "Firmados",
                                color: "rgb(0 196 5 / 75%)",
                              },
                              {
                                id: 1,
                                value: stats?.partnersWithoutContract,
                                label: "No Firmados",
                                color: "#FFC107",
                              },
                            ],
                          },
                        ]}
                        width={500}
                        height={300}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Contratos firmados por sucursal</h5>
                {loading ? (
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={600}
                      height={300}
                      animation="wave"
                    />
                  </div>
                ) : (
                  <div style={{ overflowX: "auto" }}>
                    <div style={{ minWidth: "800px" }}>
                      <BarChart
                        width={1200}
                        height={300}
                        series={[
                          {
                            data: stats?.stats?.map(
                              (record) => record.withContract
                            ),
                            label: "Firmados",
                            id: "firmados",
                            color: "rgb(0 196 5 / 75%)",
                          },
                          {
                            data: stats?.stats?.map(
                              (record) => record.withoutContract
                            ),
                            label: "No firmados",
                            id: "noFirmados",
                            color: "#FFC107",
                          },
                          {
                            data: stats?.stats?.map((record) => record.total),
                            label: "Colaboradores",
                            id: "colaboradores",
                            color: "#ff0765",
                          },
                        ]}
                        xAxis={[
                          {
                            data: stats?.stats?.map((record) => record.name),
                            scaleType: "band",
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reportes;
