export interface MessagePartnerProperties {
  id: number;
  title: string;
  message: string;
  duration: number;
  icon: string;
}

export class MessagePartner implements MessagePartnerProperties {
  id: number;
  title: string;
  message: string;
  duration: number;
  icon: string;

  constructor(message: MessagePartnerProperties) {
    this.id = message.id;
    this.title = message.title;
    this.message = message.message;
    this.duration = message.duration;
    this.icon = message.icon;
  }
}

export const messageInitialValues: MessagePartnerProperties = {
  id: -99,
  title: "",
  message: "",
  duration: -99,
  icon: "",
};
