import * as Yup from "yup";

export const ImportPartnerSchema = Yup.object().shape({
  curp: Yup.string()
    .required("Campo requerido")
    .matches(
      /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/,
      "Ingresa una CURP válida"
    ),
  name: Yup.string().required("Campo requerido"),
  lastName: Yup.string().required("Campo requerido"),
  secondLastName: Yup.string().required("Campo requerido"),
  phone: Yup.string()
    .required("Campo requerido")
    .matches(/^\d{10}$/, "Debe ser un número de 10 dígitos"),
  address: Yup.string().required("Campo requerido"),
  branch: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  position: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  size: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  shift: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),

  birthdate: Yup.date().required("Campo requerido"),

  alergias: Yup.string().required("Campo requerido"),
  tipoSangre: Yup.string().required("Campo requerido"),
  enfermedad: Yup.string().required("Campo requerido"),
  tratamiento: Yup.string().required("Campo requerido"),
});

export const ImportPartnerSchemaLider = Yup.object().shape({
  curp: Yup.string()
    .required("Campo requerido")
    .matches(
      /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/,
      "Ingresa una CURP válida"
    ),
  name: Yup.string().required("Campo requerido"),
  lastName: Yup.string().required("Campo requerido"),
  secondLastName: Yup.string().required("Campo requerido"),
  phone: Yup.string()
    .required("Campo requerido")
    .matches(/^\d{10}$/, "Debe ser un número de 10 dígitos"),
  address: Yup.string().required("Campo requerido"),
  position: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  size: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  shift: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),

  birthdate: Yup.date().required("Campo requerido"),
  alergias: Yup.string().required("Campo requerido"),
  tipoSangre: Yup.string().required("Campo requerido"),
  enfermedad: Yup.string().required("Campo requerido"),
  tratamiento: Yup.string().required("Campo requerido"),
});

export const CompletePartnerSchema = Yup.object().shape({
  recruitmentDate: Yup.date()
    .required("Campo requerido")
    .max(new Date(), "La fecha no puede ser mayor al día de hoy"),
  monthlySalary: Yup.number()
    .required("Campo requerido")
    .min(1, "El salario debe ser mayor a 0")
    .integer("Campo requerido"),
  periodicity: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
});
