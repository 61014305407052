import React, { useEffect, useState } from "react";
import { Partner } from "../../models/Partner.ts";
import {
  fetchPartner,
  getMessagePartner,
  saveMessagePartner,
  updateMessagePartner,
  updateParnterMessage,
} from "../../services/partner.service.ts";
import CustomInput from "../../components/formik/CustomInput.tsx";
import { useFormik } from "formik";
import { notification } from "../../utils/Notifications.tsx";
import { messageInitialValues } from "../../models/MessagePartner.ts";
import {
  isValidForSweetAlert,
  MessagePartnerSchema,
} from "../../validation/schemas/MessagePartnerSchema.ts";
import CustomTextArea from "../../components/formik/CustomTextArea.tsx";
import CustomSelectValues, {
  CustomSelectValuesModal,
} from "../../components/formik/CustomSelectValues.tsx";
import CustomSelectField from "../../components/formik/CustomSelectField.tsx";
import Swal from "sweetalert2";

const EditColaboradorMessage = ({ colaborador, closeFn }) => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(messageInitialValues);

  const fetchDependencies = async () => {
    try {
      const message = await getMessagePartner(colaborador.id);
      // Validar si meesaage es valido
      if (Boolean(message?.id && isValidForSweetAlert(message))) {
        setInitialValues(message);
      }
    } catch (error) {
      console.log("error", error);
      notification("Error", "Ocurrió un error al cargar los datos", "danger");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDependencies();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: MessagePartnerSchema,
    onSubmit: async (values) => {
      if (initialValues.id !== -99) {
        // Update

        try {
          await updateMessagePartner(colaborador.id, {
            title: values.title,
            message: values.message,
            duration: values.duration,
            icon: values.icon,
          });
          notification(
            "Mensaje",
            "Mensaje actualizado correctamente",
            "success"
          );
          closeFn();
          return 
        } catch (error) {
          notification(
            "Error",
            "Ocurrió un error al actualizar el mensaje",
            "danger"
          );
        }
      }
      // Save
      try {
        await saveMessagePartner({
          title: values.title,
          message: values.message,
          duration: values.duration,
          icon: values.icon,
          partnerId: colaborador.id,
        });
        notification("Mensaje", "Mensaje guardado correctamente", "success");
        closeFn();
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al guardar el mensaje",
          "danger"
        );
      }
    },
  });

  const lauchSweetAlert = async () => {
    await Swal.fire({
      title: formik.values.title,
      text: formik.values.message,
      icon: formik.values.icon || ("info" as any),
      showCancelButton: false,
      showConfirmButton: false,
      timer: formik.values.duration * 1000,
      timerProgressBar: true,
    });
  };

  return (
    <div>
      <CustomInput
        formik={formik}
        field="title"
        label="Titulo"
        placeholder="Titulo del mensaje"
        sm={9}
        smLabel={3}
      />
      <CustomTextArea
        formik={formik}
        field="message"
        label="Mensaje"
        placeholder="Mensaje"
        sm={9}
        smLabel={3}
      />

      <CustomSelectField
        otherPlaceHolder
        formik={formik}
        placeholder="Tiempo en segundos"
        field="duration"
        label="Tiempo en segundos"
        sm={9}
        smLabel={3}
        list={[
          { id: 5, name: "5" },
          { id: 10, name: "10" },
          { id: 20, name: "20" },
          { id: 30, name: "30" },
          { id: 40, name: "40" },
          { id: 50, name: "50" },
          { id: 60, name: "60" },
        ]}
      />

      <CustomSelectValuesModal
        formik={formik}
        field="icon"
        label="Icono"
        sm={9}
        smLabel={3}
        list={[
          { id: "success", name: "Acierto" },
          { id: "error", name: "Peligro" },
          { id: "warning", name: "Aviso" },
          { id: "info", name: "Información" },
          { id: "question", name: "Pregunta" },
        ]}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <button
          className="btn btn-amarillo me-2"
          disabled={!isValidForSweetAlert(formik.values)}
          onClick={() => lauchSweetAlert()}
        >
          Mostrar
        </button>
        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
            Cancelar
          </button>
          <button
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            disabled={loading}
          >
            {
              initialValues.id === -99 ? "Guardar" : "Actualizar"
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditColaboradorMessage;
