import React from 'react'
import { PanelBody } from '../../panel/panel';
import CustomInput from '../../formik/CustomInput.tsx';

const SucursalFormGeneral = ({ formik }) => {
  return (
    <PanelBody>
      <CustomInput
        formik={formik}
        field="name"
        label="Nombre de la sucursal"
        placeholder="Nombre de la sucursal"
        sm={3}
        disabled
        unclickable
      />
    </PanelBody>
  )
}

export default SucursalFormGeneral