import { Store } from "react-notifications-component";

export const notification = (
  title: string,
  message: string,
  type: "success" | "danger" | "info" | "default" | "warning"
) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeIn"],
    dismiss: {
      duration: 3000,
      onScreen: true,
      showIcon: true,
      touch: true,
    },
  });
};
