import React, { useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PostulanteService } from "../../services/postulantes.service.js";
import ReCAPTCHA from "react-google-recaptcha";
import { validateCurp } from "../../services/application.service.ts";
import {
  readBlacklistByCurp,
  readRecordsByCurp,
} from "../../services/blacklist.service.ts";
import { valiteCURP } from "../../utils/index.ts";
import { notification } from "../../utils/Notifications.tsx";
import AppVersion from "../../config/appVersion.js";

function LoginV3() {
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const [valid, setValid] = useState();
  const [notInProcess, setNotInProcess] = useState();
  const [redirect, setRedirect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [curp, setCurp] = useState();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [chaptchaValue, setCaptchaValue] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();

    // const token = await captchaRef.current.getValue();

    const body = {
      // token: token,
      curp: event.target.elements.curp.value,
    };
    // await captchaRef.current.reset();
    // setCurp(event.target.elements.curp.value);
    setSearchParams({
      curp: event.target.elements.curp.value,
      nombre: event.target.elements.nombre.value,
      apellido_p: event.target.elements.apellido_p.value,
      apellido_m: event.target.elements.apellido_m.value,
    });

    const curp = event.target.elements.curp.value;
    // var re =
    //     /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    //   validado = curp.match(re);

    // Ver si no esta en la lista negra

    const validado = valiteCURP(curp);

    if (validado && captchaVerified) {
      setValid(true);
      try {
        // Verificar si no esta en la lista negra
        const recordBlackList = await readRecordsByCurp(curp);

        if (recordBlackList?.length > 0) {
          setNotInProcess({ message: "No hay ofertas disponibles" });
          setTimeout(() => {
            setNotInProcess(null);
          }, 3000);
          return;
        }

        // Ver si no esta en un proceso

        const response = await validateCurp(body.curp);
        // return
        setCaptchaVerified(false);
        if (response === true) setRedirect(true);
      } catch (error) {
        setNotInProcess({ message: error?.message || "Ocurrió un error" });
        setTimeout(() => {
          setNotInProcess(null);
        }, 3000);
      }
    } else {
      setNotInProcess(null);
      setValid(false);
      setRedirect(false);
      notification("Error", "La curp no es válida", "danger");
    }
  };

  if (redirect) {
    navigate(`/preguntas-iniciales/form?${searchParams}`);
  }

  function onChange(value) {
    setCaptchaVerified(true);
    setCaptchaValue(value);
  }

  return (
    <div className="login login-with-news-feed position-relative">
      <div className="news-feed">
        <div
          className="news-image"
          style={{
            backgroundColor: "black",
            backgroundImage: "url(/assets/img/login-bg/unete.png)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <div className="login-container">
        <div className="d-flex justify-content-center m-2">
          <div className="brand">
            <div className="w-90px">
              <img
                src="../assets/img/logo/logo_chili_rojo.png"
                alt=""
                className="mw-100"
              />
            </div>
          </div>
        </div>
        <div className="login-content">
          {notInProcess && (
            <div
              className="alert alert-danger d-flex justify-content-center"
              role="alert"
            >
              {notInProcess?.message}
            </div>
          )}

          {/* {valid == false && (
            <div
              className="alert alert-danger d-flex justify-content-center"
              role="alert"
            >
              {blacklist == true
                ? "La CURP esta en lista negra"
                : "La CURP no es valida"}
            </div>
          )} */}
          <form className="fs-13px" onSubmit={handleSubmit}>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control h-45px fs-13px"
                placeholder="datos"
                id="curp"
                name="curp"
                required
              />
              <label
                htmlFor="curp"
                className="d-flex align-items-center fs-13px text-gray-600"
              >
                Curp{" "}
              </label>
            </div>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control h-45px fs-13px"
                placeholder="datos"
                id="nombre"
                name="nombre"
                required
              />
              <label
                htmlFor="nombre"
                className="d-flex align-items-center fs-13px text-gray-600"
              >
                Nombre{" "}
              </label>
            </div>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control h-45px fs-13px"
                placeholder="datos"
                id="apellido_p"
                name="apellido_p"
                required
              />
              <label
                htmlFor="apellido_p"
                className="d-flex align-items-center fs-13px text-gray-600"
              >
                Apellido Paterno{" "}
              </label>
            </div>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control h-45px fs-13px"
                placeholder="datos"
                id="apellido_m"
                name="apellido_m"
                required
              />
              <label
                htmlFor="apellido_m"
                className="d-flex align-items-center fs-13px text-gray-600"
              >
                Apellido Materno{" "}
              </label>
            </div>

            <ReCAPTCHA
              sitekey={"6Lf1L7kpAAAAAHawdkEOuw1uiFLZWWwnJMoHB4Zs"}
              // ref={captchaRef}
              render="explicit"
              onChange={onChange}
            />
            <br />

            <div className="mb-2">
              <button
                type="submit"
                disabled={!chaptchaValue}
                className="btn btn-amarillo d-block h-45px w-100 btn-lg fs-14px"
              >
                Continuar
              </button>
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center  mb-0">
              &copy; Derechos Reservados Chiliguajili
            </div>
            <p className="text-center mt-2">
              Ya tienes cuenta?{" "}
              <a
                href="#/"
                className="cursor-pointer underline text-warning link-underline-warning"
                onClick={() => navigate("/login")}
              >
                Inicia sesión
              </a>
            </p>
          </form>
        </div>
      </div>
      {/* Agregar el texto en la esquina inferior derecha */}
      <p
        className="position-absolute text-secondary fw-300 bottom-0 z-3 p-3 m-0 fs-10px"
        style={{ width: "100vw", textAlign: "right" }}
      >
        {AppVersion()}
      </p>
    </div>
  );
}

export default LoginV3;
