import React from "react";
import { deleteApplication } from "../../services/appoinment.service.ts";
import { notification } from "../../utils/Notifications.tsx";
const DeleteSolicitudes = ({ name, id, closeFn }) => {

  const handleDeletePending = async (id: number) => {
    try {
      await deleteApplication(id);
      notification("", "Solicitud eliminada", "success");
      await closeFn();
    } catch (error) {
      console.log(error);
      notification("", "Error al eliminar la solicitud", "danger");
    }
  }

  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás seguro de que deseas eliminar la solicitud de {name}?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-danger"
          onClick={() => handleDeletePending(id)}
        >
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteSolicitudes;
