import httpClient from "./httpClient";

const prefix = "/proceedings";

export async function createTemplate(template) {
  return (await httpClient.post(`${prefix}/create-template`, template)).data;
}

export async function updateTemplate(id, template) {
  return (await httpClient.put(`${prefix}/update-template/${id}`, template)).data;
}

export async function findTemplatesByPositionId(id) {
  return (await httpClient.get(`${prefix}/find-template/${id}`)).data;
}