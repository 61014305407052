import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFetch,
  setShowModalPosition,
} from "../../store/slices/puestosSlice.ts";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { RootState } from "../../store/index.ts";
import { PositionsSchema } from "../../validation/schemas/PositionsSchema.ts";
import { DefaultBranch } from "../../interfaces/formik/IPositions.ts";
import {
  readPositions,
  savePosition,
  updatePosition,
} from "../../services/position.service.ts";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { BusinessNames } from "../../models/BusinessNames.ts";
import { readBusinessNames } from "../../services/businessName.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { fetchDocumentTypes } from "../../services/documentTypes.service.ts";
import { findTemplateByPosition } from "../../services/filesTemplate.service.ts";
import DocumentsSelector from "./components/DocumentsSelector.tsx";
import PositionSelect from "./components/PositionSelect.tsx";
import {
  createTemplate,
  findTemplatesByPositionId,
  updateTemplate,
} from "../../services/template.service.ts";

const NewRecordPosition = ({ initialValues, onclose }) => {
  const editing = initialValues && initialValues.id;
  const [loading, setLoading] = React.useState(true);
  const { fetch } = useSelector((state: RootState) => state.puestos) || {
    fetch: false,
  };
  const dispatch = useDispatch();
  const [businessNameList, setBusinessNameList] = React.useState<
    BusinessNames[]
  >([]);
  const [documentTypesList, setDocumentTypesList] = React.useState<any[]>([]);
  const [positionsList, setPositionsList] = React.useState<any[]>([]);
  const [filesTemplateList, setFilesTemplateList] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: DefaultBranch,
    onSubmit: async (values) => {
      console.log("Enviando al servidor:", values);
      try {
        const response = await savePosition(values);
        const plantilla = await handleCreateTemplate(
          docInmediata,
          docNoInmediata,
          response.id
        );

        notification(
          "",
          `Puesto ${editing ? "editado" : "guardado"} con exito`,
          "info"
        );
        dispatch(setShowModalPosition(false));

        dispatch(setFetch(!fetch));
      } catch (error) {
        notification("Error", error.message, "danger");
        console.error("Error:", error);
      }
      console.log(values);
    },
  });

  const getDependencies = async () => {
    try {
      const businessNames = await readBusinessNames();
      setBusinessNameList(businessNames);

      const documentTypes = await fetchDocumentTypes();
      setDocumentTypesList([
        { name: "Selecciona un tipo de documento" },
        ...documentTypes,
      ]);

      const postions = await readPositions();
      setPositionsList(postions);

      setLoading(false);
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  const getFilesTemplate = async (id) => {
    try {
      const filesTemplate = await findTemplateByPosition(id);
      setFilesTemplateList(filesTemplate);
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  const [docInmediata, setDocInmediata] = React.useState(false);
  const [docNoInmediata, setDocNoInmediata] = React.useState(false);

  const handleCreateTemplate = async (inmediata, noInmediata, positionID) => {
    const inmediatesIds = inmediata.map((doc) => doc.id);
    const noInmediatesIds = noInmediata.map((doc) => doc.id);

    const inmediateObject = {
      positionId: positionID,
      inmediate: true,
      documentTypes: inmediatesIds,
    };

    const noInmediateObject = {
      positionId: positionID,
      inmediate: false,
      documentTypes: noInmediatesIds,
    };

    await createTemplate(inmediateObject);
    await createTemplate(noInmediateObject);
  };

  const removeDocumentFromTemplate = async (positionId, documentId) => {
    try {
      const positionTemplates = await findTemplatesByPositionId(positionId);
      for (const template of positionTemplates) {
        if (template.documentTypes.includes(documentId)) {
          const updatedDocumentTypes = template.documentTypes.filter(
            (docId) => docId !== documentId
          );
          await updateTemplate(template.id, updatedDocumentTypes);
        }
      }
      console.log(
        `Documento ${documentId} eliminado de la plantilla para el puesto ${positionId}.`
      );
    } catch (error) {
      console.error(
        `Error al eliminar el documento ${documentId} de la plantilla para el puesto ${positionId}:`,
        error
      );
    }
  };

  const handleUpdateTemplate = async (
    inmediata,
    noInmediata,
    positionID,
    isEditMode
  ) => {
    const positionTemplates = await findTemplatesByPositionId(positionID);
    console.log("Templates:", positionTemplates);

    if (positionTemplates.length > 0) {
      if (isEditMode) {
        if (positionTemplates[0].id) {
          const inmediateTemplate = positionTemplates[0].id;
          await updateTemplate(inmediateTemplate, inmediata);
        }
        if (positionTemplates[1] && positionTemplates[1].id) {
          const noInmediateTemplate = positionTemplates[1].id;
          await updateTemplate(noInmediateTemplate, noInmediata);
        }
        // Eliminar documentos no seleccionados de las plantillas existentes
        const existingDocIds = [
          ...inmediata.map((doc) => doc.id),
          ...noInmediata.map((doc) => doc.id),
        ];
        const allTemplateDocs = positionTemplates.flatMap(
          (template) => template.documentTypes
        );
        const docsToRemove = allTemplateDocs.filter(
          (docId) => !existingDocIds.includes(docId)
        );
        for (const docId of docsToRemove) {
          await removeDocumentFromTemplate(positionID, docId);
        }
      } else {
        console.log("No se pueden crear nuevas plantillas en modo de edición.");
      }
    } else {
      const inmediateObject = {
        positionId: positionID,
        inmediate: true,
        documentTypes: inmediata.map((doc) => doc.id),
      };
      await createTemplate(inmediateObject);

      const noInmediateObject = {
        positionId: positionID,
        inmediate: false,
        documentTypes: noInmediata.map((doc) => doc.id),
      };
      await createTemplate(noInmediateObject);
    }
  };

  useEffect(() => {
    if (editing) {
      formik.setValues(initialValues);
      getFilesTemplate(initialValues.id);
    }
  }, [initialValues]);

  if (loading) {
    return <h1>Cargando...</h1>;
  }

  return (
    <div className=" p-3 mx-auto" style={{ width: "100%" }}>
      <div className="row mt-4 fs-13px col-md-12">
        <h5 className="modal-title mb-4 fw-700 col-md-9 ms-auto">
          Datos generales
        </h5>
      </div>
      <CustomSelectField
        formik={formik}
        field="idBusinessName"
        list={businessNameList}
        label="Razón Social"
      />
      <CustomInput
        formik={formik}
        field="name"
        label="Puesto "
        placeholder="Puesto"
        sm={9}
      />
      <CustomCheckboxField
        formik={formik}
        field="highRisk"
        label="Alto riesgo"
      />

      <PositionSelect
        list={positionsList}
        getFilesTemplate={getFilesTemplate}
      />

      <DocumentsSelector
        filesTemplateList={filesTemplateList}
        documentTypesList={documentTypesList}
        setDocInmediata={setDocInmediata}
        setDocNoInmediata={setDocNoInmediata}
        editing={editing}
      />

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => onclose()}>
          Cancelar
        </button>
        <button
          className="btn btn-azul"
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          Guardar Puesto
        </button>
      </div>
    </div>
  );
};

export default NewRecordPosition;
