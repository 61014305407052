import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  total: 0,
  records: [],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  query: "",
  fetch: false,
  current: null,
  showDetails: false,
  filters: {
    tab: "entrantes",
    status: "",
    type: "approved",
  },
}

const traspasosSlice = createSlice({
  name: "traspasos",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setRecords: (state, action: PayloadAction<any[]>) => {
      state.records = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
      state.page = 0
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.page = 0
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.current = action.payload
    },
    setShowDetails: (state, action: PayloadAction<boolean>) => {
      state.showDetails = action.payload
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
  },
})

export const {
  setPage,
  setLimit,
  setRecords,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  setCurrent,
  setShowDetails,
  setFilters,
} = traspasosSlice.actions

export default traspasosSlice.reducer