
import React from "react";
import S3Service from "../../../../services/s3.service.ts";
import { notification } from "../../../../utils/Notifications.tsx";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
} from "@mui/material";

const WorkerRow = ({ originalId, name, position, shift, handleDrop, curp, freeDay, count }) => {
  const [profileImage, setProfileImage] = React.useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );

  const fetchProfileImage = async () => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + curp);
      setProfileImage(profileUrl.url);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchProfileImage();
  }, []);

  return (
    <div
      className="card droppable-element"
      unselectable="on"
      draggable={true}
      style={{
        backgroundColor:(()=>{
          if (freeDay) return "#fee0d2"
          if(count === 1) return "#e5f5e0"
          if(count > 1) return "#deebf7"
          return "white"
        })(),
        marginBottom: "10px",
        cursor: "grab",
      }}
      onDragEnd={(e) => {
        if (freeDay) {
          e.preventDefault();
          notification("Aviso", "Es el día libre del colaborador", "warning");
        }
        if (e.dataTransfer.dropEffect === "copy") {
          handleDrop(String(originalId));
        }
      }}
    >
      <Card
        sx={{
          display: "flex",
          backgroundColor: "rgba(255, 255, 255, 0)",
          flexDirection: { xs: "column", sm: "row" },
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          gap: 1,
          p: 1,
        }}
      >
        <Grid direction={"column"} display={"flex"} item sm={3} container alignItems="center" justifyItems={"center"}>
          <CardMedia
            component="img"
            sx={{
              width: 40,
              height: 40,
              mx: "auto",
              my: "auto",
              py: 0,
              borderRadius: "50%",
              userSelect: "none",
            }}
            image={profileImage}
            alt="Colaborador"
            onError={() => setProfileImage("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png")}
          />
          <Typography
            sx={{
              fontSize: "0.6rem",
              textAlign: "center",
              color: "#6e6e6e",
              userSelect: "none",
            }}
          >
            {count} turnos
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Box sx={{ display: "flex", flexDirection: "column", width: 120, justifyContent: 'left' }}>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", textAlign: "left", width: '100%' }}
              style={{ padding: 2 }}
            >
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  userSelect: "none",
                  width: '100%',
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {name} {freeDay && `(Día libre)`}
              </h4>
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "0.7rem",
                  marginBlock: "0.2rem",
                  userSelect: "none",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {position}
              </h4>
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "0.7rem",
                  color: "#6e6e6e",
                  userSelect: "none",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                Horario {shift}
              </h4>
            </CardContent>
          </Box>
        </Grid>
      </Card>
    </div>
  );
};

export default WorkerRow;
