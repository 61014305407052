import React, { useEffect } from 'react';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import Layout from '../../Layout.tsx';
import { useParams } from 'react-router-dom';
import { readHoursByBranch, readPositionsByBranch } from '../../../services/branchSchedule.service.ts';
import Title from '../../Title.tsx';
import moment from 'moment';
import { fetchPartners } from '../../../services/partner.service.ts';
import { toCamelCase } from '../../../hooks/toCameCase.tsx';

type Props = {};

const CoberturaPuestos = (props: Props) => {
  useAppSettings();
  const { id } = useParams();

  const [template, setTemplate] = React.useState<any>([]);
  const [branchHours, setBranchHours] = React.useState<any>(null);
  const [branchPositions, setBranchPositions] = React.useState<any>(null);

  function groupByShifts(data) {
    return data.reduce((acc, current) => {
      const shift = current.shift;

      if (!acc[shift]) {
        acc[shift] = [];
      }

      acc[shift].push(current);
      return acc;
    }, {});
  }

  function transformShiftsData(data) {
    const result = {};

    for (const [shiftName, positions] of Object.entries(data)) {
      const totalPositions = positions.reduce((total, position) => {
        return total + parseInt(position.numberOfWorkers, 10);
      }, 0);

      result[shiftName] = {
        positions: positions,
        totalPositions: totalPositions,
      };
    }

    return result;
  }

  function groupByShift(data) {
    return data.reduce((acc, item) => {
      const shiftName = item.candidate.shift.name;

      if (!acc[shiftName]) {
        acc[shiftName] = [];
      }

      acc[shiftName]?.push(item);

      return acc;
    }, {});
  }

  function getTotalPositionsOcupied(partners, template) {
    let counter = 0;
    template.map((position) => {
      const total = partners
      .filter(partners => position.numberOfWorkers > 0)
      .filter(
        (partner) => partner.candidate.vacancy.position.name === position.position.name
      ).length;
      counter += total;
    });

    console.log('counter: ', counter);

    return counter;
  }

  const fetchDependencies = async () => {
    const template = await readPositionsByBranch(Number(id));
    const groupedData = groupByShifts(template);
    const transformedData = transformShiftsData(groupedData);
    setTemplate(transformedData);
    console.log('transformedData: ', transformedData);

    const branchHours = await readHoursByBranch(Number(id));
    setBranchHours(branchHours);
    console.log('branchHours: ', branchHours);

    const branchPositions = await fetchPartners(`?branch=${id}&tab=activos&schedules=true`);
    const groupedBranchPositions = groupByShift(branchPositions.results);
    console.log('groupedBranchPositions: ', groupedBranchPositions);
    setBranchPositions(groupedBranchPositions);
  };

  useEffect(() => {
    fetchDependencies();
  }, [id]);

  const renderShiftRows = (shiftName) => {

    const shiftTemplate = template[shiftName];
    const shiftBranchPositions = branchPositions?.[shiftName];

    const totalPositions = shiftTemplate ? shiftTemplate.totalPositions : 0;
    const contractedPositions = shiftBranchPositions ? shiftBranchPositions.length : 0;
    let coveragePercentage = totalPositions > 0
      ? (getTotalPositionsOcupied(shiftBranchPositions, shiftTemplate.positions) / totalPositions) * 100
      : 0;

    if (!isFinite(coveragePercentage) || isNaN(coveragePercentage) || coveragePercentage < 0 || coveragePercentage > 100) {
      coveragePercentage = 0;
    }

    return (
      <>
        <tr key={shiftName} className='table-primary'>
          <td>{shiftName}</td>
          <td>Horario</td>
          <td className='text-center'>{totalPositions}</td>
          <td className='text-center'>{contractedPositions}</td>
          <td className='text-center'>{Math.max(0, totalPositions - contractedPositions)}</td>
          <td className='text-center'>{coveragePercentage.toFixed(2)}%</td>
          <td></td>
          <td>Lunes</td>
          <td>Martes</td>
          <td>Miércoles</td>
          <td>Jueves</td>
          <td>Viernes</td>
          <td>Sábado</td>
          <td>Domingo</td>
        </tr>
        {shiftTemplate?.positions
          .filter(position => position.numberOfWorkers > 0)
          .map((position) => {
            const filteredPartners = shiftBranchPositions?.filter(
              (record) => record.candidate.vacancy.position.name === position.position.name
            ) || [];
            const totalPartners = filteredPartners.length;
            const coveragePercentage = (totalPartners / position.numberOfWorkers) * 100;

            return (
              <tr
                key={position.id}
                className={coveragePercentage < 100 ? 'table-danger' : coveragePercentage === 100 ? 'table-success' : 'table-warning'}
              >
                <td>{position.position.name}</td>
                <td className='text-center'>{moment(branchHours?.[`${shiftName.toLowerCase()}Start`], 'HH:mm:ss').format('h:mm A') + ' - ' + moment(branchHours?.[`${shiftName.toLowerCase()}End`], 'HH:mm:ss').format('h:mm A')}</td>
                <td className='text-center'>{position.numberOfWorkers}</td>
                <td className='text-center'>{totalPartners}</td>
                <td className='text-center'>{Math.max(0, position.numberOfWorkers - totalPartners)}</td>
                <td className='text-center'>{(coveragePercentage || 0).toFixed(2)}%</td>
                <td className='text-center'>
                  {
                    totalPartners > 0
                      ? filteredPartners.map((partner) => toCamelCase(partner.person.firstName)).join(', ')
                      : 'Sin titular'
                  }
                </td>
                {
                  totalPartners > 0
                    ? filteredPartners.map((partner) => partner?.schedules ? Array.from({ length: 7 }).map((_, i) => (
                      <td className='text-center' key={i}>
                        {partner?.schedules[i]?.startTime ? `${moment(partner?.schedules[i]?.startTime, 'HH:mm:ss').format('h:mm A')} - ${moment(partner?.schedules[i]?.endTime.split(' - ')[1], 'HH:mm:ss').format('h:mm A')}` : `${moment(branchHours?.[`${shiftName.toLowerCase()}Start`], 'HH:mm:ss').format('h:mm A')} - ${moment(branchHours?.[`${shiftName.toLowerCase()}End`], 'HH:mm:ss').format('h:mm A')}`}
                      </td>
                    ))
                      :
                      Array.from({ length: 7 }).map((_, i) => (
                        <td className='text-center' key={i}>
                          {`${moment(branchHours?.[`${shiftName.toLowerCase()}Start`], 'HH:mm:ss').format('h:mm A')} - ${moment(branchHours?.[`${shiftName.toLowerCase()}End`], 'HH:mm:ss').format('h:mm A')}`}
                        </td>
                      ))
                    )
                    : Array.from({ length: 7 }).map((_, i) => (
                      <td className='text-center' key={i}>
                        {`${moment(branchHours?.[`${shiftName.toLowerCase()}Start`], 'HH:mm:ss').format('h:mm A')} - ${moment(branchHours?.[`${shiftName.toLowerCase()}End`], 'HH:mm:ss').format('h:mm A')}`}
                      </td>
                    ))
                }
              </tr>
            );
          })}
        <tr key={shiftName} className='table-secondary'>
          <td></td>
        </tr>
      </>
    );
  };

  if (!template || !branchHours || !branchPositions) {
    return null;
  }

  return (
    <Layout>
      <Title baseTitle='Cobertura de puestos' basePath='/Cobertura de puestos' title='Cobertura de puestos' />
      <div className='tab-pane fade active show' id='Puestos'>
        <div className='card border-0 m-4'>
          <div className='tab-content px-3'>
            <div className='table-responsive mb-3'>
              <table className='table table-auto-width align-middle mb-0 fixed-table-1'>
                <thead>
                  <tr>
                    <th className='text-center'>Horario</th>
                    <th className='text-center'></th>
                    <th className='text-center'>Colaboradores autorizados</th>
                    <th className='text-center'>Colaboradores contratados</th>
                    <th className='text-center'>Vacantes</th>
                    <th className='text-center'>Cobertura</th>
                    <th className='text-center'>Titular</th>
                    <th className='text-center' colSpan={7}>Horario real</th>
                  </tr>
                </thead>
                <tbody>
                  {['Matutino', 'Intermedio', 'Vespertino'].map((shiftName) =>
                    renderShiftRows(shiftName)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoberturaPuestos;