import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetch, setShowModal } from "../../store/slices/listaNegraSlice.ts";
import { useFormik } from "formik";
import { DeletePostulanteSchema } from "../../validation/schemas/BlackListSchema.ts";
import { ListaNegraInitial } from "../../interfaces/formik/IListaNegra.ts";
import CustomInput from "../formik/CustomInput.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import CustomTextArea from "../formik/CustomTextArea.tsx";
import { notification } from "../../utils/Notifications.tsx";
import { saveRecordBlackList } from "../../services/blacklist.service.js";
import { RootState } from "../../store/index.ts";

const NewRecordListaNegra = ({
  disabled,
  tableWalktour,
  resetWaltour,
}: {
  disabled: any;
  tableWalktour: () => void;
  resetWaltour: () => void;
}) => {
  const { fetch } = useSelector((state: RootState) => state.listaNegra);
  const dispatch = useDispatch();
  const formik = useFormik({
    validationSchema: DeletePostulanteSchema,
    initialValues: ListaNegraInitial,
    onSubmit: async (values) => {
      try {
        const body = { ...values } as any;
        if (values.isPermanent) {
          delete body.unbanDate;
        }
        const response = await saveRecordBlackList(body);
        notification("", "Registro guardado con exito", "info");
        dispatch(setShowModal(false));
        dispatch(setFetch(!fetch));
        tableWalktour();
      } catch (error) {
        notification("Error", error.message, "danger");
      }
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="paso3-blackList">
        <CustomInput
          formik={formik}
          field="name"
          label="Nombre completo"
          placeholder="Nombre de la persona"
          sm={9}
          unclickable={disabled?.curp}
          disabled={disabled?.curp}
        />
        <CustomInput
          formik={formik}
          field="curp"
          label="CURP"
          placeholder="La curp de la persona"
          sm={9}
          unclickable={disabled?.curp}
          disabled={disabled?.curp}
        />
      </div>
      <CustomTextArea
        formik={formik}
        field="reason"
        label="Motivo"
        placeholder="Motivo por el cual se ha bloqueado a la persona"
        sm={9}
        disabled={disabled?.motivo}
        classInput="paso4-blackList"
      />
      <div className="paso5-blackList">
        {!formik.values.isPermanent && (
          <CustomInput
            formik={formik}
            field="unbanDate"
            label="Fecha de desbloqueo"
            placeholder="-"
            type="date"
            sm={9}
            unclickable={disabled?.fechaBloqueo}
            disabled={disabled?.fechaBloqueo}
            // classInput="paso5-blackList"
          />
        )}
        <CustomCheckboxField
          formik={formik}
          field="isPermanent"
          label="Permanente"
          disable={disabled?.fechaBloqueo}
          // classInput="paso5-blackList"
        />
      </div>
      <div className="paso6-blackList">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary me-2"
            onClick={() => {
              dispatch(setShowModal(false));
              resetWaltour();
            }}
            disabled={disabled?.buttons}
          >
            Cancelar
          </button>
          <button
            className="btn btn-azul"
            onClick={() => formik.handleSubmit()}
            disabled={disabled?.buttons}
          >
            Guardar registro
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewRecordListaNegra;
