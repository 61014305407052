import * as yup from "yup";

export const BranchTemplateModalSchema = yup.object().shape({
  puesto: yup
    .number()
    .positive()
    .moreThan(0, "Selecciona el puesto")
    .required("Ingresa el número de trabajadores"),
  matutino: yup
    .number()
    .moreThan(-1, "Ingresa el número de trabajadores")
    .required("Ingresa el número de trabajadores"),
  intermedio: yup
    .number()
    .moreThan(-1, "Ingresa el número de trabajadores")
    .required("Ingresa el número de trabajadores"),
  vespertino: yup
    .number()
    .moreThan(-1, "Ingresa el número de trabajadores")
    .required("Ingresa el número de trabajadores"),
});
