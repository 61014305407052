import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import FilterVacantes from "./FilterVacantes.tsx";
import PaginationVacantes from "./PaginationVacantes.tsx";
import EmptyImage from "../../assets/img/ic_content.svg";
import { setDataToRemove, setShowModal, setShowModalEdit } from "../../store/slices/vacantesSlice.ts";

const TableVacantes = ({ fetchVacantes }) => {
  const dispatch = useDispatch();
  const { vacantes } = useSelector((state: RootState) => state.vacantes);

  return (
    <React.Fragment>
      <div className="input-group w-100">
        <div className="d-flex align-items-center w-100">
          <FilterVacantes />
        </div>
      </div>

      <div className="table-responsive mb-3">
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th className=" text-center">Id</th>
              <th className=" text-center">Razón social</th>
              <th className=" text-center">Sucursal</th>
              <th className=" text-center">Puestos</th>
              <th className=" text-end">Activo</th>
              <th className=" text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {vacantes?.length > 0 ?
            vacantes.map((item) => {
              return (
                <tr key={item.id}>
                  <td className=" text-center">{item.id} </td>
                  <td className=" text-center">{item?.businessName?.name}</td>
                  <td className=" text-center">{item.branch.name}</td>
                  <td className=" text-center">{item.position.name}</td>
                  <td className=" text-end">
                    {item.isAvailable ? "Si" : "No"}
                  </td>
                  <td className=" text-end">
                    {
                      <a
                        href="#/"
                        className={
                          item.isAvailable
                            ? "btn btn-orange m-2"
                            : "btn btn-green m-2"
                        }
                        onClick={() => {
                          dispatch(setDataToRemove({
                            id: item.id,
                            active: item.isAvailable,
                            name: ""
                          }))
                          dispatch(setShowModalEdit(true));
                          dispatch(setShowModal(true));
                        }}
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i
                          className={
                            item.isAvailable ? " fa fa-close" : " fa fa-check"
                          }
                        ></i>
                      </a>
                    }
                  </td>
                </tr>
              );
            })
            :
            <tr>
              <td colSpan={6}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no existen vacantes</h5>
                  <h5 className='text-center text-secondary fw-400 fs-15px'>Prueba añadiendo una en</h5>
                  <h5 className='text-center text-secondary fw-400'>el botón de 'Agregar vacante'</h5>
                </div>
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      <PaginationVacantes />
    </React.Fragment>
  );
};

export default TableVacantes;
