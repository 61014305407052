import React, { useState } from "react";
import { IncompletedPartner } from "../../models/IncompletedPartner";
import { useFormik } from "formik";
import {
  CompletedPartner,
  FillPartner,
} from "../../interfaces/formik/ICompletePartner.ts";
import CustomInput from "../formik/CustomInput.tsx";
import { CompletePartnerSchema } from "../../validation/schemas/ImportPartnerSchema.ts";
import { notification } from "../../utils/Notifications.tsx";
import { getPeriodicities } from "../../services/periodicty.service.ts";
import { IDefaultArray } from "../../utils/index.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { updateImcompletePartner } from "../../services/application.service.ts";

const CompletePartner = ({
  partner,
  close,
  refetch
}: {
  partner: IncompletedPartner;
  close: () => void;
  refetch: () => void;
}) => {
  const [initialValues] = useState<CompletedPartner>(
    FillPartner(partner)
  );
  const [periodicities, setPeriodicities] = React.useState<IDefaultArray[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: CompletePartnerSchema,
    onSubmit: async (values) => {
      const { id, periodicity, recruitmentDate, monthlySalary } = values;
      console.log({ id, periodicity, recruitmentDate, monthlySalary });

      try {
        const response = await updateImcompletePartner(id, {
          periodicity,
          recruitmentDate,
          monthlySalary,
        });
        console.log(response);
      } catch (error) {
        notification("", "Error al completar el colaborador", "danger");
      }finally{
        refetch();
        close();

      }
    },
  });

  const fetchDependencies = async () => {
    try {
      const periodicitiesResponse = await getPeriodicities();
      setPeriodicities(periodicitiesResponse);
    } catch (error) {
      notification("", "Error al obtener las periodicidades", "danger");
    }
  };

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="curp"
        label="CURP"
        placeholder="CURP"
        sm={9}
        smLabel={3}
        disabled={true}
      />

      <CustomInput
        formik={formik}
        field="name"
        label="Nombre"
        placeholder="Nombre"
        sm={9}
        smLabel={3}
        disabled={true}
      />

      <CustomInput
        formik={formik}
        field="position"
        label="Puesto"
        placeholder="Puesto"
        sm={9}
        smLabel={3}
        disabled={true}
      />

      <CustomInput
        formik={formik}
        field="branch"
        label="Sucursal"
        placeholder="Sucursal"
        sm={9}
        smLabel={3}
        disabled={true}
      />

      <CustomInput
        formik={formik}
        field="recruitmentDate"
        label="Fecha de reclutamiento"
        placeholder="Fecha de reclutamiento"
        sm={9}
        smLabel={3}
        type="date"
      />

      <CustomInput
        formik={formik}
        field="monthlySalary"
        label="Salario mensual"
        placeholder="Salario mensual"
        type="number"
        sm={9}
        smLabel={3}
      />

      <CustomSelectField
        formik={formik}
        field="periodicity"
        list={periodicities}
        label="Periodicidad"
        placeholder="registro"
      />

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => close()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default CompletePartner;
