import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import ConfigSection from "../../components/setting-page/ConfigSection.tsx";

const ConfigPage = () => {
  useAppSettings();
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Configuración"
        basePath="/configuracion"
        title="Configuración"
      />
      <div className=" card border-0 m-4">
        <div className="tab-content p-3">
          <div className="row">
            <div style={{ width: 230 }}>
              <nav className="navbar navbar-sticky d-none d-xl-block my-n4 py-4 h-100 text-end">
                <nav className="nav" id="bsSpyTarget">
                  <a
                    className="nav-link active"
                    href="#general"
                    data-toggle="scroll-to"
                  >
                    General
                  </a>
                </nav>
              </nav>
            </div>
            <ConfigSection />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConfigPage;
