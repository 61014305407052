import React, { useEffect } from 'react'
import CustomInput from '../formik/CustomInput.tsx'
import { useFormik } from 'formik';
import { notification } from '../../utils/Notifications.tsx';
import { DefaultConfig } from '../../interfaces/formik/IAssisntanceConfig.ts';
import { createAssistanceConfig, updateConfig } from '../../services/configuration.service.ts';
import { EditAssistanceTimeSchema } from '../../validation/schemas/EditAssistanceTimeSchema.ts';

const EditCheckTime = ({ closeFn, refreshFn, setLoading, assisitanceTime }) => {
  const formik = useFormik({
    initialValues: {
      onTimeBeforeMinutes: null,
      onTimeAfterMinutes: null,
      lateBeforeMinutes: null,
      lateAfterMinutes: null,
    },
    validationSchema: EditAssistanceTimeSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log(values);
        const object = {
          onTime: {
            beforeMinutes: values.onTimeBeforeMinutes,
            afterMinutes: values.onTimeAfterMinutes,
          },
          late: {
            beforeMinutes: values.onTimeAfterMinutes + 1,
            afterMinutes: values.lateAfterMinutes,
          },
        };

        console.log('object: ', object);
        const response = assisitanceTime ? await updateConfig('assistance', object) : await createAssistanceConfig(object);
        console.log('response: ', response);
        notification(
          "Guardado",
          "Tiempo de asistencia actualizado",
          "success"
        );
      } catch (error) {
        notification(
          "error",
          "Error al actualizar el tiempo de la cita",
          "danger"
        );
      } finally {
        setLoading(true);
        refreshFn();
        closeFn();
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      onTimeBeforeMinutes: assisitanceTime?.onTime.beforeMinutes,
      onTimeAfterMinutes: assisitanceTime?.onTime.afterMinutes,
      lateBeforeMinutes: assisitanceTime?.late.beforeMinutes,
      lateAfterMinutes: assisitanceTime?.late.afterMinutes,
    });
    console.log('formik.values: ', assisitanceTime);
  }, [assisitanceTime]);

  return (
    <React.Fragment>
      <CustomInput
        formik={formik}
        field="onTimeBeforeMinutes"
        label="Tiempo máximo antes de entrada"
        placeholder="Escribe los minutos máximos previos a la hora de entrada"
        type="number"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="onTimeAfterMinutes"
        label="Tiempo máximo después de entrada"
        placeholder="Escribe los minutos máximos previos a la hora de entrada"
        type="number"
        sm={8}
        smLabel={4}
      />
      <CustomInput
        formik={formik}
        field="lateAfterMinutes"
        label="Tiempo retardo"
        placeholder="Escribe el tiempo para retardo"
        type="number"
        sm={8}
        smLabel={4}
      />
      <div className="pb-3 mt-25px">
        <div style={{ display: 'flex', flex: 1, backgroundColor: '#555', borderRadius: 5, border: '1px solid #ccc' }}>
          <div style={{ display: 'flex', width: '5%', backgroundColor: '#555', height: 30 }} />
          <div style={{ display: 'flex', paddingBlock: 5, paddingInline: 15, width: '30%', backgroundColor: '#508D4E', height: 30, border: '1px solid #ccc0', borderRight: '2px solid #FFDE4D' }} >
            <p className='text-end fw-600 text-white'>-{formik.values.onTimeBeforeMinutes} min</p>
          </div>
          <div className='justify-content-end' style={{ display: 'flex', paddingBlock: 5, paddingInline: 15, width: '30%', backgroundColor: '#508D4E', height: 30, border: '1px solid #ccc0', borderLeft: '2px solid #FFDE4D' }} >
            <p className='text-end fw-600 text-white'>+{formik.values.onTimeAfterMinutes} min</p>
          </div>
          <div className='justify-content-end' style={{ display: 'flex', paddingBlock: 5, paddingInline: 15, width: '30%', backgroundColor: '#f00E', height: 30 }} >
            <p className='text-end fw-600 text-white'>+{formik.values.lateAfterMinutes} min</p>
          </div>
        </div>
        <div className='mt-25px d-flex justify-content-center'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ height: 20, width: 20, backgroundColor: '#508D4E', borderRadius: 5, display: 'inline-block', marginRight: 5, marginTop: 4 }} />
            <p className="fs-17px text-center">Asistencia en tiempo</p>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ height: 20, width: 20, backgroundColor: '#f00E', borderRadius: 5, display: 'inline-block', marginRight: 5, marginTop: 4 }} />
            <p className="fs-17px text-center">Asistencia con retardo</p>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ height: 20, width: 20, backgroundColor: '#555', borderRadius: 5, display: 'inline-block', marginRight: 5, marginTop: 4 }} />
            <p className="fs-17px text-center">Fuera de horario</p>
          </div>
        </div>

      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()} type='submit'>
          Actualizar
        </button>
      </div>
    </React.Fragment>
  )
}

export default EditCheckTime