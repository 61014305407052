
import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { notification } from '../../../utils/Notifications.tsx';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { createHours, readHoursByBranch } from '../../../services/branchSchedule.service.ts';

const EditTime = ({ branchId, closeFn, refreshFn, setLoading }) => {

  const formik = useFormik({
    initialValues: {
      branch: branchId,
      matutinoStart: null,
      matutinoEnd: null,
      intermedioStart: null,
      intermedioEnd: null,
      vespertinoStart: null,
      vespertinoEnd: null,
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if(moment(values.matutinoEnd).isBefore(moment(values.matutinoStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin",
            "danger"
          );
          formik.setErrors({ matutinoEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.intermedioStart).isBefore(moment(values.matutinoStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ intermedioEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.intermedioEnd).isBefore(moment(values.intermedioStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin",
            "danger"
          );
          formik.setErrors({ intermedioEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.vespertinoStart).isBefore(moment(values.intermedioStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ vespertinoStart: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.vespertinoEnd).isBefore(moment(values.intermedioEnd))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ vespertinoStart: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.vespertinoStart).isBefore(moment(values.intermedioStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ vespertinoStart: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }
        if(moment(values.vespertinoEnd).isBefore(moment(values.vespertinoStart))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin",
            "danger"
          );
          formik.setErrors({ vespertinoEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }

        if(moment(values.intermedioStart).isAfter(moment(values.matutinoEnd))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ matutinoEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }

        if(moment(values.vespertinoStart).isAfter(moment(values.intermedioEnd))) {
          notification(
            "Error",
            "La hora de inicio debe ser menor a la hora de fin del turno anterior",
            "danger"
          );
          formik.setErrors({ intermedioEnd: 'La hora de inicio debe ser menor a la hora de fin' });
          return;
        }

        const data = {
          branch: branchId,
          matutinoStart: moment(values.matutinoStart).format('HH:mm'),
          matutinoEnd: moment(values.matutinoEnd).format('HH:mm'),
          intermedioStart: moment(values.intermedioStart).format('HH:mm'),
          intermedioEnd: moment(values.intermedioEnd).format('HH:mm'),
          vespertinoStart: moment(values.vespertinoStart).format('HH:mm'),
          vespertinoEnd: moment(values.vespertinoEnd).format('HH:mm'),
        }

        const response = await createHours(data);

        notification(
          "Éxito",
          "Horario actualizado",
          "success"
        );
        closeFn();
      } catch (error) {
        console.log('error: ', error);
        notification(
          "error",
          "Error al actualizar el tiempo",
          "danger"
        );
      } finally {
        setLoading(true);
      }
    },
  });

  useEffect(() => {
    const existinHours = async () => {
      const response = await readHoursByBranch(branchId);
      if(response) {
        formik.setValues({
          branch: branchId,
          matutinoStart: response.matutinoStart ? moment(response.matutinoStart, 'HH:mm') : null,
          matutinoEnd: response.matutinoEnd ? moment(response.matutinoEnd, 'HH:mm') : null,
          intermedioStart: response.intermedioStart ? moment(response.intermedioStart, 'HH:mm') : null,
          intermedioEnd: response.intermedioEnd ? moment(response.intermedioEnd, 'HH:mm') : null,
          vespertinoStart: response.vespertinoStart ? moment(response.vespertinoStart, 'HH:mm') : null,
          vespertinoEnd: response.vespertinoEnd ? moment(response.vespertinoEnd, 'HH:mm') : null,
        })
      }
    }
    existinHours();
  }, [branchId]);

  return (
    <React.Fragment>
      <div>
        <h5 className={`pb-2 ${(formik.errors.matutinoEnd ||formik.errors.matutinoStart) && 'text-danger' }`}>Horario matutino</h5>
        <div className="d-flex flex-row gap-3">
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Inicio matutino" value={formik.values.matutinoStart} onChange={(date) => formik.setFieldValue('matutinoStart', date)} />
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Fin matutino" value={formik.values.matutinoEnd} onChange={(date) => formik.setFieldValue('matutinoEnd', date)} />
        </div>
      </div>
      <div className='mt-4 py-2'>
      <h5 className={`pb-2 ${(formik.errors.intermedioStart ||formik.errors.intermedioEnd) && 'text-danger' }`}>Horario intermedio</h5>
      <div className="d-flex flex-row gap-3">
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Inicio intermedio" value={formik.values.intermedioStart} onChange={(date) => formik.setFieldValue('intermedioStart', date)} />
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Fin intermedio" value={formik.values.intermedioEnd} onChange={(date) => formik.setFieldValue('intermedioEnd', date)} />
        </div>
      </div>
      <div className='mt-4'>
      <h5 className={`pb-2 ${(formik.errors.vespertinoEnd ||formik.errors.vespertinoStart) && 'text-danger' }`}>Horario vespertino</h5>
        <div className="d-flex flex-row gap-3">
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Inicio vespertino" value={formik.values.vespertinoStart} onChange={(date) => formik.setFieldValue('vespertinoStart', date)} />
          <TimePicker views={['hours', 'minutes']} minutesStep={30} ampm label="Fin vespertino" value={formik.values.vespertinoEnd} onChange={(date) => formik.setFieldValue('vespertinoEnd', date)} />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Actualizar
        </button>
      </div>
    </React.Fragment>
  )
}

export default EditTime
