import React from "react";
import { notification } from "../../utils/Notifications.tsx";
import { readApplicationStatuses } from "../../services/applicationStatuses.service.ts";
import { readPositions } from "../../services/position.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import ApplicationStatus from "../../models/ApplicationStatuses.ts";
import { Position } from "../../models/Position.ts";
import Shift from "../../models/Shift.ts";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../../store/slices/solicitudesSlice.ts";
import { RootState } from "../../store/index.ts";

const FiltroSolicitudes = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const initialValues = {
    status: -1,
    position: -1,
    monthlySalary: 0,
    shift: -1,
    search: "",
  };

  const dispatch = useDispatch();
  const [status, setStatus] = React.useState<ApplicationStatus[]>([]);
  const [positions, setPositions] = React.useState<Position[]>([]);
  const [shifts, setShifts] = React.useState<Shift[]>([]);
  const [filters, setFilters] = React.useState(initialValues);
  const [timer, setTimer] = React.useState<any>(null);
  const [showPills, setShowPills] = React.useState(false);

  const fetchDependencies = async () => {
    try {
      const statusResponse = await readApplicationStatuses();
      setStatus(statusResponse);

      const positionResposne = await readPositions();
      setPositions(positionResposne);

      const shiftsResponse = await getShifts();
      setShifts(shiftsResponse);
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al tratar de obtener los filtros",
        "danger"
      );
    }
  };

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  const updateFilters = () => {
    const queryString = Object.keys(filters)
      .filter((key) => filters[key] !== -1 && filters[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue })); // Actualización inmediata
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, search: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));

        setTimer(null);
      }, 500)
    );
  };

  const selectedFilters = (filters: any) => {
    //return the propertie, the value and the value name in one single object
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== -1 && filters[key] !== "" && filters[key] !== 0) {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "status":
            keyName = "Estatus";
            valueName = status.find((item) => item.id === value)?.name || "";
            break;
          case "position":
            keyName = "Puesto";
            valueName = positions.find((item) => item.id === value)?.name || "";
            break;
          case "shift":
            keyName = "Turno";
            valueName = shifts.find((item) => item.id === value)?.name || "";
            break;
          case "search":
            keyName = "Búsqueda";
            valueName = value;
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    console.log(selected);
    return selected;
  }

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const queryString = Object.keys(updatedFilters)
      .filter((key) => updatedFilters[key] !== -1 && updatedFilters[key] !== "")
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(updatedFilters[key])}`
      )
      .join("&");
    dispatch(setQuery(queryString));
  }

  return (
    <div>
      <div className="input-group mb-2">
        {Boolean(user.rol !== "Reclutador/Archivos") && (
          <button
            className="btn btn-white dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
          >
            <span className="d-none d-md-inline">Filtrar </span>
            <span className="d-inline d-md-none">
              <i className="fa fa-filter"></i>
            </span>
            <b className="caret"></b>
          </button>
        )}
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          {!user.branch && (
            <React.Fragment>
              <label>Status:</label>
              <select
                className="form-select mt-1"
                id="status"
                name="status"
                onChange={(e) =>
                  setFilters({ ...filters, status: Number(e.target.value) })
                }
                value={filters.status}
              >
                <option disabled value={-1}>
                  Todos
                </option>
                {status.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </React.Fragment>
          )}

          <label className="mt-2">Puesto:</label>
          <select
            className="form-select mt-1"
            id="position"
            name="position"
            onChange={(e) =>
              setFilters({ ...filters, position: Number(e.target.value) })
            }
            value={filters.position}
          >
            <option value={-1} disabled>
              Todos
            </option>
            {positions.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>

          <label className="mt-2">Turno:</label>
          <select
            className="form-select mt-1"
            id="shift"
            name="shift"
            onChange={(e) =>
              setFilters({ ...filters, shift: Number(e.target.value) })
            }
            value={filters.shift}
          >
            <option value={-1} disabled>
              Todos
            </option>
            {shifts.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>

          <label className="mt-2">Expectativa Salarial:</label>
          <input
            type="number"
            className="form-control mt-1"
            id="monthly_salary"
            name="monthlySalary"
            placeholder="Expectativa"
            onChange={(e) =>
              setFilters({ ...filters, monthlySalary: Number(e.target.value) })
            }
            value={filters.monthlySalary}
          />

          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    status: -1,
                    position: -1,
                    monthlySalary: 0,
                    shift: -1,
                    search: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                // type="submit"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Ingresa un nombre..."
              id="name"
              name="name"
              onChange={(e) => handleSearchChange(e)}
              value={filters.search}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        {
        showPills && selectedFilters(filters).map((filter: any) => (
          <div className="badge bg-primary text-white fs-6 me-2 position-relative pe-4">
            {filter.keyName}: {filter.valueName}
            <button
              type="button"
              className="btn-close btn-close-white position-absolute top-0 end-0 mt-1 me-1 ps-1s"
              aria-label="Close"
              onClick={() => removeFilter(filter.key)}
            ></button>
          </div>
        ))
      }
      </div>
    </div>
  );
};

export default FiltroSolicitudes;
