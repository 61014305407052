import React, { useEffect, useState } from "react";
import Modal from "./Modal.tsx";

const DocumentsSelector = ({
  filesTemplateList,
  documentTypesList,
  setDocInmediata,
  setDocNoInmediata,
  editing,
}) => {
  const filterDocuments = (selectedDocs, availableDocs) => {
    return availableDocs.filter(
      (doc) => !selectedDocs.some((selectedDoc) => selectedDoc.id === doc.id)
    );
  };

  const [availableDocuments, setAvailableDocuments] = useState(
    documentTypesList || []
  );
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedDocumentPreview, setSelectedDocumentPreview] = useState();
  const [selectedDocumentImportant, setSelectedDocumentImportant] =
    useState(false);
  const [selectedDocuments2, setSelectedDocuments2] = useState([]);
  const [selectedDocumentPreview2, setSelectedDocumentPreview2] = useState();
  const [selectedDocumentImportant2, setSelectedDocumentImportant2] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const switchModal = () => {
    setShowModal(!showModal);
  };

  const switchModal2 = () => {
    setShowModal2(!showModal2);
  };

  const resetModal = () => {
    setSelectedDocumentPreview(false);
    setSelectedDocumentImportant(false);
    setSelectedDocumentPreview2(false);
    setSelectedDocumentImportant2(false);
  };

  const handleDocumentChange = () => {
    let selectedDocument = availableDocuments.find(
      (doc) => doc.id === Number(selectedDocumentPreview)
    );

    if (selectedDocumentImportant) {
      selectedDocument = addImportantAttribute(selectedDocument);
    } else {
      selectedDocument = removeImportantAttribute(selectedDocument);
    }

    setSelectedDocuments([...selectedDocuments, selectedDocument]);

    setAvailableDocuments(
      availableDocuments.filter((doc) => doc.id !== selectedDocument.id)
    );
    resetModal();
    switchModal();
  };

  const handleDocumentChange2 = () => {
    let selectedDocument2 = availableDocuments.find(
      (doc) => doc.id === Number(selectedDocumentPreview2)
    );

    if (selectedDocumentImportant2) {
      selectedDocument2 = addImportantAttribute(selectedDocument2);
    }

    setSelectedDocuments2([...selectedDocuments2, selectedDocument2]);
    setAvailableDocuments(
      availableDocuments.filter((doc) => doc.id !== selectedDocument2.id)
    );
    resetModal();
    switchModal2();
  };

  const handleRemoveDocument = (doc) => {
    setSelectedDocuments(
      selectedDocuments.filter((selectedDoc) => selectedDoc.id !== doc.id)
    );
    setAvailableDocuments([...availableDocuments, doc]);
  };

  const handleRemoveDocument2 = (doc) => {
    setSelectedDocuments2(
      selectedDocuments2.filter((selectedDoc) => selectedDoc.id !== doc.id)
    );
    setAvailableDocuments([...availableDocuments, doc]);
  };

  const addImportantAttribute = (doc) => {
    return {
      ...doc,
      obligatory: true,
    };
  };

  const removeImportantAttribute = (doc) => {
    return {
      ...doc,
      obligatory: false,
    };
  };

  useEffect(() => {
    const { documents: inmediatos } = filesTemplateList[0] || { documents: [] };
    const { documents: noInmediatos } = filesTemplateList[1] || {
      documents: [],
    };

    setSelectedDocuments(inmediatos);
    setSelectedDocuments2(noInmediatos);
    console.log("inmediatos", inmediatos);

    const usedDocuments = inmediatos.concat(noInmediatos);

    const availableDocs = filterDocuments(usedDocuments, documentTypesList);

    setAvailableDocuments(availableDocs);

    setSelectedDocumentImportant(false);
    setSelectedDocumentImportant2(false);
  }, [filesTemplateList]);

  useEffect(() => {
    setDocInmediata(selectedDocuments);
    setDocNoInmediata(selectedDocuments2);
  }, [selectedDocuments, selectedDocuments2]);

  return (
    <div className="mt-5">
      <div>
        <div className="row mt-4 fs-13px col-md-12">
          <h5 className="modal-title mb-4 fw-700 col-md-9 ms-auto">
            Documentación inmediata
          </h5>
        </div>
        <div className="row mb-4 fs-13px col-md-12">
          <label className="form-label col-form-label col-md-3 text-end">
            Documento:
          </label>
          <Modal
            title="Documentación inmediata"
            switchModal={switchModal}
            showModal={showModal}
          >
            <div className="row mb-4 fs-13px col-md-12">
              <label className="form-label col-form-label col-md-3 text-end">
                Documento:
              </label>

              <div className="col-md-9">
                <select
                  className="form-select form-control fs-14px"
                  id="floatingSelect"
                  name="warranty_type_id"
                  style={{
                    width: "100%",
                    display: "block",
                  }}
                  onChange={(e) => setSelectedDocumentPreview(e.target.value)}
                >
                  {availableDocuments.map((doc) => (
                    <option key={doc.id} value={doc.id}>
                      {doc.name === "Selecciona un tipo de documento"
                        ? "-- Selecciona un documento --"
                        : doc.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row mb-15px fs-13px d-flex align-items-center">
                <label className="form-label col-form-label col-md-3 text-end">
                  Obligatorio:
                </label>
                <div className="col-md-9">
                  <input
                    type="checkbox"
                    className="form-check-input ms-1"
                    onChange={(e) =>
                      setSelectedDocumentImportant(e.target.checked)
                    }
                    checked={selectedDocumentImportant}
                  />
                </div>
              </div>

              <button
                className="btn btn-primary mt-3"
                onClick={handleDocumentChange}
              >
                Agregar
              </button>
            </div>
          </Modal>
        </div>

        <div className="row mb-4 fs-13px col-md-12">
          {selectedDocuments.length === 0 && (
            <div className="col-md-9 ms-auto">
              <p className="mt-3">No hay documentos seleccionados</p>
            </div>
          )}

          <div className="col-md-12">
            <ul className="col-md-9 ms-auto">
              {selectedDocuments.map((doc) => (
                <li key={doc.id} className="d-flex align-items-center">
                  <button
                    onClick={() => handleRemoveDocument(doc)}
                    className="btn btn-link me-2 p-0"
                  >
                    <i className="fa-solid fa-circle-xmark text-danger"></i>
                  </button>
                  <span>
                    {doc.name} {doc.obligatory && "(Obligatorio)"}{" "}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div>
        <h5 className="modal-title mb-4 mt-5 fw-700">
          Documentación no inmediata
        </h5>
        <div className="row mb-4 fs-13px col-md-12">
          <label className="form-label col-form-label col-md-3 text-end">
            Documento:
          </label>

          <Modal
            title="Documentación no inmediata"
            switchModal={switchModal2}
            showModal={showModal2}
          >
            <div className="row mb-4 fs-13px col-md-12">
              <label className="form-label col-form-label col-md-3 text-end">
                Documento:
              </label>
              <div className="col-md-9">
                <select
                  className="form-select form-control fs-14px"
                  id="floatingSelect"
                  name="warranty_type_id"
                  style={{
                    width: "100%",
                    display: "block",
                  }}
                  onChange={(e) => setSelectedDocumentPreview2(e.target.value)}
                >
                  {availableDocuments.map((doc) => (
                    <option key={doc.id} value={doc.id}>
                      {doc.name === "Selecciona un tipo de documento"
                        ? "-- Selecciona un documento --"
                        : doc.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row mb-15px fs-13px d-flex align-items-center">
                <label className="form-label col-form-label col-md-3 text-end">
                  Obligatorio:
                </label>
                <div className="col-md-9">
                  <input
                    type="checkbox"
                    className="form-check-input ms-1"
                    onChange={(e) =>
                      setSelectedDocumentImportant2(e.target.checked)
                    }
                    checked={selectedDocumentImportant2}
                  />
                </div>
              </div>

              <button
                className="btn btn-primary mt-3"
                onClick={handleDocumentChange2}
                disabled={!selectedDocumentPreview2}
              >
                Agregar
              </button>
            </div>
          </Modal>
        </div>

        <div className="row mb-4 fs-13px col-md-12">
          {selectedDocuments2.length === 0 && (
            <div className="col-md-9 px-4 ms-auto">
              <p className="mt-3">No hay documentos seleccionados</p>
            </div>
          )}

          <div className="col-md-12">
            <ul className="col-md-9 ms-auto">
              {selectedDocuments2.map((doc) => (
                <li key={doc.id} className="d-flex align-items-center">
                  <button
                    onClick={() => handleRemoveDocument2(doc)}
                    className="btn btn-link me-2 p-0"
                  >
                    <i className="fa-solid fa-circle-xmark text-danger"></i>
                  </button>
                  <span>
                    {doc.name} {doc.obligatory && "(Obligatorio)"}{" "}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsSelector;
