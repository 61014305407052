import React from 'react'
import EmptyImage from '../../assets/img/ic_content.svg'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index.ts';
import moment from 'moment';
import { toCamelCase } from '../../hooks/toCameCase.tsx';

const TableEntradas = () => {
  const { records } =
    useSelector((state: RootState) => state.entradas);

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-3">
        <thead>
          <tr>
            <th style={{ width: '23%' }}>Colaborador</th>
            <th style={{ width: '23%' }}>Sucursal asignada</th>
            <th style={{ width: '23%' }}>Sucursal de checado</th>
            <th style={{ width: '23%' }}>Fecha</th>
            <th style={{ width: '22%' }}>Hora de checado</th>
            <th style={{ width: '20%' }}>Estatus</th>
          </tr>
        </thead>
        <tbody>
          {
            records?.length === 0 ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <img src={EmptyImage} alt="No hay registros" />
                  <h6 className="mt-2">No hay registros</h6>
                </td>
              </tr>
            ) : records?.map((record: any) => (
              <tr key={record.id}>
                <td>{toCamelCase(`${record.partner.person.firstName} ${record.partner.person.lastName}`)}</td>
                <td>{record.partner.candidate.branch.name}</td>
                <td>{record.branch?.name}</td>
                <td>{moment(record.exactDate).format('DD/MM/YYYY')}</td>
                <td>{record?.checkTime}</td>
                <td>{record?.status}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default TableEntradas