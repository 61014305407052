import httpClient from "./httpClient";

const prefix = "/proceedings";

export async function fetchTemplantes() {
  return (await httpClient.get(`${prefix}/get-templates`)).data;
}

export async function findTemplateByPosition(id: number) {
  return (await httpClient.get(`${prefix}/find-template/${id}`)).data;
}