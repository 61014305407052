import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { RootState } from "../../store/index.ts";

import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { formatDate } from "../../utils/index.ts";
import { IPendingChange } from "../../interfaces/others/IPendingChange";
import { CheckPositionChangeSchema } from "../../validation/schemas/CheckPositionChangeSchema.ts";
import { CheckPositionChangeInitial } from "../../interfaces/formik/ICheckPositionChange.ts";
import CustomInput from "../formik/CustomInput.tsx";
import { setFetch, setLoading } from "../../store/slices/colaboradoresSlice.ts";
import {
  authorizeOperation,
  rejectOperation,
} from "../../services/changePosition.service.ts";
import { notification } from "../../utils/Notifications.tsx";

const PendingChange: React.FC<IPendingChange> = ({
  partner,
  pendingChange,
  closeFn,
}) => {
  const dispatch = useDispatch();
  const { fetch } = useSelector((state: RootState) => state.colaboradores);
  const { user } = useSelector((state: RootState) => state.auth);

  const formik = useFormik({
    validationSchema: CheckPositionChangeSchema,
    initialValues: CheckPositionChangeInitial,
    onSubmit: async (values) => {
      const { status, reasonForRejection } = values;
      dispatch(setLoading(true));
      try {
        if (status) {
          // Aprobar
          const response = await authorizeOperation(pendingChange.id);
          notification("", "Cambio de posición aprobado", "success");
        } else {
          // Rechazar
          const response = await rejectOperation(pendingChange.id, {
            reasonForRejection,
          });
          notification("", "Cambio de posición rechazado", "info");
        }
      } catch (error) {
        notification("", "Ah ocurrido un error, intentalo mas tarde", "danger");
      } finally {
        closeFn();
        dispatch(setFetch(fetch));
        dispatch(setLoading(false));
      }
    },
  });

  return (
    <React.Fragment>
      <div className="alert alert-warning" role="alert">
        <h5 className="card-title text-muted">
          <i className="fa-solid fa-triangle-exclamation"></i>&#160;
          {toCamelCase(partner.person.firstName)} tiene pendiente un cambio de
          posición{" "}
        </h5>
      </div>

      <div className="card border-0">
        <ul className="list-group list-group-flush">
          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              Antiguo puesto:&#160;
            </p>
            {pendingChange.oldPosition.name}
          </li>
          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              Antigua sucursal:&#160;
            </p>
            {pendingChange.oldBranch.name}
          </li>

          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              Nuevo puesto:&#160;
            </p>
            {pendingChange.newPosition.name}
          </li>
          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              Nueva sucursal:&#160;
            </p>
            {pendingChange.newBranch.name}
          </li>

          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              {" "}
              Razón:&#160;
            </p>
            {pendingChange.reasonForPositionChange}
          </li>
          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              {" "}
              Usuario que solicito:&#160;
            </p>
            {pendingChange.requesterUser.name}{" "}
            {pendingChange.requesterUser.lastName}{" "}
            {pendingChange.requesterUser.secondLastName}
          </li>
          <li
            className="list-group-item"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              {" "}
              Fecha de solicitud:&#160;
            </p>
            {formatDate(pendingChange.requestDate)}
          </li>
        </ul>
      </div>
      {user?.rol === "Administrador" && (
        <React.Fragment>
          <div className="border-top " style={{ margin: 0 }}></div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <CustomCheckboxField
                formik={formik}
                field="status"
                label="Aprobar cambio"
                mdLabel={4}
              />

              {/* Si no poner un input del motivo de porque no  */}
              {!formik.values.status && (
                <CustomInput
                  formik={formik}
                  field="reasonForRejection"
                  label="Motivo de rechazo"
                  placeholder="Motivo de rechazo"
                  sm={9}
                  smLabel={3}
                />
              )}
            </li>
          </ul>
        </React.Fragment>
      )}

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cerrar
        </button>
        {user?.rol === "Administrador" && (
          <button
            className="btn btn-azul me-2"
            onClick={() => formik.handleSubmit()}
          >
            Enviar
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default PendingChange;
