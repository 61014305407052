import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AppRoute, { PublicRoutes } from "./config/app-route.jsx";
import { Provider } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
// bootstrap
import "bootstrap";

// css
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import "./scss/react.scss";
import "bootstrap-social/bootstrap-social.css";
import "./assets/css/talentohumano.css";
import "react-notifications-component/dist/theme.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useDispatch, useSelector } from "react-redux";
import { store } from "./store/index.ts";
import Loader from "./components/Loader.tsx";

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token !== undefined) {
      setLoading(false);
    }
  }, [token]);

  let element = token ? AppRoute : PublicRoutes;

  if (token && user?.rol !== "Administrador") {
    let routes = element;
    const rutas = routes[0]?.children?.filter((route) => {
      if (route?.children) {
        route.children = route.children.filter((child) => {
          return child?.roles?.includes(user?.rol);
        });

        return route?.children.length > 0;
      }
      return route?.roles?.includes(user?.rol);
    });

    routes[0].children = rutas;
    element = routes;
  }

  const routes = useRoutes(element);

  return (
    <React.Fragment>
      {loading ? <Loader isLoading={true} /> : routes}
    </React.Fragment>
  );
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <ReactNotifications />
    </Provider>
  </BrowserRouter>
);
