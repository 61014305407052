import React from 'react'
import CustomSelectField from '../../formik/CustomSelectField.tsx'
import { BusinessNames } from '../../../models/BusinessNames.ts';
import { notification } from '../../../utils/Notifications.tsx';
import { readBusinessNames } from '../../../services/businessName.service.ts';

const BussinesNameSelect = ({ formik }) => {
  const [businessNameList, setBusinessNameList] = React.useState<
    BusinessNames[]
  >([]);

  const getDependencies = async () => {
    try {
      const businessNames = await readBusinessNames();
      setBusinessNameList(businessNames);
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  return (
    <div>
      <CustomSelectField
        formik={formik}
        field='businessName'
        list={businessNameList}
        label='Nombre de la empresa'
        placeholder='Selecciona una empresa'
      />
    </div>
  )
}

export default BussinesNameSelect