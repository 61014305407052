import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps";

const SelectEditProfile = ({
  formik,
  field,
  list,
  label,
  onChange = () => {},
  disabled = false,
  numeric = true,
  sm = 2,
  ...props
}: IFormikProps & {
  field: string;
  list: any[];
  label: string;
  onChange?: () => void;
  disabled?: boolean;
  numeric?: boolean;
  sm?: number;
  unclickable?: boolean;
}) => {
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  return (
    <React.Fragment>
      <div className={`col-md-${sm}`}>
        <select
          className={`form-select form-control fs-13px ${
            error ? "is-invalid" : ""
          }`}
          id="floatingSelect"
          name="warranty_type_id"
          onChange={(e) => {
            formik.setFieldValue(field, numeric ? Number(e.target.value) : e.target.value);
            onChange();
          }}
          style={{
            color: error ? "red" : "inherit",
            width: "100%",
            display: "block",
          }}
          value={formik.values[field] !== -99 ? formik.values[field] : "_"}
          disabled={disabled}
        >
          <option value="_">
            -- Selecciona un {label} --
          </option>
          {list.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>
        {formik.touched[field] && formik.errors[field] && (
          <div
            className="invalid-feedback"
            style={{
              marginTop: "1px",
              display: "flex",
              marginLeft: "0.25em",
            }}
          >
            {formik.touched[field] && formik.errors[field] ? (
              <>{formik.errors[field]}</>
            ) : (
              <p> </p>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectEditProfile;
