import React, { useContext, useEffect, useState } from "react";

import { AppSettings } from "../../config/app-settings";

import CalendarDisponibilidad from "./calendarDisponibilidad";
import CalendarAgenda from "./calendarAgenda";
import Layout from "../../components/Layout.tsx";

function Calendar() {
  const context = useContext(AppSettings);
  const [renderDispCalendar, setRenderDispCalendar] = useState(false);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-0");
    context.handleSetAppTopMenu(true);

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
      context.handleSetAppTopMenu(false);
    };

    // eslint-disable-next-line
  }, []);

  const handleShowTab2 = () => {
    setRenderDispCalendar(true);
  };
  const handleShowTab1 = () => {
    setRenderDispCalendar(false);
  };

  return (
    <Layout>
      <ul className="nav nav-pills m-2">
        <li className="nav-item" onClick={handleShowTab1}>
          <a
            href="#nav-pills-tab-1"
            data-bs-toggle="tab"
            className="nav-link active"
          >
            <span className="d-sm-none">Agenda</span>
            <span className="d-sm-block d-none">Agenda</span>
          </a>
        </li>
        <li className="nav-item" onClick={handleShowTab2}>
          <a href="#nav-pills-tab-2" data-bs-toggle="tab" className="nav-link">
            <span className="d-sm-none">Disponibilidad</span>
            <span className="d-sm-block d-none">Disponibilidad</span>
          </a>
        </li>
      </ul>
      <div className="tab-content p-3 rounded-top panel rounded-0 m-0">
        <div className="tab-pane fade active show" id="nav-pills-tab-1">
          {!renderDispCalendar && <CalendarAgenda />}
        </div>
        <div className="tab-pane fade" id="nav-pills-tab-2">
          {renderDispCalendar && <CalendarDisponibilidad />}
        </div>
      </div>
    </Layout>
  );
}

export default Calendar;
