import * as Yup from "yup";

export const PositionSchema = Yup.object().shape({
  positionName: Yup.string().required("Campo requerido"),
  highRisk: Yup.boolean().required("Campo requerido"),
  idDepartment: Yup.number().required("Campo requerido"),
  idBusinessName: Yup.number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  documentTypesInmediate: Yup.array()
    .min(1, "Debe seleccionar al menos un documento")
    .required("Campo requerido"),
  documentTypesNoInmediate: Yup.array()
    .min(1, "Debe seleccionar al menos un documento")
    .required("Campo requerido"),
});
