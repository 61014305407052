import React from "react";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import { useFormik } from "formik";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { AddDocSchema } from "../../validation/schemas/AddDocSchema.ts";

const AddDocumentEdit = ({
  formik,
  documentList,
  closeFn,
  originalField,
}: IFormikProps & {
  documentList: Array<{ id: number; name: string }>;
  closeFn: () => void;
  originalField: string;
}) => {
  const formikDoc = useFormik({
    validationSchema: AddDocSchema,
    initialValues: {
      idDoc: -99,
      required: false,
    },
    onSubmit: (values) => {
      console.log(values)
      const originalArray = formik.values[originalField];
      originalArray.push(values.idDoc);
      formik.setFieldValue(originalField, originalArray);
      closeFn();
    },
  });

  return (
    <React.Fragment>
      <CustomSelectField
        formik={formikDoc}
        field="idDoc"
        list={documentList}
        label="Documento"
      />

      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className="btn btn-success"
          onClick={() => formikDoc.handleSubmit()}
        >
          Guardar
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddDocumentEdit;
