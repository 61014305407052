import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  total: 0,
  records: [],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  query: "",
  fetch: false,
  current: {},
  showDetails: false,
  filters: {
    checkBranch: "",
    partnerBranch: "",
  },
}

const assistanceChecksSlice = createSlice({
  name: "assistanceChecks",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    },
    setRecords: (state, action) => {
      state.records = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.page = 0
    },
    setFetch: (state, action) => {
      state.fetch = !state.fetch
    },
    setQuery: (state, action) => {
      state.query = action.payload
      state.page = 0
    },
    setCurrent: (state, action) => {
      state.current = action.payload
    },
    setShowDetails: (state, action) => {
      state.showDetails = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const {
  setPage,
  setLimit,
  setRecords,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  setCurrent,
  setShowDetails,
  setFilters,
} = assistanceChecksSlice.actions

export default assistanceChecksSlice.reducer