import React from "react";
import IFormikProps from "../../interfaces/others/IFormikProps.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import { PanelBody } from "../panel/panel.jsx";
import CustomInput from "../formik/CustomInput.tsx";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { readBusinessNames } from "../../services/businessName.service.ts";
import { BusinessNames } from "../../models/BusinessNames.ts";
import { notification } from "../../utils/Notifications.tsx";
import { getAllAvailablesTemplates } from "../../services/proceedings.service.ts";
import { fetchDepartments } from "../../services/department.service.ts";
import { sortByName, sortByPosition } from "../../utils/index.ts";

const PositionFormEdit = ({ formik }: IFormikProps) => {
  const [businessNameList, setBusinessNameList] = React.useState<
    BusinessNames[]
  >([]);
  const [positionWithTemplate, setPositionWithTemplate] = React.useState<any[]>(
    []
  );
  const [templates, setTemplates] = React.useState<any[]>([]);
  const [departments, setDepartments] = React.useState<any[]>([]);

  const getDependencies = async () => {
    try {
      const businessNames = await readBusinessNames();
      const orderBusinessNames = sortByName(businessNames);
      setBusinessNameList(orderBusinessNames);

      const templates = await getAllAvailablesTemplates();
      const orderTemplates = sortByPosition(templates);
      setTemplates(orderTemplates);

      const departments = await fetchDepartments();
      const orderDepartaments = sortByName(departments);
      setDepartments(orderDepartaments);
      if (templates.length > 0) {
        const temp = templates.map((template) => template.position);
        const orderTemplates = sortByName(temp);
        setPositionWithTemplate(orderTemplates);
      }
    } catch (error) {
      notification("Error ⚠️", "Error al obtener las dependencias", "danger");
    }
  };

  React.useEffect(() => {
    getDependencies();
  }, []);

  return (
    <React.Fragment>
      <div>
        <PanelBody>
          <CustomInput
            formik={formik}
            field="positionName"
            label="Nombre del puesto"
            placeholder="Puesto"
            sm={3}
          />
          <CustomSelectField
            formik={formik}
            field="idBusinessName"
            list={businessNameList}
            label="Razón social"
            sm={3}
          />

          <CustomCheckboxField
            formik={formik}
            field="highRisk"
            label="Alto riesgo"
          />

          <CustomSelectField
            formik={formik}
            field="copyTemplate"
            list={positionWithTemplate}
            label="Copiar plantilla de"
            sm={3}
            placeholder={"puesto"}
            updateFormik={(e) => {
              // Buscar las plantillas del puesto seleccionado
              const templateFound: any = templates.find(
                (template: any) =>
                  template.position.id === Number(e.target.value)
              );
              if (templateFound?.templates?.length > 0) {
                formik.setFieldValue("documentTypesInmediate", []);
                formik.setFieldValue("documentTypesNoInmediate", []);

                const inmediateDocuemnts = templateFound.templates.find(
                  (template: any) => template.inmediate
                );

                const idDocumentsInmediate = inmediateDocuemnts.documents.map(
                  (record) => record.id
                );

                const noInmediateDocuemnts = templateFound.templates.find(
                  (template: any) => !template.inmediate
                );

                const idDocuemtnsNoInmediate =
                  noInmediateDocuemnts.documents.map((record) => record.id);

                formik.setFieldValue(
                  "documentTypesInmediate",
                  idDocumentsInmediate
                );
                formik.setFieldValue(
                  "documentTypesNoInmediate",
                  idDocuemtnsNoInmediate
                );
              }
            }}
          />
          <CustomSelectField
            formik={formik}
            field="idDepartment"
            list={departments}
            label="Departamento"
            sm={3}
          />
        </PanelBody>
      </div>
    </React.Fragment>
  );
};

export default PositionFormEdit;
