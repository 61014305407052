import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSettings } from '../../../hooks/useAppSettings.tsx';
import moment from 'moment';
import { findByExactDateAndBranch, readPositionsByBranchAndShift } from '../../../services/branchSchedule.service.ts';
import { shifts } from '../../../utils/constanst.js';
import Layout from '../../Layout.tsx';
import Title from '../../Title.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/index.ts';

const groupPositionsByUniqueName = (positions) => {
  const groupedPositions = {};

  positions.forEach((position) => {
    const positionName = position.position.name;

    if (!groupedPositions[positionName]) {
      groupedPositions[positionName] = [];
    }

    const totalWorkersRequired = position.numberOfWorkers;
    for (let i = 0; i < totalWorkersRequired; i++) {
      const workerKey = `${positionName} ${i + 1}`;
      const workerIndex = groupedPositions[positionName].findIndex(
        (worker) => worker.positionName === workerKey
      );

      if (workerIndex === -1) {
        groupedPositions[positionName].push({
          positionName: workerKey,
          shifts: [position.shift],
        });
      } else {
        groupedPositions[positionName][workerIndex].shifts.push(position.shift);
      }
    }
  });

  return Object.values(groupedPositions).flat();
};

const ResumenHorariosAsistencia = () => {
  let id

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if(user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }
  
  useAppSettings();
  const [schedules, setSchedules] = useState<any[]>([]);
  const [positions, setPositions] = useState<any[]>([]);

  const fetchDependencies = useCallback(async () => {
    const today = moment().format('YYYY-MM-DD');

    try {
      const fetchedSchedules = await findByExactDateAndBranch(today, Number(id));
      setSchedules(fetchedSchedules);

      const fetchedPositions = await readPositionsByBranchAndShift(Number(id), 'Todos');
      const uniquePositionArray = groupPositionsByUniqueName(fetchedPositions);
      setPositions(uniquePositionArray);

      console.log('Fetched schedules: ', fetchedSchedules);
      console.log('Unique position array: ', uniquePositionArray);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, [id]);

  useEffect(() => {
    fetchDependencies();
  }, [fetchDependencies]);

  
  const getColorByPartners = (partners) => {

    if (partners.length === 0) {
      return 'white';
    }

    let missingCounter = 0;

    partners.forEach(partner => {
      if (partner.assistanceStatus === null || partner.assistanceStatus === '' || partner.assistanceStatus === 'Fuera de horario' ) {
        missingCounter++;
      }
    });

    if (missingCounter === partners.length) {
      return 'red';
    }

    if (partners.length === 1) {
      return partners[0].assistanceStatus === 'Asistencia' ? '#0f0' : '';
    }
    
    return missingCounter > 0 ? 'yellow' : '#0f0';
  };


  return (
    <Layout>
      <Title baseTitle="Resumen de turnos" basePath="/Resumen de turnos" title="Resumen de turnos" />
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <div className="table-responsive mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }} className="border-end">Puesto</th>
                    <th style={{ width: '20%' }} className="border-end">Matutino</th>
                    <th style={{ width: '20%' }} className="border-end">Intermedio</th>
                    <th style={{ width: '20%' }} className="border-end">Vespertino</th>
                  </tr>
                </thead>
                <tbody>
                  {positions.map((position, index) => (
                    <tr key={index}>
                      <td style={{ width: '20%' }} className="border-end">{position.positionName}</td>
                      {shifts.filter(shift => shift.name !== 'Todos').map((shift, idx) => {
                        const partners = schedules.filter(
                          schedule =>
                            schedule.positionName === position.positionName &&
                            schedule.shift === shift.name
                        );

                        // const backgroundColor = position.shifts.includes(shift.name)
                        //   ? partner?.assistanceStatus === 'Asistencia'
                        //     ? '#0f0'
                        //     : partner?.assistanceStatus === 'Retardo'
                        //       ? '#f00'
                        //       : '#fff'
                        //   : '#fff';

                        // const textColor = position.shifts.includes(shift.name) && partner
                        //   ? 'red'
                        //   : 'black';

                        // const content = !position.shifts.includes(shift.name)
                        //   ? 'No aplica'
                        //   : partner
                        //     ? `${partner.partner.person.firstName} ${partner.partner.person.lastName}`
                        //     : '';

                        // return (
                        //   <td key={idx} className="border-end" style={{ width: '20%', backgroundColor }}>
                        //     <span style={{ color: textColor }}>{content}</span>
                        //   </td>
                        // );

                        return (
                          <td key={idx} className="border-end" style={{ width: '20%', backgroundColor: getColorByPartners(partners) }}>
                            {partners.map((partner, idx) => (
                              <div key={idx}>{`${partner.partner.person.firstName} ${partner.partner.person.lastName}`}</div>
                            ))}
                            {
                              !position.shifts.includes(shift.name) && <div>No aplica</div>
                            }
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResumenHorariosAsistencia;
