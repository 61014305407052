import React from "react";
import Role from "../../models/Role";
import { listAllRoles } from "../../services/role.service";
import IFormikProps from "../../interfaces/others/IFormikProps";
import { notification } from "../../utils/Notifications.tsx";
import Loader from "../Loader.tsx";

const SelectRoles = ({ formik, field }: IFormikProps & { field: string }) => {
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const error = formik.touched[field] && Boolean(formik.errors[field]);

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await listAllRoles();
      setRoles(response);
    } catch (error) {
      console.error(error);
      notification("Ocurrio un error", "Error al obtener los roles", "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  return (
    <React.Fragment>
      <div className="row mb-15px fs-13px">
        <label
          className="form-label col-form-label col-md-3 text-end"
          style={{ color: error ? "red" : "inherit" }}
        >
          Selecciona un rol&nbsp;
          <span style={{ top: 0, right: 0, color: error ? "red" : "inherit" }}>
            *
          </span>
        </label>
        <div className="col-md-3">
          <select
            className={`form-select form-control fs-15px ${
              error ? "is-invalid" : ""
            }`}
            id="floatingSelect"
            name="warranty_type_id"
            onChange={(e) => formik.setFieldValue(field, e.target.value)}
            style={{ color: error ? "red" : "inherit" }}
            value={formik.values[field] !== -99 ? formik.values[field] : "_"}
          >
            <option value="_" disabled>
              -- Selecciona un rol --
            </option>
            {roles.map((role: Role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>

          {formik.touched[field] && formik.errors[field] && (
            <div
              className="invalid-feedback"
              style={{
                marginTop: "1px",
                display: "flex",
                marginLeft: "0.25em",
              }}
            >
              {formik.touched[field] && formik.errors[field] ? (
                <>{formik.errors[field]}</>
              ) : (
                <p> </p>
              )}
            </div>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </React.Fragment>
  );
};

export default SelectRoles;
