import { useState } from "react";

const apiUrl = process.env.REACT_APP_API_URL;
export class CalendarService {


    static getAvaibles = async () => {
        const url = apiUrl + "/availabilities";
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        const resp = await fetch(url, {
            method: "GET",
            headers,
        });

        const data = await resp.json();
        console.log(data)
        
        return data;
    }
    
    static postAvaibles = async (formData) => {
        console.log(formData)
        const url = apiUrl + "/availabilities";
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        const resp = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(formData)
        });

        const data = await resp.json();
        return data;
    }

    static putAvaibles = async (formData,id) => {
        const url = apiUrl + "/availabilities/"+id;
        
        console.log("URL",url);
        console.log("FORMDATA",formData);

        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        const resp = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(formData),
            
        });

        const data = await resp.json();
        return data;
    }

    static getApplications = async () => {
        const url = apiUrl + "/applications/?page=1";
        let headers = new Headers();
        headers.append("Content-Type", "application/json");

        const resp = await fetch(url, {
            method: "GET",
            headers,
        });

        const data = await resp.json();
        return data;
    }

}