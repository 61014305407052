import { IUser } from "../interfaces/formik/IUser";
import httpClient from "./httpClient";

const prefix = "/user";

export async function paginateUsers(query: string) {
  return (await httpClient.get(`${prefix}/list-paginated?${query}`)).data;
}

export async function saveUser(data: any) {
  return (await httpClient.post(`${prefix}/save`, data)).data;
}

export async function getUser(id: number) {
  return (await httpClient.get(`${prefix}/get-user/${id}`)).data;
}

export async function updateUser(id: number, data: any) {
  return (await httpClient.put(`${prefix}/update-user/${id}`, data)).data;
}

export async function deleteUser(id: number) {
  return (await httpClient.delete(`${prefix}/delete-user/${id}`)).data;
}
