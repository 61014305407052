import * as yup from "yup";

const SaveCredentialsSchema = yup.object({
  email: yup
    .string()
    .email("Escribe un correo válido")
    .required("Campo requerido"),
});

export default SaveCredentialsSchema;
