import React from "react";
import { IFormikFieldProps } from "../../interfaces/formik/IFormikField";
import InputMask from "react-input-mask";

const InputPhone = ({
  sm = 12,
  smLabel = 3,
  min = 0,
  max = 1000000,
  hidden = false,
  required = true,
  readonly = false,
  withIcon = false,
  autoFocus = false,
  type = "text",
  label = "",
  unclickable = false,
  onChangeFile = (e: any) => { },
  ...props
}: IFormikFieldProps & { onChangeFile?: (e: any) => void; label: string }) => {
  const error =
    props.formik.touched[props.field] &&
    Boolean(props.formik.errors[props.field]);

  const unclickableObject = { pointerEvents: 'none', cursor: 'not-allowed' }

  return (
    <tr>
      <td className={`field`} style={{ color: `${error ? "red" : "black"}` }}>
        {label} :{" "}
      </td>
      <td>
        <div className={`col-md-2`}>
          <InputMask
            disabled={props.disabled}
            mask="(999) 999-99-99"
            className={`form-control  mb-5px fs-13px ${error ? "is-invalid" : ""
              }`}
            placeholder="(951) 123-45-67"
            name="telefono"
            value={props.formik.values[props.field]}
            onChange={(e) => {
              const phone = e.target.value.replace(/[^0-9]/g, "");
              props.formik.setFieldValue(props.field, phone);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                props.formik.handleSubmit();
              }
            }}
            style={unclickable ? unclickableObject : {}}

          />
          <div
            className="invalid-feedback"
            style={{
              marginTop: "-1%",
              display: "flex",
              marginLeft: "0.25em",
            }}
          >
            {props.formik.touched[props.field] &&
              props.formik.errors[props.field] && (
                <>{props.formik.errors[props.field]}</>
              )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default InputPhone;
