import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { RootState } from "../../store/index.ts";
import { read, utils, write } from "xlsx";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { excelDateToJSDate, formatDate } from "../../utils/index.ts";
import { notification } from "../../utils/Notifications.tsx";
import ModalContainer from "../../components/ModalContainer.tsx";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import {
  BlackListExcel,
  ImportBlackList,
} from "../../models/ImportBlackList.ts";
import { importBlackList } from "../../services/blacklist.service.ts";
import ImportBlackListForm from "../../components/forms/ImportBlackListForm.tsx";
import { setShowModalEditCurrentBlackList } from "../../store/slices/listaNegraSlice.ts";

const ImportListaNegra = () => {
  useAppSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentFile, showModalEditCurrentBlackList } = useSelector(
    (state: RootState) => state.listaNegra
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [records, setRecords] = React.useState<BlackListExcel[]>([]);
  const [currentBlackList, setCurrentBlackList] =
    React.useState<BlackListExcel>({} as BlackListExcel);
  const [currentIndex, setCurrentIndex] = React.useState<number>(-1);
  const [validRecords, setValidRecords] = React.useState<number>(0);

  const readFile = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) return;

      const workbook = read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = utils.sheet_to_json(sheet) as ImportBlackList[];

      const list: BlackListExcel[] = rows.map((row, index) => {
        return new BlackListExcel({
          CURP: row.CURP,
          FECHA_BANEO: excelDateToJSDate(Number(row.FECHA_BANEO)),
          RAZON: row.RAZON,
          ES_PERMANENTE: row.ES_PERMANENTE,
        });
      });
      setRecords(list);
      console.log(list);
    };

    reader.readAsArrayBuffer(currentFile);
  };

  const uploadRecords = async () => {
    setLoading(true);
    try {
      const validRecords = records.filter((record) => record.validate());
      if (validRecords.length === 0) {
        notification("", "No hay registros validos para subir", "danger");
        return;
      }

      const response = await importBlackList({ blacklists: validRecords });

      const translatedResponse = response.map((record) => ({
        ICONO: record?.status === "Record already exists" ? "❌" : "✅",
        MENSAJE:
          record?.status === "Record already exists"
            ? "Usuario previamente agregado"
            : "Usuario agregado",
        CURP: record.CURP,
        FECHA_BANEO: record.ES_PERMANENTE
          ? ""
          : formatDate(record.FECHA_BANEO?.toString()),
        RAZON: record.RAZON,
        ES_PERMANENTE: record.ES_PERMANENTE ? "Si" : "No",
      }));

      const wb = utils.book_new();

      const ws = utils.json_to_sheet(translatedResponse);

      utils.book_append_sheet(wb, ws, "Registros subidos");

      const wbout = write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      saveAs(blob, "registros_subidos.xlsx");

      notification("", "Registros subidos con exito", "success");
    } catch (error) {
      console.log(error);
      notification("Ha ocurrido un error", error, "danger");
    } finally {
      setLoading(false);
      // navigate("/lista-negra")
    }
  };

  // React.useEffect(() => {
  //   if (!currentFile) setError("No se ha seleccionado un archivo");
  //   if (currentFile) {
  //     readFile();
  //   }
  // }, [currentFile]);

  React.useEffect(() => {
    setValidRecords(records.filter((record) => record.validate()).length);
  }, [records]);

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Lista negra"
        basePath="/lista-negra"
        title="Lista negra"
        activeTitle="Importar lista negra"
        activePath="/lista-negra/importar"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Información a importar
          </PanelHeader>
          <PanelBody>
            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="fa-solid fa-triangle-exclamation fa-lg"></i>{" "}
                {error}
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <div className="alert alert-primary" role="alert">
                  Registros totales: {records.length}
                </div>
                {/* <div className="alert alert-success" role="alert">
                  Registros validos: {validRecords}
                </div>
                <div className="alert alert-warning" role="alert">
                  Registros no validos: {records.length - validRecords}
                </div> */}
              </div>
              <div style={{}}>
                <button
                  type="button"
                  className="btn btn-azul"
                  onClick={() => uploadRecords()}
                  style={{
                    cursor: records.length === 0 ? "not-allowed" : "",
                    pointerEvents: "all",
                  }}
                  disabled={records.length === 0}
                >
                  Subir registros
                </button>
                <button
                  type="button"
                  className="btn btn-azul"
                  onClick={() => {
                    setCurrentIndex(-1);
                    setCurrentBlackList({} as BlackListExcel);
                    dispatch(setShowModalEditCurrentBlackList(true));
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Nuevo
                </button>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-panel text-nowrap align-middle mb-0 table-bordered  text-nowrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>CURP</th>
                    <th>Razón</th>
                    <th>Fecha de baneo</th>
                    <th>Es permanente</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => {
                    const isValid = record.validate();
                    const curpValid = !record.curpValid();
                    const badDataStyle = {
                      backgroundColor: "#e00404",
                      color: "white",
                    };

                    return (
                      <tr key={index}>
                        <td>
                          <i
                            className={`fa-solid fa-${
                              isValid ? "check" : "xmark"
                            } fa-2x`}
                            style={{
                              color: isValid ? "green" : "red",
                            }}
                          />
                        </td>
                        <td style={curpValid ? badDataStyle : {}}>
                          {record.CURP}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.RAZON) ? badDataStyle : {}
                          }
                        >
                          {record.RAZON}
                        </td>
                        <td
                        // style={
                        //   record.dateValid(record.FECHA_BANEO)
                        //     ? badDataStyle
                        //     : {}
                        // }
                        >
                          {record?.FECHA_BANEO
                            ? formatDate(record.FECHA_BANEO.toString())
                            : ""}
                        </td>
                        <td
                          style={
                            record.isInvalid(record.ES_PERMANENTE)
                              ? badDataStyle
                              : {}
                          }
                        >
                          {record.ES_PERMANENTE}
                        </td>
                        <td>
                          <i
                            className="fa-solid fa-pencil fa-2x amarillo"
                            onClick={() => {
                              dispatch(setShowModalEditCurrentBlackList(true));
                              setCurrentBlackList(record);
                              setCurrentIndex(index);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </PanelBody>
          <ModalContainer
            open={showModalEditCurrentBlackList}
            closeFn={() => dispatch(setShowModalEditCurrentBlackList(false))}
            title={"Agregar registro"}
            children={
              <ImportBlackListForm
                initialValues={currentBlackList}
                records={records}
                setRecords={setRecords}
                currentIndex={currentIndex}
              />
            }
          />
        </Panel>
      </div>
    </Layout>
  );
};

export default ImportListaNegra;
