import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMenuColaboradoresSelected,
  MenuSelectedColaboradoresEnum,
} from "../../types/ColaboradoresSliceType.ts";
import { Partner } from "../../models/Partner.ts";
import { PartnerExcel } from "../../models/ImportPartner.ts";

const initialState = {
  menuSelected: MenuSelectedColaboradoresEnum.todos,
  menuSelectedByBranch: MenuSelectedColaboradoresEnum.activos,
  total: 0,
  colaboradores: [] as Partner[],
  page: 0,
  limit: 50,
  loading: true,
  param: "",
  fetch: false,
  filtros: {
    search: "",
    ingreso: "",
    sucursal: -1,
    puesto: -1,
  },
  query: "",
  showModal: false,
  modalChildren: null,
  modalTitle: "",
  show: false,
  currentUser: null,
  modalCredentials: false,
  showDeleteModal: false,
  dataToRemove: null,
  modalAcountant: false,
  showModalImportExcel: false,
  currentFile: null,
  currentPartner: {} as PartnerExcel,
  showModalEditCurrentPartner: false,
  sortBy: "id",
  defaultSort: "desc",
  messageModal: false,
};

const colaboradoresSlice = createSlice({
  name: "colaboradores",
  initialState,
  reducers: {
    setMenu: (state, action: ActionMenuColaboradoresSelected) => {
      state.menuSelected = action.payload;
      state.menuSelectedByBranch = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setColaboradores: (state, action: PayloadAction<Partner[]>) => {
      state.colaboradores = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setFiltros: (state, action: PayloadAction<any>) => {
      state.filtros = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    resetState: (state) => {
      const modalShow = state.showModal;
      const title = state.modalTitle;
      const children = state.modalChildren;
      Object.assign(state, initialState);
      state.showModal = modalShow;
      state.modalTitle = title;
      state.modalChildren = children;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalChildren: (state, action: PayloadAction<any>) => {
      state.modalChildren = action.payload;
    },
    setModalTitle: (state, action: PayloadAction<string>) => {
      state.modalTitle = action.payload;
    },
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },
    setModalCredentials: (state, action: PayloadAction<boolean>) => {
      state.modalCredentials = action.payload;
    },
    setModalDelete: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
    setDataToRemove: (state, action: PayloadAction<any>) => {
      state.dataToRemove = action.payload;
    },
    setShowModalImportExcel: (state, action: PayloadAction<boolean>) => {
      state.showModalImportExcel = action.payload;
    },
    setCurrentFile: (state, action: PayloadAction<any>) => {
      state.currentFile = action.payload;
    },
    setCurrentPartner: (state, action: PayloadAction<PartnerExcel>) => {
      state.currentPartner = action.payload;
    },
    setShowModalEditCurrentPartner: (state, action: PayloadAction<boolean>) => {
      state.showModalEditCurrentPartner = action.payload;
    },

    setModalAcountant: (state, action: PayloadAction<boolean>) => {
      state.modalAcountant = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
    setMessageModal: (state, action: PayloadAction<boolean>) => {
      state.messageModal = action.payload;
    }
  },
});

export const {
  setMenu,
  setPage,
  setLimit,
  setColaboradores,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setFiltros,
  setQuery,
  resetState,
  setShowModal,
  setModalChildren,
  setModalTitle,
  setShow,
  setCurrentUser,
  setModalCredentials,
  setModalDelete,
  setDataToRemove,
  setShowModalImportExcel,
  setCurrentFile,
  setCurrentPartner,
  setShowModalEditCurrentPartner,
  setModalAcountant,
  setSortBy,
  setDefaultSort,
  setMessageModal,
} = colaboradoresSlice.actions;

export default colaboradoresSlice.reducer;
