export enum MenuSelectedEnum {
    position = "positions",
    businessNames = "business-names",
    branch = "branches",
    schooling = "schoolings",
    shift = "shifts",
    size = "sizes",
    Reasons = "Motivos"
}
export interface ActionMenuSelected {
    payload: MenuSelectedEnum;
}