import React from "react";

import { notification } from "../../utils/Notifications.tsx";

import { desactivateVacancie } from "../../services/vacancy.service.ts";

const EditVacante = ({ id, closeFn }) => {
  const onSubmit = async () => {
    try {
      await desactivateVacancie(id.id);
      notification("", "Vacante desactivada correctamente", "info");
      closeFn();
    } catch (error) {
      notification(
        "error",
        "Ocurrio un error al desactivar la vacante",
        "danger"
      );
    }
  };

  return (
    <React.Fragment>
      <div className="mb-3">
        <label className="form-label">
          ¿Esta seguro que desea cambiar el estatus de la vacante?
        </label>
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          className={`btn btn-${!id.active ? "success" : "danger"}`}
          type="submit"
          onClick={() => onSubmit()}
        >
          {!id.active ? " Activar" : " Desactivar"}
        </button>
      </div>
    </React.Fragment>
  );
};

export default EditVacante;
