import * as yup from "yup";

export const EditAssistanceTimeSchema = yup.object({
  onTimeBeforeMinutes: yup
    .number()
    .required("El tiempo es requerido"),
  onTimeAfterMinutes: yup
    .number()
    .required("El tiempo es requerido"),
  lateAfterMinutes: yup
    .number()
    .min(yup.ref('onTimeAfterMinutes'), "El valor debe de ser mayor al tiempo de entrada")
    .required("El tiempo es requerido"),
});
