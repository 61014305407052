import { updatePartner } from "../services/partner.service.ts";

export async function updateUserData(field, partnerObject, values) {
  console.log("updateUserData: ", partnerObject.person);
  const address = partnerObject.person.address
  const addressBeneficiary = partnerObject.person.addressBeneficiary;
  const birthdate = partnerObject.person.birthdate;
  const email = partnerObject.person.email;
  const firstNameBeneficiary = partnerObject.person.firstNameBeneficiary;
  const idMex = partnerObject.person.idMex;
  const genere = partnerObject.person.genere;
  const idSchooling = partnerObject.person.schooling?.id;
  const lastName = partnerObject.person.lastName;
  const lastNameBeneficiary = partnerObject.person.lastNameBeneficiary;
  const maritalStatus = partnerObject.person.maritalStatus;
  const name = partnerObject.person.firstName;
  const nss = partnerObject.person.nss;
  const ocr = partnerObject.person.ocr;
  const phone = partnerObject.person.phone;
  const phoneBeneficiary = partnerObject.person.phoneBeneficiary;
  const relationshipBeneficiary = partnerObject.person.relationshipBeneficiary;
  const secondLastName = partnerObject.person.secondLastName;
  const secondLastNameBeneficiary = partnerObject.person.secondLastNameBeneficiary;
  const rfc = values[field];

  const partner = {
      address,
      addressBeneficiary,
      birthdate,
      email,
      firstNameBeneficiary,
      genere,
      idMex,
      idSchooling,
      lastName,
      lastNameBeneficiary,
      maritalStatus,
      name,
      nss,
      ocr,
      phone,
      phoneBeneficiary,
      relationshipBeneficiary,
      secondLastName,
      secondLastNameBeneficiary,
      rfc,
  };
  console.log("partner: ", partner);
  console.log("partnerObject: ", partnerObject);
  await updatePartner(partnerObject.person.id, partner);
}
