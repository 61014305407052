import httpClient from "./httpClient";

const prefix = "/proceedings";

export async function getProceedingByPartner(id) {
  return (await httpClient.get(`${prefix}/get-by-partner/${id}`)).data;
}

export async function getProceedingByPartnerAndType(id, type) {
  return (await httpClient.get(`${prefix}/${id}/${type}`)).data;
}

export async function getTemplateByPosition(id: number) {
  return (await httpClient.get(`${prefix}/find-template/${id}`)).data;
}

export async function getAllAvailablesTemplates() {
  return (await httpClient.get(`${prefix}/find-all-templates-availables`)).data;
}

export async function updateTemplate(id: number, data: any) {
  return (await httpClient.put(`${prefix}/update-template/${id}`, data)).data;
}

export async function deleteFileProceeding(id: number) {
  return (await httpClient.delete(`${prefix}/delete-file-proceeding/${id}`))
    .data;
}

export async function getFileById(id: string) {
  return (await httpClient.get(`${prefix}/find-file/${id}`)).data;
}

export async function getFilePreviewById(id: string) {
  return (await httpClient.get(`${prefix}/find-file-preview/${id}`)).data;
}

export async function getPdfPreviewById(id: string) {
  return (await httpClient.get(`${prefix}/find-pdf-preview/${id}`)).data;
}