import * as yup from "yup";

export const AddDocSchema = yup.object().shape({
  idDoc: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  required: yup.boolean().required("Availability is required"),
});
