import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";

function ExtraError() {
  const contextApp = useAppSettings();
  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-0");

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="error">
      <div className="error-code">404</div>
      <div className="error-content">
        <div className="error-message">No pudimos encontrar la página...</div>
        <div className="error-desc mb-4">
          La página que estás buscando no existe. <br />
        </div>
        <div>
          <Link to="/" className="btn btn-success px-3">
            Ir al inicio
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ExtraError;
