import React from "react";

const FullScreenContainer = ({
  open,
  tittle,
  children,
  onClsFn,
}) => {

  if (open) return (
    <div className="modal d-block" role="dialog">
      <div className="modal-dialog modal-fullscreen" role="document">
        <div className="modal-content" style={{ height: '100vh' }}>
          <h5 className="modal-title p-3">{tittle}</h5>

            {children}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClsFn}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenContainer;
