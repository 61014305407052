import * as yup from "yup";

export const DeletePostulanteSchema = yup.object().shape({
  reasonId: yup
    .number()
    .required("Campo requerido")
    .positive("Campo requerido")
    .integer("Campo requerido"),
  comments: yup.string().required("Campo requerido"),
});
