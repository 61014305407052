import React, { useEffect } from 'react'
import moment from 'moment'
import CustomInput from '../../../formik/CustomInput.tsx'
import { useFormik } from 'formik'
import CustomSelectField from '../../../formik/CustomSelectField.tsx'
import { updateAssistance, updateScheduleStatus, updateScheduleStatusName, updateScheduleStatusOut } from '../../../../services/assistanceCheck.service.ts'
import { notification } from '../../../../utils/Notifications.tsx'
type Props = {}

const ModalEditAssistance = ({ record, checkListArray, closeFn }) => {

  const [checkList, setCheckList] = React.useState<any[]>([])
  const statusList =
    [{ id: 0, name: 'Asistencia' }, { id: 1, name: 'Retardo' }, { id: 2, name: 'Fuera de horario' }, { id: 3, name: 'Sin registro' }]

  const currentIndex = record?.branchSchedule?.assistanceStatus ? statusList.find((status) => status.name === record?.branchSchedule?.assistanceStatus)?.id : -99

  useEffect(() => {
    const checkList = record.assistanceChecks.map((check) => ({
      id: check.id,
      name: check.checkTime,
    }))

    setCheckList(checkList)
  }, [])

  const formik = useFormik({
    initialValues: {
      fecha: moment(record?.branchSchedule.exactDate).format('dddd, D [de] MMMM [de] YYYY'),
      hora: moment(record?.branchSchedule.startTime.split(' - ')[0], 'HH:mm:ss').format('h:mm:ss A'),
      estatus: currentIndex === 0 ? 0 : currentIndex || -99,
      checado: record?.branchSchedule?.assistanceCheck?.id || -99,
      checadoSalida: record?.branchSchedule?.assistanceCheckout?.id || -99,
    },
    onSubmit: async (values) => {
      try {
        console.log('values: ', values)
        const selectedCheck = record.assistanceChecks.find((check) => check.id === values.checado)
        const selectedCheckOut = record.assistanceChecks.find((check) => check.id === values.checadoSalida)

        console.log('selectedCheck: ', selectedCheck)
        console.log('selectedCheckOut: ', selectedCheckOut)

        const obj = {
          ...selectedCheck,
          status: statusList.find((status) => status.id === values.estatus).name
        }
        const obj2 = {
          ...selectedCheck,
          status: statusList.find((status) => status.id === values.estatus).name
        }

        delete obj.id
        delete obj2.id
        delete obj.partner
        delete obj2.partner

        const updatedCheck = await updateAssistance(selectedCheck.id, obj)

        console.log('updatedCheck: ', updatedCheck)

        const updatedSchedule = await updateScheduleStatus(updatedCheck.id, record?.branchSchedule.id)
        const updatedScheduleOut = await updateScheduleStatusOut(selectedCheckOut.id, record?.branchSchedule.id)
        await updateScheduleStatusName(selectedCheck.id, record?.branchSchedule.id)

        console.log('updatedSchedule: ', updatedSchedule)
        console.log('updatedScheduleOut: ', updatedScheduleOut)

        await closeFn()

        notification('Guardado', 'Registro actualizado correctamente', 'success')
      } catch (error) {
        console.error('error: ', error)
        notification('error', 'Ocurrió un error al actualizar el registro', 'danger')
      }
    }
  });

  console.log('record: ', record)

  return (
    <div className='d-flex flex-column'>
      <CustomInput
        formik={formik}
        field={'fecha'}
        label={'Fecha'}
        sm={9}
        disabled
      />
      <CustomInput
        formik={formik}
        field={'hora'}
        label={'Hora'}
        sm={9}
        disabled
      />
      <CustomSelectField
        formik={formik}
        field={'checado'}
        label={'Entrada'}
        list={checkList}
        sm={9}
      />
      <CustomSelectField
        formik={formik}
        field={'checadoSalida'}
        label={'Salida'}
        list={checkList}
        sm={9}
      />
      <CustomSelectField
        formik={formik}
        field={'estatus'}
        label={'Estatus'}
        sm={9}
        list={statusList}
      />
      <button className="btn btn-primary ms-auto me-4" onClick={formik.submitForm}>Guardar</button>
    </div>
  )
}

export default ModalEditAssistance