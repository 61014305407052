import React from "react";
import { IDeleteUser } from "../../interfaces/others/IDeleteUser";
import { notification } from "../../utils/Notifications.tsx";
import { deleteUser } from "../../services/user.service.ts";
import { useDispatch } from "react-redux";
import { setFetch } from "../../store/slices/userSlice.ts";

const DeleteUser: React.FC<IDeleteUser> = ({ name, id, closeFn }) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      await deleteUser(id);
      notification("", `Usuario ${name} eliminado correctamente`, "info");
    } catch (error) {
      notification(
        "Error :(",
        `Ocurrio un error al tratar de eliminar a ${name}`,
        "danger"
      );
    } finally {
      dispatch(setFetch(true));
      closeFn();
    }
  };

  return (
    <React.Fragment>
      <p className="fs-15px">¿Estás seguro de que deseas eliminar a {name}?</p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-danger" onClick={() => handleDelete()}>
          Eliminar
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteUser;
