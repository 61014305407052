import React, { useEffect, useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { CatalogosService } from "../../services/catalogos.service.js";
import { PostulanteService } from "../../services/postulantes.service.js";
import ModalContainer from "../../components/ModalContainer.tsx";
import S3Service from "../../services/s3.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { readApplicationStatuses } from "../../services/applicationStatuses.service.ts";
import { readReason } from "../../services/reasons.service.ts";
import { getSchoolings } from "../../services/schooling.service.ts";
import { getShifts } from "../../services/shift.service.ts";
import { getVacancies } from "../../services/vacancy.service.ts";
import {
  approbePhase,
  rejectPhase,
  updateApplication,
} from "../../services/application.service.ts";
import DeletePostulante from "../../components/postulantes/DeletePostulante.tsx";
import Loader from "../../components/Loader.tsx";
import Dropzone from "react-dropzone";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

function FormResult({ route }) {
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [schoolings, setSchoolings] = useState([]);
  const [positions, setPositions] = useState([]);
  const [shifts, setShifts] = useState([]);

  const [perfil, setPerfil] = useState(null);
  const [perfilUrl, setPerfilUrl] = useState(null);
  const [ine, setIne] = useState(null);
  const [ine1, setIne1] = useState(null);
  const [ineUrl, setIneUrl] = useState(null);
  const [ine1Url, setIne1Url] = useState(null);
  const [domicilio, setDomicilio] = useState(null);
  const [domicilioUrl, setDomicilioUrl] = useState(null);
  const [solicitud, setSolicitud] = useState(null);
  const [solicitudUrl, setSolicitudUrl] = useState(null);
  const [psicometrics, setPsicometrics] = useState([]);
  const [psicometricsUrl, setPsicometricsUrl] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [status, setStatus] = useState("");
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState("");
  const [applicationStatuses, setApplicationStatuses] = useState([]);

  const [isDisabled, setIsDisabled] = useState(
    location.state.applicationStatus.name !== "Solicitado"
  );
  useEffect(() => {
    dependecies();

    fetchImages();

    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass("p-4");

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass("");
    };
    // eslint-disable-next-line
  }, []);

  const dependecies = async () => {
    try {
      const positions = await getVacancies();
      setPositions(positions);

      const schoolings = await getSchoolings();
      setSchoolings(schoolings);

      const shifts = await getShifts();
      setShifts(shifts);

      const applicationStatuses = await readApplicationStatuses();
      setApplicationStatuses(applicationStatuses);

      const reasons = await readReason();
      setReasons(reasons);
    } catch (error) {
      notification("Ocurrio un error!", error.message, "danger");
      console.log(error);
    }
  };

  const fetchImages = async () => {
    try {
      const profileUrl = await S3Service.getFile(
        "PERFIL_" + location.state.person.curp
      );
      setPerfilUrl(profileUrl.url);
      const ineUrl = await S3Service.getFile(
        "INE_" + location.state.person.curp
      );
      setIneUrl(ineUrl.url);
      const ine1Url = await S3Service.getFile(
        "INE_1_" + location.state.person.curp
      );
      setIne1Url(ine1Url.url);
      const domicilioUrl = await S3Service.getFile(
        "DOMICILIO_" + location.state.person.curp
      );
      setDomicilioUrl(domicilioUrl.url);
      const solicitudUrl = await S3Service.getFile(
        "SOLICITUD_" + location.state.person.curp
      );
      setSolicitudUrl(solicitudUrl.url);

      const psicometricsUrl = await S3Service.getFiles(
        "PSICOMETRICO_" + location.state.person.curp
      );
      setPsicometricsUrl(psicometricsUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeReason = (event) => {
    setReason(event.target.selectedIndex);
  };

  const handlePerfilChange = (e) => {
    const file = e.target.files[0];
    setPerfil(file);
  };

  const handleIneChange = (e) => {
    const file = e.target.files[0];
    setIne(file);
  };

  const handleIne1Change = (e) => {
    const file = e.target.files[0];
    setIne1(file);
  };

  const handleDomicilioChange = (e) => {
    const file = e.target.files[0];
    setDomicilio(file);
  };

  const handleSolicitudChange = (e) => {
    const file = e.target.files[0];
    setSolicitud(file);
  };

  const handleFileChange = async (acceptedFiles) => {
    const validImages = [];
    const invalidFiles = [];

    acceptedFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        validImages.push(file);
      } else {
        invalidFiles.push(file);
        notification("Error", "Solo se permiten imágenes", "danger");
      }
    });

    setPsicometrics([...psicometrics, ...validImages]);
  };

  const handleDelete = (index) => {
    const newPsicometrics = [...psicometrics];
    newPsicometrics.splice(index, 1);
    setPsicometrics(newPsicometrics);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    /*
    if (Boolean(psicometrics.length === 0) && !isDisabled) {
    // if (!isDisabled) {
      notification(
        "Error",
        "Se requiere al menos una prueba psicométrica",
        "danger"
      );
      setIsLoading(false);
      return;
    }
    */
    let operation;
    let body = {};
    if (location.state.applicationStatus.name == "Solicitado") {
      operation = "fill_out";

      body.personId = location.state.id_person;
      body.curp = location.state.person.curp;
      body.firstName = event.target.firstName.value;
      body.lastName = event.target.lastName.value;
      body.secondLastName = event.target.secondLastName.value;
      body.birthdate = event.target.birthdate.value;
      body.phone = event.target.phone.value;

      for (let index = 0; index < schoolings.length; index++) {
        if (schoolings[index].name == event.target.schoolingId.value) {
          body.schoolingId = schoolings[index].id;
          index = schoolings.length;
        }
      }

      body.address = event.target.address.value;
      body.positionId = event.target.positionId.value;

      // ! ?????????
      // for (let index = 0; index < positions.length; index++) {
      //   if (positions[index].name == event.target.positionId.value) {
      //     body.positionId = positions[index].id;
      //     index = positions.length;
      //   }
      // }

      body.monthlySalary = event.target.monthlySalary.value;
      body.stayingMonths = event.target.stayingMonths.value;

      for (let index = 0; index < shifts.length; index++) {
        if (shifts[index].name == event.target.shiftId.value) {
          body.shiftId = shifts[index].id;
          index = shifts.length;
        }
      }

      body.experience = event.target.experience.value;
      body.children = event.target.children.value;
      body.health = event.target.health.value;
      body.diseases = event.target.diseases.value;

      body.applicationDate = location.state.application_date;
      body.applicationTime = location.state.application_time;
      // body.applicationStatusId = location.state.id_application_status;

      // Key: "PERFIL_" + location.state.person.curp,
      // Key: "INE_" + location.state.person.curp,
      // Key: "DOMICILIO_" + location.state.person.curp,
      // Key: "SOLICITUD_" + location.state.person.curp,

      try {
        const profile = await S3Service.upload(
          perfil,
          "PERFIL_" + location.state.person.curp
        );
        const ineS3 = await S3Service.upload(
          ine,
          "INE_" + location.state.person.curp
        );
        const ine1S3 = await S3Service.upload(
          ine1,
          "INE_1_" + location.state.person.curp
        );
        const domiclioS3 = await S3Service.upload(
          domicilio,
          "DOMICILIO_" + location.state.person.curp
        );
        const solicitudS3 = await S3Service.upload(
          solicitud,
          "SOLICITUD_" + location.state.person.curp
        );

        // Upload psicometrics using promise.all
        // const psicometricsS3 = await Promise.all(
        //   psicometrics.map((file, index) => {
        //     return S3Service.upload(
        //       file,
        //       "PSICOMETRICO_" + location.state.person.curp + "_" + index
        //     );
        //   })
        // );
        // console.log("psicometricsS3", psicometricsS3);
      } catch (error) {
        console.log(error);
      }

      // return;
    } else if (location.state.applicationStatus.name == "Entrevistado") {
      // Si el array de psicometricas tiene algo subirlos
      if (psicometrics.length > 0) {
        // Upload psicometrics using promise.all
        const psicometricsS3 = await Promise.all(
          psicometrics.map((file, index) => {
            return S3Service.upload(
              file,
              "PSICOMETRICO_" + location.state.person.curp + "_" + index
            );
          })
        );
      }

      if (status == "Aceptado") {
        operation = "approve";
      } else if (status == "Rechazado") {
        operation = "reject";
        body.reasonId = reason;
        body.comments = event.target.comments.value;
      }
    }

    if (status == "Aceptado") {
      try {
        const response = await approbePhase(location.state.id);
      } catch (error) {
        console.log("Error!!", error);
      }
    } else if (status == "Rechazado") {
      console.log(location.state.id, body);
      try {
        const response = await rejectPhase(location.state.id, body);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await updateApplication(
          location.state.id,
          body,
          operation
        );
      } catch (error) {
        console.log(error);
        notification("Ocurrio un error!", error.message, "danger");
      }
    }

    // console.log(location.state.id, body, operation);
    setIsLoading(false);

    // return;

    setRedirect(true);

    return;
    await PostulanteService.updateApplication(
      location.state.id,
      operation,
      body
    );
  };

  if (redirect) {
    return <Navigate to="/solicitudes" />;
  }

  const handleCancel = async () => {
    try {
      // console.log(location.state.id);
      // const response = await PostulanteService.cancelApplication(
      //   location.state.id
      // );
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-center m-2">
        <div className="brand">
          <div className="w-90px">
            <img
              src="../assets/img/logo/logo_chili_rojo.png"
              alt=""
              className="mw-100"
            />
          </div>
        </div>
      </div>
      <div>
        <form
          onSubmit={handleSubmit}
          key={location.state.id}
          className="fs-13px"
        >
          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              <div className="d-flex align-items-center">
                <div className="flex-1">Datos Generales</div>
                <div className="btn-group my-n1">
                  <a
                    href={`/postulante/historial/${location.state.person.curp}`}
                    style={{ textDecoration: "none" }}
                    onClick={() =>
                      navigate(
                        `/postulante/historial/${location.state.person.curp}`
                      )
                    }
                  >
                    <span class="badge bg-warning fs-12px">
                      Revisar historial <i class="fa-solid fa-file"></i>
                    </span>
                  </a>
                </div>
              </div>
            </PanelHeader>
            <PanelBody>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="firstName"
                      name="firstName"
                      placeholder="Nombre"
                      defaultValue={toCamelCase(location.state.person.firstName)}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="firstName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Nombre(s) *
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="lastName"
                      name="lastName"
                      placeholder="Apellido Paterno"
                      defaultValue={toCamelCase(location.state.person.lastName)}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="lastName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Paterno *
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="secondLastName"
                      name="secondLastName"
                      placeholder="Apellido Materno"
                      defaultValue={toCamelCase(location.state.person.secondLastName)}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="secondLastName"
                      className="d-flex align-items-center fs-13px"
                    >
                      Apellido Materno *
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="date"
                      className="form-control fs-15px"
                      id="birthdate"
                      name="birthdate"
                      defaultValue={location.state.person.birthdate}
                      disabled={isDisabled}
                    />
                    <label
                      htmlFor="nacimiento"
                      className="d-flex align-items-center fs-13px"
                    >
                      Fecha de Nacimiento
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="(999) 999-9999"
                      defaultValue={location.state.person.phone}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="phone"
                      className="d-flex align-items-center fs-13px"
                    >
                      Teléfono
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating mb-3 mb-md-0">
                    <select
                      className="form-select"
                      id="schoolingId"
                      name="schoolingId"
                      required
                      disabled={isDisabled}
                    >
                      {schoolings.map((item) => {
                        const selected =
                          location.state.person.schooling.id == item.id
                            ? "selected"
                            : "";
                        return (
                          <option
                            key={item.id}
                            value={item.name}
                            selected={selected}
                            disabled={isDisabled}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      htmlFor="schoolingId"
                      className="d-flex align-items-center fs-13px"
                    >
                      Escolaridad *
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-floating mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control fs-15px"
                      id="address"
                      name="address"
                      defaultValue={location.state.person.address}
                      disabled={isDisabled}
                      required
                    />
                    <label
                      htmlFor="direccion"
                      className="d-flex align-items-center fs-13px"
                    >
                      Domicilio (Calle, número, colonia, código postal)
                    </label>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Información de la solicitud
            </PanelHeader>
            <PanelBody>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Puesto: *</b>
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    id="positionId"
                    name="positionId"
                    required
                    disabled={isDisabled}
                  >
                    {positions.map((item) => {
                      const selected =
                        location.state.vacancy.position.id == item.position.id
                          ? true
                          : false;
                      return (
                        <option
                          key={item.position.id}
                          value={item.position.id}
                          selected={selected}
                          disabled={isDisabled}
                        >
                          {item.position.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Expectativa Salarial al Mes: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control fs-15px"
                    id="monthlySalary"
                    name="monthlySalary"
                    placeholder="Expectativa Salarial al Mes"
                    defaultValue={location.state.monthlySalary}
                    disabled={isDisabled}
                    min="0"
                    required
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Expectativa de Permanencia en Meses: *</b>
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className="form-control fs-15px"
                    id="stayingMonths"
                    name="stayingMonths"
                    placeholder="Expectativa de permanencia en meses"
                    defaultValue={location.state.stayingMonths}
                    disabled={isDisabled}
                    min="0"
                    required
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Turno: *</b>
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    id="shiftId"
                    name="shiftId"
                    required
                    disabled={isDisabled}
                  >
                    {shifts.map((item) => {
                      const selected =
                        location.state.shift.id == item.id ? "selected" : "";
                      return (
                        <option
                          key={item.id}
                          value={item.name}
                          selected={selected}
                          disabled={isDisabled}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Experiencia:</b>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="3"
                    className="form-control fs-15px"
                    id="experience"
                    name="experience"
                    defaultValue={location.state.experience}
                    disabled={isDisabled}
                    required
                  ></textarea>
                </div>
              </div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Datos personales
            </PanelHeader>
            <PanelBody>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Hijos:</b>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="1"
                    className="form-control fs-15px"
                    id="children"
                    name="children"
                    defaultValue={location.state.children}
                    disabled={isDisabled}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Estado de salud:</b>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="1"
                    className="form-control fs-15px"
                    id="health"
                    name="health"
                    defaultValue={location.state.health}
                    disabled={isDisabled}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 d-flex justify-content-end">
                  <b>Enfermedades crónicas:</b>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="1"
                    className="form-control fs-15px"
                    id="diseases"
                    name="diseases"
                    defaultValue={location.state.diseases}
                    disabled={isDisabled}
                    required
                  ></textarea>
                </div>
              </div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader noButton={true} className="bg-azul">
              Documentación
            </PanelHeader>
            <PanelBody>
              {location.state.applicationStatus.name === "Solicitado" ? (
                <React.Fragment>
                  <div className="row gap-5">
                    <div className="col-md-2">
                      <label className="form-label col-form-label ">
                        Imagen de Perfil
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="perfil"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handlePerfilChange}
                        required
                      />
                      {perfil && (
                        <img
                          src={URL.createObjectURL(perfil)}
                          alt="PERFIL"
                          style={{
                            display: "inline-block",
                            width: "18vw",
                            marginTop: "5%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label ">
                        Identificación Oficial Frente
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="ine"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleIneChange}
                        required
                      />
                      {ine && (
                        <img
                          src={URL.createObjectURL(ine)}
                          alt="INE"
                          style={{
                            display: "inline-block",
                            width: "18vw",
                            marginTop: "5%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label ">
                        Identificación Oficial Posterior
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="ine"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleIne1Change}
                        required
                      />
                      {ine1 && (
                        <img
                          src={URL.createObjectURL(ine1)}
                          alt="INE"
                          style={{
                            display: "inline-block",
                            width: "18vw",
                            marginTop: "5%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Comprobante de domicilio
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="domicilio"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleDomicilioChange}
                        required
                      />
                      {domicilio && (
                        <img
                          src={URL.createObjectURL(domicilio)}
                          alt="DOMICILIO"
                          style={{
                            display: "inline-block",
                            width: "18vw",
                            marginTop: "5%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Solicitud de empleo
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="solicitud"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleSolicitudChange}
                        required
                      />
                      {solicitud && (
                        <img
                          src={URL.createObjectURL(solicitud)}
                          alt="SOLICITUD"
                          style={{
                            display: "inline-block",
                            width: "18vw",
                            marginTop: "5%",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="row gap-5">
                    <div className="col-md-2">
                      <label className="form-label col-form-label ">
                        Imagen de Perfil
                      </label>
                      <a href={perfilUrl}>
                        <img
                          src={perfilUrl}
                          alt="PROFILE"
                          style={{ display: "inline-block", width: "18vw" }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Identificación Oficial Frente
                      </label>
                      <a href={ineUrl}>
                        <img
                          src={ineUrl}
                          alt="INE"
                          style={{ display: "inline-block", width: "18vw" }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Identificación Oficial Posterior
                      </label>
                      <a href={ine1Url}>
                        <img
                          src={ine1Url}
                          alt="INE"
                          style={{ display: "inline-block", width: "18vw" }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Comprobante de domicilio
                      </label>
                      <a href={domicilioUrl}>
                        <img
                          src={domicilioUrl}
                          alt="DOMICILIO"
                          style={{ display: "inline-block", width: "18vw" }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label col-form-label">
                        Solicitud de empleo
                      </label>
                      <a href={solicitudUrl}>
                        <img
                          src={solicitudUrl}
                          alt="SOLICITUD"
                          style={{ display: "inline-block", width: "18vw" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label col-form-label">
                        Pruebas psicométricas
                      </label>
                      <Dropzone
                        accept={"image/png, image/jpeg"}
                        onDrop={(acceptedFiles) =>
                          handleFileChange(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone-container border rounded p-4 text-center"
                            >
                              <input {...getInputProps()} />
                              <i className="fas fa-cloud-upload-alt fa-3x mb-3"></i>
                              <p className="mb-0">
                                Arrastra y suelta archivos aquí o haz clic para
                                seleccionar archivos
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <div className="row">
                        {psicometrics.map((item, index) => {
                          // Verificar si el tipo de archivo comienza con "image/"
                          if (item.type.startsWith("image/")) {
                            return (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  width: "min-content",
                                  backgroundColor: "#f5f6f7",
                                }}
                                className="d-flex m-2 rounded-2"
                              >
                                <button
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }}
                                  onClick={() => handleDelete(index)}
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                                <img
                                  src={URL.createObjectURL(item)}
                                  alt="PSICOMETRICO"
                                  style={{
                                    width: "18vw",
                                    marginTop: "5%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            );
                          } else {
                            //Render a div with the file name
                            return <div key={index}>{item.name}</div>;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </PanelBody>
          </Panel>

          {location.state.applicationStatus.name === "Entrevistado" ? (
            <Panel>
              <PanelHeader noButton={true} className="bg-azul">
                Status
              </PanelHeader>
              <PanelBody>
                <div className="row ">
                  <div className="col-md-6 mt-2 d-flex justify-content-center">
                    <div className="form-floating mb-3 mb-md-0 w-75">
                      <select
                        className="form-select"
                        id="status"
                        name="status"
                        onChange={handleStatusChange}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {applicationStatuses.map((item) => {
                          if (
                            item.name !== "Aceptado" &&
                            item.name !== "Rechazado"
                          )
                            return;
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="status"
                        className="d-flex align-items-center fs-13px"
                      >
                        Status
                      </label>
                    </div>
                  </div>

                  {status === "Rechazado" ? (
                    <div className="row mt-4 " id="rechazo-panel">
                      <div className="col-md-6 mt-2 d-flex justify-content-center">
                        <div className="form-floating mb-3 mb-md-0 w-75">
                          <select
                            className="form-select"
                            id="reason"
                            name="reason"
                            onChange={handleChangeReason}
                            required
                          >
                            <option value="">Seleccione una opción</option>
                            {reasons.map((item) => {
                              return (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            htmlFor="motivoRechazo"
                            className="d-flex align-items-center fs-13px"
                          >
                            Motivo
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex justify-content-center">
                        <div className="form-floating mb-3 mb-md-0 w-75">
                          <input
                            type="text"
                            className="form-control fs-15px"
                            id="comments"
                            name="comments"
                            placeholder="motivo del rechazo"
                            min="0"
                          />
                          <label
                            htmlFor="comentarios"
                            className="d-flex align-items-center fs-13px"
                          >
                            Comentarios
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </PanelBody>
            </Panel>
          ) : (
            <></>
          )}

          <Panel>
            <PanelBody>
              <div className="row noGutters">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-warning h-45px w-75 btn-lg fs-14px"
                    onClick={() => {
                      setShowModal(true);
                      // console.log(location.state.id)
                      // navigate("/solicitudes")
                    }}
                  // Abrir modal y preguntar si quiere solo quiere cancelar la solicitud o si tambien quiere banearlo
                  >
                    Cancelar solicitud
                  </button>
                </div>
                {isLoading == true ? (
                  <center className="col">
                    <div class="loader"></div>
                  </center>
                ) : (
                  <div
                    className="col-md-6"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-azul h-45px w-75 btn-lg fs-14px"
                      disabled={!psicometricsUrl || !psicometrics}
                    >
                      Guardar
                    </button>
                  </div>
                )}
              </div>
            </PanelBody>
          </Panel>
        </form>
      </div>
      <div className="h-75px"></div>
      <div
        className="w-1000px h-75px"
        style={{
          backgroundImage: "url(../../../assets/img/footer/footer-chili.png)",
          backgroundRepeat: "repeat-x",
          position: "fixed",
          bottom: "0",
          left: "0",
          width: "100%",
          zIndex: 1,
        }}
      />
      <ModalContainer
        open={showModal}
        closeFn={() => setShowModal(false)}
        title={`Cancelar solicitud de ${toCamelCase(location.state.person.firstName + ' ' +  location.state.person.lastName)}`}
        children={
          <DeletePostulante
            id={location.state.id}
            name={`${toCamelCase(location.state.person.firstName + ' ' +  location.state.person.lastName)}`}
            closeFn={() => setShowModal(false)}
            handleDelete={() => handleCancel()}
          />
        }
      />
      {/* isLoading */}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default FormResult;
