import React, { useState } from "react";

const Modal = ({ title, children, switchModal, showModal }) => {
  return (
    <div className="col-md-9">
      <button onClick={switchModal} className="btn btn-primary">
        Seleccionar documento
      </button>
      {showModal && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: 1,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <div
            style={{
              backgroundColor: "#fefefe",
              padding: "20px",
              borderRadius: "8px",
              width: "30%",
            }}
          >
            <div className="d-flex mb-4 flex-row justify-content-between align-items-center">
              <h3>{title}</h3>
              <span
                style={{
                  color: "#aaa",
                  float: "right",
                  fontSize: "28px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
                onClick={switchModal}
              >
                &times;
              </span>
            </div>

            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
