import { Partner } from "../../models/Partner";
import { getCurrencyFormat } from "../../utils/index.ts";

export const EditProfileDefault = {
  name: "",
  lastName: "",
  secondLastName: "",
  email: "",
  phone: "",
  idSchooling: -99,
  address: "",
  birthdate: "",
};

export const FillEditProfile = (data: Partner) => {
  return {
    name: data.person.firstName,
    lastName: data.person.lastName,
    secondLastName: data?.person?.secondLastName,
    email: data.person.email,
    phone: data.person.phone,
    idSchooling: data.person.schooling?.id,
    address: data.person.address,
    birthdate: data.person.birthdate,
    genere: data.person.genere,
    maritalStatus: data.person.maritalStatus,
    rfc: data.person.rfc,
    nss: data.person.nss,
    idMex: data.person.idMex,
    ocr: data.person.ocr,
    firstNameBeneficiary: data.person.firstNameBeneficiary,
    lastNameBeneficiary: data.person.lastNameBeneficiary,
    secondLastNameBeneficiary: data.person.secondLastNameBeneficiary,
    relationshipBeneficiary: data.person.relationshipBeneficiary,
    phoneBeneficiary: data.person.phoneBeneficiary,
    addressBeneficiary: data.person.addressBeneficiary,
    clockNumber: data?.clockNumber,
    alergias: data?.person?.alergias,
    tipoSangre: data?.person?.tipoSangre,
    enfermedad: data?.person?.enfermedad,
    tratamiento: data?.person?.tratamiento,
  };
};

export const FillPreviewInfo = (data: Partner) => {
  return {
    branchName: data.candidate.branch.name,
    recruitmentDate: data.candidate.recruitmentDate,
    monthlySalary: data.candidate.monthlySalary,
    monthlySalaryIMSS: data.candidate.monthlySalaryIMSS,
    comments: data.candidate.comments,
    banDate: data.banDate,
    shiftManager: data.candidate.shiftManager,
    shift: data.candidate.shift.id,
  };
};
