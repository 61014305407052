import httpClient from "./httpClient";

const prefix = "/availabilities";

export async function readAvailabilities(start: string, end: string) {
  return (await httpClient.get(`${prefix}?start=${start}&end=${end}`)).data;
}

export async function saveAvailability(data: {}) {
  return (await httpClient.post(`${prefix}`, data)).data;
}

export async function updateAvailability(body: {}, id: string) {
  return (await httpClient.patch(`${prefix}/${id}`, body)).data;
}

export async function getAvailabilitiesByPosition(id: string) {
  return (await httpClient.get(`${prefix}/get-availabilites/${id}`)).data;
}

export async function removeAvailability(id: number) {
  return (await httpClient.delete(`${prefix}/${id}`)).data;
}
