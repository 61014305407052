import React from "react";
import { FileType } from "../../interfaces/others/ILegacyDocument";
import DocumentsViewer from "./DocumentsViewer.tsx";
import DocumentsViewerLegacy from "./DocumentsViewerLegacy.tsx";
import ModalContainer from "../ModalContainer.tsx";
import EditLegacyFile from "./EditLegacyFile.tsx";

const LegacyDocItem = ({
  legacyDoc,
  obligatory,
  closeFn,
  partnerObject,
  refetch,
}: {
  legacyDoc: FileType;
  obligatory: boolean;
  closeFn: any;
  partnerObject?: any;
  refetch?: any;
}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)

  return (
    <div className="timeline-item">
      {/* BEGIN timeline-time */}
      <div className="timeline-time">
        <span className="date">&nbsp;</span>
        <span className="time">{obligatory ? "Obligatorio" : "Opcional"}</span>
      </div>
      <div className="timeline-icon">
        <a>&nbsp;</a>
      </div>
      <div className="timeline-content">
        {/* BEGIN timeline-header */}
        <div className="timeline-header">
          <div className="username fs-1">
            {/* <span className="fs-12px">Subido por el reclutador</span> */}
            <p>{legacyDoc.typeFile}</p>
          </div>
          <div>
            <a
              onClick={() => {
                // setEditing(true);
                setShowModal(true);
              }}
              href="#/"
              className="btn btn-lg border-0 rounded-pill w-40px h-40px p-0 d-flex align-items-center justify-content-center"
            >
              <i className="fa fa-edit text-gray-600" />
            </a>
          </div>
        </div>

        <div className="timeline-body">
          <div className="mb-3">
            <div className="row">
              {
                legacyDoc.files.length > 0 ?
                  <DocumentsViewerLegacy fileData={legacyDoc.files} />
                  :
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary fs-16px"
                      style={{ gap: 10 }}
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fa-solid fa-cloud-arrow-up" />
                      Subir documento
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
        <ModalContainer
          open={showModal}
          title="Subir documento"
          size="lg"
          closeFn={() =>
            setShowModal(false)
          }
          children={
            <EditLegacyFile
              partnerObject={partnerObject}
              file={legacyDoc}
              closeFn={() => {
                setShowModal(false);
                closeFn();
              }}
              refetch={(name)=>{
                console.log("refetching", name)
                refetch(name)
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default LegacyDocItem;
