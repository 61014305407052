import React from 'react'
import { PanelBody } from '../panel/panel.jsx'
import ModalContainer from '../ModalContainer.tsx'
import PlantillaModalPuestos from './PlantillaModalPuestos.tsx'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/index.ts'
import { setPositions } from '../../store/slices/branchTemplateSlice.ts'

const PlantillaFormPuestos = ({ formik, error }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false)
  const [currentRecord, setCurrentRecord] = React.useState(null)
  const { positionsOriginal } = useSelector((state: RootState) => state.branchTemplates);

  const handleDelete = (index) => {
    const remainingPositions = [...positionsOriginal];
    dispatch(setPositions(remainingPositions));

    const newPuestos = formik.values.puestos.filter((_, i) => i !== index);
    formik.setFieldValue('puestos', newPuestos);
  }

  const addAllPositions = () => {
    const newPuestos = positionsOriginal.map((position) => {
      return {
        puesto: position.id,
        matutino: 1,
        intermedio: 1,
        vespertino: 1,
      }
    });

    formik.setFieldValue('puestos', newPuestos);
  }

  return (
    <div>
      <PanelBody>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "1%" }}>
          <div className="col-md-1" />
          <div className="col-md-10">
            <button
              type="button"
              className={`btn btn-outline-${error ? 'danger' : 'secondary'}`}
              onClick={() => setShowModal(true)}
            >
              <i className="fas fa-plus"></i> Agregar puesto
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary ms-3`}
              onClick={() => addAllPositions()}
            >
              <i className="fas fa-plus"></i> Agregar a todos los puestos disponibles
            </button>
            {error &&
              <div className="text-danger mt-1" role="alert">
                {error}
              </div>
            }

            <div className="widget-list rounded mt-4 w-100">
              {formik.values.puestos.map((doc, index) => (
                <div className="widget-list-item" key={index}>
                  <div className="widget-list-media icon my-auto">
                    <a href="#/">
                      <i
                        className="fa fa-trash bg-yellow text-dark"
                        onClick={() => handleDelete(index)}
                      ></i>
                    </a>
                  </div>
                  <div className="widget-list-content col-12">
                    <p className="widget-list-title d-flex flex-row">
                      <h4 className='col-5 my-auto'>
                        {positionsOriginal.find((position) => position.id === doc.puesto || position.id === doc.id)?.name}
                      </h4>
                      <h5 className='my-auto col-6'>Matutino: {doc.matutino}, Intermedio: {doc.intermedio}, Vespertino: {doc.vespertino}</h5>
                      <div className="widget-list-media icon col-1">
                        <a href="#/">
                          <i
                            className="fa fa-pen btn-azul text-dark"
                            onClick={() => {
                              setCurrentRecord(doc);
                              setShowModal(true);
                            }}
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </PanelBody>
      <ModalContainer
        open={showModal}
        closeFn={() => {setShowModal(false);  setCurrentRecord(null);}}
        title={currentRecord ? 'Editar puesto' : 'Agregar puesto'}
        children={
          <PlantillaModalPuestos fomik={formik} closeFn={() => { setShowModal(false); setCurrentRecord(null) }} currentRecord={currentRecord} />
        }
      />
    </div>
  )
}

export default PlantillaFormPuestos



//Editar numero de colaboradores
//nueva vista de colaboradores requeridos
//función para agregar todos los puestos disponibles, agregar con 1 por cada turno
//agregar a sucursal
//personalizado por sucursal
//sucursal en main nav plantilla y sucursal