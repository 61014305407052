import React from "react";
import { PostulanteService } from "../../services/postulantes.service";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader.tsx";
import UpdateCollaborator from "./UpdateCollaborator";
import { fetchApplication } from "../../services/application.service.ts";

const FetchCollaborator = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [data, setData] = React.useState({});

  const fetchCollaborator = async () => {
    setLoading(true);
    try {
      const response = await fetchApplication(Number(id));
      setData(response);
    } catch (error) {
      setError(error);
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCollaborator();
  }, []);

  if (loading) return <Loader isLoading={loading} />;

  return <UpdateCollaborator location={data} />;
};

export default FetchCollaborator;
