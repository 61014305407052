import React from "react";
import { IUpgradeToCollaborator } from "../../interfaces/others/IUpgradeToCollaborator";
import ConfettiExplosion from "react-confetti-explosion";

const UpgradeToCollaborator: React.FC<IUpgradeToCollaborator> = ({
  id,
  func,
  closeFn,
  name,
}) => {
  return (
    <React.Fragment>
      <p className="fs-15px">
        ¿Estás a punto de actualizar a {name} de pre-colaborador a partner?
      </p>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-success" onClick={() => func()}>
          Actualizar
        </button>
      </div>
    </React.Fragment>
  );
};

export default UpgradeToCollaborator;
