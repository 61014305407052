import React from "react";
import { useFormik } from "formik";
import { IUploadContract } from "../../interfaces/others/IUploadContract.ts";
import { SaveFileSchema } from "../../validation/schemas/SaveFileSchema.ts";
import CustomInput from "../formik/CustomInput.tsx";
import S3Service from "../../services/s3.service.ts";
import { uploadImage } from "../../services/files.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { saveContract } from "../../services/contract.service.ts";
import Loader from "../Loader.tsx";

const UploadContract: React.FC<IUploadContract> = ({ partner, closeFn }) => {
  const [file, setFile] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    validationSchema: SaveFileSchema,
    initialValues: { file: null },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const fileResponse = await uploadImage(file);
        const response = await saveContract({
          candidate_id: partner.candidate.id,
          file_id: fileResponse.id,
        });
        console.log("fileResponse", fileResponse);
        console.log("response", response);
        notification("Éxito", "Contrato subido correctamente", "success");
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al subir el contrato",
          "danger"
        );
      } finally {
        setLoading(false);
        closeFn();
      }
    },
  });

  const handleFileChange = async (e) => {
    const file = e
    if (!file) return;
    if(file.type !== 'application/pdf') {
      notification("Error", "Solo se permiten archivos PDF", "danger");
      setFile(null);
      return;
    } else {
      setFile(file);
    }
  }

  return (
    <React.Fragment>
      {/* <p className="fs-15px">¿Estás seguro de que deseas eliminar a {name}?</p> */}
      <CustomInput
        smLabel={3}
        sm={9}
        type="file"
        field="file"
        formik={formik}
        label="Contrato"
        onChangeFile={(e) => handleFileChange(e)}
        accept="application/pdf"
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button className="btn btn-azul" onClick={() => formik.handleSubmit()}>
          Guardar
        </button>
      </div>
      <Loader isLoading={loading} />
    </React.Fragment>
  );
};

export default UploadContract;
