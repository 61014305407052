import React, { useEffect } from "react";
import { useFormik } from "formik";
import FileUploader from "./FileUploader.tsx";
import S3Service from "../../services/s3.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { ca } from "date-fns/locale";

const EditLegacyFile = ({
  file,
  closeFn,
  partnerObject,
  refetch,
}: {
  file: any;
  closeFn: any;
  partnerObject: any;
  refetch: any;
}) => {
  const [files, setFiles] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState(false);

  const name = file.files[0]?.id || file.name;
  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      setLoading(true);
      try {
        //Si es uno o si son varios, si es uno solo se sube con el nombre original, si son varios se suben con el nombre original y un número al final
        if (file.files.length === 0) {
          const curp = partnerObject.person.curp;

          await Promise.all(files.map(async (newFile, index) => {
            if (index === 0) {
              let newName;
              switch (file.typeFile) {
                case "Original solicitud de empleo":
                  newName = `SOLICITUD_${curp}`;
                  break;
                case "INE frente y vuelta":
                  newName = `INE_${curp}`;
                  break;
                case "Comprobante de domicilio actual":
                  newName = `DOMICILIO_${curp}`;
                  break;
                case "Foto colaborador":
                  newName = `PERFIL_${curp}`;
                  break;
                case "Pruebas psicométricas aplicadas por reclutamiento":
                  newName = `PSICOMETRICO_${curp}`;
                  break;
                default:
                  break;
              }
              await S3Service.upload(newFile, newName);
            } else {
              let newName;
              switch (file.typeFile) {
                //Solicitud, ine, domicilio, perfil, psicometric
                case "Original solicitud de empleo":
                  newName = `SOLICITUD_${curp}_${index}`;
                  break;
                case "INE frente y vuelta":
                  newName = `INE_${curp}_${index}`;
                  break;
                case "Comprobante de domicilio actual":
                  newName = `DOMICILIO_${curp}_${index}`;
                  break;
                case "Foto colaborador":
                  newName = `PERFIL_${curp}_${index}`;
                  break;
                case "Pruebas psicométricas aplicadas por reclutamiento":
                  newName = `PSICOMETRICO_${curp}_${index}`;
                  break;
                default:
                  break;
              }
              await S3Service.upload(newFile, newName);
            }
          })).then(async () => {
            await refetch(name);
          })
        } else {
          if (files.length > 2) {
            const firstChildName = files[1].id
            const initialIndex = Number(firstChildName.charAt(firstChildName.length - 1));
            Promise.all(files.map(async (newFile, index) => {
              const newName = name + `_${initialIndex + index}`
              if (newFile.url) return;
              await S3Service.upload(newFile, newName)
            })).then(async () => {
              await refetch(name);
            })
          } else {
            Promise.all(files.map(async (newFile, index) => {
              const newName = name + `_${index}`
              if (newFile.url) return;
              await S3Service.upload(newFile, newName)
            })).then(async () => {
              await refetch(name);
            })
          }
        }
        notification(
          "Archivo subido",
          "El archivo ha sido subido",
          "success"
        );
        setLoading(false);
      } catch (error) {
        console.error("Error uploading file", error);
        setLoading(false);
        notification(
          "Error",
          "Ocurrió un error al subir el archivo",
          "danger"
        );
      } finally {
        await closeFn();
      }

    },
  });

  useEffect(() => {
    setFiles(file);
  }, [file]);

  return (
    <React.Fragment>
      <FileUploader
        onFilesSelected={(files) => setFiles(files)}
        legacyFiles={file}
        closeFn={closeFn}
        refetch={(name) => refetch(name)}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          disabled={!files || loading}
          className="btn btn-azul"
          onClick={() => formik.handleSubmit()}
          type="submit"
        >
          Subir
        </button>
      </div>
    </React.Fragment>
  );
};

export default EditLegacyFile;
